import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
    namespaced: true,
    state() {
        return {
            searchString: "",
            category: "",
            cart: JSON.parse(localStorage.getItem("cart")) || [],
            shipping_address:
                JSON.parse(localStorage.getItem("shipping_address")) || {},
            client: JSON.parse(localStorage.getItem("client")) || {},
            mobileToCreate: "",
            payment: JSON.parse(localStorage.getItem('payment')) || {}
        };
    },
    mutations,
    actions,
    getters,
};
