<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("updateCompany") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("view") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitCompanyHandler">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("name") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('name') && form.name === '',
                      }"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      :placeholder="$t('name')"
                    />
                  </div>
                  <p
                    v-if="findFieldinFeedback('name') && form.name === ''"
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("industry") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('industry') &&
                          form.industry === '',
                      }"
                      v-model="form.industry"
                      type="text"
                      class="form-control"
                      :placeholder="$t('industry')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('industry') && form.industry === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("mobile") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('mobile') && form.mobile === '',
                      }"
                      v-model="form.mobile"
                      type="text"
                      class="form-control"
                      :placeholder="$t('mobile')"
                    />
                  </div>
                  <p
                    v-if="findFieldinFeedback('mobile') && form.mobile === ''"
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                  <p
                    v-if="
                      findFieldinFeedback('mobile') &&
                      (form.mobile.length !== 9 || form.mobile.length !== 10)
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldShouldHave9or10Characters") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("fax") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('fax') && form.fax === '',
                      }"
                      v-model="form.fax"
                      type="text"
                      class="form-control"
                      :placeholder="$t('fax')"
                    />
                  </div>
                  <p
                    v-if="findFieldinFeedback('fax') && form.fax === ''"
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                  <p
                    v-if="
                      findFieldinFeedback('fax') &&
                      (form.fax.length !== 9 || form.fax.length !== 10)
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldShouldHave9or10Characters") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("email") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('email') && form.email === '',
                      }"
                      v-model="form.email"
                      type="email"
                      class="form-control"
                      :placeholder="$t('email')"
                    />
                  </div>
                  <p
                    v-if="findFieldinFeedback('email') && form.email === ''"
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("currency") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <prime-dropdown
                      v-model="form.currency_id"
                      :options="currencies"
                      optionLabel="code"
                      optionValue="id"
                      :filter="true"
                      :placeholder="$t('selectCurrency')"
                      :class="{
                        'border-red':
                          findFieldinFeedback('currency_id') &&
                          form.currency_id === '',
                      }"
                    >
                      <template #label="slotProps">
                        <div
                          class="country-item country-item-value"
                          v-if="slotProps.label"
                        >
                          <div>{{ slotProps }}</div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="country-item">
                          <div>{{ slotProps.option.code }}</div>
                        </div>
                      </template>
                    </prime-dropdown>
                    <p
                      v-if="
                        findFieldinFeedback('currency_id') &&
                        form.currency_id === ''
                      "
                      class="text-danger"
                    >
                      {{ $t("currencyCannotBeBlank") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("address") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      id="company-address-autocomplete"
                      :class="{
                        'border-red':
                          findFieldinFeedback('address') && form.address === '',
                      }"
                      v-model.trim="form.address"
                      type="text"
                      class="form-control"
                      :placeholder="$t('address')"
                    />
                  </div>
                  <p
                    v-if="findFieldinFeedback('address') && form.address === ''"
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("zipCode") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      id="billing_postal_code"
                      v-model.trim="form.postal_code"
                      :class="{
                        'border-red':
                          findFieldinFeedback('postal_code') &&
                          form.postal_code === '',
                      }"
                      type="text"
                      class="form-control"
                      :placeholder="$t('zipCode')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('postal_code') &&
                      form.postal_code === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("city") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      id="billing_locality"
                      v-model.trim="form.locality"
                      :class="{
                        'border-red':
                          findFieldinFeedback('locality') &&
                          form.locality === '',
                      }"
                      type="text"
                      class="form-control"
                      :placeholder="$t('city')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('locality') && form.locality === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">State<span>*</span></label>
                  <div class="input-group">
                    <input
                      id="billing_administrative_area_level_1"
                      :class="{
                        'border-red':
                          findFieldinFeedback('administrative_area_level_1') &&
                          form.administrative_area_level_1 === '',
                      }"
                      v-model.trim="form.administrative_area_level_1"
                      type="text"
                      class="form-control"
                      placeholder="State"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('administrative_area_level_1') &&
                      form.administrative_area_level_1 === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("country") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      id="billing_country"
                      :class="{
                        'border-red':
                          findFieldinFeedback('country') && form.country === '',
                      }"
                      v-model.trim="form.country"
                      type="text"
                      class="form-control"
                      :placeholder="$t('country')"
                    />
                  </div>
                  <p
                    v-if="findFieldinFeedback('country') && form.country === ''"
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :class="{
                    'btn-loading': is_sending,
                  }"
                  :disabled="is_sending"
                >
                  {{ $t("update") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../../../mixins/showError";
import setFeedbackMixin from "../../../../mixins/setFeedback";
import notifyMixin from "../../../../mixins/notify";
import checkToken from "../../../../mixins/checkToken";
// import _ from "lodash";

export default {
  mixins: [showErrorMixin, setFeedbackMixin, notifyMixin, checkToken],
  data() {
    return {
      feedback: [],
      form: {
        name: "",
        industry: "",
        mobile: "",
        fax: "",
        email: "",
        currency_id: "",
        address: "",
        postal_code: "",
        locality: "",
        administrative_area_level_1: "",
        country: "",
      },
      is_sending: false,
    };
  },
  created() {
    this.getCompany();
    this.loadCurrencies();
  },
  mounted() {
    let addressScript = document.getElementById("address-script");
    if (addressScript !== null) {
      addressScript.remove();
    }
    if (addressScript === null) {
      this.loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCuX-hAjtq9BiF4vZywT-dvUQaHWbEVd6Q&libraries=places"
      )
        .then(() => {
          this.initAutocomplete();
        })
        .catch(() => {
          console.error("Script loading failed!");
        });
    } else {
      this.initAutocomplete();
    }
  },
  computed: {
    currencies() {
      return this.$store.getters["currencies/currencies"];
    },
    industries() {
      return this.$store.getters["masters/industry"];
    },
    countries() {
      return this.$store.getters["countries/countries"];
    },
  },
  methods: {
    async loadCurrencies() {
      try {
        const response = await this.$store.dispatch("currencies/getCurrencies");
        if (response.status === 200) {
          this.$store.dispatch(
            "currencies/setCurrencies",
            response.data.currencies
          );
          this.loading = false;
        }
      } catch (err) {
        console.log("error");
        this.loading = false;
      }
    },
    async getCompany() {
      try {
        const response = await this.$store.dispatch(
          "companies/getUserCompany",
          {
            id: this.id,
          }
        );
        this.loading = false;
        if (response.status === 200) {
          let company = response.data;
          console.log(company);
          if (Object.keys(company).length > 0) {
            this.setUpForm(company);
          }
        }
      } catch (err) {
        console.log("error");
        console.log(err);
        this.loading = false;
      }
    },

    setUpForm(company) {
      this.clearForm();
      this.form.name = company.name;
      this.form.industry = company.industry;
      this.form.mobile = company.mobile;
      this.form.fax = company.fax;
      this.form.email = company.email;
      this.form.currency_id = company.currency.id;
      this.form.address = company.address;
      this.form.postal_code = company.zip_code;
      this.form.locality = company.city;
      this.form.administrative_area_level_1 = company.state;
      this.form.country = company.name;
    },

    clearForm() {
      this.form.name = "";
      this.form.industry = "";
      this.form.mobile = "";
      this.form.fax = "";
      this.form.email = "";
      this.form.currency_id = "";
      this.form.address = "";
      this.form.postal_code = "";
      this.form.locality = "";
      this.form.administrative_area_level_1 = "";
      this.form.country = "";
    },

    async submitCompanyHandler() {
      if (!this.validate()) {
        return;
      }

      this.is_sending = true;

      try {
        const response = await this.$store.dispatch(
          "companies/updateUserCompany",
          this.form
        );
        this.is_sending = false;
        if (response.status === 200) {
          this.$store.dispatch("setUser", {
            user: response.data,
          });
          this.$router.replace("/profile");
        }
      } catch (err) {
        this.is_sending = false;
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    validate() {
      this.feedback = [];
      if (this.form.name === "") {
        this.setFeedback("error", "name");
      }
      if (this.form.industry === "") {
        this.setFeedback("error", "industry");
      }
      if (
        this.form.mobile === "" ||
        (this.form.mobile.length !== 9 && this.form.mobile.length !== 10)
      ) {
        this.setFeedback("error", "mobile");
      }
      if (
        this.form.mobile === "" ||
        (this.form.fax.length !== 9 && this.form.fax.length !== 10)
      ) {
        this.setFeedback("error", "fax");
      }
      if (this.form.email === "") {
        this.setFeedback("error", "email");
      }
      if (this.form.currency_id === "") {
        this.setFeedback("error", "currency_id");
      }
      if (this.form.address === "") {
        this.setFeedback("error", "address");
      }
      if (this.form.postal_code === "") {
        this.setFeedback("error", "postal_code");
      }
      if (this.form.locality === "") {
        this.setFeedback("error", "locality");
      }
      if (this.form.administrative_area_level_1 === "") {
        this.setFeedback("error", "administrative_area_level_1");
      }
      if (this.form.country === "") {
        this.setFeedback("error", "country");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },

    initAutocomplete() {
      // const billing_field_id = "company-address-autocomplete";
      // const billing_field = document.getElementById(billing_field_id);

      // if (!billing_field) {
      //   return;
      // }

      // const autocomplete_billing = new google.maps.places.Autocomplete(
      //   billing_field,
      //   {
      //     types: ["geocode"],
      //   }
      // );
      // autocomplete_billing.addListener(
      //   "place_changed",
      //   this.fillInBillingAddress
      // );
    },

    fillInBillingAddress() {
      // var place = autocomplete_billing.getPlace();
      // this.form.address = place.formatted_address;

      // this.clearBillingAddress();

      // for (var i = 0; i < place.address_components.length; i++) {
      //   var addressType = place.address_components[i].types[0];
      //   if (componentForm[addressType]) {
      //     var val = place.address_components[i][componentForm[addressType]];
      //     this.form[addressType] = val;
      //   }
      // }
    },
    clearBillingAddress() {
      this.form.locality = "";
      this.form.administrative_area_level_1 = "";
      this.form.country = "";
      this.form.postal_code = "";
    },
    loadScript(scriptUrl) {
      const script = document.createElement("script");
      script.src = scriptUrl;
      script.id = "address-script";
      document.body.appendChild(script);

      return new Promise((res, rej) => {
        script.onload = function () {
          res();
        };
        script.onerror = function () {
          rej();
        };
      });
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}
</style>