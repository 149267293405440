<template>
  <div class="modal-div">
    <div @click="$emit('close')" class="modal-div"></div>
    <dialog class="dialog" open>
      <div class="modal d-block pos-static">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">{{ title }}</h6>
            <button
              @click="$emit('close')"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label"
                      >{{ $t("denyReason") }}<span>*</span></label
                    >
                    <div class="input-group">
                      <textarea
                        class="form-control"
                        v-model="denyReason"
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                    <p
                      v-if="
                        findFieldinFeedback('denyReason') && denyReason === ''
                      "
                      class="text-danger"
                    >
                      {{ $t("denyReasonCannotBeBlank") }}
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-indigo" type="button" @click="sendReason">
              {{ $t("submit") }}
            </button>
            <button class="btn btn-light" type="button" @click="$emit('close')">
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
import notify from "../../mixins/notify";
import setFeedback from "../../mixins/setFeedback";
import showError from "../../mixins/showError";
export default {
  emits: ["close", "reason"],
  props: ["title"],
  mixins: [setFeedback, showError, notify],
  data() {
    return {
      denyReason: "",
      feedback: [],
    };
  },
  methods: {
    async sendReason() {
      this.feedback = [];
      if (this.status === "") {
        this.setFeedback("error", "denyReason");
      }
      if (this.feedback.length !== 0) {
        return;
      }
      this.$emit("reason", this.denyReason);
    },
  },
};
</script>

<style scoped>
.modal-div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10000;
}

.dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 10001;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  .dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>