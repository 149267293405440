<template>
  <div class="page-header" v-if="Object.keys(user).length > 0">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("userDetails") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/users">
            <a>{{ $t("users") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("view") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="Object.keys(user).length > 0 && !loading">
    <div class="col-xl-4 col-lg-4 col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="dropdown user-pro-body text-center">
            <div class="user-pic">
              <img
                :src="imgLink"
                alt="user-img"
                class="avatar-xl rounded-circle mb-1"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("name") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    {{ user.first_name }} {{ user.last_name }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50">{{
                      $t("userName")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">{{ user.username }}</td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50">{{
                      $t("phone")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                    <a :href="getPhoneLink(user.phone)" target="_blank">{{
                      user.phone
                    }}</a>
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("email") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    <a :href="getMailLink(user.email)" target="_blank">{{
                      user.email
                    }}</a>
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("role") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    {{ user.role ? user.role.name : "" }}
                  </td>
                </tr>
                <tr v-if="user.credit">
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50">{{
                      $t("credit")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                     <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span> {{ user.credit ? user.credit : "" }}
                  </td>
                </tr>
                <!-- <tr v-if="user.credit_limit_start_date">
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50">{{
                      $t("creditLimitStartDate")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                    $
                    {{
                      user.credit_limit_start_date
                        ? user.credit_limit_start_date
                        : ""
                    }}
                  </td>
                </tr>
                <tr v-if="user.credit_limit_end_date">
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50">{{
                      $t("creditLimitEndDate")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                    $
                    {{
                      user.credit_limit_end_date
                        ? user.credit_limit_end_date
                        : ""
                    }}
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12">
      <div class="main-content-body main-content-body-profile card mg-b-20">
        <div class="main-profile-body">
          <div class="card-header">
            <div class="card-options">
              <div class="btn-group">
                <router-link to="/users/create">
                  <a class="btn btn-sm btn-primary mr-2">
                    {{ $t("addNew") }}
                  </a>
                </router-link>
                <router-link :to="`/users/${user.id}/edit`">
                  <a class="btn btn-sm btn-primary">
                    <i
                      class="fa fa-pencil pointer"
                      data-toggle="tooltip"
                      title="fa fa-trash"
                    ></i>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="panel panel-primary">
              <div class="tab-menu-heading p-0 bg-light">
                <div class="tabs-menu1">
                  <ul class="nav panel-tabs">
                    <li class="">
                      <a href="#orders-tab" class="active" data-toggle="tab">
                        {{ $t("clients") }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="panel-body tabs-menu-body">
                <div class="tab-content">
                  <div class="tab-pane active" id="orders-tab">
                    <div class="table-responsive">
                      <div
                        id="example1_wrapper"
                        class="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div class="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="example1_length">
                              <label>
                                <select
                                  v-model="tableData.length"
                                  @change="getClients()"
                                  name="example1_length"
                                  aria-controls="example1"
                                  class="
                                    custom-select custom-select-sm
                                    form-control form-control-sm
                                  "
                                >
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div id="example1_filter" class="dataTables_filter">
                              <label
                                ><input
                                  type="search"
                                  v-model="tableData.search"
                                  @input="search()"
                                  class="form-control form-control-sm"
                                  :placeholder="$t('search')"
                                  aria-controls="example1"
                              /></label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <datatable
                              :columns="columns"
                              :sortKey="sortKey"
                              :sortOrders="sortOrders"
                              @sort="sortBy"
                            >
                              <tbody>
                                <tr
                                  role="row"
                                  class="odd"
                                  v-for="client in clients"
                                  :key="client.id"
                                >
                                  <td>
                                    <router-link :to="`/clients/${client.id}`">
                                      <a
                                        class="
                                          text-primary
                                          font-weight-bold
                                          fs-12
                                        "
                                        >{{ client.first_name }}
                                        {{ client.last_name }}</a
                                      >
                                    </router-link>
                                  </td>
                                  <td>
                                    {{ client.mobile }}
                                  </td>
                                  <td>
                                    {{ client.email }}
                                  </td>
                                  <td>
                                    <span
                                      :class="{
                                        'badge-success': client.status === 1,
                                        'badge-danger': client.status === 0,
                                      }"
                                      class="badge"
                                    >
                                      {{ fixStatus(client.status) }}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </datatable>
                          </div>
                        </div>
                        <div class="row">
                          <pagination
                            :pagination="pagination"
                            @prev="getClients(pagination.prevPageUrl)"
                            @next="getClients(pagination.nextPageUrl)"
                          >
                          </pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkToken from "../../mixins/checkToken";
import fixStatus from "../../mixins/fixStatus";
import contact from "../../mixins/contact";
import catchForbiddenError from "../../mixins/catchForbiddenError";
import showError from "../../mixins/showError";
import _ from "lodash";
export default {
  mixins: [checkToken, fixStatus, contact, catchForbiddenError, showError],
  props: ["id"],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("name"),
        name: "first_name",
        sortable: true,
      },
      {
        label: this.$t("mobile"),
        name: "mobile",
        sortable: true,
      },
      {
        label: this.$t("email"),
        name: "email",
        sortable: true,
      },
      {
        label: this.$t("status"),
        name: "status",
        sortable: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.name = 1;
    return {
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "name",
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        user_id: null,
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      clients: [],
      user: {},
      loading: true,
    };
  },
  created() {
    this.tableData.user_id = this.id;
    this.getUser();
    this.getClients();
  },
  mounted() {
    if (Object.keys(this.user).length > 0) {
      this.resetActiveClass();
    }
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    users() {
      return this.$store.getters["users/users"];
    },
    loggedUser() {
      return this.$store.getters.user;
    },
    imgLink() {
      if (this.user.photo) {
        return `${this.$baseURL}storage/${this.user.photo.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
  },
  methods: {
    resetActiveClass() {
      // Reset the active class of the tab links to be the first one
      const panelTabs = document.querySelector(".nav.panel-tabs");
      const listItems = panelTabs.getElementsByTagName("li");

      for (let i = 0; i <= listItems.length - 1; i++) {
        let item = listItems[i];
        const link = item.querySelector("a");
        if (i === 0) {
          if (!link.classList.contains("active")) {
            link.classList.add("active");
          }
        } else {
          if (link.classList.contains("active")) {
            link.classList.remove("active");
          }
        }
      }
    },
    search: _.debounce(function () {
      this.getClients();
    }, 500),
    sortBy(key) {
      this.sortKey = key; // Name of collumn being sorted
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getClients();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },

    getClients(url = "/api/v1/paginated-clients") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          this.loading = false;
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.clients = data.clients.data;
            this.configPagination(data.clients);
          }
        })
        .catch(() => {
          console.log("Error");
          this.loading = false;
        });
    },
    async getUser() {
      try {
        const response = await this.$store.dispatch("users/getUser", {
          id: this.id,
        });
        if (response.status === 200) {
          this.user = response.data;
        }
      } catch (err) {
        console.log("error");
        this.catchForbiddenError(err);
      }
    },
  },
};
</script>

<style scoped>
</style>