<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("categories") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/categories">
            <a>{{ $t("categories") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("create") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" id="lead-div" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("categoriesDetails") }}</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">{{ $t("parent") }}</label>
                  <div class="input-group">
                    <prime-dropdown v-model="form.parent_id" :options="parents" optionLabel="full_name" optionValue="id"
                      :filter="true" :placeholder="$t('selectParent')">
                      <template #label="slotProps">
                        <div class="country-item country-item-value" v-if="slotProps.label">
                          <div>{{ slotProps }}</div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="country-item">
                          <div>{{ slotProps.option.full_name }}</div>
                        </div>
                      </template>
                    </prime-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">{{ $t("name") }}<span>*</span></label>
                  <div class="input-group">
                    <input :class="{
                      'border-red':
                        findFieldinFeedback('name') && form.name === '',
                    }" v-model="form.name" type="text" class="form-control" :placeholder="$t('name')" />
                  </div>
                  <p v-if="findFieldinFeedback('name') && form.name == ''" class="text-danger d-block">
                    {{ $t("nameCannotBeBlank") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">{{ $t("code") }} <span>*</span></label>
                  <div class="input-group">
                    <input :class="{
                      'border-red':
                        findFieldinFeedback('code') && form.code === '',
                    }" v-model="form.code" type="text" class="form-control" :placeholder="$t('code')" />
                  </div>
                  <p v-if="
                    findFieldinFeedback('code') &&
                    (form.code === '' || form.code.length > 10)
                  " class="text-danger">
                    {{ $t("codeCannotBeBlankOrMoreThan10Characters") }}
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"></label>
                  <div class="input-group">
                    <label class="custom-switch">
                      <input v-model="has_attribute" type="checkbox" name="custom-switch-checkbox"
                        class="custom-switch-input" />
                      <span class="custom-switch-indicator"></span>
                      <span class="custom-switch-description">{{
                          $t("hasAttributes")
                      }}</span>
                    </label>
                    <p v-if="
                      feedback.field === 'has_attribute' &&
                      has_attribute === null
                    " class="text-danger">
                      {{ feedback.message }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-for="attribute in form.attributes" :key="attribute">
              <div :class="{
                'col-4': attribute.unit != '',
                'col-6': attribute.unit === '',
              }">
                <div class="form-group">
                  <label class="form-label">{{ $t("attributes") }}<span>*</span></label>
                  <div class="input-group">
                    <select class="form-control" @change="changeAttribute($event, attribute, 'attribute')">
                      <option v-for="single in attributeTypes" :key="single.id" :value="single.id"
                        :selected="single.id === attribute.id">
                        {{ single.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-4" v-if="attribute.unit != ''">
                <div class="form-group">
                  <label class="form-label">{{ $t("unit") }}</label>
                  <div class="input-group">
                    <select class="form-control" @change="changeAttribute($event, attribute, 'unit')"
                      :disabled="getAttributeUnits(attribute.id).length === 0">
                      <option v-for="single in getAttributeUnits(attribute.id)" :key="single.id" :value="single.id"
                        :selected="single.id === attribute.unit">
                        {{ single.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div :class="{
                'col-4': attribute.unit != '',
                'col-6': attribute.unit === '',
              }">
                <div class="form-group">
                  <label class="form-label">{{ $t("value") }}<span>*</span></label>
                  <div class="row">
                    <div class="col">
                      <prime-color-picker v-if="isColorAttribute(attribute)" :defaultColor="
                        attribute.value.substr(attribute.value.length - 6)
                      " @focusin="setUpColorField(attribute)" v-model="colors[attribute.value]"></prime-color-picker>
                      <input v-else @change="changeAttribute($event, attribute, 'value')" type="text"
                        class="form-control" :placeholder="$t('value')" :value="attribute.value" />
                    </div>
                    <span class="col-auto">
                      <button class="btn btn-sm btn-light" type="button" @click="removeAttribute(attribute)">
                        <i class="fe fe-minus"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="has_attribute">
              <div :class="{
                'col-4': units.length > 0,
                'col-6': units.length === 0,
              }">
                <div class="form-group">
                  <label class="form-label">{{ $t("attribute") }}<span>*</span></label>
                  <div class="input-group">
                    <prime-dropdown v-model="attribute.id" :options="attributeTypes" optionLabel="name" optionValue="id"
                      :filter="true" :placeholder="$t('selectAttribute')" :class="{
                        'border-red':
                          findFieldinFeedback('attribute_id') &&
                          attribute.id === '',
                      }">
                      <template #label="slotProps">
                        <div class="country-item country-item-value" v-if="slotProps.label">
                          <div>{{ slotProps }}</div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="country-item">
                          <div>{{ slotProps.option.name }}</div>
                        </div>
                      </template>
                    </prime-dropdown>
                    <p v-if="
                      findFieldinFeedback('attribute_id') &&
                      attribute.id === ''
                    " class="text-danger d-block">
                      {{ $t("attributeTypeCannotBeBlank") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-4" v-if="units.length > 0">
                <div class="form-group">
                  <label class="form-label">{{ $t("unit") }}</label>
                  <div class="input-group">
                    <prime-dropdown v-model="attribute.unit" :options="units" optionLabel="name" optionValue="id"
                      :filter="true" :disabled="units.length === 0" :placeholder="$t('selectUnit')" :class="{
                        'border-red':
                          findFieldinFeedback('unit') && attribute.unit === '',
                      }">
                      <template #label="slotProps">
                        <div class="country-item country-item-value" v-if="slotProps.label">
                          <div>{{ slotProps }}</div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="country-item">
                          <div>{{ slotProps.option.name }}</div>
                        </div>
                      </template>
                    </prime-dropdown>
                    <p v-if="
                      findFieldinFeedback('unit') && attribute.unit === ''
                    " class="text-danger d-block">
                      {{ $t("unitCannotBeBlank") }}
                    </p>
                  </div>
                </div>
              </div>
              <div :class="{
                'col-4': units.length > 0,
                'col-6': units.length === 0,
              }">
                <div class="form-group">
                  <label class="form-label">{{ $t("value") }}<span>*</span>
                  </label>
                  <div class="row">
                    <div class="col">
                      <prime-color-picker v-if="
                        activeAttribute &&
                        Object.keys(activeAttribute).length > 0 &&
                        isColor(activeAttribute.name)
                      " v-model="attribute.value"></prime-color-picker>
                      <input v-else v-model="attribute.value" type="text" class="form-control"
                        :placeholder="$t('value')" />
                      <p v-if="
                        feedback.field === 'value' && attribute.value === ''
                      " class="text-danger">
                        {{ feedback.message }}
                      </p>
                    </div>
                    <span class="col-auto">
                      <button class="btn btn-sm btn-success" type="button" @click="addAttribute">
                        <i class="fe fe-plus"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button type="submit" class="pull-right btn btn-sm btn-primary">
                  {{ $t("submit") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import setFeedbackMixin from "../../mixins/setFeedback";
import notifyMixin from "../../mixins/notify";
import checkToken from "../../mixins/checkToken";
import _ from "lodash";

export default {
  mixins: [showErrorMixin, setFeedbackMixin, notifyMixin, checkToken],
  data() {
    return {
      attribute: {
        id: "",
        unit: "",
        value: "",
      },
      units: [],
      has_attribute: false,
      feedback: [],
      form: {
        id: null,
        parent_id: "",
        name: "",
        code: "",
        attributes: [],
      },
      loading: true,
      colors: {},
      color: "",
      selectedAttribute: {},
    };
  },
  created() {
    this.loadCategories();
    this.loadAttributeTypes();
  },
  computed: {
    parents() {
      return this.$store.getters["categories/categories"];
    },
    attributeTypes() {
      return this.$store.getters["attributeTypes/attributeTypes"];
    },
    attributeId() {
      return this.attribute.id;
    },
    activeAttribute() {
      return this.attributeTypes.find((type) => {
        return type.id === parseInt(this.attribute.id);
      });
    },
  },
  watch: {
    colors: {
      handler(val) {
        this.handleColorChange(val);
      },
      deep: true,
    },
    activeAttribute: function (newValue) {
      if (newValue && this.isColor(newValue.name)) {
        this.attribute.value = "000000";
      } else {
        this.attribute.value = "";
      }
    },
    attributeId: function (newValue) {
      this.units = [];
      const selected = this.attributeTypes.find((type) => {
        return type.id === parseInt(newValue);
      });

      if (!!selected && selected.units.length > 0) {
        this.units = selected.units;
      }
    },
  },
  methods: {
    handleColorChange: _.debounce(function () {
      if (
        Object.keys(this.selectedAttribute).length > 0 &&
        this.color in this.colors &&
        this.colors[this.color].length === 6
      ) {
        this.changeColorAttribute(this.selectedAttribute);
      }
    }, 100),
    async loadCategories() {
      try {
        const response = await this.$store.dispatch("categories/getCategories");
        if (response.status === 200) {
          this.loading = false;
          this.$store.dispatch(
            "categories/setCategories",
            response.data.categories
          );
        }
      } catch (err) {
        console.log("Error");
        this.loading = false;
      }
    },

    async loadAttributeTypes() {
      try {
        const response = await this.$store.dispatch(
          "attributeTypes/getAttributeTypes"
        );
        if (response.status === 200) {
          this.$store.dispatch(
            "attributeTypes/setAttributeTypes",
            response.data.attribute_types
          );
          this.loading = false;
        }
      } catch (err) {
        console.log("Error");
        this.loading = false;
      }
    },

    addAttribute() {
      this.feedback = [];
      if (this.attribute.id === "") {
        this.setFeedback("error", "attribute_id");
        return false;
      }
      if (this.attribute.unit === "" && this.units.length > 0) {
        this.setFeedback("error", "unit");
        return false;
      }
      if (this.attribute.value.trim() === "") {
        this.setFeedback("error", "value");
        return false;
      }

      let attribute = {};
      attribute.id = this.attribute.id;
      attribute.unit = this.attribute.unit;
      attribute.value = this.attribute.value;

      // attribute.value = this.isColor(this.activeAttribute.name)
      //   ? `0xFF${this.attribute.value}`
      //   : this.attribute.value;

      this.form.attributes.push(attribute);

      this.attribute.id = "";
      this.attribute.unit = "";
      this.attribute.value = "";
    },
    changeAttribute(event, attribute, type) {
      const attribute_index = this.form.attributes.findIndex((att) => {
        return att.id === attribute.id && att.value === attribute.value;
      });

      if (type == "value" && event.target.value != "") {
        this.form.attributes[attribute_index].value = event.target.value;
      } else if (type == "value" && event.target.value.trim() === "") {
        event.target.value = this.form.attributes[attribute_index].value;
      }
      if (type == "attribute") {
        const attUnits = this.getAttributeUnits(event.target.value);
        if (attUnits.length > 0) {
          this.form.attributes[attribute_index].unit = attUnits[0].id; // Default unit selection is the first one when attribute is changed
        } else {
          this.form.attributes[attribute_index].unit = "";
        }
        this.form.attributes[attribute_index].id = parseInt(event.target.value);
      }
      if (type == "unit") {
        this.form.attributes[attribute_index].unit = parseInt(
          event.target.value
        );
      }
    },

    changeColorAttribute(attribute) {
      const attribute_index = this.form.attributes.findIndex((att) => {
        return att.id === attribute.id && att.value === attribute.value;
      });
      this.form.attributes[attribute_index].value = this.colors[attribute.value];

      // `0xFF${
      //   this.colors[attribute.value]
      // }`;
    },

    getAttributeUnits(id) {
      let units = [];
      const selected = this.attributeTypes.find((type) => {
        return type.id === parseInt(id);
      });

      if (!!selected && selected.units.length > 0) {
        units = selected.units;
        return units;
      }
      return [];
    },

    removeAttribute(attribute) {
      const attribute_index = this.form.attributes.findIndex((att) => {
        return att.id === attribute.id && att.value === attribute.value;
      });
      this.form.attributes.splice(attribute_index, 1);
    },
    async submit() {
      this.feedback = [];

      if (!this.has_attribute) {
        this.form.attributes = [];
      }

      if (!this.validate()) {
        return;
      }

      try {
        const response = await this.$store.dispatch(
          "categories/submitCategory",
          this.form
        );
        if (response.status === 201) {
          this.notify("Category Saved");
          this.$router.replace(`/categories/${response.data.id}`);
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
          console.log("Error");
        }
      }
    },
    validate() {
      if (this.form.name === "") {
        this.setFeedback("error", "name");
      }
      if (this.form.code === "" || this.form.code.length > 10) {
        this.setFeedback("error", "code");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },
    isColor(name) {
      return (
        name === "Color" ||
        name === "color" ||
        name === "colour" ||
        name === "Colour"
      );
    },
    isColorAttribute(attributeParam) {
      const attribute = this.attributeTypes.find((type) => {
        return type.id === parseInt(attributeParam.id);
      });

      if (
        attribute &&
        Object.keys(attribute).length > 0 &&
        this.isColor(attribute.name)
      ) {
        return true;
      }
      return false;
    },
    setUpColorField(attribute) {
      this.colors = {};
      this.color = attribute.value;
      this.colors[attribute.value] = attribute.value;
      this.selectedAttribute = attribute;
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}
</style>