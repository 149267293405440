export default {
    data() {
        return {
            feedback: {
                alert_type: null,
                field: null,
                message: null,
            },
            validation_errors: {},
        };
    },
    methods: {
        showError(error) {
            this.$swal.fire({
                title: "Error",
                icon: "error",
                text: error,
            });
        },
    },
};
