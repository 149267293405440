<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("shoppingCart") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/products-catalog">
            <a class="d-flex">{{ $t("productsCatalog") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("cart") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 bootstrap snippets">
      <div class="card">
        <div class="card-header"></div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered text-nowrap border-top">
              <thead class="">
                <tr>
                  <th>{{ $t("productName") }}</th>
                  <th>{{ $t("quantity") }}</th>
                  <th>{{ $t("unitPrice") }}</th>
                  <th>{{ $t("subTotal") }}</th>
                  <th class="w-5"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in cart" :key="product.id">
                  <td>
                    <div class="d-flex w-200">
                      <div>
                        <img
                          :src="getFirstImageLink(product)"
                          alt="img"
                          title=""
                          class="w-7 h-7"
                        />
                      </div>
                      <h6 class="mb-0 mt-4 font-weight-bold ml-4">
                        <p>{{ displayName(product.name) }}</p>
                        <p>
                          <small
                            class="d-block"
                            v-for="attributeID in product.selectedAttributes"
                            :key="attributeID"
                          >
                            <span
                              v-if="
                                getAttributeDetails(attributeID, product)
                                  .name === 'Color' ||
                                getAttributeDetails(attributeID, product)
                                  .name === 'color' ||
                                getAttributeDetails(attributeID, product)
                                  .name === 'Colour' ||
                                getAttributeDetails(attributeID, product)
                                  .name === 'colour'
                              "
                            >
                              <span style="padding-right: 5px"
                                >{{
                                  getAttributeDetails(attributeID, product)
                                    .name
                                }}:
                              </span>
                              <span :style="getStyle(attributeID, product)">
                              </span>
                            </span>
                            <span v-else>
                              {{
                                getAttributeDetails(attributeID, product).name
                              }}:
                              {{
                                getAttributeDetails(attributeID, product).value
                              }}
                            </span>
                          </small>
                        </p>
                      </h6>
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control w-50"
                      :value="product.amountInCart"
                      @input="changeAmountInCart($event, product)"
                    />
                  </td>
                  <td class="price text-right">
                     <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>

                    {{ product.price }}
                  </td>
                  <td class="text-right">
                   <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                    {{ getTotalPrice(product) }}
                  </td>
                  <td class="text-center">
                    <a
                      @click="removeFromCart(product)"
                      class="remove_cart"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Remove"
                    >
                      <svg
                        class="svg-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                        <path d="M8 9h8v10H8z" opacity=".3"></path>
                        <path
                          d="M15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"
                        ></path>
                      </svg>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">{{ $t("total") }}</td>
                  <td
                    class="total h4 mb-0 font-weight-bold text-right"
                    colspan="1"
                  >
                    <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                    {{ cartTotal.toFixed(2) }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="float-left mt-2"></div>
          <div class="float-right">
            <div class="row">
              <div class="col-6">
                <a @click="checkoutHandler" class="btn btn-secondary"
                  >{{ $t("checkout") }} <i class="fe fe-arrow-right"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import fixStatus from "../../mixins/fixStatus";
import checkToken from "../../mixins/checkToken";
import setFeedbackMixin from "../../mixins/setFeedback";
import displayNameMixin from "../../mixins/displayNameMixin";
export default {
  mixins: [
    showErrorMixin,
    notifyMixin,
    checkToken,
    fixStatus,
    setFeedbackMixin,
    displayNameMixin,
  ],
  data() {
    return {
      client_id: "",
      feedback: [],
    };
  },
  created() {
    this.loadClients();
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },

    token() {
      return this.$store.getters.token;
    },
    cart() {
      return this.$store.getters["catalog/cart"];
    },
    clients() {
      return this.$store.getters["clients/clients"];
    },
    cartTotal() {
      return this.$store.getters["catalog/cartTotal"];
    },
  },
  methods: {
    async loadClients() {
      try {
        const response = await this.$store.dispatch("clients/getClients");
        if (response.status === 200) {
          this.$store.dispatch("clients/setClients", response.data.clients);
        }
      } catch (err) {
        console.log("Error");
      }
    },
    getFirstImageLink(product) {
      if (product.photos && product.photos.length > 0) {
        return `${this.$baseURL}storage/${product.photos[0].file_path}`;
      }
      return "/images/no-image.png";
    },
    removeFromCart(product) {
      this.$store.dispatch("catalog/removeFromCart", product);
    },
    getTotalPrice(product) {
      return product.amountInCart * product.price;
    },
    changeAmountInCart(event, product) {
      const quantity = event.target.value;
      if (quantity < 1 || quantity > product.quantity) {
        this.showError(
          this.$t("pleaseChooseAValueBetween1And") + " " + product.quantity
        );
        event.target.value = product.amountInCart;
        return;
      }
      const newProductQuantity = {
        product: product,
        newQuantity: quantity,
      };
      this.$store.dispatch("catalog/changeAmountInCart", newProductQuantity);
    },

    getAttributeDetails(attributeID, product) {
      if (product.attributes.length === 0) {
        return "";
      }
      const attribute = product.attributes.find((att) => {
        return att.pivot.id === attributeID;
      });

      const productAttribute = {
        name: attribute.name,
        value: `${attribute.pivot.value}${
          attribute.pivot.unit_name !== null ? attribute.pivot.unit_name : ""
        }`,
      };
      return productAttribute;
    },
    checkoutHandler() {
      this.$router.replace({
        name: "orderPreview",
      });
    },
    getHexCode(attribute) {
      var formatedColorCode = attribute.value.substr(
        attribute.value.length - 6
      );
      return formatedColorCode;
    },
    getStyle(attributeID, product) {
      if (product.attributes.length === 0) {
        return "";
      }
      const attribute = product.attributes.find((att) => {
        return att.pivot.id === attributeID;
      });

      return {
        background: `#${this.getHexCode(attribute.pivot)}`,
        height: "10px",
        width: "30px",
        display: "inline-block",
      };
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}
.table-no-border {
  background: white;
}
.tb-no-border {
  border: 0px;
}
</style>