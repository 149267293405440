import Auth from "./components/auth/Login.vue";
import ChangePasswordOTP from "./components/auth/ChangePasswordOTP.vue";
import Forgot from "./components/auth/ForgotPassword.vue";
import ChangePassword from "./components/auth/ChangePassword.vue";
import Dashboard from "./components/Dashboard.vue";
import CurrenciesIndex from "./components/currencies/Index.vue";
import UserRoles from "./components/access/roles/Index.vue";
import RolePermissions from "./components/access/permissions/Index.vue";
import Users from "./components/users/Index.vue";
import UsersDeposit from "./components/users/Deposit.vue";
import UsersUpdateCredit from "./components/users/UpdateCredit.vue";
import UsersCreate from "./components/users/Create.vue";
import UsersEdit from "./components/users/Edit.vue";
import UsersShow from "./components/users/Show.vue";
import Profile from "./components/users/profile/Show.vue";
import ProfileEdit from "./components/users/profile/Edit.vue";
import UsersCompanyEdit from "./components/users/profile/companies/Edit.vue";
import CategoriesIndex from "./components/categories/Index.vue";
import CategoriesCreate from "./components/categories/Create.vue";
import CategoriesShow from "./components/categories/Show.vue";
import CategoriesEdit from "./components/categories/Edit.vue";
import ProductsIndex from "./components/products/Index.vue";
import ProductsCreate from "./components/products/Create.vue";
import ProductsShow from "./components/products/Show.vue";
import ProductsEdit from "./components/products/Edit.vue";
import PagesIndex from "./components/pages/Index.vue";
import PagesCreate from "./components/pages/Create.vue";
import PagesEdit from "./components/pages/Edit.vue";
import CatalogIndex from "./components/products_catalog/Index.vue";
import CatalogCart from "./components/products_catalog/Cart.vue";
import CatalogPreview from "./components/products_catalog/Preview.vue";
import CatalogInvoice from "./components/products_catalog/Invoice.vue";
import CatalogPayment from "./components/products_catalog/Payment.vue";
import UpdateOrder from "./components/products_catalog/UpdateOrder.vue";
import InvoicesIndex from "./components/orders/InvoicesIndex.vue";
import OrdersIndex from "./components/orders/OrdersIndex.vue";
import OrdersByDebt from "./components/orders/OrdersByDebt.vue";
import CustomersByDebt from "./components/orders/CustomersByDebt.vue";
import PendingPayments from "./components/orders/PendingPayments.vue";
import ClientsIndex from "./components/clients/Index.vue";
import ClientsCreate from "./components/clients/Create.vue";
import ClientsShow from "./components/clients/Show.vue";
import ClientsEdit from "./components/clients/Edit.vue";
import SalesReport from "./components/orders/reports/Sales.vue";
import CreditSpendingSearch from "./components/orders/reports/CreditSpendingSearch.vue";
import CollectionReport from "./components/orders/reports/Collection.vue";
import BrandsIndex from "./components/brands/Index.vue";
import AttributeTypeIndex from "./components/attribute_types/Index.vue";
import BlogsIndex from "./components/blogs/Index.vue";
import BlogsCreate from "./components/blogs/Create.vue";
import BlogsEdit from "./components/blogs/Edit.vue";
import BlogCategoriesIndex from "./components/blogs/categories/Index.vue";
import BlogCommentsIndex from "./components/blogs/comments/Index.vue";
import JobPostingsIndex from "./components/job-postings/Index.vue";
import JobPostingsCreate from "./components/job-postings/Create.vue";
import JobPostingsEdit from "./components/job-postings/Edit.vue";
import JobApplicationsIndex from "./components/job-applications/Index.vue";
import JobApplicationsShow from "./components/job-applications/Show.vue";
import DemoRequestsIndex from "./components/demos/Index.vue";
import NotFound from "./components/NotFound.vue";

const routes = [
    {
        path: "/",
        // redirect: '/auth',
        component: Auth,
        meta: {
            requiresUnAuth: true,
        },
    },
    {
        path: "/auth",
        component: Auth,
        meta: {
            requiresUnAuth: true,
        },
    },
    {
        path: "/change-password-otp",
        component: ChangePasswordOTP,
        meta: {
            requiresUnAuth: true,
        },
    },
    {
        path: "/forgot",
        component: Forgot,
        meta: {
            requiresUnAuth: true,
        },
    },
    {
        path: "/change-password/:token",
        props: true,
        component: ChangePassword,
        meta: {
            requiredDestroyToken: true,
        },
    },
    {
        path: "/dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/currencies",
        component: CurrenciesIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/roles",
        component: UserRoles,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/roles/:id",
        component: RolePermissions,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiredAdmin: true,
        },
    },
    {
        path: "/users",
        component: Users,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/users/deposit",
        component: UsersDeposit,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/users/update-credit",
        component: UsersUpdateCredit,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/users/create",
        component: UsersCreate,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/users/:id/edit",
        component: UsersEdit,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/users/:id",
        component: UsersShow,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/profile",
        component: Profile,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
        },
    },
    {
        path: "/profile/personal-details/edit",
        component: ProfileEdit,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
        },
    },
    {
        path: "/profile/company-details/edit",
        component: UsersCompanyEdit,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
        },
    },
    {
        path: "/categories",
        component: CategoriesIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/categories/create",
        component: CategoriesCreate,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/categories/:id",
        component: CategoriesShow,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/categories/:id/edit",
        component: CategoriesEdit,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/products",
        component: ProductsIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/products/create",
        component: ProductsCreate,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/products/:id",
        component: ProductsShow,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/products/:id/edit",
        component: ProductsEdit,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/pages",
        component: PagesIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/pages/create",
        component: PagesCreate,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/pages/:id/edit",
        component: PagesEdit,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/products-catalog",
        component: CatalogIndex,
        name: "catalog",
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/catalog/cart",
        component: CatalogCart,
        name: "cart",
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/catalog/order-preview/:id?",
        component: CatalogPreview,
        props: true,
        name: "orderPreview",
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/catalog/invoice/:id?",
        component: CatalogInvoice,
        props: true,
        name: "order.invoice",
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/catalog/payment/:id",
        component: CatalogPayment,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/catalog/order/edit/:id",
        component: UpdateOrder,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/invoices/:id?",
        component: InvoicesIndex,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/orders/index",
        component: OrdersIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/order-by-debt",
        component: OrdersByDebt,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/customer-by-debt",
        component: CustomersByDebt,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/pending-payments",
        component: PendingPayments,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/clients",
        component: ClientsIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/clients/create/:from?",
        component: ClientsCreate,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/clients/:id",
        component: ClientsShow,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/clients/:id/edit/:from?",
        component: ClientsEdit,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/reports/sales",
        component: SalesReport,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/reports/credit-spending-search",
        component: CreditSpendingSearch,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/reports/collection",
        component: CollectionReport,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
        },
    },
    {
        path: "/brands",
        component: BrandsIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/attributes",
        component: AttributeTypeIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/cms-blogs",
        component: BlogsIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/cms-blogs/create",
        component: BlogsCreate,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/cms-blogs/:id/edit",
        component: BlogsEdit,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/blog-categories",
        component: BlogCategoriesIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/blog-comments",
        component: BlogCommentsIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/job-postings",
        component: JobPostingsIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/job-postings/create",
        component: JobPostingsCreate,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/job-postings/:id/edit",
        component: JobPostingsEdit,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/job-applications",
        component: JobApplicationsIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/job-applications/:id",
        component: JobApplicationsShow,
        props: true,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/demos",
        component: DemoRequestsIndex,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            requiresCompanyInfo: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/:notFound(.*)",
        component: NotFound,
    },
];

export default routes;
