const axios = require('axios').default;
export default {
    submitBrand(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/brands";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    getBrands(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = "/api/v1/brands";
        return axios({ method: submit_method, url: uri });
    },

    // getUser(context, payload) {
    //     axios.defaults.headers.common["Authorization"] =
    //         "Bearer " + context.rootGetters.token;

    //     let submit_method = "GET";
    //     let uri = `/api/v1/users/${payload.id}`;
    //     return axios({ method: submit_method, url: uri });
    // },

    setBrands(context, payload) {
        context.commit("setBrands", payload);
    },

    updateBrand(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = `/api/v1/brands/${payload.get('id')}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    deleteBrand(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/brands/${payload}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },
};
