<template>
  <aside class="app-sidebar app-sidebar3">
    <div class="app-sidebar__user">
      <div class="dropdown user-pro-body text-center">
        <div class="user-pic">
          <img :src="imgLink" alt="user-img" class="avatar-xl rounded-circle mb-1" />
        </div>
        <div class="user-info">
          <h5 class="mb-1 font-weight-bold">
            {{ loggedUser.first_name }} {{ loggedUser.last_name }}
          </h5>
          <span class="text-muted app-sidebar__user-name text-sm">{{
              loggedUser.their_role ? loggedUser.their_role.name : ""
          }}</span>
        </div>
      </div>
    </div>
    <ul class="side-menu">
      <li class="slide">
        <router-link to="/dashboard">
          <a class="side-menu__item" data-toggle="">
            <i class="fa fa-home fa-fw" data-toggle="tooltip" title="fa fa-home"></i>
            <span class="side-menu__label ml-3">
              {{ $t("dashboard") }}
            </span></a>
        </router-link>
      </li>
      <li class="slide" v-if="isAdmin">
        <router-link to="/products">
          <a class="side-menu__item" data-toggle="">
            <i class="fa fa-shopping-bag fa-fw" data-toggle="tooltip" title=""
              data-original-title="fa fa-shopping-bag"></i>
            <span class="side-menu__label ml-3">
              {{ $t("productManagement") }}
            </span></a>
        </router-link>
      </li>
      <li class="slide">
        <router-link to="/products-catalog">
          <a class="side-menu__item" data-toggle="">
            <i class="fa fa-shopping-cart fa-fw" data-toggle="tooltip" title=""
              data-original-title="fa fa-shopping-bag"></i>
            <span class="side-menu__label ml-3">
              {{ $t("shop") }}
            </span>
          </a>
        </router-link>
      </li>

      <li class="slide">
        <a class="side-menu__item" data-toggle="slide" @click.prevent="toggleExpanded" href="#">
          <i class="fa fa-handshake-o fa-fw" data-toggle="tooltip" title="" data-original-title="fa fa-handshake-o"></i>
          <span class="side-menu__label ml-3">
            {{ $t("orders") }}
          </span>
          <i class="angle fa fa-angle-right"></i></a>
        <ul class="slide-menu">
          <li>
            <router-link to="/orders/index">
              <a class="slide-item" data-toggle="">
                {{ $t("allOrders") }}
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/order-by-debt">
              <a class="slide-item" data-toggle="">
                {{ $t("orderByDebt") }}
              </a>
            </router-link>
          </li>
        </ul>
      </li>
      <li class="slide">
        <a class="side-menu__item" data-toggle="slide" @click.prevent="toggleExpanded" href="#">
          <i class="fa fa-dollar fa-fw" data-toggle="tooltip" title="" data-original-title="fa fa-dollar"></i>
          <span class="side-menu__label ml-3">
            {{ $t("payments") }}
          </span>
          <i class="angle fa fa-angle-right"></i></a>
        <ul class="slide-menu">
          <li v-if="isAdmin">
            <router-link to="/pending-payments">
              <a class="slide-item" data-toggle="">
                {{ $t("walletTransactions") }}
              </a>
            </router-link>
          </li>
          <li v-if="isAdmin || isCommercial">
            <router-link to="/users/deposit">
              <a class="slide-item" data-toggle="">
                {{ $t("bankDeposit") }}
              </a>
            </router-link>
          </li>
        </ul>
      </li>
      <li class="slide">
        <a class="side-menu__item" data-toggle="slide" @click.prevent="toggleExpanded" href="#">
          <i class="fa fa-line-chart fa-fw" data-toggle="tooltip" title="" data-original-title="fa fa-line-chart"></i>
          <span class="side-menu__label ml-3">
            {{ $t("reports") }}
          </span>
          <i class="angle fa fa-angle-right"></i></a>
        <ul class="slide-menu">
          <li>
            <router-link to="/reports/sales">
              <a class="slide-item" data-toggle="">
                {{ $t("salesReport") }}
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/reports/credit-spending-search">
              <a class="slide-item" data-toggle="">
                {{ $t("creditSpendingSearch") }}
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/reports/collection">
              <a class="slide-item" data-toggle="">
                {{ $t("collectionReport") }}
              </a>
            </router-link>
          </li>
        </ul>
        <ul class="slide-menu">
          <li>
            <router-link to="/customer-by-debt">
              <a class="slide-item" data-toggle="">
                {{ $t("customerByDebt") }}
              </a>
            </router-link>
          </li>
        </ul>
      </li>
      <li class="slide">
        <a class="side-menu__item" data-toggle="slide" @click.prevent="toggleExpanded" href="#">
          <i class="fa fa-address-book fa-fw" data-toggle="tooltip" title=""
            data-original-title="fa fa-address-book"></i>
          <span class="side-menu__label ml-2">
            {{ $t("clientManagement") }} </span><i class="angle fa fa-angle-right"></i></a>
        <ul class="slide-menu">
          <li>
            <router-link to="/clients">
              <a class="slide-item" data-toggle="">
                {{ $t("clients") }}
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/invoices">
              <a class="slide-item" data-toggle="">
                {{ $t("invoices") }}
              </a>
            </router-link>
          </li>
        </ul>
      </li>

      <li class="slide" v-if="isAdmin">
        <a class="side-menu__item" data-toggle="slide" @click.prevent="toggleExpanded" href="#">
          <i class="fa fa-group fa-fw" data-toggle="tooltip" title="" data-original-title="fa fa-group"></i>
          <span class="side-menu__label ml-3"> {{ $t("userManagement") }} </span><i
            class="angle fa fa-angle-right"></i></a>
        <ul class="slide-menu">
          <li v-if="isAdmin">
            <router-link to="/users">
              <a class="slide-item" data-toggle="">
                {{ $t("users") }}
              </a>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link to="/users/update-credit">
              <a class="slide-item" data-toggle="">
                {{ $t("updateCredit") }}
              </a>
            </router-link>
          </li>

          <li v-if="isAdmin">
            <router-link to="/roles">
              <a class="slide-item" data-toggle="">
                {{ $t("roles") }}
              </a>
            </router-link>
          </li>
        </ul>
      </li>
      <!-- <li class="slide" v-if="isAdmin">
        <a
          class="side-menu__item"
          data-toggle="slide"
          @click.prevent="toggleExpanded"
          href="#"
        >
          <i
            class="fa fa-folder-o fa-fw"
            data-toggle="tooltip"
            title=""
            data-original-title="fa fa-folder-o"
          ></i>

          <span class="side-menu__label ml-3"> {{ $t("career") }} </span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li v-if="isAdmin">
            <router-link to="/job-postings">
              <a class="slide-item" data-toggle="">
                {{ $t("jobPostings") }}
              </a>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link to="/job-applications">
              <a class="slide-item" data-toggle="">
                {{ $t("jobApplications") }}
              </a>
            </router-link>
          </li>
        </ul>
      </li> -->
      <!-- <li class="slide" v-if="isAdmin">
        <a
          class="side-menu__item"
          data-toggle="slide"
          @click.prevent="toggleExpanded"
          href="#"
        >
          <i
            class="fa fa-folder-o fa-fw"
            data-toggle="tooltip"
            title=""
            data-original-title="fa fa-folder-o"
          ></i>

          <span class="side-menu__label ml-3"> {{ $t("cms") }} </span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li v-if="isAdmin">
            <router-link to="/pages">
              <a class="slide-item" data-toggle="">
                {{ $t("pages") }}
              </a>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link to="/cms-blogs">
              <a class="slide-item" data-toggle="">
                {{ $t("blog") }}
              </a>
            </router-link>
          </li>
        </ul>
      </li> -->
      <!-- <li class="slide" v-if="isAdmin">
        <a
          class="side-menu__item"
          data-toggle="slide"
          @click.prevent="toggleExpanded"
          href="#"
        >
          <i
            class="fa fa-folder-o fa-fw"
            data-toggle="tooltip"
            title=""
            data-original-title="fa fa-folder-o"
          ></i>

          <span class="side-menu__label ml-3"> {{ $t("demos") }} </span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li v-if="isAdmin">
            <router-link to="/demos">
              <a class="slide-item" data-toggle="">
                {{ $t("requests") }}
              </a>
            </router-link>
          </li>
        </ul>
      </li> -->
      <li class="slide" v-if="isAdmin">
        <a class="side-menu__item" data-toggle="slide" @click.prevent="toggleExpanded" href="#">
          <i class="fa fa-folder-o fa-fw" data-toggle="tooltip" title="" data-original-title="fa fa-folder-o"></i>

          <span class="side-menu__label ml-3"> {{ $t("masters") }} </span><i class="angle fa fa-angle-right"></i></a>
        <ul class="slide-menu">
          <li v-if="isAdmin">
            <router-link to="/categories">
              <a class="slide-item" data-toggle="">
                {{ $t("categories") }}
              </a>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link to="/currencies">
              <a class="slide-item" data-toggle="">
                {{ $t("currencies") }}
              </a>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link to="/brands">
              <a class="slide-item" data-toggle="">
                {{ $t("brands") }}
              </a>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link to="/attributes">
              <a class="slide-item" data-toggle="">
                {{ $t("categoryAttributes") }}
              </a>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      slideMenu: {},
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    imgLink() {
      if (this.loggedUser.photo) {
        return `${this.$baseURL}storage/${this.loggedUser.photo.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
    types() {
      return this.$store.getters["masters/types"];
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isCommercial() {
      return this.$store.getters.isCommercial;
    },
  },
  created() {
    this.loadMasters();
  },
  mounted() {
    this.slideMenu = document.querySelector(".side-menu");
  },
  methods: {
    toggleExpanded(event) {
      const parent = event.currentTarget.parentElement;
      if (!parent.classList.contains("is-expanded")) {
        let link = this.slideMenu.querySelector("[data-toggle='slide']");
        link.parentElement.classList.remove("is-expanded");
      }
      parent.classList.toggle("is-expanded");

      var slides = document.querySelectorAll(".slide");
      slides.forEach((slide) => {
        if (slide !== parent) {
          slide.classList.remove("is-expanded");
        }
      });
    },
    async loadMasters(type = "all") {
      try {
        const response = await this.$store.dispatch("masters/getMasters", {
          type,
        });
        if (response.status === 200) {
          this.$store.dispatch("masters/setMasters", response.data.masters);
          this.$store.dispatch("masters/setTypes", response.data.types);
        }
      } catch (err) {
        console.log("Error");
      }
    },

    capitalizeFirstLetter(string) {
      let spaced_string = string.replace("_", " ");
      return spaced_string.charAt(0).toUpperCase() + spaced_string.slice(1);
    },
  },
};
</script>

<style scoped>
.side-menu {
  overflow-y: scroll;
  height: 60vh;
}
</style>