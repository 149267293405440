<template>
  <div class="page-header" v-if="Object.keys(product).length > 0">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("productsDetails") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/products">
            <a>{{ $t("products") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("view") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="Object.keys(product).length > 0 && !loading">
    <div class="col-md-12 wrapper wrapper-content">
      <div class="ibox card">
        <div class="card-header">
          <h3 class="card-title">{{ product.name }}</h3>
          <div class="card-options">
            <div class="btn-group">
              <router-link :to="`/products/${product.id}/edit`">
                <a class="btn btn-sm btn-primary">
                  <i
                    class="fa fa-pencil pointer"
                    data-toggle="tooltip"
                    title="fa fa-trash"
                  ></i>
                </a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-5 position-relative cart-container">
              <div class="row">
                <div
                  v-if="product && product.photos && product.photos.length > 0"
                  class="
                    col-3
                    rowstuff
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
                >
                  <span
                    class="text-center thumb-btn"
                    @click="moveThumb('back')"
                  >
                    <i
                      class="fa fa-caret-up"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="fa fa-angle-up"
                    ></i>
                  </span>
                  <img
                    v-for="photo in thumbs"
                    :key="photo.id"
                    @click="selectPhoto(photo)"
                    :src="getProductPhoto(photo)"
                    class="my-1 thumbs img-thumbnail"
                    :class="{
                      'selected-thumb': photo.id === selectedPhoto.id,
                    }"
                  />
                  <span
                    class="text-center thumb-btn"
                    @click="moveThumb('forward')"
                  >
                    <i
                      class="fa fa-caret-down"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="fa fa-angle-up"
                    ></i>
                  </span>
                </div>
                <div
                  id="pictureContainer"
                  class="
                    col-9
                    selected-image
                    d-flex
                    justify-content-center
                    img-magnifier-container
                  "
                >
                  <div class="rect" id="rect"></div>
                  <img
                    id="product-image"
                    class="img-responsive"
                    :src="getProductPhoto(selectedPhoto)"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <form>
                    <div
                      class="form-group row"
                      v-for="attribute in attributes"
                      :key="attribute.name"
                    >
                      <label class="col-md-3 form-label"
                        >{{ attribute.name }}<span>*</span></label
                      >
                      <div class="col-md-9">
                        <select
                          class="form-control"
                          v-model="form[attribute.name]"
                          :style="getSelectedBackgroundColor(attribute.name)"
                        >
                          <option
                            v-for="value in attribute.values"
                            :key="value.pivot.id"
                            :value="value.pivot.id"
                            :style="
                              getBackgroundColor(
                                attribute.name,
                                value.pivot.value
                              )
                            "
                          >
                            <span v-if="!isColor(attribute.name)"
                              >{{ value.pivot.value }}
                              {{ value.pivot.unit_name }}</span
                            >
                          </option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="border-top py-4">
                    <button
                      @click.prevent="addBtnHandler(product)"
                      href="cart"
                      class="text-center btn btn-block mt-1 mb-1"
                      :class="{
                        'btn-primary': !checkIfInCart(product.id),
                        'btn-success': checkIfInCart(product.id),
                      }"
                    >
                      <svg
                        class="svg-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                        <path
                          d="M15.55 11l2.76-5H6.16l2.37 5z"
                          opacity=".3"
                        ></path>
                        <path
                          d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                        ></path>
                      </svg>
                      <span v-if="checkIfInCart(product.id)">{{
                        $t("addedToCart")
                      }}</span>
                      <span v-else>{{ $t("addToCart") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7" style="position: relative">
              <div class="zoom" id="zoom"></div>
              <h5>{{ product.name }}</h5>
              <div v-if="product.categories && product.categories.length > 0">
                <p
                  v-for="category in product.categories"
                  :key="category.id"
                  class="mb-2 text-muted text-uppercase small"
                >
                  {{ category.full_name }}
                </p>
              </div>
              <p>
                <span v-if="product.price" class="mr-1"
                  ><strong>
                     <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                    {{ product.price }}
                  </strong></span
                >
              </p>
              <p
                class="pt-1"
                v-if="product.description"
                v-html="product.description"
              ></p>

              <!-- <div class="table-responsive">
                <table class="table table-sm table-borderless mb-0">
                  <tbody>
                    <tr
                      v-for="attribute in product.attributes"
                      :key="attribute.id"
                    >
                      <th class="pl-0 w-25" scope="row">
                        <strong>{{ attribute.name }}</strong>
                      </th>
                      <td>
                        {{ attribute.pivot.value }}

                        {{
                          attribute.pivot.unit_name
                            ? attribute.pivot.unit_name
                            : ""
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
            </div>
          </div>
          <h5 class="mt-4 mb-4">{{ $t("productsDetails") }}</h5>
          <table class="table table-striped table-bordered">
            <tbody>
              <tr v-if="product.price">
                <th scope="row">{{ $t("price") }}</th>
                <td>
                   <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                  {{ product.price }}
                </td>
              </tr>
              <tr v-if="product.model">
                <th scope="row">{{ $t("model") }}</th>
                <td>{{ product.model }}</td>
              </tr>
              <tr v-if="product.sku">
                <th scope="row">{{ $t("sku") }}</th>
                <td>{{ product.sku }}</td>
              </tr>
              <tr v-if="product.quantity">
                <th scope="row">{{ $t("quantity") }}</th>
                <td>{{ product.quantity }}</td>
              </tr>
              <tr v-if="product.minimum">
                <th scope="row">{{ $t("minimum") }}</th>
                <td>{{ product.minimum }}</td>
              </tr>
              <tr>
                <th scope="row">{{ $t("subtract") }}</th>
                <td>{{ product.subtract === 1 ? $t("yes") : $t("no") }}</td>
              </tr>
              <tr v-if="product.stock_status">
                <th scope="row">{{ $t("status") }}</th>
                <td>{{ product.stock_status }}</td>
              </tr>
              <tr>
                <th scope="row">{{ $t("shipping") }}</th>
                <td>{{ product.shipping ? $t("yes") : $t("no") }}</td>
              </tr>
              <tr v-if="product.date_available">
                <th scope="row">{{ $t("dateAvailable") }}</th>
                <td>{{ product.date_available }}</td>
              </tr>
              <tr>
                <th scope="row">{{ $t("status") }}</th>
                <td>
                  <span
                    :class="{
                      'badge-success': product.status === 1,
                      'badge-danger': product.status === 0,
                    }"
                    class="badge"
                  >
                    {{ fixStatus(product.status) }}
                  </span>
                </td>
              </tr>
              <tr v-if="product.sort_order">
                <th scope="row">{{ $t("sortOrder") }}</th>
                <td>{{ product.sort_order }}</td>
              </tr>
              <tr v-if="product.manufacturer">
                <th scope="row">{{ $t("manufacturer") }}</th>
                <td>{{ product.manufacturer.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkToken from "../../mixins/checkToken";
import notifyMixin from "../../mixins/notify";
import forbiddenError from "../../mixins/catchForbiddenError";
import showError from "../../mixins/showError";
import _ from "lodash";

export default {
  mixins: [checkToken, forbiddenError, notifyMixin, showError],
  props: ["id"],
  data() {
    return {
      product: {},
      selectedPhoto: {},
      previous: null,
      next: null,
      loading: true,
      form: {},
      attributes: [],
      atest: [1, 2],
    };
  },
  created() {
    this.getProduct();
  },
  mounted() {
    window.addEventListener("load", () => {
      this.$nextTick(function () {
        // amazonZoom(
        //   "product-image",
        //   "pictureContainer",
        //   "rect",
        //   "zoom",
        //   this.getProductPhoto(this.selectedPhoto)
        // );
      });
    });
  },
  updated() {
    // amazonZoom(
    //   "product-image",
    //   "pictureContainer",
    //   "rect",
    //   "zoom",
    //   this.getProductPhoto(this.selectedPhoto)
    // );
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    firstImageLink() {
      if (this.product.photos && this.product.photos.length > 0) {
        return `${this.$baseURL}storage/${this.product.photos[0].file_path}`;
      }
      return "";
    },
    thumbs() {
      if (!this.product.photos || this.product.photos.length === 0) {
        return;
      }
      let prev;
      let next;
      const selected_index = this.product.photos.findIndex((photo) => {
        return photo.id === this.selectedPhoto.id;
      });
      prev = selected_index !== 0 ? selected_index - 1 : null;
      next =
        selected_index !== this.product.photos.length - 1
          ? selected_index + 1
          : null;

      if (prev !== null && next !== null) {
        return [
          this.product.photos[prev],
          this.selectedPhoto,
          this.product.photos[next],
        ];
      }
      if (prev !== null) {
        return [this.product.photos[prev], this.selectedPhoto];
      }
      if (next !== null) {
        return [this.selectedPhoto, this.product.photos[next]];
      }
      return null;
    },
    cart() {
      return this.$store.getters["catalog/cart"];
    },
  },
  methods: {
    async getProduct() {
      try {
        const response = await this.$store.dispatch("products/getProduct", {
          id: this.id,
        });
        if (response.status === 200) {
          this.product = response.data;
          if (this.product.photos.length > 0) {
            this.selectedPhoto = this.product.photos[0];
          }
          this.loading = false;
          this.setAttributes();
        }
      } catch (err) {
        console.log("error");
        this.catchForbiddenError(err);
        this.loading = false;
      }
    },
    setAttributes() {
      let result = this.product.attributes.reduce(function (r, a) {
        r[a.name] = r[a.name] || [];
        r[a.name].push(a);
        return r;
      }, Object.create(null));
      for (const key in result) {
        const attributeAndValues = {
          name: key,
          values: result[key],
        };
        this.attributes.push(attributeAndValues);
      }
      const obj = {};
      for (const key of this.attributes) {
        const single = this.attributes.find((att) => {
          return att.name === key.name;
        });
        obj[key.name] = single.values[0].pivot.id;
      }
      this.form = obj;
    },
    fixStatus(status) {
      if (status === 1) {
        return this.$t("enabled");
      }
      return this.$t("disabled");
    },
    getProductPhoto(photo) {
      const photoLink = photo.file_path;
      return `${this.$baseURL}storage/${photoLink}`;
    },
    selectPhoto(photo) {
      this.selectedPhoto = photo;
      // amazonZoom(
      //   "product-image",
      //   "pictureContainer",
      //   "rect",
      //   "zoom",
      //   this.getProductPhoto(this.selectedPhoto)
      // );
    },
    moveThumb(direction) {
      const selected_index = this.product.photos.findIndex((photo) => {
        return photo.id === this.selectedPhoto.id;
      });
      this.previous = selected_index !== 0 ? selected_index - 1 : null;
      this.next =
        selected_index !== this.product.photos.length - 1
          ? selected_index + 1
          : null;

      if (direction === "back" && this.previous !== null) {
        this.selectedPhoto = this.product.photos[this.previous];
      }
      if (direction === "forward" && this.next !== null) {
        this.selectedPhoto = this.product.photos[this.next];
      }
      // amazonZoom(
      //   "product-image",
      //   "pictureContainer",
      //   "rect",
      //   "zoom",
      //   this.getProductPhoto(this.selectedPhoto)
      // );
    },
    addBtnHandler(product) {
      if (this.checkIfInCart(product.id)) {
        this.removeFromCart(product);
      } else {
        this.addToCart(product);
      }
    },
    checkIfInCart(product_id) {
      const category = this.cart.find((product) => {
        const attributeIDs = Object.values(this.form);
        const similarAttributesExistInCart = _.isEqual(
          product.selectedAttributes,
          attributeIDs
        );
        return product.id === product_id && similarAttributesExistInCart;
      });
      return category ? true : false;
    },
    addToCart(productParam) {
      // Without this the selectedAttributes array is different variants already added.
      var product = Object.assign({}, productParam);
      let attributeIDs = Object.values(this.form);
      product.selectedAttributes = attributeIDs;
      this.$store.dispatch("catalog/addToCart", product);
    },
    removeFromCart(product) {
      product["selectedAttributes"] = Object.values(this.form);
      this.$store.dispatch("catalog/removeFromCart", product);
    },
    getBackgroundColor(attribute, value) {
      if (this.isColor(attribute)) {
        var formatedColorCode = value.substr(value.length - 6);
        const backgroundColor = {
          backgroundColor: `#${formatedColorCode}`,
        };
        return backgroundColor;
      }
      return {};
    },
    getSelectedBackgroundColor(attributeName) {
      if (this.attributes.length === 0) {
        return {};
      }
      if (this.isColor(attributeName)) {
        let attributeId = this.form[attributeName];
        const colorAttributes = this.attributes.find((attribute) => {
          return this.isColor(attribute.name);
        });
        const selectedColorAttribute = colorAttributes.values.find((color) => {
          return color.pivot.id === attributeId;
        });
        const color = selectedColorAttribute.pivot.value;
        var formatedColorCode = color.substr(color.length - 6);
        const backgroundColor = {
          backgroundColor: `#${formatedColorCode}`,
        };
        return backgroundColor;
      }
      return {};
    },
    isColor(name) {
      return (
        name === "Color" ||
        name === "color" ||
        name === "colour" ||
        name === "Colour"
      );
    },
  },
};
</script>

<style scoped>
i {
  width: 26px;
}

.selected-thumb {
  border: 2px solid green;
}

.thumbs {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.selected-image {
  width: 300px;
  height: 300px;
}

.img-responsive {
  max-height: 100%;
}

.thumb-btn {
  cursor: pointer;
}
</style>