<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("attributes") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("attributes") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 pr-2">
              <h3 class="card-title">
                {{ typeOf }} {{ $t("attribute") }}

                <button v-if="mode === 'edit'" @click="changeToNew" class="pull-right btn btn-primary btn-sm">
                  {{ $t("clear") }}
                </button>
              </h3>
              <form class="form-horizontal" @submit.prevent="submitAttributeHandler">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label">{{ $t("name") }}<span>*</span></label>
                      <div class="input-group">
                        <input :class="{
                          'border-red':
                            findFieldinFeedback('name') && form.name === '',
                        }" v-model="form.name" type="text" class="form-control" :placeholder="$t('name')" />
                      </div>
                      <p v-if="findFieldinFeedback('name') && form.name == ''" class="text-danger d-block">
                        {{ $t("nameCannotBeBlank") }}
                      </p>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label"></label>
                      <div class="input-group">
                        <label class="custom-switch">
                          <input v-model="has_many_units" type="checkbox" name="custom-switch-checkbox"
                            class="custom-switch-input" />
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">{{
                              $t("hasUnits")
                          }}</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-12" v-if="has_many_units">
                    <div class="row mb-2" v-for="(unit, index) in form.units" :key="unit">
                      <div class="col-6">{{ unit }}</div>
                      <div class="col-6 text-right">
                        <button class="btn btn-sm btn-light" type="button" @click="removeUnit(index)">
                          <i class="fe fe-minus"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-12" v-if="has_many_units">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <input :class="{
                            'border-red':
                              findFieldinFeedback('unit') && unit === '',
                          }" v-model="unit" type="text" class="form-control" placeholder="Add new unit" />
                        </div>
                        <span class="col-auto">
                          <button class="btn btn-sm btn-success" type="button" @click="addUnit">
                            <i class="fe fe-plus"></i>
                          </button>
                        </span>
                      </div>
                      <p v-if="findFieldinFeedback('unit') && unit == ''" class="text-danger d-block">
                        {{ $t("unitCannotBeBlank") }}
                      </p>
                    </div>
                  </div>

                  <div class="col-12">
                    <button type="submit" class="pull-right btn btn-sm btn-primary">
                      {{ submitBtnText }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm-8 pl-5">
              <div class="table-responsive">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select v-model="tableData.length" @change="getAttributeTypes()" name="example1_length"
                            aria-controls="example1" class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            ">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div id="example1_filter" class="dataTables_filter">
                        <label><input type="search" v-model="tableData.search" @input="search()"
                            class="form-control form-control-sm" :placeholder="$t('search')"
                            aria-controls="example1" /></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                        <tbody>
                          <tr role="row" class="odd" v-for="attribute in attribute_types" :key="attribute.id">
                            <td>
                              {{ attribute.name }}
                            </td>
                            <td>
                              <li style="list-style-type: none" v-for="unit in attribute.units" :key="unit.id">
                                {{ unit.name }}
                              </li>
                            </td>
                            <td>
                              <span class="mr-2">
                                <i @click="setUpForm(attribute)" class="fa fa-pencil" data-toggle="tooltip"
                                  title="fa fa-pencil"></i>
                              </span>
                              <span>
                                <i @click="deleteAttribute(attribute.id)" class="text-danger fa fa-trash pointer"
                                  data-toggle="tooltip" title="fa fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination :pagination="pagination" @prev="getAttributeTypes(pagination.prevPageUrl)"
                      @next="getAttributeTypes(pagination.nextPageUrl)">
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import showErrorMixin from "../../mixins/showError";
import setFeedbackMixin from "../../mixins/setFeedback";
import notifyMixin from "../../mixins/notify";
import checkToken from "../../mixins/checkToken";
import fixStatus from "../../mixins/fixStatus";
import _ from "lodash";

export default {
  mixins: [
    showErrorMixin,
    setFeedbackMixin,
    notifyMixin,
    checkToken,
    fixStatus,
  ],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("name"),
        name: "name",
        sortable: true,
      },
      {
        label: this.$t("units"),
        name: "units",
        sortable: false,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.name = 1;

    return {
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "name",
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      attribute_types: [],

      validation_errors: {},
      feedback: [],
      form: {
        name: "",
        units: [],
      },
      has_many_units: false,
      unit: "",

      mode: null,
      loading: true,
    };
  },
  computed: {
    submitBtnText() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("create");
    },
    typeOf() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("new");
    },
    countries() {
      return this.$store.getters["countries/countries"];
    },
    token() {
      return this.$store.getters.token;
    },
  },
  watch: {
    has_many_units: function (newValue) {
      if (!newValue) {
        this.form.units = [];
      }
    },
  },
  created() {
    // this.loadCountries();
    this.getAttributeTypes();
  },
  methods: {
    search: _.debounce(function () {
      this.getAttributeTypes();
    }, 500),
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getAttributeTypes();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },

    async submitAttributeHandler() {
      this.feedback = [];

      if (!this.validate()) {
        return;
      }

      try {
        if (this.mode === "edit") {
          const response = await this.$store.dispatch(
            "attributeTypes/updateAttributeType",
            this.form
          );
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.clearForm();
            this.getAttributeTypes();
            this.mode = "";
          }
        } else {
          const response = await this.$store.dispatch(
            "attributeTypes/submitAttributeType",
            this.form
          );
          if (response.status === 201) {
            this.feedback = [];
            this.notify(this.$t("saved"));
            this.clearForm();
            this.getAttributeTypes();
          }
        }
      } catch (err) {
        console.log(err);
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    addUnit() {
      this.feedback = [];
      if (this.unit == "") {
        this.setFeedback("error", "unit");
        return;
      }

      this.form.units.push(this.unit);
      this.unit = "";
    },

    removeUnit(index) {
      this.form.units.splice(index, 1);
    },

    getAttributeTypes(url = "/api/v1/paginated-attribute-types") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          this.loading = false;
          if (this.tableData.draw === data.draw) {
            this.attribute_types = data.attribute_types.data;
            this.configPagination(data.attribute_types);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    validate() {
      if (this.form.name === "") {
        this.setFeedback("error", "name");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },

    findFieldinFeedback(field_name) {
      const field_in_feedback = this.feedback.find((feedback) => {
        return feedback.field === field_name;
      });
      if (field_in_feedback) {
        return true;
      }
      return false;
    },
    setUpForm(attribute_type) {
      this.clearForm();
      this.form.id = attribute_type.id;
      this.form.name = attribute_type.name;
      if (attribute_type.units.length > 0) {
        this.has_many_units = true;
        attribute_type.units.forEach((unit) => {
          this.form.units.push(unit.name);
        });
      }
      this.mode = "edit";
    },
    async deleteAttribute(id) {
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        icon: "warning",
        text: this.$t("youWontBeAbleToReverseThis"),
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("delete"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch(
            "attributeTypes/deleteAttributeType",
            id
          );
          if (response.status === 204) {
            this.notify(this.$t("deleted"));
            this.getAttributeTypes();
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    changeToNew() {
      this.mode = "";
      this.clearForm();
    },

    clearForm() {
      this.has_many_units = false;
      this.form.name = "";
      this.form.units = [];
    },
  },
};
</script>

<style scoped>
.badge,
i {
  cursor: pointer;
}

.border-red {
  border: 1px solid red;
}
</style>