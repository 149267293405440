<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("creditSpendingSearch") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("creditSpendingSearch") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <div
                  id="example1_wrapper"
                  class="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <div class="row">
                    <div class="col-4">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select
                            v-model="tableData.length"
                            @change="getCollectionReport()"
                            name="example1_length"
                            aria-controls="example1"
                            class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            "
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div id="example1_filter" class="dataTables_filter">
                        <div class="row">
                          <span class="font-weight-bold">{{ $t("from") }}</span>
                          <label
                            ><input
                              type="date"
                              v-model="tableData.from"
                              @change="search()"
                              class="form-control form-control-sm"
                          /></label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div id="example1_filter" class="dataTables_filter">
                        <div class="row">
                          <span class="font-weight-bold">{{ $t("to") }}</span>
                          <label
                            ><input
                              type="date"
                              v-model="tableData.to"
                              @change="search()"
                              class="form-control form-control-sm"
                          /></label>
                        </div>
                      </div>
                    </div>
                    <div class="col-1">
                      <button
                        @click="clearRange"
                        type="button"
                        class="btn btn-info btn-sm"
                      >
                        {{ $t("clear") }}
                      </button>
                    </div>
                    <div class="col-1">
                      <button
                        @click="exportLeads"
                        type="button"
                        class="btn btn-info btn-sm"
                      >
                        {{ $t("export") }}
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <table
                        class="
                          table table-bordered
                          text-nowrap
                          dataTable
                          no-footer
                        "
                        role="grid"
                        aria-describedby="example1_info"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              class="wd-15p border-bottom-0"
                              tabindex="0"
                              aria-controls="example1"
                              rowspan="1"
                              colspan="1"
                              aria-label="First name: activate to sort column descending"
                              aria-sort="ascending"
                            >
                              {{ $t("date") }}
                              <span
                                style="
                                  text-transform: lowercase;
                                  float: right;
                                  color: #4454c3;
                                  cursor: pointer;
                                "
                                @click="clearDate"
                                >{{ $t("clear") }}</span
                              >
                              <input
                                @change="search()"
                                v-model="tableData.created_at"
                                type="date"
                                class="form-control mt-3"
                                :placeholder="$t('search')"
                              />
                            </th>
                            <th
                              class="wd-15p border-bottom-0"
                              tabindex="0"
                              aria-controls="example1"
                              rowspan="1"
                              colspan="1"
                              aria-label="First name: activate to sort column descending"
                              aria-sort="ascending"
                            >
                              {{ $t("order#") }}
                              <div>
                                <input
                                  @input="search()"
                                  v-model="tableData.order_number"
                                  type="text"
                                  class="form-control mt-3"
                                  :placeholder="$t('search')"
                                />
                              </div>
                            </th>
                            <th
                              class="wd-15p border-bottom-0"
                              tabindex="0"
                              aria-controls="example1"
                              rowspan="1"
                              colspan="1"
                              aria-label="First name: activate to sort column descending"
                              aria-sort="ascending"
                            >
                              {{ $t("clientName") }}
                              <input
                                @input="search()"
                                v-model="tableData.name"
                                type="text"
                                class="form-control mt-3"
                                :placeholder="$t('search')"
                              />
                            </th>
                            <th
                              class="wd-15p border-bottom-0"
                              tabindex="0"
                              aria-controls="example1"
                              rowspan="1"
                              colspan="1"
                              aria-label="First name: activate to sort column descending"
                              aria-sort="ascending"
                            >
                              {{ $t("total") }}
                              <input
                                @input="search()"
                                v-model="tableData.total"
                                type="text"
                                class="form-control mt-3"
                                :placeholder="$t('search')"
                              />
                            </th>
                            <th
                              class="wd-15p border-bottom-0"
                              tabindex="0"
                              aria-controls="example1"
                              rowspan="1"
                              colspan="1"
                              aria-label="First name: activate to sort column descending"
                              aria-sort="ascending"
                            >
                              {{ $t("paid") }}
                              <input
                                @input="search()"
                                v-model="tableData.amount_paid"
                                type="text"
                                class="form-control mt-3"
                                :placeholder="$t('search')"
                              />
                            </th>
                            <th
                              class="wd-15p border-bottom-0"
                              tabindex="0"
                              aria-controls="example1"
                              rowspan="1"
                              colspan="1"
                              aria-label="First name: activate to sort column descending"
                              aria-sort="ascending"
                              @click="sortBy('amount_remaining')"
                            >
                              {{ $t("debt") }}
                              <input
                                @input="search()"
                                v-model="tableData.amount_remaining"
                                type="text"
                                class="form-control mt-3"
                                :placeholder="$t('search')"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            role="row"
                            class="odd"
                            v-for="order in orders"
                            :key="order.id"
                          >
                            <td>
                              {{ order.created_at }}
                            </td>
                            <td>
                              <router-link :to="`/catalog/invoice/${order.id}`">
                                <a class="text-primary font-weight-bold fs-12">
                                  {{ order.order_number }}
                                </a>
                              </router-link>
                            </td>
                            <td>
                              <router-link :to="`/clients/${order.client.id}`">
                                <a class="text-primary font-weight-bold fs-12">
                                  {{
                                    order.client
                                      ? `${order.client.first_name} ${order.client.last_name}`
                                      : ""
                                  }}
                                </a>
                              </router-link>
                            </td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{ order.total }}
                            </td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{ order.amount_paid }}
                            </td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{ order.amount_remaining }}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="3"></td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{ pageSumTotal.toFixed(2) }}
                            </td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{ pagePaidTotal.toFixed(2) }}
                            </td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{ pageDebtTotal.toFixed(2) }}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <pagination
                      :pagination="pagination"
                      @prev="getCollectionReport(pagination.prevPageUrl)"
                      @next="getCollectionReport(pagination.nextPageUrl)"
                    >
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    let sortOrders = {};

    let columns = [
      {
        label: this.$t("date"),
        name: "created_at",
        sortable: true,
        searchable: false,
        relationship: false,
      },
      {
        label: this.$t("order#"),
        name: "order_number",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("client"),
        name: "client",
        sortable: false,
        searchable: true,
        relationship: true,
        relationship_field: "first_name",
      },
      {
        label: this.$t("total"),
        name: "total",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("paid"),
        name: "amount_paid",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("debt"),
        name: "amount_remaining",
        sortable: true,
        searchable: true,
        relationship: false,
      },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.name = 1;
    return {
      sortOrders: sortOrders,
      sortKey: "name",
      columns: columns,
      tableData: {
        tableColumns: columns.map(({ name }) => name),
        draw: 0,
        length: 10,
        column: 0,
        dir: "desc",
        created_at: "",
        order_number: "",
        name: "",
        total: "",
        amount_remaining: "",
        amount_paid: "",
        to: "",
        from: "",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      orders: [],
      loading: true,
    };
  },
  created() {
    this.getCollectionReport();
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },

    token() {
      return this.$store.getters.token;
    },
    pageDebtTotal() {
      return this.orders.reduce((acc, curr) => {
        let amount_remaining = curr.amount_remaining
          ? curr.amount_remaining
          : 0.0;
        return acc + parseFloat(amount_remaining);
      }, 0);
    },
    pagePaidTotal() {
      return this.orders.reduce((acc, curr) => {
        let amount_paid = curr.amount_paid ? curr.amount_paid : 0.0;
        return acc + parseFloat(amount_paid);
      }, 0);
    },
    pageSumTotal() {
      return this.orders.reduce((acc, curr) => {
        let total = curr.total ? curr.total : 0.0;
        return acc + parseFloat(total);
      }, 0);
    },
  },
  methods: {
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getCollectionReport();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    clearDate() {
      this.tableData.created_at = "";
      this.getCollectionReport();
    },
    clearRange() {
      this.tableData.to = "";
      this.tableData.from = "";
      this.getCollectionReport();
    },
    search: _.debounce(function () {
      this.getCollectionReport();
    }, 500),
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    getCollectionReport(url = "/api/v1/orders/sales-report") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.orders = data.orders.data;
            this.configPagination(data.orders);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    exportLeads() {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let url = "/api/v1/orders/export";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data }).then(
        (response) => {
          window.open(response.data.url);
        }
      );
    },
  },
};
</script>