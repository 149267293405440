import { createRouter, createWebHashHistory } from "vue-router";
import backendRoutes from "./backend_routes";
import frontendRoutes from "./frontend_routes";

import store from "./store/index.js";

console.log(process.env.IS_ELECTRON);

const router = createRouter({
    history: createWebHashHistory(),
    routes: [...backendRoutes, ...frontendRoutes],
});

router.beforeEach(function (to, _, next) {
    if (to.meta.requiresLoggedIn && !store.getters.loggedIn) {
        next("/auth");
    } else if (
        to.meta.requiresEmailVerification &&
        !store.getters.emailIsVerified
    ) {
        if (!store.getters.emailIsVerified) {
            window.location.replace("/email/verify");
        }
    } else if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next("/auth");
    } else if (to.meta.requiresUnAuth && store.getters.isAuthenticated) {
        next("/");
    } else if (to.meta.requiresCompanyInfo && !store.getters.companyIsSet) {
        next("/profile");
    } else if (to.meta.requiredDestroyToken && store.getters.isAuthenticated) {
        store.dispatch("destroyToken");
        store.dispatch("destroyUser");
        next();
    } else if (to.meta.requiresAdmin && !store.getters.isAdmin) {
        next("/dashboard");
    } else {
        next();
    }
});

export default router;
