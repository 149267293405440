const axios = require('axios').default;
export default {
    submitCountry(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/countries";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    getCountries(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = "/api/v1/countries";
        return axios({ method: submit_method, url: uri });
    },
    getCountry(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = `/api/v1/countries/${payload.id}`;
        return axios({ method: submit_method, url: uri });
    },
    setCountries(context, payload) {
        context.commit("setCountries", payload);
    },

    updateCountry(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/countries/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    deleteCountry(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/countries/${payload}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },
};
