<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("categories") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/cms-blogs">
            {{ $t("blogs") }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("categories") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 pr-2">
              <h3 class="card-title">
                {{ typeOf }} {{ $t("category") }}

                <button v-if="mode === 'edit'" @click="changeToNew" class="pull-right btn btn-primary btn-sm">
                  {{ $t("clear") }}
                </button>
              </h3>
              <form class="form-horizontal" @submit.prevent="submitCategoryHandler">
                <div class="form-group row">
                  <label class="col-md-4 form-label">{{ $t("name") }} <span>*</span></label>
                  <div class="col-md-8">
                    <input :class="{
                      'border-red':
                        findFieldinFeedback('name') && form.name === '',
                    }" v-model="form.name" type="text" class="form-control" :placeholder="$t('name')" />
                    <p v-if="findFieldinFeedback('name') && form.name === ''" class="text-danger">
                      {{ $t("nameCannotBeBlank") }}
                    </p>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-12">
                    <button type="submit" class="pull-right btn btn-sm btn-primary">
                      {{ submitBtnText }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm-8 pl-5">
              <div class="table-responsive">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select v-model="tableData.length" @change="getCategories()" name="example1_length"
                            aria-controls="example1" class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            ">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div id="example1_filter" class="dataTables_filter">
                        <label><input type="search" v-model="tableData.search" @input="search()"
                            class="form-control form-control-sm" placeholder="Search..."
                            aria-controls="example1" /></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                        <tbody>
                          <tr role="row" class="odd" v-for="category in blogCategories" :key="category.id">
                            <td>
                              {{ category.name }}
                            </td>
                            <td>
                              <span @click="updateStatus(category)" :class="{
                                'badge-success': category.active === 1,
                                'badge-danger': category.active === 0,
                              }" class="badge">
                                {{ fixStatus(category.active) }}
                              </span>
                            </td>
                            <td>
                              <span class="mr-2">
                                <i @click="setUpForm(category)" class="fa fa-pencil" data-toggle="tooltip"
                                  title="fa fa-pencil"></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination :pagination="pagination" @prev="getCategories(pagination.prevPageUrl)"
                      @next="getCategories(pagination.nextPageUrl)">
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import showErrorMixin from "../../../mixins/showError";
import notifyMixin from "../../../mixins/notify";
import checkToken from "../../../mixins/checkToken";
import fixStatus from "../../../mixins/fixStatus";
import _ from "lodash";

export default {
  mixins: [showErrorMixin, notifyMixin, checkToken, fixStatus],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("name"),
        name: "name",
        sortable: true,
      },
      {
        label: this.$t("status"),
        name: "status",
        sortable: false,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.name = 1;

    return {
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "name",
      tableData: {
        length: 10,
        column: 0,
        dir: "asc",
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      validation_errors: {},
      feedback: [],
      form: {
        id: "",
        name: "",
      },
      mode: null,
      loading: true,
      blogCategories: [],
    };
  },
  computed: {
    submitBtnText() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("submit");
    },
    typeOf() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("new");
    },
    token() {
      return this.$store.getters.token;
    },
  },
  created() {
    this.getCategories();
  },
  methods: {
    search: _.debounce(function () {
      this.getCategories();
    }, 500),
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getCategories();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.meta.last_page;
      this.pagination.currentPage = data.meta.current_page;
      this.pagination.total = data.meta.total;
      this.pagination.lastPageUrl = data.links.last;
      this.pagination.nextPageUrl = data.links.next;
      this.pagination.prevPageUrl = data.links.prev;
      this.pagination.from = data.meta.from;
      this.pagination.to = data.meta.to;
    },
    async submitCategoryHandler() {
      this.feedback = [];

      if (!this.validate()) {
        return;
      }

      try {
        if (this.mode === "edit") {
          const response = await this.$axios({
            method: "PUT",
            url: `/api/v1/blog-categories/${this.form.id}`,
            data: this.form,
          });
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.clearForm();
            this.getCategories();
          }
        } else {
          const response = await this.$axios({
            method: "POST",
            url: "/api/v1/blog-categories",
            data: this.form,
          });
          if (response.status === 201) {
            this.notify(this.$t("saved"));
            this.clearForm();
            this.getCategories();
          }
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
    getCategories(url = "/api/v1/blog-categories") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      this.$axios({
        method: "GET",
        url: url,
        params: this.tableData,
      })
        .then((response) => {
          this.loading = false;
          let data = response.data;
          this.blogCategories = data.data;
          this.configPagination(data);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    validate() {
      if (this.form.name === "") {
        this.setFeedback("error", this.$t("nameCannotBeBlank"), "name");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },
    setFeedback(type, message, field) {
      let validation_error = {};
      validation_error.alert_type = type;
      validation_error.field = field;
      validation_error.message = message;
      this.feedback.push(validation_error);
    },
    findFieldinFeedback(field_name) {
      const field_in_feedback = this.feedback.find((feedback) => {
        return feedback.field === field_name;
      });
      if (field_in_feedback) {
        return true;
      }
      return false;
    },
    setUpForm(category) {
      this.form.name = category.name;
      this.form.id = category.id;
      this.mode = "edit";
    },
    async updateStatus(category) {
      const active = category.active === 1 ? 0 : 1;
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        text: this.$t("pleaseConfirm"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("update"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$axios({
            method: "PUT",
            url: `/api/v1/blog-categories/status/${category.id}`,
            data: {
              id: category.id,
              active: active,
            },
          });
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.clearForm();
            this.getCategories();
            this.mode = "";
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
    changeToNew() {
      this.mode = "";
      this.clearForm();
    },

    clearForm() {
      this.form.id = "";
      this.form.name = "";
      this.mode = "";
    },
  },
};
</script>

<style scoped>
.badge,
i {
  cursor: pointer;
}

.border-red {
  border: 1px solid red;
}
</style>