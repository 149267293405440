const axios = require('axios').default;
export default {
    submitRole(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/roles";
        let submit_data = payload;
        return axios({
            method: submit_method,
            url: uri,
            data: submit_data,
        });
    },

    getRoles(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = "/api/v1/roles";
        return axios({ method: submit_method, url: uri });
    },

    getRole(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = `/api/v1/roles/${payload.id}`;
        return axios({ method: submit_method, url: uri });
    },

    setRoles(context, payload) {
        context.commit("setRoles", payload);
    },

    updateRole(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/roles/${payload.id}`;
        let submit_data = payload;
        return axios({
            method: submit_method,
            url: uri,
            data: submit_data,
        });
    },

    deleteRole(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/roles/${payload}`;
        let submit_data = payload;
        return axios({
            method: submit_method,
            url: uri,
            data: submit_data,
        });
    },
};
