<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("currencies") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("currencies") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 pr-2">
              <h3 class="card-title">
                {{ typeOf }} {{ $t("currency") }}

                <button v-if="mode === 'edit'" @click="changeToNew" class="pull-right btn btn-primary btn-sm">
                  {{ $t("clear") }}
                </button>
              </h3>
              <form class="form-horizontal" @submit.prevent="submitCurrencyHandler">
                <div class="form-group row">
                  <label class="col-md-4 form-label">{{ $t("name") }} <span>*</span></label>
                  <div class="col-md-8">
                    <input :class="{
                      'border-red':
                        findFieldinFeedback('name') && form.name === '',
                    }" v-model="form.name" type="text" class="form-control" :placeholder="$t('name')" />
                    <p v-if="findFieldinFeedback('name') && form.name === ''" class="text-danger">
                      {{ $t("nameCannotBeBlank") }}
                    </p>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 form-label">{{ $t("code") }}<span>*</span></label>
                  <div class="col-md-8">
                    <input v-model="form.code" type="text" class="form-control" :class="{
                      'border-red':
                        findFieldinFeedback('code') && form.code === '',
                    }" placeholder="Code" />
                    <p v-if="findFieldinFeedback('code') && form.code === ''" class="text-danger">
                      {{ $t("codeCannotBeBlank") }}
                    </p>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 form-label">{{ $t("symbol") }}<span>*</span></label>
                  <div class="col-md-8">
                    <input v-model="form.symbol" type="text" class="form-control" :class="{
                      'border-red':
                        findFieldinFeedback('symbol') && form.symbol === '',
                    }" :placeholder="$t('symbol')" />
                    <p v-if="findFieldinFeedback('symbol') && form.symbol === ''" class="text-danger">
                      {{ $t("symbolCannotBeBlank") }}
                    </p>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-12">
                    <button type="submit" class="pull-right btn btn-sm btn-primary">
                      {{ submitBtnText }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm-8 pl-5">
              <div class="table-responsive">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select v-model="tableData.length" @change="getCurrencies()" name="example1_length"
                            aria-controls="example1" class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            ">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div id="example1_filter" class="dataTables_filter">
                        <label><input type="search" v-model="tableData.search" @input="search()"
                            class="form-control form-control-sm" placeholder="Search..."
                            aria-controls="example1" /></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                        <tbody>
                          <tr role="row" class="odd" v-for="currency in currencies" :key="currency.id">
                            <td>
                              {{ currency.name }}
                            </td>
                            <td>
                              {{ currency.code }}
                            </td>
                            <td>
                              {{ currency.symbol }}
                            </td>
                            <td>
                              <span @click="updateStatus(currency)" :class="{
                                'badge-success': currency.status === 1,
                                'badge-danger': currency.status === 0,
                              }" class="badge">
                                {{ fixStatus(currency.status) }}
                              </span>
                            </td>
                            <td>
                              <span class="mr-2">
                                <i @click="setUpForm(currency)" class="fa fa-pencil" data-toggle="tooltip"
                                  title="fa fa-pencil"></i>
                              </span>
                              <span>
                                <i @click="deleteCurrency(currency.id)" class="text-danger fa fa-trash pointer"
                                  data-toggle="tooltip" title="fa fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination :pagination="pagination" @prev="getCurrencies(pagination.prevPageUrl)"
                      @next="getCurrencies(pagination.nextPageUrl)">
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import checkToken from "../../mixins/checkToken";
import fixStatus from "../../mixins/fixStatus";
import _ from "lodash";

export default {
  mixins: [showErrorMixin, notifyMixin, checkToken, fixStatus],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("name"),
        name: "name",
        sortable: true,
      },
      {
        label: this.$t("code"),
        name: "code",
        sortable: true,
      },
      {
        label: this.$t("symbol"),
        name: "symbol",
        sortable: false,
      },
      {
        label: this.$t("status"),
        name: "status",
        sortable: false,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.name = 1;

    return {
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "name",
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      // states: [],
      currencies: [],

      validation_errors: {},
      feedback: [],
      form: {
        id: "",
        symbol: "",
        code: "",
        name: "",
      },
      mode: null,
      loading: true,
    };
  },
  computed: {
    submitBtnText() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("submit");
    },
    typeOf() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("new");
    },
    countries() {
      return this.$store.getters["countries/countries"];
    },
    token() {
      return this.$store.getters.token;
    },
  },
  created() {
    this.getCurrencies();
  },
  methods: {
    search: _.debounce(function () {
      this.getCurrencies();
    }, 500),
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getCurrencies();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },

    async submitCurrencyHandler() {
      this.feedback = [];

      if (!this.validate()) {
        return;
      }

      try {
        if (this.mode === "edit") {
          const response = await this.$store.dispatch(
            "currencies/updateCurrency",
            this.form
          );
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.clearForm();
            this.getCurrencies();
          }
        } else {
          const response = await this.$store.dispatch(
            "currencies/submitCurrencies",
            this.form
          );
          if (response.status === 201) {
            this.notify(this.$t("saved"));
            this.clearForm();
            this.getCurrencies();
          }
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
    getCurrencies(url = "/api/v1/paginated-currencies") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          this.loading = false;
          if (this.tableData.draw === data.draw) {
            this.currencies = data.currencies.data;
            this.configPagination(data.currencies);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async loadCountries() {
      try {
        const response = await this.$store.dispatch("countries/getCountries");
        if (response.status === 200) {
          this.$store.dispatch(
            "countries/setCountries",
            response.data.countries
          );
        }
      } catch (err) {
        console.log("Error");
      }
    },
    validate() {
      if (this.form.symbol === "") {
        this.setFeedback("error", this.$t("symbolCannotBeBlank"), "symbol");
      }
      if (this.form.code === "") {
        this.setFeedback("error", this.$t("codeCannotBeBlank"), "code");
      }
      if (this.form.name === "") {
        this.setFeedback("error", this.$t("nameCannotBeBlank"), "name");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },
    setFeedback(type, message, field) {
      let validation_error = {};
      validation_error.alert_type = type;
      validation_error.field = field;
      validation_error.message = message;
      this.feedback.push(validation_error);
    },
    findFieldinFeedback(field_name) {
      const field_in_feedback = this.feedback.find((feedback) => {
        return feedback.field === field_name;
      });
      if (field_in_feedback) {
        return true;
      }
      return false;
    },
    setUpForm(state) {
      this.form.name = state.name;
      this.form.code = state.code;
      this.form.symbol = state.symbol;
      this.form.id = state.id;
      this.mode = "edit";
    },
    async deleteCurrency(id) {
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        icon: "warning",
        text: this.$t("youWontBeAbleToReverseThis"),
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("delete"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch(
            "currencies/deleteCurrency",
            id
          );
          if (response.status === 204) {
            this.notify(this.$t("deleted"));
            this.getCurrencies();
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
    async updateStatus(currency) {
      const status = currency.status === 1 ? 0 : 1;
      let text;
      if (currency.status === 1) {
        text = this.$t("doYouWantToInactivateThisCurrency");
      } else {
        text = this.$t("doYouWantToActivateThisCurrency");
      }
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        text: text,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("update"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch(
            "currencies/updateCurrency",
            {
              id: currency.id,
              status: status,
            }
          );
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.clearForm();
            this.getCurrencies();
            this.mode = "";
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
    changeToNew() {
      this.mode = "";
      this.clearForm();
    },

    clearForm() {
      this.form.id = "";
      this.form.name = "";
      this.form.code = "";
      this.form.symbol = "";
      this.mode = "";
    },
  },
};
</script>

<style scoped>
.badge,
i {
  cursor: pointer;
}

.border-red {
  border: 1px solid red;
}
</style>