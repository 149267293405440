export default {
    token(state) {
        return state.token;
    },
    user(state) {
        return state.user;
    },
    locale(state) {
        return state.locale;
    },
    loggedIn(state) {
        return !!state.token && !!state.user;
    },
    isAdmin(state) {
        return state.user.role.name === "Administrator";
    },
    isCommercial(state) {
        return state.user.role.name === "Commercial";
    },
    emailIsVerified(state) {
        return !!state.user.email_verified_at;
        // return true;
    },
    isAuthenticated(state) {
        return (
            !!state.token &&
            !!state.user &&
            state.user.active === 1 &&
            !!state.user.email_verified_at
        );
    },
    email(state) {
        return state.email;
    },
    companyIsSet(state) {
        return !!state.user.company;
    },
};
