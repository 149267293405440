export default {
    created() {
        this.checkToken();
    },
    methods: {
        async checkToken() {
            try {
                await this.$store.dispatch("getUser");
            } catch (err) {
                if (
                    err.response &&
                    err.response.status &&
                    err.response.status === 401
                ) {
                    this.logout();
                }
            }
        },
        async logout() {
            try {
                await this.$store.dispatch("logOut");
                this.$store.dispatch("destroyToken");
                this.$store.dispatch("destroyUser");
                this.$router.replace("/");
            } catch (err) {
                console.log("Logout Error");
                this.$store.dispatch("destroyToken");
                this.$store.dispatch("destroyUser");
                this.$router.replace("/");
            }
        },
    },
};
