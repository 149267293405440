<template>
  <manage :from="from"></manage>
</template>

<script>
import Manage from "./Manage.vue";
export default {
  props: {
    from: {
      type: String,
      required: false,
    },
  },
  components: {
    Manage,
  },
};
</script>