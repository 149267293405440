<template>
  <div
    v-for="error in errors" :key="error.name"
    class="mt-5 alert text-white alert-danger"
    role="alert"
  >
    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
      ×
    </button>
    {{ error[0] }}
  </div>
</template>

<script>
export default {
  props: ["errors"],
};
</script>