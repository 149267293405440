<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("customerByDebt") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("customerByDebt") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select v-model="tableData.length" @change="getClientsDebts()" name="example1_length"
                            aria-controls="example1" class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            ">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy"
                        :tableIsSearchable="tableIsSearchable" @search="searchField">
                        <tbody>
                          <tr role="row" class="odd" v-for="client in clients" :key="client.id">
                            <td>
                              <router-link :to="`/clients/${client.id}`">
                                <a class="text-primary font-weight-bold fs-12">{{ client.first_name }}
                                  {{ client.last_name }}</a>
                              </router-link>
                            </td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{ client.debts ? client.debts.toFixed(2) : 0 }}
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination :pagination="pagination" @prev="getClientsDebts(pagination.prevPageUrl)"
                      @next="getClientsDebts(pagination.nextPageUrl)">
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("client"),
        name: "first_name",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("total"),
        name: "total",
        sortable: false,
        searchable: false,
        relationship: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.name = 1;
    return {
      tableIsSearchable: true,
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "name",
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        field: "",
        relationship: false,
        relationship_field: "",
        dir: "asc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      clients: [],
      loading: true,
    };
  },
  created() {
    this.getClientsDebts();
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    loggedUser() {
      return this.$store.getters.user;
    },
  },
  methods: {
    searchField(searchValue, searchField, relationship, relationship_field) {
      this.tableData.search = searchValue;
      this.tableData.field = searchField;
      this.tableData.relationship = relationship;
      this.tableData.relationship_field = relationship_field;
      this.getClientsDebts();
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getClientsDebts();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    getClientsDebts(url = "/api/v1/clients/debts") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.clients = data.clients.data;
            this.configPagination(data.clients);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>