<template>
  <footer class="footer">
    <div class="container">
      <div class="row align-items-center flex-row-reverse">
        <div class="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center">
          <span class="pr-3">
            {{ $t("copyright") }} © {{ new Date().getFullYear() }}
            <a href="jithvar.com" target="_blank">CSV</a>
          </span>
          <span>|</span>
          <span class="pl-3"
            >{{ $t("developedBy") }}
            <a href="jithvar.com" target="_blank">Jithvar</a>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>