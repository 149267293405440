<template>
  <manage :id="id"></manage>
</template>

<script>
import Manage from "./Manage.vue";
export default {
  components: {
    Manage,
  },
  props: ["id"],
}
</script>