<template>
  <deny-dialog :title="denyTitle" v-if="showModal" @close="showModal = false" @reason="deny">
  </deny-dialog>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("collectionReport") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("collectionReport") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-4">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select v-model="tableData.length" @change="getCollectionReport()" name="example1_length"
                            aria-controls="example1" class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            ">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div id="example1_filter" class="dataTables_filter">
                        <div class="row">
                          <span class="font-weight-bold">{{ $t("from") }}</span>
                          <label><input type="date" v-model="tableData.from" @change="search()"
                              class="form-control form-control-sm" /></label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div id="example1_filter" class="dataTables_filter">
                        <div class="row">
                          <span class="font-weight-bold">{{ $t("to") }}</span>
                          <label><input type="date" v-model="tableData.to" @change="search()"
                              class="form-control form-control-sm" /></label>
                        </div>
                      </div>
                    </div>
                    <div class="col-1">
                      <button @click="clearRange" type="button" class="btn btn-info btn-sm">
                        {{ $t("clear") }}
                      </button>
                    </div>
                    <div class="col-1">
                      <button @click="exportLeads" type="button" class="btn btn-info btn-sm">
                        {{ $t("export") }}
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <table class="
                          table table-bordered
                          text-nowrap
                          dataTable
                          no-footer
                        " role="grid" aria-describedby="example1_info">
                        <thead>
                          <tr role="row">
                            <th class="wd-15p border-bottom-0" rowspan="1" colspan="1">
                              {{ $t("paymentDate") }}
                              <span style="
                                  text-transform: lowercase;
                                  float: right;
                                  color: #4454c3;
                                  cursor: pointer;
                                " @click="clearDate">{{ $t("clear") }}</span>
                              <input @change="search()" v-model="tableData.created_at" type="date"
                                class="form-control mt-3" :placeholder="$t('search')" />
                            </th>
                            <th class="wd-15p border-bottom-0" rowspan="1" colspan="1">
                              {{ $t("paymentMethod") }}
                              <input @input="search()" v-model="tableData.payment_method" type="text"
                                class="form-control mt-3" :placeholder="$t('search')" />
                            </th>
                            <th class="wd-15p border-bottom-0" rowspan="1" colspan="1">
                              {{ $t("order#") }}
                              <div>
                                <input @input="search()" v-model="tableData.orderNumber" type="text"
                                  class="form-control mt-3" :placeholder="$t('search')" />
                              </div>
                            </th>
                            <th class="wd-15p border-bottom-0" rowspan="1" colspan="1">
                              {{ $t("clientName") }}
                              <input @input="search()" v-model="tableData.name" type="text" class="form-control mt-3"
                                :placeholder="$t('search')" />
                            </th>
                            <th class="wd-15p border-bottom-0" rowspan="1" colspan="1">
                              {{ $t("paidAmount") }}
                              <input @input="search()" v-model="tableData.amount_paid" type="text"
                                class="form-control mt-3" :placeholder="$t('search')" />
                            </th>
                            <th class="wd-15p border-bottom-0" rowspan="1" colspan="1">
                              {{ $t("status") }}
                              <select v-model="tableData.status" class="form-control mt-3" @change="search()">
                                <option value="">{{ $t("all") }}</option>
                                <option value="pending">
                                  {{ $t("pending") }}
                                </option>
                                <option value="approved">
                                  {{ $t("approved") }}
                                </option>
                              </select>
                            </th>
                            <th class="wd-15p border-bottom-0" rowspan="1" colspan="1">
                              {{ $t("denyReason") }}
                            </th>
                            <th class="wd-15p border-bottom-0" rowspan="1" colspan="1">
                              {{ $t("actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" class="odd" v-for="collection in collections" :key="collection.id">
                            <td>
                              {{ collection.created_at }}
                            </td>
                            <td>
                              {{ collection.payment_method }}
                            </td>
                            <td>
                              <router-link :to="`/catalog/invoice/${collection.order.id}`">
                                <a class="text-primary font-weight-bold fs-12">
                                  {{
                                      collection.order.order_number
                                        ? collection.order.order_number
                                        : ""
                                  }}
                                </a>
                              </router-link>
                            </td>
                            <td>
                              <router-link :to="`/clients/${collection.order.client.id}`">
                                <a class="text-primary font-weight-bold fs-12">
                                  {{
                                      collection.order.client
                                        ? `${collection.order.client.first_name} ${collection.order.client.last_name}`
                                        : ""
                                  }}
                                </a>
                              </router-link>
                            </td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{ collection.amount_paid }}
                            </td>
                            <td>
                              <span>
                                {{ collection.status }}
                              </span>
                            </td>
                            <td>
                              {{
                                  collection.status === "Denied" &&
                                    collection.reason_denied
                                    ? collection.reason_denied
                                    : ""
                              }}
                            </td>
                            <td>
                              <button v-if="
                                collection.payment_method === 'wallet' &&
                                (collection.status === 'Pending' ||
                                  collection.status === 'pending') &&
                                isAdmin
                              " @click.prevent="approve(collection)" class="btn btn-primary btn-sm mr-1">
                                <i class="fa fa-check" data-toggle="tooltip" title=""
                                  data-original-title="fa fa-check"></i>
                              </button>
                              <button v-if="
                                collection.payment_method === 'wallet' &&
                                (collection.status === 'Pending' ||
                                  collection.status === 'pending') &&
                                isAdmin
                              " @click.prevent="showDenyModal(collection)" class="btn btn-danger btn-sm mr-1">
                                <i class="fa fa-close" data-toggle="tooltip" title=""
                                  data-original-title="fa fa-close"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="4"></td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{ pageTotal.toFixed(2) }}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <pagination :pagination="pagination" @prev="getCollectionReport(pagination.prevPageUrl)"
                      @next="getCollectionReport(pagination.nextPageUrl)">
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import catchForbiddenError from "../../../mixins/catchForbiddenError";
import notify from "../../../mixins/notify";
export default {
  mixins: [catchForbiddenError, notify],
  data() {
    let columns = [
      {
        label: this.$t("paymentDate"),
        name: "created_at",
        sortable: true,
        searchable: false,
        relationship: false,
      },
      {
        label: this.$t("paymentMethod"),
        name: "payment_method",
        sortable: true,
        searchable: false,
        relationship: false,
      },
      {
        label: this.$t("orderId"),
        name: "id",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("client"),
        name: "client",
        sortable: false,
        searchable: true,
        relationship: true,
        relationship_field: "first_name",
      },
      {
        label: this.$t("debt"),
        name: "amount_remaining",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("status"),
        name: "status",
        sortable: false,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("denyReason"),
        name: "reason_denied",
        sortable: false,
        searchable: false,
        relationship: false,
      },
      {
        label: this.$t("actions"),
        name: "actions",
        sortable: false,
        searchable: false,
        relationship: false,
      },
    ];
    return {
      tableData: {
        tableColumns: columns.map(({ name }) => name),
        draw: 0,
        length: 10,
        column: 0,
        dir: "desc",
        created_at: "",
        name: "",
        amount_paid: "",
        to: "",
        from: "",
        orderNumber: "",
        payment_method: "",
        status: "",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      orders: [],
      collections: [],
      loading: true,
      collection: {},
      showModal: false,
      denyTitle: this.$t("denyWalletPayment"),
    };
  },
  created() {
    this.getCollectionReport();
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
    pageTotal() {
      return this.collections.reduce((acc, curr) => {
        let amount_paid = curr.amount_paid ? curr.amount_paid : 0.0;
        return acc + parseFloat(amount_paid);
      }, 0);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    clearDate() {
      this.tableData.created_at = "";
      this.getCollectionReport();
    },
    clearRange() {
      this.tableData.to = "";
      this.tableData.from = "";
      this.getCollectionReport();
    },
    search: _.debounce(function () {
      this.getCollectionReport();
    }, 500),
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    getCollectionReport(url = "/api/v1/payments/reports/collection") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.collections = data.collections.data;
            this.configPagination(data.collections);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    exportLeads() {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let url = "/api/v1/payments/export/collection";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data }).then(
        (response) => {
          window.open(response.data.url);
        }
      );
    },
    async approve(collection) {
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        text: this.$t("doYouWantToApproveThisPayment"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("approve"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch(
            "catalog/approveCollection",
            {
              id: collection.id,
            }
          );
          if (response.status === 200) {
            this.notify(this.$t("approved"));
            this.getCollectionReport();
          }
        } catch (err) {
          this.catchForbiddenError(err);
          console.log("error");
        }
      }
    },
    showDenyModal(collection) {
      this.collection = collection;
      this.showModal = true;
    },
    async deny(reason) {
      let data = {
        id: this.collection.id,
        reason_denied: reason,
      };

      try {
        const response = await this.$store.dispatch(
          "catalog/denyCollection",
          data
        );
        if (response.status === 200) {
          this.notify(this.$t("denied"));
          this.collection = {};
          this.showModal = false;
          this.getCollectionReport();
        }
      } catch (err) {
        this.catchForbiddenError(err);
        console.log("error");
      }
    },
  },
};
</script>

<style scoped>
.badge,
i {
  cursor: pointer;
}
</style>