<template>
  <deny-dialog
    :title="denyTitle"
    v-if="showModal"
    @close="showModal = false"
    @reason="deny"
  >
  </deny-dialog>

  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("uploadBankSlip") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("brands") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-5 pr-2">
              <h3 class="card-title">
                {{ typeOf }} {{ $t("bankSlip") }}
                <button
                  v-if="mode === 'edit'"
                  @click="changeToNew"
                  class="pull-right btn btn-primary btn-sm"
                >
                  {{ $t("clear") }}
                </button>
              </h3>
              <form
                class="form-horizontal"
                @submit.prevent="submitBrandHandler"
              >
                <div class="form-group row">
                  <label class="col-md-4 form-label"
                    >{{ $t("date") }} <span>*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('date') && form.date === '',
                      }"
                      v-model="form.date"
                      type="date"
                      class="form-control"
                      :placeholder="$t('date')"
                    />
                    <p
                      v-if="findFieldinFeedback('date') && form.date === ''"
                      class="text-danger"
                    >
                      {{ $t("dateCannotBeBlank") }}
                    </p>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 form-label"
                    >{{ $t("amount") }} <span>*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('amount') && form.amount === '',
                      }"
                      v-model="form.amount"
                      type="number"
                      step="any"
                      min="0"
                      class="form-control"
                      :placeholder="$t('amount')"
                    />
                    <p
                      v-if="findFieldinFeedback('amount') && form.amount === ''"
                      class="text-danger"
                    >
                      {{ $t("pleaseEnterAnAmount") }}
                    </p>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 form-label">
                    {{ $t("slip") }} <span>*</span>
                    <br />
                    <a v-if="isPhotoLink" :href="photoLink" target="_blank">{{
                      $t("viewCurrent")
                    }}</a>
                  </label>
                  <div class="col-md-8">
                    <div
                      class="custom-file"
                      :class="{
                        'border-red':
                          findFieldinFeedback('photo') && form.photo === '',
                      }"
                    >
                      <input
                        v-on:change="onChange"
                        type="file"
                        class="custom-file-input"
                        name="example-file-input-custom"
                      />
                      <label class="custom-file-label">{{
                        $t("choosePhoto")
                      }}</label>
                    </div>
                    <p
                      v-if="findFieldinFeedback('photo') && form.photo === ''"
                      class="text-danger"
                    >
                      {{ $t("noPhotoSelected") }}
                    </p>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-12">
                    <button
                      type="submit"
                      class="pull-right btn btn-sm btn-primary"
                      :class="{
                        'btn-loading': is_sending,
                      }"
                      :disabled="is_sending"
                    >
                      {{ submitBtnText }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm-6 pl-5">
              <div class="table-responsive">
                <div
                  id="example1_wrapper"
                  class="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select
                            v-model="tableData.length"
                            @change="getCollections()"
                            name="example1_length"
                            aria-controls="example1"
                            class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            "
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div id="example1_filter" class="dataTables_filter">
                        <label
                          ><input
                            type="search"
                            v-model="tableData.search"
                            @input="search()"
                            class="form-control form-control-sm"
                            :placeholder="$t('search')"
                            aria-controls="example1"
                        /></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable
                        :columns="columns"
                        :sortKey="sortKey"
                        :sortOrders="sortOrders"
                        @sort="sortBy"
                      >
                        <tbody>
                          <tr
                            role="row"
                            class="odd"
                            v-for="collection in collections"
                            :key="collection.id"
                          >
                            <td>
                              {{ collection.date }}
                            </td>
                            <td>
                               <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                              {{ collection.amount }}
                            </td>
                            <td>
                              <a
                                :href="getSlipLink(collection)"
                                class="btn btn-sm btn-success"
                                download
                              >
                                <i
                                  class="fa fa-download"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="fa fa-download"
                                ></i>
                              </a>
                            </td>
                            <td>
                              {{ collection.status }}
                            </td>
                            <td>
                              {{
                                collection.denied && collection.reason_denied
                                  ? collection.reason_denied
                                  : ""
                              }}
                            </td>
                            <td>
                              <button
                                v-if="collection.approved === 0 && isAdmin"
                                @click.prevent="approve(collection)"
                                class="btn btn-primary btn-sm mr-1"
                              >
                                <i
                                  class="fa fa-check"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="fa fa-check"
                                ></i>
                              </button>
                              <button
                                v-if="
                                  collection.approved === 0 &&
                                  isAdmin &&
                                  !collection.denied
                                "
                                @click.prevent="showDenyModal(collection)"
                                class="btn btn-danger btn-sm mr-1"
                              >
                                <i
                                  class="fa fa-close"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="fa fa-close"
                                ></i>
                              </button>
                              <button
                                v-if="collection.approved === 0"
                                @click.prevent="setUpForm(collection)"
                                class="btn btn-primary btn-sm mr-1"
                              >
                                <i
                                  class="fa fa-pencil"
                                  data-toggle="tooltip"
                                  title="fa fa-pencil"
                                ></i>
                              </button>
                              <button
                                v-if="collection.approved === 0"
                                @click="deleteCollection(collection.id)"
                                class="btn btn-danger btn-sm mr-1"
                              >
                                <i
                                  class="fa fa-trash pointer"
                                  data-toggle="tooltip"
                                  title="fa fa-trash"
                                ></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination
                      :pagination="pagination"
                      @prev="getCollections(pagination.prevPageUrl)"
                      @next="getCollections(pagination.nextPageUrl)"
                    >
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import setFeedbackMixin from "../../mixins/setFeedback";
import checkToken from "../../mixins/checkToken";
import _ from "lodash";
import catchForbiddenError from "../../mixins/catchForbiddenError";

export default {
  mixins: [
    showErrorMixin,
    notifyMixin,
    setFeedbackMixin,
    checkToken,
    catchForbiddenError,
  ],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("date"),
        name: "date",
        sortable: true,
      },
      {
        label: this.$t("amount"),
        name: "amount",
        sortable: true,
      },
      {
        label: this.$t("slip"),
        name: "photo",
        sortable: false,
      },
      {
        label: this.$t("status"),
        name: "status",
        sortable: false,
      },
      {
        label: this.$t("denyReason"),
        name: "reason_denied",
        sortable: false,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.date = 1;

    return {
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "date",
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      collections: [],
      collection: "",
      showModal: false,
      denyTitle: this.$t("denyBankDeposit"),

      is_sending: false,
      validation_errors: {},
      feedback: [],
      form: {
        photo: "",
        date: "",
        amount: "",
      },

      id: "",
      mode: "",
      loading: true,
    };
  },
  created() {
    this.getCollections();
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    isPhotoLink() {
      return (
        this.form.photo &&
        this.mode === "edit" &&
        this.id &&
        !(this.form.photo instanceof File)
      );
    },
    photoLink() {
      if (this.form.photo) {
        return `${this.$baseURL}storage/${this.form.photo}`;
      }
      return "";
    },
    submitBtnText() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("submit");
    },
    typeOf() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("new");
    },
    token() {
      return this.$store.getters.token;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    search: _.debounce(function () {
      this.getCollections();
    }, 500),

    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getCollections();
    },

    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },

    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },

    onChange(event) {
      this.form.photo = event.target.files[0];
      event.target.value = null;
    },

    getCollections(url = "/api/v1/paginated-collections") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      console.log(this.tableData);

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          this.loading = false;
          if (this.tableData.draw === data.draw) {
            this.collections = data.collections.data;
            this.configPagination(data.collections);
          }
        })
        .catch(() => {
          console.log("Error");
          this.loading = false;
        });
    },

    async submitBrandHandler() {
      this.feedback = [];

      if (!this.validate()) {
        return;
      }

      this.is_sending = true;

      const data = new FormData();

      data.append("amount", this.form.amount);
      data.append("date", this.form.date);
      if (this.form.photo && this.form.photo instanceof File) {
        data.append("uploaded_photo", this.form.photo);
      }

      if (this.mode === "edit") {
        data.append("id", this.id);
        data.append("_method", "PUT");
      }

      try {
        if (this.mode === "edit") {
          const response = await this.$store.dispatch(
            "users/updateDeposit",
            data
          );
          if (response.status === 200) {
            this.notify(this.$t("saved"));
            this.mode = "";
            this.id = "";
            this.is_sending = false;
            this.form.date = "";
            this.form.amount = "";
            this.form.photo = "";
            this.is_sending = false;
            this.getCollections();
          }
        } else {
          const response = await this.$store.dispatch(
            "users/submitDeposit",
            data
          );
          this.is_sending = false;
          if (response.status === 201) {
            this.notify(this.$t("saved"));
            this.form.date = "";
            this.form.amount = "";
            this.form.photo = "";
            this.getCollections();
            this.is_sending = false;
          }
        }
      } catch (err) {
        this.is_sending = false;
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    validate() {
      if (this.form.date === "") {
        this.setFeedback("error", "date");
      }

      if (this.form.amount === "") {
        this.setFeedback("error", "amount");
      }

      if (this.form.photo === "") {
        this.setFeedback("error", "photo");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },

    getSlipLink(collection) {
      const link = collection.photo ? collection.photo.file_path : "";
      return `${this.$baseURL}storage/${link}`;
    },

    setUpForm(collection) {
      this.form.date = "";
      this.form.amount = "";
      this.form.date = collection.date;
      this.form.amount = collection.amount;
      this.form.photo = collection.photo ? collection.photo.file_path : "";
      this.id = collection.id;
      this.mode = "edit";
    },

    async deleteCollection(id) {
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        icon: "warning",
        text: this.$t("youWontBeAbleToReverseThis"),
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("delete"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch(
            "users/deleteCollection",
            id
          );
          if (response.status === 204) {
            this.notify(this.$t("deleted"));
            this.getCollections();
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    changeToNew() {
      this.mode = "";
      this.form.date = "";
      this.form.amount = "";
      this.form.photo = "";
    },

    async approve(collection) {
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        text: this.$t("doYouWantToApproveThisDeposit"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("approve"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch(
            "users/approveCollection",
            {
              id: collection.id,
            }
          );
          if (response.status === 200) {
            this.notify(this.$t("approved"));
            this.getCollections();
          }
        } catch (err) {
          this.catchForbiddenError(err);
          console.log("error");
        }
      }
    },

    showDenyModal(collection) {
      this.collection = collection;
      this.showModal = true;
    },

    async deny(reason) {
      let data = {
        id: this.collection.id,
        reason_denied: reason,
      };
      try {
        const response = await this.$store.dispatch(
          "users/denyCollection",
          data
        );
        if (response.status === 200) {
          this.notify(this.$t("denied"));
          this.collection = {};
          this.showModal = false;
          this.getCollections();
        }
      } catch (err) {
        this.catchForbiddenError(err);
        console.log("error");
      }
    },
  },
};
</script>

<style scoped>
i,
.badge {
  cursor: pointer;
}

.form-label a {
  text-decoration: underline;
}

.border-red {
  border: 1px solid red;
}
</style>