<template>
  <div class="page page-style1">
    <div class="page-single">
      <div class="p-5">
        <div class="row">
          <div class="col mx-auto">
            <div class="row justify-content-center">
              <div class="col-lg-9 col-xl-8">
                <div class="card-group mb-0">
                  <div class="card p-4 page-content">
                    <div class="card-body page-single-content">
                      <div class="w-100">
                        <div class="">
                          <h1 class="mb-2">{{ $t("setPassword") }}</h1>
                          <p class="text-muted">
                            {{ $t("pleaseEnterYourEmail") }}
                          </p>

                          <div class="row" v-if="notFound">
                            <div class="col-12">
                              <div class="alert alert-danger">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="alert"
                                  aria-hidden="true"
                                >
                                  ×
                                </button>
                                <strong>{{ $t("noSuchAccount") }}</strong>
                                <hr class="message-inner-separator" />
                                <p>
                                  {{
                                    $t(
                                      "weCouldNotFindAnAccountWithThatEmailAddress"
                                    )
                                  }},
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="row" v-if="passwordReset">
                            <div class="col-12">
                              <div class="alert alert-info">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="alert"
                                  aria-hidden="true"
                                >
                                  ×
                                </button>
                                <strong>{{ $t("passwordReset") }}</strong>
                                <hr class="message-inner-separator" />
                                <p>
                                  {{
                                    $t(
                                      "pleaseVisitYourEmailToSeeYourOneTimePassword"
                                    )
                                  }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr class="divider my-3" />
                        <form id="login-form" @submit.prevent="resetBtnHandler">
                          <div
                            class="input-group mb-3"
                            :class="{
                              'border-red':
                                findFieldinFeedback('email') && email === '',
                            }"
                          >
                            <span class="input-group-addon">
                              <svg
                                class="svg-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none"></path>
                                <path
                                  d="M20 8l-8 5-8-5v10h16zm0-2H4l8 4.99z"
                                  opacity=".3"
                                ></path>
                                <path
                                  d="M4 20h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2zM20 6l-8 4.99L4 6h16zM4 8l8 5 8-5v10H4V8z"
                                ></path>
                              </svg>
                            </span>
                            <input
                              v-model="email"
                              required
                              type="email"
                              class="form-control"
                              autofocus
                              :placeholder="$t('emailAddress')"
                            />
                          </div>
                          <p
                            v-if="findFieldinFeedback('email') && email === ''"
                            class="text-danger"
                          >
                            {{ $t("pleaseEnterYourEmail") }}
                          </p>
                          <div class="row">
                            <div class="col-12"></div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <button
                                type="submit"
                                class="btn btn-lg btn-primary btn-block"
                              >
                                {{ $t("reset") }}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      card
                      text-white
                      bg-primary
                      py-5
                      d-md-down-none
                      page-content
                      mt-0
                    "
                  >
                    <div
                      class="
                        card-body
                        text-center
                        justify-content-center
                        page-single-content
                      "
                    >
                      <img src="assets/images/pattern/login.png" alt="img" />
                    </div>
                  </div>
                </div>
                <div class="text-center pt-4">
                  <div class="font-weight-normal fs-16">
                    {{ $t("forgetIt") }}
                    <router-link to="/">
                      <a class="btn-link font-weight-normal">{{ $t("sendMeBack") }}</a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setFeedbackMixin from "../../mixins/setFeedback";
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
export default {
  mixins: [setFeedbackMixin, showErrorMixin, notifyMixin],
  data() {
    return {
      feedback: [],
      email: "",
      notFound: false,
      passwordReset: false,
    };
  },
  methods: {
    async resetBtnHandler() {
      this.notFound = false;
      this.passwordReset = false;
      if (this.email === null || this.email === "") {
        this.setFeedback("error", "email");
        return;
      }
      try {
        const response = await this.$store.dispatch("forgotPassword", {
          email: this.email,
        });
        if (response.status === 200) {
          this.$store.dispatch("setEmail", {
            email: this.email,
          });
          this.passwordReset = true;
          this.$router.replace("/change-password-otp");
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else if (!!err.response && err.response.status === 404) {
          this.notFound = true;
        } else {
          this.showError(
            this.$t("somethingWentWrong")
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}
</style>
