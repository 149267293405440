<template>
  <!--=========================-->
  <!--=        Footer         =-->
  <!--=========================-->
  <footer id="footer" class="footer-two">
    <div class="container">
      <div class="footer-inner wow pixFadeUp">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="widget footer-widget">
              <h3 class="widget-title">Company</h3>

              <ul class="footer-menu">
                <li><a href="#">Features</a></li>
                <li><a href="#">Dashboard & Tool</a></li>
                <li><a href="#">Our Portfolio</a></li>
                <li><a href="#">About Us</a></li>
                <li><a href="#">Get In Touch</a></li>
              </ul>
            </div>
            <!-- /.widget footer-widget -->
          </div>
          <!-- /.col-lg-3 col-md-6 -->

          <div class="col-lg-3 col-md-6">
            <div class="widget footer-widget">
              <h3 class="widget-title">Services</h3>

              <ul class="footer-menu">
                <li><a href="#">Web Design</a></li>
                <li><a href="#">Development</a></li>
                <li><a href="#">Wordpress</a></li>
                <li><a href="#">Online Marketing</a></li>
                <li><a href="#">Content</a></li>
              </ul>
            </div>
            <!-- /.widget footer-widget -->
          </div>
          <!-- /.col-lg-3 col-md-6 -->

          <div class="col-lg-3 col-md-6">
            <div class="widget footer-widget">
              <h3 class="widget-title">Digital Experience</h3>

              <ul class="footer-menu">
                <li><a href="#">Web Design</a></li>
                <li><a href="#">Mobile App Design</a></li>
                <li><a href="#">UX / UI design</a></li>
                <li><a href="#">Dashboard & Tool</a></li>
                <li><a href="#">Logo & Identity</a></li>
              </ul>
            </div>
            <!-- /.widget footer-widget -->
          </div>
          <!-- /.col-lg-3 col-md-6 -->

          <div class="col-lg-3 col-md-6">
            <div class="widget footer-widget">
              <h3 class="widget-title">Our Address</h3>

              <p>Lavaca Street, Suite 24, Road Apt New York, USA</p>

              <ul class="footer-social-link">
                <li>
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-google-plus-g"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div>
            <!-- /.widget footer-widget -->
          </div>
          <!-- /.col-lg-3 col-md-6 -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.footer-inner -->

      <div class="site-info">
        <div class="copyright">
          <p>
            © 2019 All Rights Reserved Design by
            <a href="http://www.pixelsigns.art" target="_blank">PixelSigns</a>
          </p>
        </div>

        <ul class="site-info-menu">
          <li><a href="#">Privacy & Policy.</a></li>
          <li><a href="#">Faq.</a></li>
          <li><a href="#">Terms.</a></li>
        </ul>
      </div>
      <!-- /.site-info -->
    </div>
    <!-- /.container -->
  </footer>
  <!-- /#footer -->
</template>