<template>
  <div class="app-header header top-header">
    <div class="container-fluid">
      <div class="d-flex">
        <a class="header-brand" href="index">
          <span class="text-white" style="font-size: 24px">CSV</span>
        </a>
        <div class="dropdown side-nav">
          <div class="app-sidebar__toggle" data-toggle="sidebar">
            <a class="open-toggle" href="#">
              <svg class="header-icon mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <a class="close-toggle" href="#">
              <svg class="header-icon mt-1" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24"
                width="24">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
              </svg>
            </a>
          </div>
        </div>
        <div class="dropdown header-option" v-if="isCatalog">
          <prime-dropdown v-model="category" :options="categories" optionLabel="full_name" optionValue="id"
            :filter="true" placeholder="Category">
            <template #label="slotProps">
              <div class="country-item country-item-value" v-if="slotProps.label">
                <div>{{ slotProps }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.full_name }}</div>
              </div>
            </template>
          </prime-dropdown>
        </div>
        <!-- <div class="dropdown header-option" v-else>
          <a class="nav-link icon p-0" data-toggle="dropdown">
            <span class="nav-span">
              <svg v-if="locale === 'en'" xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-gb" viewBox="0 0 640 480"
                style="width: 20px; height: 16px" class="mr-1">
                <path fill="#012169" d="M0 0h640v480H0z" />
                <path fill="#FFF"
                  d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z" />
                <path fill="#C8102E"
                  d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z" />
                <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
                <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
              </svg>
              <svg v-if="locale === 'fr'" style="width: 20px; height: 16px" class="mr-1"
                xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-fr" viewBox="0 0 640 480">
                <g fill-rule="evenodd" stroke-width="1pt">
                  <path fill="#fff" d="M0 0h640v480H0z" />
                  <path fill="#00267f" d="M0 0h213.3v480H0z" />
                  <path fill="#f31830" d="M426.7 0H640v480H426.7z" />
                </g>
              </svg>
              <span v-if="locale === 'en'">{{ $t("english") }}</span>
              <span v-if="locale === 'fr'">{{ $t("french") }}</span>
              <i class="fa fa-angle-down ml-1 fs-18"></i></span>
          </a>
          <div class="
              dropdown-menu dropdown-menu-left dropdown-menu-arrow
              animated
            ">
            <a class="dropdown-item" @click.prevent="changeUserLocale('en')">
              <svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-gb" viewBox="0 0 640 480"
                style="width: 20px; height: 16px" class="mr-1">
                <path fill="#012169" d="M0 0h640v480H0z" />
                <path fill="#FFF"
                  d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z" />
                <path fill="#C8102E"
                  d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z" />
                <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
                <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
              </svg>
              {{ $t("english") }}</a>
            <a class="dropdown-item" @click.prevent="changeUserLocale('fr')">
              <svg style="width: 20px; height: 16px" class="mr-1" xmlns="http://www.w3.org/2000/svg"
                id="flag-icon-css-fr" viewBox="0 0 640 480">
                <g fill-rule="evenodd" stroke-width="1pt">
                  <path fill="#fff" d="M0 0h640v480H0z" />
                  <path fill="#00267f" d="M0 0h213.3v480H0z" />
                  <path fill="#f31830" d="M426.7 0H640v480H426.7z" />
                </g>
              </svg>
              {{ $t("french") }}</a>
          </div>
        </div> -->
        <div class="d-flex order-lg-2 ml-auto">
          <a href="#" data-toggle="search" class="nav-link nav-link-lg d-md-none navsearch">
            <svg class="header-icon search-icon" x="1008" y="1248" viewBox="0 0 24 24" height="100%" width="100%"
              preserveAspectRatio="xMidYMid meet" focusable="false">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </svg>
          </a>
          <div class="mt-1" v-if="isCatalog">
            <form class="form-inline">
              <div class="search-element">
                <input type="search" @input="search()" v-model="searchString" class="form-control header-search"
                  placeholder="Search product" aria-label="Search" tabindex="1" />
              </div>
            </form>
          </div>
          <div class="dropdown header-notify pl-4">
            <div class="
                dropdown-menu dropdown-menu-right dropdown-menu-arrow
                animated
              ">
              <a href="#" class="dropdown-item d-flex pb-3">
                <svg class="header-icon mr-4" x="1008" y="1248" viewBox="0 0 24 24" height="100%" width="100%"
                  preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 11V4H4v8.17l.59-.58.58-.59H6z" opacity=".3" />
                  <path
                    d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-5 7c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10zM4.59 11.59l-.59.58V4h11v7H5.17l-.58.59z" />
                </svg>
                <div>
                  <div class="font-weight-bold">Message Sent.</div>
                  <div class="small text-muted">3 hours ago</div>
                </div>
              </a>
              <a href="#" class="dropdown-item d-flex pb-3">
                <svg class="header-icon mr-4" x="1008" y="1248" viewBox="0 0 24 24" height="100%" width="100%"
                  preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M15.55 11l2.76-5H6.16l2.37 5z" opacity=".3" />
                  <path
                    d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
                </svg>
                <div>
                  <div class="font-weight-bold">Order Placed</div>
                  <div class="small text-muted">5 hour ago</div>
                </div>
              </a>
              <a href="#" class="dropdown-item d-flex pb-3">
                <svg class="header-icon mr-4" x="1008" y="1248" viewBox="0 0 24 24" height="100%" width="100%"
                  preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M5 8h14V6H5z" opacity=".3" />
                  <path
                    d="M7 11h2v2H7zm12-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-4 3h2v2h-2zm-4 0h2v2h-2z" />
                </svg>
                <div>
                  <div class="font-weight-bold">Event Started</div>
                  <div class="small text-muted">45 mintues ago</div>
                </div>
              </a>
              <a href="#" class="dropdown-item d-flex pb-3">
                <svg class="header-icon mr-4" x="1008" y="1248" viewBox="0 0 24 24" height="100%" width="100%"
                  preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M18 13h5v7h-5z" opacity=".3" />
                  <path
                    d="M23 11.01L18 11c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-9c0-.55-.45-.99-1-.99zM23 20h-5v-7h5v7zM2 4h18v5h2V4c0-1.11-.9-2-2-2H2C.89 2 0 2.89 0 4v12c0 1.1.89 2 2 2h7v2H7v2h8v-2h-2v-2h2v-2H2V4zm9 2l-.97 3H7l2.47 1.76-.94 2.91 2.47-1.8 2.47 1.8-.94-2.91L15 9h-3.03z" />
                </svg>
                <div>
                  <div class="font-weight-bold">Your Admin lanuched</div>
                  <div class="small text-muted">1 daya ago</div>
                </div>
              </a>
              <div class="text-center p-2 border-top">
                <a href="#" class="">View All Notifications</a>
              </div>
            </div>
          </div>
          <div class="dropdown profile-dropdown">
            <a href="#" class="nav-link pr-0 leading-none" data-toggle="dropdown">
              <span>
                <img :src="imgLink" alt="img" class="avatar avatar-md brround" />
              </span>
            </a>
            <div class="
                dropdown-menu dropdown-menu-right dropdown-menu-arrow
                animated
              ">
              <div class="text-center">
                <a href="#" class="dropdown-item text-center user pb-0 font-weight-bold">{{ loggedUser.first_name }} {{
                    loggedUser.last_name
                }}</a>
                <div class="dropdown-divider"></div>
              </div>
              <router-link class="dropdown-item d-flex" to="/profile">
                <svg class="header-icon mr-3" x="1008" y="1248" viewBox="0 0 24 24" height="100%" width="100%"
                  preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M0 0h24v24H0V0z" fill="none"></path>
                  <path d="M12 16c-2.69 0-5.77 1.28-6 2h12c-.2-.71-3.3-2-6-2z" opacity=".3"></path>
                  <circle cx="12" cy="8" opacity=".3" r="2"></circle>
                  <path
                    d="M12 14c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm-6 4c.22-.72 3.31-2 6-2 2.7 0 5.8 1.29 6 2H6zm6-6c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z">
                  </path>
                </svg>
                <div class="mt-1">Profile</div>
              </router-link>
              <a class="dropdown-item d-flex" href="#" @click.prevent="logout">
                <svg class="header-icon mr-3" x="1008" y="1248" viewBox="0 0 24 24" height="100%" width="100%"
                  preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
                  <path d="M6 20h12V10H6v10zm2-6h3v-3h2v3h3v2h-3v3h-2v-3H8v-2z" opacity=".3" />
                  <path
                    d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10zm-7-1h2v-3h3v-2h-3v-3h-2v3H8v2h3z" />
                </svg>
                <div class="mt-1">{{ $t("signOut") }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import _ from "lodash";
export default {
  mixins: [showErrorMixin],
  data() {
    return {
      searchString: "",
      category: null,
    };
  },
  created() { },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    imgLink() {
      if (this.loggedUser.photo) {
        return `${this.$baseURL}storage/${this.loggedUser.photo.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
    isCatalog() {
      return this.$route.name === "catalog";
    },
    categories() {
      return this.$store.getters["categories/categoriesAndAll"];
    },
    locale() {
      return this.$store.getters.locale;
    },
  },
  watch: {
    $route: "currentRoute",
    category: function (value) {
      this.$store.dispatch("catalog/setCategory", value);
    },
  },
  methods: {
    search: _.debounce(function () {
      this.$store.dispatch("catalog/setSearchString", this.searchString);
    }, 500),
    currentRoute() {
      this.$nextTick(() => {
        if (this.isCatalog) {
          this.searchString = "";
          this.category = null;
          this.loadCategories();
        }
      });
    },
    async logout() {
      console.log("Logout");
      this.$store.dispatch("catalog/clearCart");
      this.$store.dispatch("catalog/clearPayment");
      this.$store.dispatch("catalog/clearClient");
      try {
        const response = await this.$store.dispatch("logOut");
        if (response.status === 200) {
          this.$store.dispatch("destroyToken");
          this.$store.dispatch("destroyUser");
          // window.location.replace("/");
          this.$router.replace("/");
        }
      } catch (err) {
        console.log("Logout Error");
        console.log(err);
        window.location.replace("/");
        this.$store.dispatch("destroyToken");
        this.$store.dispatch("destroyUser");
      }
    },
    async loadCategories() {
      try {
        const response = await this.$store.dispatch("categories/getCategories");
        if (response.status === 200) {
          this.$store.dispatch(
            "categories/setCategories",
            response.data.categories
          );
        }
      } catch (err) {
        console.log("error");
      }
    },
    async changeUserLocale(locale) {
      const data = new FormData();

      data.append("id", this.loggedUser.id);
      data.append("locale", locale);
      data.append("_method", "PUT");

      try {
        const response = await this.$store.dispatch("users/updateUser", data);
        if (response.status === 200) {
          this.$store.dispatch("setLocale", {
            locale: response.data.locale,
          });
          location.reload();
        }
      } catch (err) {
        this.showError(this.$t("somethingWentWrong"));
      }
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>