<template>
  <div class="modal-div">
    <div @click="$emit('close')" class="modal-div"></div>
    <dialog class="dialog" open>
      <div class="modal d-block pos-static">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">{{ $t("selectProductAttribute") }}</h6>
            <button
              @click="$emit('close')"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="productExistsInCart" class="row mb-2">
              <div class="col-9 offset-3">
                <p class="text-danger">{{ $t("productExistsInCart") }}</p>
              </div>
            </div>
            <form>
              <div
                class="form-group row"
                v-for="attribute in attributes"
                :key="attribute.name"
              >
                <label class="col-md-3 form-label"
                  >{{ attribute.name }}<span>*</span></label
                >
                <div class="col-md-9">
                  <select
                    class="form-control"
                    v-model="form[attribute.name]"
                    :style="getSelectedBackgroundColor(attribute.name)"
                  >
                    <option
                      v-for="value in attribute.values"
                      :key="value.pivot.id"
                      :value="value.pivot.id"
                      :style="
                        getBackgroundColor(attribute.name, value.pivot.value)
                      "
                    >
                      <span v-if="!isColor(attribute.name)"
                        >{{ value.pivot.value }}
                        {{ value.pivot.unit_name }}</span
                      >
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-indigo"
              type="button"
              @click="submitAttributes"
            >
              {{ $t("submit") }}
            </button>
            <button class="btn btn-light" type="button" @click="$emit('close')">
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
import notify from "../../../mixins/notify";
import setFeedback from "../../../mixins/setFeedback";
import showError from "../../../mixins/showError";
import _ from "lodash";

export default {
  emits: ["close", "selectAttribute"],
  mixins: [setFeedback, showError, notify],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // status: this.orderStatus,
      feedback: [],
      form: {},
      attributes: [],
      productExistsInCart: false,
    };
  },

  created() {
    let result = this.product.attributes.reduce(function (r, a) {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a);
      return r;
    }, Object.create(null));
    for (const key in result) {
      const attributeAndValues = {
        name: key,
        values: result[key],
      };
      this.attributes.push(attributeAndValues);
    }
    const obj = {};
    for (const key of this.attributes) {
      const single = this.attributes.find((att) => {
        return att.name === key.name;
      });
      obj[key.name] = single.values[0].pivot.id;
    }
    this.form = obj;
  },
  computed: {
    cart() {
      return this.$store.getters["catalog/cart"];
    },
  },
  methods: {
    submitAttributes() {
      this.productExistsInCart = false;
      if (this.variantExistsInCart(this.product.id)) {
        this.productExistsInCart = true;
        return;
      }
      const attributeIDs = Object.values(this.form);
      this.$emit("selectAttribute", attributeIDs);
    },
    getBackgroundColor(attribute, value) {
      if (this.isColor(attribute)) {
        var formatedColorCode = value.substr(value.length - 6);
        const backgroundColor = {
          backgroundColor: `#${formatedColorCode}`,
        };
        return backgroundColor;
      }
      return {};
    },
    getSelectedBackgroundColor(attributeName) {
      if (this.attributes.length === 0) {
        return {};
      }
      if (this.isColor(attributeName)) {
        let attributeId = this.form[attributeName];
        const colorAttributes = this.attributes.find((attribute) => {
          return this.isColor(attribute.name);
        });
        const selectedColorAttribute = colorAttributes.values.find((color) => {
          return color.pivot.id === attributeId;
        });
        const color = selectedColorAttribute.pivot.value;
        var formatedColorCode = color.substr(color.length - 6);
        const backgroundColor = {
          backgroundColor: `#${formatedColorCode}`,
        };
        return backgroundColor;
      }
      return {};
    },
    isColor(name) {
      return (
        name === "Color" ||
        name === "color" ||
        name === "colour" ||
        name === "Colour"
      );
    },
    variantExistsInCart(product_id) {
      const category = this.cart.find((product) => {
        const attributeIDs = Object.values(this.form);
        const similarAttributesExistInCart = _.isEqual(
          product.selectedAttributes,
          attributeIDs
        );
        return product.id === product_id && similarAttributesExistInCart;
      });
      return category ? true : false;
    },
  },
};
</script>

<style scoped>
.modal-div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10000;
}

.dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 10001;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  .dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>