<template>
  <div class="page-header" v-if="Object.keys(order).length > 0">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("updateOrder") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/products-catalog">
            <a class="d-flex">{{ $t("productsCatalog") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("edit") }}</a>
        </li>
      </ol>
    </div>
  </div>
  <div class="row" v-if="showProducts && Object.keys(order).length > 0">
    <div class="col-12 mb-3">
      <button class="btn btn-sm btn-info" @click="showProducts = false">
        {{ $t("hide") }}
      </button>
    </div>
    <div class="col-12">
      <div class="table-responsive">
        <div
          id="example1_wrapper"
          class="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="example1_length">
                <label>
                  <select
                    v-model="tableData.length"
                    @change="getProducts()"
                    name="example1_length"
                    aria-controls="example1"
                    class="
                      custom-select custom-select-sm
                      form-control form-control-sm
                    "
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="example1_filter" class="dataTables_filter">
                <label
                  ><input
                    type="search"
                    v-model="tableData.search"
                    @input="searchString()"
                    class="form-control form-control-sm"
                    placeholder="Search..."
                    aria-controls="example1"
                /></label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr
                    role="row"
                    class="odd"
                    v-for="product in searchedProducts"
                    :key="product.id"
                  >
                    <td>
                      {{ displayName(product.name) }}
                    </td>
                    <td>
                      {{ product.model }}
                    </td>
                    <td>
                     <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                      {{ product.price }}
                    </td>
                    <td>
                      {{ product.quantity }}
                    </td>
                    <td>
                      <button
                        @click.prevent="addBtnHandler(product)"
                        href="cart"
                        class="text-center btn btn-block mt-1 mb-1"
                        :class="{
                          'btn-primary': !checkIfInCart(product.id),
                          'btn-success': checkIfInCart(product.id),
                        }"
                      >
                        <svg
                          class="svg-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none"></path>
                          <path
                            d="M15.55 11l2.76-5H6.16l2.37 5z"
                            opacity=".3"
                          ></path>
                          <path
                            d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                          ></path>
                        </svg>
                        <span v-if="checkIfInCart(product.id)">{{
                          $t("addedToCart")
                        }}</span>
                        <span v-else>{{ $t("addToCart") }}</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </datatable>
            </div>
          </div>
          <div class="row">
            <pagination
              :pagination="pagination"
              @prev="getProducts(pagination.prevPageUrl)"
              @next="getProducts(pagination.nextPageUrl)"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="Object.keys(order).length > 0">
    <div class="col-12">
      <div class="card" v-if="Object.keys(order).length > 0">
        <div class="card-header">
          <h3 class="card-title">{{ $t("cart") }}</h3>
          <div class="card-options">
            <button class="btn btn-sm btn-info" @click="showProducts = true">
              {{ $t("add") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-bordered text-nowrap border-top">
                  <thead class="">
                    <tr>
                      <th>{{ $t("product") }}</th>
                      <th>{{ $t("quantity") }}</th>
                      <th>{{ $t("unitPrice") }}</th>
                      <th>{{ $t("subTotal") }}</th>
                      <th class="w-5"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="product in products" :key="product.id">
                      <td>
                        <div class="d-flex w-200">
                          <div>
                            <img
                              :src="getFirstImageLink(product.product)"
                              alt="img"
                              title=""
                              class="w-7 h-7"
                            />
                          </div>
                          <h6 class="mb-0 mt-4 font-weight-bold ml-4">
                            {{ displayName(product.product.name) }}
                          </h6>
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control w-50"
                          :value="product.quantity"
                          @input="changeAmountInCart($event, product)"
                        />
                      </td>
                      <td class="price text-right">
                       <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>

                        {{ product.price }}
                      </td>
                      <td class="text-right">
                         <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                        {{ getTotalPrice(product) }}
                      </td>
                      <td class="text-center">
                        <a
                          @click="removeFromCart(product)"
                          class="remove_cart"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Remove"
                        >
                          <svg
                            class="svg-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none"></path>
                            <path d="M8 9h8v10H8z" opacity=".3"></path>
                            <path
                              d="M15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"
                            ></path>
                          </svg>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">{{ $t("total") }}</td>
                      <td
                        class="total h4 mb-0 font-weight-bold text-right"
                        colspan="1"
                      >
                        <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                        {{ cartTotal.toFixed(2) }}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="Object.keys(order).length > 0">
    <div class="col-6">
      <div class="card" v-if="Object.keys(selectedClient).length === 0">
        <div class="card-header">
          <h3 class="card-title">{{ $t("searchClient") }}</h3>
        </div>
        <div class="card-body">
          <input
            class="form-control form-control-sm mb-4"
            :class="{
              'border-red':
                findFieldinFeedback('search') && tableData.search === '',
            }"
            :placeholder="$t('searchClientNameOrMobile')"
            v-model="tableData.search"
            @blur="getClients"
            type="text"
          />
        </div>
      </div>
      <div
        class="mb-3"
        v-if="findFieldinFeedback('search') && tableData.search === ''"
      >
        <span class="d-block text-danger">{{
          $t("pleaseEnterNameOrMobileNumber")
        }}</span>
      </div>
      <div class="mb-3" v-if="not_found">
        <span class="d-block text-danger">{{
          $t("thereIsNoClientWithTheseDetails")
        }}</span>
      </div>
      <div
        class="table-responsive mb-5"
        v-if="selectedClients.length > 0 && !loading"
      >
        <table
          class="table card-table table-vcenter text-nowrap table-primary mb-0"
        >
          <thead class="bg-primary text-white">
            <tr>
              <th class="text-white">{{ $t("name") }}</th>
              <th class="text-white">{{ $t("mobile") }}</th>
              <th class="text-white"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="client in selectedClients" :key="client.id">
              <td>{{ client.first_name }} {{ client.last_name }}</td>
              <td>{{ client.mobile }}</td>
              <td>
                <button
                  class="btn btn-sm btn-success"
                  type="button"
                  @click="selectClient(client)"
                >
                  <i class="fe fe-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="row" v-if="Object.keys(selectedClient).length === 0">
        <div class="col-12">
          <input
            class="form-control form-control-sm mb-4"
            :class="{
              'border-red':
                findFieldinFeedback('search') &&
                (search === '' || search.length !== 10),
            }"
            :placeholder="$t('clientMobile')"
            v-model="search"
            type="number"
            @blur="searchClient"
            @focus="feedback = []"
          />
        </div>
      </div> -->
      <!-- <div
        class="mb-3"
        v-if="
          findFieldinFeedback('search') &&
          (search === '' || search.length !== 10)
        "
      >
        <span class="d-block text-danger">{{
          $t("phoneNumberMustHave10Characters")
        }}</span>
      </div> -->
      <div class="mb-3" v-if="not_found">
        <span class="d-block text-danger">{{
          $t("thereIsNoClientWithThisMobileNumber")
        }}</span>
        <button class="btn btn-primary btn-sm" @click="addClient">
          {{ $t("addClient") }}
        </button>
      </div>
      <div
        class="card overflow-hidden"
        v-if="Object.keys(selectedClient).length > 0"
      >
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("clientDetails") }}</h3>
          <div class="card-options">
            <button class="btn btn-sm btn-info" @click="changeSelectedClient">
              {{ $t("changeClient") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="dropdown user-pro-body text-center" data-v-62b8754f="">
            <div class="user-pic" data-v-62b8754f="">
              <img
                :src="imgLink"
                alt="user-img"
                class="avatar-xl rounded-circle mb-1"
                data-v-62b8754f=""
              />
            </div>
          </div>
          <h4 class="card-title mb-1">{{ $t("personalDetails") }}</h4>
          <div class="table-responsive">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("name") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    {{ selectedClient.first_name }}
                    {{ selectedClient.last_name }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("email") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    <a
                      :href="getMailLink(selectedClient.email)"
                      target="_blank"
                      >{{ selectedClient.email }}</a
                    >
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("phone") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    <a
                      :href="getPhoneLink(selectedClient.mobile)"
                      target="_blank"
                      >{{ selectedClient.mobile }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        class="card overflow-hidden card-collapsed"
        v-if="
          Object.keys(selectedClient).length > 0 &&
          selectedClient.shipping_addresses.length > 0
        "
      >
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("deliveryAddress") }}</h3>
          <div class="card-options">
            <a
              href="#"
              class="card-options-collapse mr-2"
              data-toggle="card-collapse"
              ><i class="fe fe-chevron-up text-white"></i
            ></a>
          </div>
        </div>
        <div class="card-body">
          <div
            class="
              d-sm-flex
              mt-0
              p-3
              sub-review-section
              border border-bottom-0
              br-bl-0 br-br-0
            "
            v-for="address in selectedClient.shipping_addresses"
            :key="address.id"
          >
            <div class="media-body">
              <h5 class="mt-0 mb-1 font-weight-semibold">
                {{ selectedClient.first_name }}
                {{ selectedClient.last_name }}
                <span
                  class="badge badge-success mt-2"
                  v-if="address.id === address_id"
                  >{{ $t("selected") }}</span
                >
              </h5>
              <p class="font-13 mb-2 mt-2">
                <span v-if="address.zip_code">{{ address.zip_code }}, </span>
                <span v-if="address.street_address"
                  >{{ address.street_address }},
                </span>
                <span v-if="address.locality">{{ address.locality }}, </span>
                <span v-if="address.city">{{ address.city }}, </span>
                <span v-if="address.country">{{ address.country }}</span>
              </p>
              <button
                class="btn btn-sm btn-primary"
                v-if="address.id !== address_id"
                @click="address_id = address.id"
              >
                {{ $t("deliverHere") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card" v-if="Object.keys(order).length > 0">
        <div class="card-header">
          <h3 class="card-title">{{ $t("paymentDetails") }}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <strong>{{ $t("invoiceTotal") }}</strong>
            </div>
            <div class="col-sm-6 text-right">
              <span>
                  <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                {{ cartTotal.toFixed(2) }} </span
              ><br />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <strong>{{ $t("paidAmount") }}</strong>
            </div>
            <div class="col-sm-6 text-right">
              <span>
                <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                {{ parseFloat(order.amount_paid).toFixed(2) }}</span
              ><br />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-sm-6">
              <strong>{{ $t("remainingAmount") }}</strong>
            </div>
            <div class="col-sm-6 text-right">
              <span>
                <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                {{ remainingAmount }}</span
              ><br />
            </div>
          </div>
        </div>
      </div>
      <div
        class="card overflow-hidden"
        v-if="Object.keys(order).length > 0 && adress_id != ''"
      >
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("payment") }}</h3>
        </div>
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("method") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <select
                      @change="changePayment"
                      v-model="payment.method"
                      class="form-control"
                      :class="{
                        'border-red':
                          findFieldinFeedback('method') &&
                          payment.method === '',
                      }"
                    >
                      <option value="wallet">{{ $t("wallet") }}</option>
                      <option value="cash">{{ $t("cash") }}</option>
                    </select>
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('method') && payment.method === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("methodCannotBeBlank") }}
                  </p>
                </div>
              </div>
              <div class="col-12" v-if="payment.method == 'wallet'">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("typeOfWallet") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <select
                      @change="changePayment"
                      v-model="payment.walletType"
                      class="form-control"
                      :class="{
                        'border-red':
                          findFieldinFeedback('typeOfWallet') &&
                          payment.typeOfWallet === '',
                      }"
                    >
                      <option value="smilepay">Smilepay</option>
                      <option value="orangePay">Orange pay</option>
                    </select>
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('typeOfWallet') &&
                      payment.typeOfWallet === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("walletTypeCannotBeBlank") }}
                  </p>
                </div>
              </div>
              <div class="col-12" v-if="payment.method == 'wallet'">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("transactionId") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      @blur="changePayment"
                      :class="{
                        'border-red':
                          findFieldinFeedback('transaction_id') &&
                          payment.method === 'wallet' &&
                          payment.transaction_id === '',
                      }"
                      v-model="payment.transaction_id"
                      type="text"
                      class="form-control"
                      :placeholder="$t('transactionId')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('transaction_id') &&
                      payment.method === 'wallet' &&
                      payment.transaction_id === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("enterATransactionId") }}
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("paidAmount") }}<span>*</span>
                  </label>
                  <div class="input-group">
                    <input
                      @blur="changePayment"
                      :class="{
                        'border-red':
                          findFieldinFeedback('paid') && fullPriceIsNotPaid,
                      }"
                      v-model.number="payment.paid"
                      type="number"
                      min="0"
                      step="any"
                      class="form-control"
                      :placeholder="$t('paidAmount')"
                    />
                  </div>
                  <p
                    v-if="findFieldinFeedback('paid') && fullPriceIsNotPaid"
                    class="text-danger"
                  >
                    {{
                      $t(
                        "amountPaidAmountRemainingMustEqualTheTotalAmountRemaining"
                      )
                    }}
                  </p>
                  <p
                    v-if="
                      findFieldinFeedback('paid') &&
                      amountPaidGreaterThanRemainingAmount
                    "
                    class="text-danger"
                  >
                    {{ $t("amountPaidCannotBeGreaterThanRemainingAmount") }}
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("remainingAmount") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      @blur="changePayment"
                      :class="{
                        'border-red':
                          findFieldinFeedback('remaining') &&
                          payment.remaining === '',
                      }"
                      v-model="payment.remaining"
                      type="number"
                      step="any"
                      min="0"
                      readonly
                      class="form-control"
                      :placeholder="$t('remainingAmount')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('remaining') &&
                      payment.remaining === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("enterAnAmount") }}
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12">
              <div class="card-options pull-right">
                <button
                  @click="updateOrder"
                  class="d-block btn btn-success btn-sm"
                  v-if="
                    Object.keys(order).length > 0 &&
                    products.length > 0 &&
                    adress_id !== '' &&
                    Object.keys(selectedClient).length > 0
                  "
                >
                  {{ $t("updateOrder") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import fixStatus from "../../mixins/fixStatus";
import checkToken from "../../mixins/checkToken";
import setFeedbackMixin from "../../mixins/setFeedback";
import contact from "../../mixins/contact";
import _ from "lodash";
import catchForbiddenError from "../../mixins/catchForbiddenError";
import catchBadRequestError from "../../mixins/catchBadRequestError";
import displayNameMixin from "../../mixins/displayNameMixin";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  mixins: [
    showErrorMixin,
    notifyMixin,
    checkToken,
    fixStatus,
    setFeedbackMixin,
    contact,
    catchForbiddenError,
    catchBadRequestError,
    displayNameMixin,
  ],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("name"),
        name: "name",
        sortable: true,
      },
      {
        label: this.$t("model"),
        name: "model",
        sortable: true,
      },
      {
        label: this.$t("price"),
        name: "price",
        sortable: true,
      },
      {
        label: this.$t("quantity"),
        name: "quantity",
        sortable: true,
      },
      {
        label: this.$t("status"),
        name: "status",
        sortable: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.name = 1;
    return {
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "name",
      tableData: {
        draw: 0,
        length: 5,
        search: "",
        column: 0,
        dir: "asc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      searchedProducts: [],
      not_found: false,
      selectedClient: {},
      selectedClients: [],
      search: "",
      address_id: "",
      feedback: [],
      order: {},
      products: [],
      status: "",
      payment: {
        method: "wallet",
        walletType: "smilepay",
        paid: 0,
        remaining: 0,
        transaction_id: "",
      },
      showProducts: false,
      paidInDb: "",
    };
  },
  watch: {
    cashPaid: function (newValue) {
      if (!this.amountPaidGreaterThanRemainingAmount) {
        if (newValue === "") {
          this.payment.remaining = parseFloat(this.cartTotal);
        } else {
          this.payment.remaining = parseFloat(
            (
              parseFloat(this.remainingAmount) - parseFloat(this.payment.paid)
            ).toFixed(2)
          );
        }
      }
    },
  },
  created() {
    this.getOrder();
    this.getProducts();
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    cashPaid() {
      return this.payment.paid;
    },
    token() {
      return this.$store.getters.token;
    },
    imgLink() {
      if (this.selectedClient.photo) {
        return `${this.$baseURL}storage/${this.selectedClient.photo.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
    cartTotal() {
      return this.products.reduce((acc, curr) => {
        let price = parseFloat(curr.quantity) * parseFloat(curr.price);
        return acc + price;
      }, 0);
    },
    remainingAmount() {
      return (parseFloat(this.cartTotal) - parseFloat(this.paidInDb)).toFixed(
        2
      );
    },
    fullPriceIsNotPaid() {
      return (
        parseFloat(
          (
            parseFloat(this.payment.paid === "" ? 0 : this.payment.paid) +
            parseFloat(this.payment.remaining)
          ).toFixed(2)
        ) != parseFloat(parseFloat(this.remainingAmount).toFixed(2))
      );
    },
    amountPaidGreaterThanRemainingAmount() {
      return parseFloat(this.payment.paid) > parseFloat(this.remainingAmount);
    },
  },
  methods: {
    searchString: _.debounce(function () {
      this.getProducts();
    }, 500),
    sortBy(key) {
      this.sortKey = key; // Name of collumn being sorted
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getProducts();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },

    getProducts(url = "/api/v1/paginated-products") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data }).then(
        (response) => {
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.searchedProducts = data.products.data;
            this.configPagination(data.products);
          }
        }
      );
    },
    async getOrder() {
      try {
        const response = await this.$store.dispatch("catalog/getOrder", {
          id: this.id,
        });
        if (response.status === 200) {
          this.order = response.data;
          this.selectedClient = this.order.client;
          this.status = this.order.status;
          this.setOrderProducts();
          this.address_id = this.order.address.id;
          this.paidInDb = this.order.amount_paid;
        }
      } catch (err) {
        console.log("error");
        this.catchForbiddenError(err);
      }
    },
    getClients() {
      this.loading = true;
      this.tableData.draw++;
      this.feedback = [];
      if (this.tableData.search === "") {
        this.setFeedback("error", "search");
      }
      if (this.feedback.length !== 0) {
        this.loading = false;
        return;
      }
      this.not_found = false;

      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let url = "/api/v1/clients/search-add-money";
      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          this.loading = false;
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.selectedClients = data.clients;
          }
        })
        .catch((error) => {
          console.log("Error");
          this.loading = false;
          if (!!error.response && error.response.status === 404) {
            this.not_found = true;
          }
        });
    },
    setOrderProducts() {
      this.products = this.order.products;

      this.products.forEach((prd) => {
        prd["product_id"] = prd.product.id;
      });
    },
    changeSelectedClient() {
      this.search = "";
      this.selectedClient = {};
      this.address_id = "";
    },
    searchClient() {
      this.feedback = [];
      if (this.search === "" || this.search.length !== 10) {
        this.setFeedback("error", "search");
      }
      if (this.feedback.length !== 0) {
        return;
      }

      this.not_found = false;
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let url = "/api/v1/search-clients";
      let submit_method = "POST";
      const tableData = {
        search: this.search,
      };
      let submit_data = tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          if (response.status === 200) {
            this.selectClient(data.client);
          }
        })
        .catch((error) => {
          if (!!error.response && error.response.status === 404) {
            this.not_found = true;
          }
          console.log("Error");
          console.log(error);
        });
    },
    selectClient(client) {
      this.selectedClient = client;
      this.selectedClients = [];
      if (
        this.selectedClient.shipping_addresses &&
        this.selectedClient.shipping_addresses.length > 0
      ) {
        if (this.selectedClient.shipping_addresses.length === 1) {
          this.address_id = this.selectedClient.shipping_addresses[0].id;
          return;
        }
        this.selectedClient.shipping_addresses.forEach((address) => {
          if (address.is_default) {
            this.address_id = address.id;
          }
        });
      }
    },
    getFirstImageLink(product) {
      if (product.photos && product.photos.length > 0) {
        return `${this.$baseURL}storage/${product.photos[0].file_path}`;
      }
      return "/images/no-image.png";
    },
    changeAmountInCart(event, product) {
      const quantity = event.target.value;
      if (quantity < 1 || quantity > product.product.quantity) {
        this.showError(
          this.$t("pleaseChooseAValueBetween1And") +
            " " +
            product.product.quantity
        );
        event.target.value = product.quantity;
        return;
      }

      const product_index = this.products.findIndex((prd) => {
        return prd.id === product.id;
      });
      this.products[product_index].quantity = quantity;
    },
    getTotalPrice(product) {
      return product.quantity * product.price;
    },
    // removeFromCart(product) {
    //   const product_index = this.products.findIndex((prd) => {
    //     return prd.id === product.id;
    //   });
    //   this.products.splice(product_index, 1);
    // },
    changePayment() {
      if (this.payment.method === "cash") {
        this.payment.walletType = "smilepay";
        this.payment.transaction_id = "";
      }
    },
    async updateOrder() {
      this.feedback = [];
      if (this.fullPriceIsNotPaid) {
        this.setFeedback("error", "paid");
      }

      if (this.amountPaidGreaterThanRemainingAmount) {
        this.setFeedback("error", "paid");
      }

      if (
        this.payment.method === "wallet" &&
        this.payment.transaction_id === ""
      ) {
        this.setFeedback("error", "transaction_id");
      }
      if (this.feedback.length !== 0) {
        return;
      }

      // https://stackoverflow.com/questions/54907549/keep-only-selected-keys-in-every-object-from-array
      const cart_keys_to_submit = ["id", "quantity", "product_id"];

      const redux = (array) =>
        array.map((o) =>
          cart_keys_to_submit.reduce((acc, curr) => {
            acc[curr] = o[curr];
            return acc;
          }, {})
        );

      const data = {
        id: this.id,
        total: this.cartTotal,
        payment_method: this.payment.method,
        type_of_wallet: this.payment.walletType,
        transaction_id: this.payment.transaction_id,
        amount_paid: this.payment.paid === "" ? 0 : this.payment.paid,
        amount_remaining: this.payment.remaining,
        address_id: this.address_id,
        client_id: this.selectedClient.id,
        cart: redux(this.products),
      };

      console.log(data);

      try {
        const response = await this.$store.dispatch(
          "catalog/updateOrder",
          data
        );
        if (response.status === 200) {
          this.$router.push(`/catalog/invoice/${this.id}`);
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else if (!!err.response && err.response.status === 403) {
          this.showError(this.$t("thisOrderCannotBeUpdated"));
        } else if (err.response.status === 400) {
          this.catchBadRequestError(
            err,
            this.$t("theRemainingAmountIsGreaterThanYourAvailableCreditLimit")
          );
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
    addBtnHandler(product) {
      if (this.checkIfInCart(product.id)) {
        this.removeFromCart(product);
      } else {
        this.addToCart(product);
      }
    },
    checkIfInCart(product_id) {
      const exists = this.products.find((prd) => {
        return prd.product_id === product_id;
      });
      return exists ? true : false;
    },
    addToCart(product) {
      const prd = {
        id: -1,
        quantity: 1,
        price: product.price,
        product_id: product.id,
        product: product,
      };
      this.products.push(prd);
    },
    removeFromCart(product) {
      const product_index = this.products.findIndex((prd) => {
        return prd.product_id === product.id;
      });
      this.products.splice(product_index, 1);
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}
</style>