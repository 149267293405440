const axios = require('axios').default;
export default {
    submitLead(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/leads";
        let submit_data = payload;
        return axios({
            method: submit_method,
            url: uri,
            data: submit_data,
        });
    },

    getLead(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = `/api/v1/leads/${payload.id}`;
        return axios({ method: submit_method, url: uri });
    },

    updateLead(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/leads/${payload.id}`;
        let submit_data = payload;

        return axios({
            method: submit_method,
            url: uri,
            data: submit_data,
        });
    },

    deleteLead(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/leads/${payload}`;
        let submit_data = payload;
        return axios({
            method: submit_method,
            url: uri,
            data: submit_data,
        });
    },
};
