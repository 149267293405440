<template>
  <div class="modal-div">
    <div @click="$emit('close')" class="modal-div"></div>
    <dialog class="dialog" open>
      <div class="modal d-block pos-static">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">{{ $t("updateOrderStatus") }}</h6>
            <button
              @click="$emit('close')"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label"
                      >{{ $t("orderStatus") }}<span>*</span></label
                    >
                    <div class="input-group">
                      <select
                        v-model="status"
                        class="form-control"
                        :class="{
                          'border-red':
                            findFieldinFeedback('status') && status === '',
                        }"
                      >
                        <option value="processing">
                          {{ $t("processing") }}
                        </option>
                        <option value="dispatched">
                          {{ $t("dispatched") }}
                        </option>
                        <option value="in transit">
                          {{ $t("inTransit") }}
                        </option>
                        <option value="delivered">{{ $t("delivered") }}</option>
                      </select>
                    </div>
                    <p
                      v-if="findFieldinFeedback('status') && status === ''"
                      class="text-danger"
                    >
                      {{ $t("statusCannotBeBlank") }}
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-indigo" type="button" @click="updateStatus">
              {{ $t("submit") }}
            </button>
            <button class="btn btn-light" type="button" @click="$emit('close')">
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
import notify from "../../../mixins/notify";
import setFeedback from "../../../mixins/setFeedback";
import showError from "../../../mixins/showError";
export default {
  emits: ["close", "updated"],
  mixins: [setFeedback, showError, notify],
  props: {
    orderStatus: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      requred: true,
    },
  },
  data() {
    return {
      status: this.orderStatus,
      feedback: [],
    };
  },
  methods: {
    async updateStatus() {
      this.feedback = [];
      if (this.status === "") {
        this.setFeedback("error", "status");
      }
      if (this.feedback.length !== 0) {
        return;
      }

      const data = {
        id: this.id,
        status: this.status,
      };

      try {
        const response = await this.$store.dispatch(
          "catalog/updateOrderStatus",
          data
        );
        if (response.status === 200) {
          this.$emit("updated");
          this.$store.dispatch("clients/orderUpdated");
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
  },
};
</script>

<style scoped>
.modal-div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10000;
}

.dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 10001;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  .dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>