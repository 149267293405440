const axios = require('axios').default;
export default {
    getAttributeTypes(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = "/api/v1/attribute-types";
        return axios({ method: submit_method, url: uri });
    },


    setAttributeTypes(context, payload) {
        context.commit("setAttributeTypes", payload);
    },

    submitAttributeType(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/attribute-types";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    updateAttributeType(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/attribute-types/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    deleteAttributeType(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/attribute-types/${payload}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },
};
