<template>
  <base-dialog
    v-if="showModal"
    :id="orderId"
    :order-status="orderStatus"
    @close="showModal = false"
    @updated="updated"
  >
  </base-dialog>
  <div class="table-responsive">
    <div
      id="example1_wrapper"
      class="dataTables_wrapper dt-bootstrap4 no-footer"
    >
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="dataTables_length" id="example1_length">
            <label>
              <select
                v-model="tableData.length"
                @change="getClientOrders()"
                name="example1_length"
                aria-controls="example1"
                class="
                  custom-select custom-select-sm
                  form-control form-control-sm
                "
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div id="example1_filter" class="dataTables_filter">
            <label
              ><input
                type="search"
                v-model="tableData.search"
                @input="search()"
                class="form-control form-control-sm"
                placeholder="Search..."
                aria-controls="example1"
            /></label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <datatable
            :columns="computedColumnsToDisplay"
            :sortKey="sortKey"
            :sortOrders="sortOrders"
            @sort="sortBy"
          >
            <tbody>
              <tr
                role="row"
                class="odd"
                v-for="order in orders"
                :key="order.id"
              >
                <td>
                  {{ order.created_at }}
                </td>
                <td>
                  <router-link :to="`/catalog/invoice/${order.id}`">
                    <a class="text-primary font-weight-bold fs-12">
                      {{ order.order_number }}
                    </a>
                  </router-link>
                </td>
                <td>
                  <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                  {{ order.total }}
                </td>
                <td v-if="!isDebt">
                  <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                  {{ order.amount_paid }}
                </td>
                <td>
                  <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                  {{ order.amount_remaining }}
                </td>
                <td v-if="!isDebt">
                  <span
                    @click="setOrderToUpdate(order)"
                    class="badge badge-info pointer"
                  >
                    {{ order.status }}
                  </span>
                </td>
                <td>
                  <router-link
                    :to="`/invoice/${locale}/${order.id}`"
                    target="_blank"
                  >
                    <a
                      target="_blank"
                      type="button"
                      class="btn btn-info btn-sm mr-1"
                    >
                      <i
                        class="fa fa-print"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="fa fa-print"
                      ></i>
                    </a>
                  </router-link>
                  <button
                    @click.prevent="setOrderToUpdate(order)"
                    class="btn btn-success btn-sm mr-1"
                  >
                    <i
                      class="fa fa-check-square-o"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="fa fa-check-square-o"
                    ></i>
                  </button>
                  <router-link :to="`/catalog/payment/${order.id}`">
                    <a class="btn btn-success btn-sm" v-if="isDebt">
                      <i
                        class="fa fa-money"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="fa fa-money"
                      ></i>
                    </a>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </datatable>
        </div>
      </div>
      <div class="row">
        <pagination
          :pagination="pagination"
          @prev="getClientOrders(pagination.prevPageUrl)"
          @next="getClientOrders(pagination.nextPageUrl)"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import notify from "../../../mixins/notify";
import setFeedbackMixin from "../../../mixins/setFeedback";
import showError from "../../../mixins/showError";
import _ from "lodash";

export default {
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDebt: {
      type: Boolean,
      required: false,
    },
  },
  mixins: [setFeedbackMixin, showError, notify],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("date"),
        name: "created_at",
        sortable: true,
        type: "date",
      },
      {
        label: this.$t("order#"),
        name: "order_number",
        sortable: true,
      },
      {
        label: this.$t("total"),
        name: "total",
        sortable: true,
      },
      {
        label: this.$t("paid"),
        name: "amount_paid",
        sortable: true,
      },
      {
        label: this.$t("debt"),
        name: "amount_remaining",
        sortable: true,
      },
      {
        label: this.$t("status"),
        name: "status",
        sortable: true,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.id = 1;

    return {
      feedback: [],
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "created_at",
      tableData: {
        tableColumns: [],
        draw: 0,
        length: 5,
        search: "",
        column: 0,
        dir: "desc",
        id: "",
        isDebt: false,
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      orders: [],
      is_sending: "",
      showModal: false,
      orderId: "",
      orderStatus: "",
    };
  },
  created() {
    this.tableData.id = this.id;
    if (this.isDebt) {
      this.tableData.isDebt = true;
    }
    this.getClientOrders();
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
    locale() {
      return this.$store.getters.locale;
    },
    orderUpdated() {
      return this.$store.getters["clients/orderUpdated"];
    },
    computedColumnsToDisplay() {
      if (this.isDebt) {
        return this.columns.filter((column) => {
          return column.name !== "amount_paid" && column.name !== "status";
        });
      }
      return this.columns;
    },
    computedColumns() {
      if (this.isDebt) {
        return [
          "created_at",
          "order_number",
          "total",
          "amount_remaining",
          "actions",
        ];
      }
      return [
        "created_at",
        "order_number",
        "total",
        "amount_paid",
        "amount_remaining",
        "status",
        "actions",
      ];
    },
  },
  watch: {
    orderUpdated: function () {
      this.getClientOrders();
    },
  },
  methods: {
    search: _.debounce(function () {
      this.getClientOrders();
    }, 500),
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getClientOrders();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    getClientOrders(url = "/api/v1/paginated-orders") {
      this.tableData.tableColumns = this.computedColumns;
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data }).then(
        (response) => {
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.orders = data.orders.data;
            this.configPagination(data.orders);
          }
        }
      );
    },
    async downloadInvoice(id) {
      this.is_sending = id;
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = {
        id: id,
      };
      let url = "/api/v1/orders/download";

      try {
        const response = await this.$axios({
          method: submit_method,
          url: url,
          data: submit_data,
        });
        if (response.status === 200) {
          window.open(response.data.url, "Download");
          this.is_sending = "";
        }
      } catch (error) {
        this.is_sending = "";
        console.log("Error");
      }
    },
    async setOrderToUpdate(order) {
      this.orderId = order.id;
      this.orderStatus = order.status;
      this.showModal = true;
    },
    updated() {
      this.notify(this.$t("saved"));
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.badge,
i {
  cursor: pointer;
}
</style>