<template>
  <div class="row">
    <div class="col-12">
      <div class="card loader-card">
        <div class="card-body loader-body">
          <div class="dimmer active">
            <div class="spinner4">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>