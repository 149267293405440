<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("pages") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("pages") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header pr-0">
          <div class="row w-100">
            <div class="col-6 offset-6 text-right">
              <router-link to="/pages/create">
                <a class="pull-right btn btn-sm btn-primary">{{
                  $t("addNew")
                }}</a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <div
                  id="example1_wrapper"
                  class="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select
                            v-model="tableData.length"
                            @change="getPages()"
                            name="example1_length"
                            aria-controls="example1"
                            class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            "
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable
                        :columns="columns"
                        :sortKey="sortKey"
                        :sortOrders="sortOrders"
                        @sort="sortBy"
                        :tableIsSearchable="tableIsSearchable"
                        @search="searchField"
                      >
                        <tbody>
                          <tr
                            role="row"
                            class="odd"
                            v-for="page in pages"
                            :key="page.id"
                          >
                            <td>
                              {{ page.title }}
                            </td>
                            <td>
                              {{ page.link }}
                            </td>
                            <td>
                              <span
                                @click="updateStatus(page)"
                                :class="{
                                  'badge-success': page.active === 1,
                                  'badge-danger': page.active === 0,
                                }"
                                class="badge"
                              >
                                {{ fixStatus(page.active) }}
                              </span>
                            </td>
                            <td>
                              <span class="mr-2">
                                <router-link :to="`/pages/${page.id}/edit`">
                                  <a
                                    ><i
                                      class="fa fa-pencil"
                                      data-toggle="tooltip"
                                      title="fa fa-pencil"
                                    ></i
                                  ></a>
                                </router-link>
                              </span>
                              <span>
                                <i
                                  @click="deletePage(page.id)"
                                  class="text-danger fa fa-trash edit-icon"
                                  data-toggle="tooltip"
                                  title="fa fa-trash"
                                ></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination
                      :pagination="pagination"
                      @prev="getPages(pagination.prevPageUrl)"
                      @next="getPages(pagination.nextPageUrl)"
                    >
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import showErrorMixin from "../../mixins/showError";
import setFeedbackMixin from "../../mixins/setFeedback";
import notifyMixin from "../../mixins/notify";
import checkToken from "../../mixins/checkToken";
import contact from "../../mixins/contact";
import _ from "lodash";
export default {
  mixins: [showErrorMixin, setFeedbackMixin, notifyMixin, checkToken, contact],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("title"),
        name: "title",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("link"),
        name: "link",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      // {
      //   label: this.$t("description"),
      //   name: "description",
      //   sortable: true,
      //   searchable: true,
      //   relationship: false,
      // },
      {
        label: this.$t("status"),
        name: "status",
        sortable: false,
        searchable: false,
        relationship: false,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
        searchable: false,
        relationship: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.title = 1;
    return {
      tableIsSearchable: true,
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "title",
      tableData: {
        length: 10,
        column: 0,
        dir: "asc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      pages: [],
      loading: true,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  created() {
    this.getPages();
  },
  methods: {
    searchField(searchValue, searchField, relationship, relationship_field) {
      this.tableData.search = searchValue;
      this.tableData.field = searchField;
      this.tableData.relationship = relationship;
      this.tableData.relationship_field = relationship_field;

      this.getPages();
    },
    search: _.debounce(function () {
      this.getPages();
    }, 500),
    sortBy(key) {
      this.sortKey = key; // Name of collumn being sorted
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getPages();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.meta.last_page;
      this.pagination.currentPage = data.meta.current_page;
      this.pagination.total = data.meta.total;
      this.pagination.lastPageUrl = data.links.last;
      this.pagination.nextPageUrl = data.links.next;
      this.pagination.prevPageUrl = data.links.prev;
      this.pagination.from = data.meta.from;
      this.pagination.to = data.meta.to;
    },

    getPages(url = "/api/v1/pages") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      this.$axios({
        method: "GET",
        url: url,
        params: this.tableData,
      })
        .then((response) => {
          this.loading = false;
          let data = response.data;
          this.pages = data.data;
          this.configPagination(data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async deletePage(id) {
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        icon: "warning",
        text: this.$t("thisCannotBeReversed"),
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("delete"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch("pages/deletePage", id);
          if (response.status === 204) {
            this.notify(this.$t("deleted"));
            this.getPages();
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
    async updateStatus(page) {
      const active = page.active === 1 ? 0 : 1;
      let text;
      if (page.active === 1) {
        text = this.$t("doYouWantToInactivateThisPage");
      } else {
        text = this.$t("doYouWantToActivateThisPage");
      }
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        text: text,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("update"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch("pages/updateStatus", {
            id: page.id,
            active: active,
          });
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.getPages();
            this.mode = "";
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
          console.log("Error");
        }
      }
    },
    fixStatus(status) {
      if (status === 1) {
        return this.$t("active");
      }
      return this.$t("inactive");
    },
  },
};
</script>

<style scoped>
.badge,
.edit-icon {
  cursor: pointer;
}
</style>