<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("dashboard") }}</h4>
    </div>
  </div>
  <loader v-if="loading"></loader>
  <div class="row" v-if="!loading">
    <div class="col-lg-4" v-if="clientsCount !== null">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="mb-2 fs-18 text-muted">{{ $t("clients") }}</div>
              <h3 class="font-weight-bold mb-1">{{ clientsCount }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4" v-if="totalDebt != null">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="mb-2 fs-18 text-muted">{{ $t("totalDebts") }}</div>
              <h3 class="font-weight-bold mb-1">
                <span v-if="loggedUser.company">
                  {{ loggedUser.company.currency.symbol }}
                </span>
                {{ totalDebt.toFixed(2) }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4" v-if="totalPayment != null">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="mb-2 fs-18 text-muted">{{ $t("totalPayments") }}</div>
              <h3 class="font-weight-bold mb-1">
                <span v-if="loggedUser.company">
                  {{ loggedUser.company.currency.symbol }}
                </span>
                {{ totalPayment.toFixed(2) }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-deck" v-if="!loading">
    <div class="col-xl-12 col-md-12 col-lg-7">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">{{ $t("recentOrders") }}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy"
                        :tableIsSearchable="tableIsSearchable" @search="searchField">
                        <tbody>
                          <tr role="row" class="odd" v-for="order in orders" :key="order.id">
                            <td>
                              <router-link :to="`/catalog/invoice/${order.id}`">
                                <a class="text-primary font-weight-bold fs-12">
                                  {{ order.order_number }}
                                </a>
                              </router-link>
                            </td>
                            <td v-if="order.client">
                              <router-link :to="`/clients/${order.client.id}`">
                                <a class="text-primary font-weight-bold fs-12">
                                  {{
                                      order.client
                                        ? `${order.client.first_name} ${order.client.last_name}`
                                        : ""
                                  }}
                                </a>
                              </router-link>
                            </td>
                            <td>
                              {{ order.created_at }}
                            </td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{ order.total }}
                            </td>
                            <td>
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span>
                              {{
                                  order.amount_remaining
                                    ? order.amount_remaining
                                    : 0
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination :pagination="pagination" @prev="getDebts(pagination.prevPageUrl)"
                      @next="getDebts(pagination.nextPageUrl)">
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row row-deck" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">{{ $t("dailyDebtCollection") }}</h3>
          <div class="ml-auto">
            <label><input type="date" v-model="chartData.from" class="form-control form-control-sm"
                :placeholder="$t('search')" aria-controls="example1" /></label>
            {{ $t("to") }}
            <label><input type="date" v-model="chartData.to" class="form-control form-control-sm"
                placeholder="Search..." aria-controls="example1" /></label>
            <button class="btn btn-primary btn-sm ml-1" href="#" @click="getDailyCollections">
              {{ $t("go") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <line-chart :chartData="dailyCollections" :options="chartOptions" :chartColors="dailyChartColors"
                label="Total Collections" v-if="dailyCollections.length > 0 && drawDaily"></line-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">{{ $t("monthlyDebtCollection") }}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <line-chart :chartData="monthlyCollections" :options="chartOptions" :chartColors="monthlyChartColors"
                label="Monthly Collections" v-if="monthlyCollections.length > 0 && drawMonthly"></line-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkToken from "../mixins/checkToken";
import moment from "moment";
import LineChart from "./orders/charts/LineChart.vue";

export default {
  components: {
    LineChart,
  },
  mixins: [checkToken],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("orderNumber"),
        name: "order_number",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("client"),
        name: "client",
        sortable: false,
        searchable: true,
        relationship: true,
        relationship_field: "first_name",
      },
      {
        label: this.$t("date"),
        name: "created_at",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("total"),
        name: "total",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("debt"),
        name: "amount_remaining",
        sortable: true,
        searchable: true,
        relationship: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.id = 1;
    return {
      tableIsSearchable: true,
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "id",
      tableData: {
        tableColumns: columns.map(({ name }) => name),
        draw: 0,
        length: 5,
        search: "",
        column: 0,
        field: "",
        relationship: false,
        relationship_field: "",
        dir: "desc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      orders: [],

      clientsCount: null,
      clientsThisMonth: null,
      month: null,
      totalDebt: null,
      debtPercent: null,
      totalPayment: null,
      paymentPercent: null,

      dailyCollections: [],
      monthlyCollections: [],
      dailyChartColors: {
        borderColor: "#251F47",
        pointBorderColor: "#260F26",
        pointBackgroundColor: "#858EAB",
        backgroundColor: "#858EAB",
      },
      monthlyChartColors: {
        borderColor: "#251F47",
        pointBorderColor: "#260F26",
        pointBackgroundColor: "#858EAB",
        backgroundColor: "#858EAB",
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      chartData: {
        from: "",
        to: "",
      },
      drawDaily: false,
      drawMonthly: false,
      loading: true,
    };
  },
  created() {
    this.getNumbers();
    this.getDebts();

    this.chartData.from = moment().subtract(1, "months").format("YYYY-MM-DD");
    this.chartData.to = moment().format("YYYY-MM-DD");
    this.getDailyCollections();
    this.getMonthlyCollections();
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    loggedUser() {
      return this.$store.getters.user;
    },
  },
  methods: {
    getDailyCollections() {
      this.drawDaily = false;
      this.dailyCollections = [];
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.chartData;
      let url = "/api/v1/charts/collections/daily";

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data.dailyCollections;
          data.forEach((d) => {
            const date = moment(d.date, "YYYY-MM-DD").format("MM/DD");
            const { total } = d;
            this.dailyCollections.push({ date, total: total });
            this.drawDaily = true;
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getMonthlyCollections() {
      this.drawMonthly = false;
      this.monthlyCollections = [];
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      const monthlyCollectionRange = {
        from: moment().subtract(12, "months").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      };

      let submit_method = "POST";
      let submit_data = monthlyCollectionRange;
      let url = "/api/v1/charts/collections/monthly";

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data.monthlyCollections.reverse();
          data.forEach((d) => {
            const date = moment(d.months, "MMMM-YYYY").format("MM/YY");
            const { total } = d;
            this.monthlyCollections.push({ date, total: total });
            this.drawMonthly = true;
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log("Error");
          console.log(error);
          this.loading = false;
        });
    },
    debtPortion() {
      return (this.debtPercent / 100).toFixed(2);
    },
    getNumbers() {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let url = "/api/v1/dashboard";
      let submit_method = "GET";

      this.$axios({ method: submit_method, url: url })
        .then((response) => {
          this.clientsCount = response.data.clientsCount;
          this.clientsThisMonth = response.data.clientsThisMonth;
          this.month = response.data.month;
          this.totalDebt = response.data.totalDebt;
          this.debtPercent = response.data.debtPercent;
          this.totalPayment = response.data.totalPayment;
          this.paymentPercent = response.data.paymentPercent;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    searchField(searchValue, searchField, relationship, relationship_field) {
      this.tableData.search = searchValue;
      this.tableData.field = searchField;
      this.tableData.relationship = relationship;
      this.tableData.relationship_field = relationship_field;
      this.getDebts();
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getDebts();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    getDebts(url = "/api/v1/orders/all") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data }).then(
        (response) => {
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.orders = data.orders.data;
            this.configPagination(data.orders);
            this.loading = false;
          }
        }
      );
    },
    async downloadInvoice(id) {
      this.is_sending = id;
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = {
        id: id,
      };
      let url = "/api/v1/orders/download";

      try {
        const response = await this.$axios({
          method: submit_method,
          url: url,
          data: submit_data,
        });
        if (response.status === 200) {
          window.open(response.data.url, "Download");
          this.is_sending = "";
        }
      } catch (error) {
        this.is_sending = "";
        console.log(error);
      }
    },
  },
};
</script>

<style>
a {
  cursor: pointer;
}
</style>