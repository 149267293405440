<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("users") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/users">
            <a>{{ $t("users") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ submitBtnText }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" id="lead-div" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{ typeOfUser }} {{ $t("user") }}</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="submitUserHandler">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("title") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <prime-dropdown
                      :class="{
                        'border-red':
                          findFieldinFeedback('title') && form.title === '',
                      }"
                      v-model="form.title"
                      :options="titles"
                      optionLabel="name"
                      optionValue="name"
                      :filter="true"
                      :placeholder="$t('selectATitle')"
                    >
                      <template #label="slotProps">
                        <div
                          class="country-item country-item-value"
                          v-if="slotProps.label"
                        >
                          <div>{{ slotProps }}</div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="country-item">
                          <div>{{ slotProps.option.name }}</div>
                        </div>
                      </template>
                    </prime-dropdown>
                  </div>
                  <p
                    v-if="findFieldinFeedback('title') && form.title === ''"
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("firstName") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('first_name') &&
                          form.first_name === '',
                      }"
                      v-model="form.first_name"
                      type="text"
                      class="form-control"
                      :placeholder="$t('firstName')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('first_name') &&
                      form.first_name.trim() === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("lastName") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('last_name') &&
                          form.last_name === '',
                      }"
                      v-model="form.last_name"
                      type="text"
                      class="form-control"
                      :placeholder="$t('lastName')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('last_name') &&
                      form.last_name.trim() === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("userName") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('username') &&
                          form.username === '',
                      }"
                      v-model="form.username"
                      type="text"
                      class="form-control"
                      :placeholder="$t('userName')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('username') &&
                      form.username.trim() === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("phone") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('countryCode') &&
                          form.countryCode === '',
                      }"
                      v-model="form.countryCode"
                      type="text"
                      class="form-control"
                      :placeholder="$t('countryCode')"
                    />
                    <input
                      style="width: 75%"
                      :class="{
                        'border-red':
                          findFieldinFeedback('phone') &&
                          (form.phone === '' ||
                            (form.phone.length !== 9 &&
                              form.phone.length !== 10)),
                      }"
                      v-model="form.phone"
                      type="text"
                      class="form-control"
                      :placeholder="$t('phone')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('phone') &&
                      (form.phone === '' ||
                        (form.phone.length !== 9 && form.phone.length !== 10))
                    "
                    class="text-danger"
                  >
                    {{ $t("pleaseEnterAMobileNumberWith9or10Characters") }}
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("email") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('email') &&
                          (form.email === '' ||
                            !form.email.includes('@') ||
                            !form.email.includes('.')),
                      }"
                      v-model="form.email"
                      type="text"
                      class="form-control"
                      :placeholder="$t('email')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('email') &&
                      (!form.email.includes('@') || !form.email.includes('.'))
                    "
                    class="text-danger"
                  >
                    {{ $t("emailMustContainAnAndAtLeastOneDot") }}
                    <span v-if="form.email.trim() === ''">
                      {{ $t("andMustNotBeBlank") }}</span
                    >
                  </p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("role") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <prime-dropdown
                      :class="{
                        'border-red':
                          findFieldinFeedback('user_role') &&
                          form.user_role === '',
                      }"
                      v-model="form.user_role"
                      :options="roles"
                      optionLabel="name"
                      optionValue="id"
                      :filter="true"
                      :placeholder="$t('selectARole')"
                    >
                      <template #label="slotProps">
                        <div
                          class="country-item country-item-value"
                          v-if="slotProps.label"
                        >
                          <div>{{ slotProps }}</div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="country-item">
                          <div>{{ slotProps.option.name }}</div>
                        </div>
                      </template>
                    </prime-dropdown>
                    <p
                      v-if="
                        findFieldinFeedback('user_role') &&
                        form.user_role === ''
                      "
                      class="text-danger"
                    >
                      {{ $t("thisFieldIsRequired") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">
                    {{ $t("photo") }}
                    <span
                      ><a v-if="isPhotoLink" :href="photoLink" target="_blank"
                        ><small> ({{ $t("viewCurrent") }}) </small></a
                      ></span
                    >
                  </label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input
                        :class="{
                          'border-red':
                            findFieldinFeedback('photo') && form.photo === '',
                        }"
                        v-on:change="onChange"
                        type="file"
                        class="custom-file-input"
                        name="example-file-input-custom"
                      />
                      <label class="custom-file-label">{{
                        $t("choosePhoto")
                      }}</label>
                    </div>
                    <p
                      v-if="findFieldinFeedback('photo') && form.photo === null"
                      class="text-danger"
                    >
                      {{ $t("chechPhoto") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :class="{
                    'btn-loading': is_sending,
                  }"
                  :disabled="is_sending"
                >
                  {{ submitBtnText }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clearFormMixin from "../../../mixins/clearForm";
import showErrorMixin from "../../../mixins/showError";
import setFeedbackMixin from "../../../mixins/setFeedback";
import notifyMixin from "../../../mixins/notify";
import catchForbiddenError from "../../../mixins/catchForbiddenError";
export default {
  mixins: [
    clearFormMixin,
    showErrorMixin,
    setFeedbackMixin,
    notifyMixin,
    catchForbiddenError,
  ],
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      is_sending: false,
      validation_errors: {},
      feedback: [],
      form: {
        title: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        countryCode: "",
        phone: "",
        user_role: "",
        photo: null,
        force_pw_change: false,
        credit: "",
      },
      mode: "",
      loading: true,
    };
  },
  created() {
    this.form.title = this.loggedUser.title;
    this.form.first_name = this.loggedUser.first_name;
    this.form.last_name = this.loggedUser.last_name;
    this.form.username = this.loggedUser.username;
    this.form.countryCode = this.loggedUser.country_code_only;
    this.form.countryCode = this.loggedUser.country_code_only;
    this.form.phone = this.loggedUser.phone_only;
    this.form.email = this.loggedUser.email;
    this.form.user_role = this.loggedUser.role.id;
    this.form.photo = this.loggedUser.photo ? this.loggedUser.photo : "";
    this.loadMasters();
    this.loadRoles();
  },
  computed: {
    isPhotoLink() {
      return this.form.photo && !(this.form.photo instanceof File);
    },
    photoLink() {
      if (this.form.photo) {
        return `${this.$baseURL}storage/${this.form.photo.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
    titles() {
      return this.$store.getters["masters/titles"];
    },
    token() {
      return this.$store.getters.token;
    },
    roles() {
      return this.$store.getters["roles/roles"];
    },
    submitBtnText() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("submit");
    },
    typeOfUser() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("new");
    },
    loggedUser() {
      return this.$store.getters.user;
    },
    selectedRole() {
      const role = this.roles.find((single) => {
        return single.id === this.form.user_role;
      });
      if (role) {
        return role.name;
      }
      return "";
    },
  },
  methods: {
    async loadRoles() {
      try {
        const response = await this.$store.dispatch("roles/getRoles");
        this.loading = false;
        if (response.status === 200) {
          this.$store.dispatch("roles/setRoles", response.data.roles);
          this.form.name = null;
        }
      } catch (err) {
        console.log("Error");
        this.loading = false;
      }
    },
    async loadMasters() {
      this.setFeedback(null, null, null);

      try {
        const response = await this.$store.dispatch("masters/getMasters", {
          type: "all",
        });
        this.loading = false;
        if (response.status === 200) {
          this.$store.dispatch("masters/setMasters", response.data.masters);
        }
      } catch (err) {
        console.log("Error");
        this.loading = false;
      }
    },
    onChange(event) {
      this.form.photo = event.target.files[0];
      event.target.value = null;
    },
    async submitUserHandler() {
      this.feedback = [];

      if (!this.validate()) {
        return;
      }

      console.log("this.form");
      console.log(this.form);

      this.is_sending = true;

      const data = new FormData();

      data.append("title", this.form.title);
      data.append("first_name", this.form.first_name);
      data.append("last_name", this.form.last_name);
      data.append("email", this.form.email);
      data.append("username", this.form.username);
      data.append("country_code", this.form.countryCode);
      data.append("phone", this.form.phone);
      data.append("user_role", this.form.user_role);

      if (this.form.photo && this.form.photo instanceof File) {
        data.append("uploaded_photo", this.form.photo);
      }

      data.append("id", this.loggedUser.id);
      data.append("_method", "PUT");

      try {
        const response = await this.$store.dispatch("users/updateUser", data);
        this.is_sending = false;
        if (response.status === 200) {
          console.log("response.data");
          console.log(response.data);
          this.notify(this.$t("updated"));
          this.$store.dispatch("setUser", {
            user: response.data,
          });
          this.$router.replace("/profile");
        }
      } catch (err) {
        this.is_sending = false;
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    validate() {
      if (this.form.title === "") {
        this.setFeedback("error", "title");
      }
      if (this.form.first_name.trim() === "") {
        this.setFeedback("error", "first_name");
      }
      if (this.form.last_name.trim() === "") {
        this.setFeedback("error", "last_name");
      }
      if (this.form.username.trim() === "") {
        this.setFeedback("error", "username");
      }
   
      if (this.form.countryCode.trim() === "") {
        this.setFeedback("error", "countryCode");
      }
      if (
        this.form.phone === "" ||
        (this.form.phone.length !== 9 && this.form.phone.length !== 10)
      ) {
        this.setFeedback("error", "phone");
      }
      if (
        this.form.email.trim() === "" ||
        !this.form.email.includes("@") ||
        !this.form.email.includes(".")
      ) {
        this.setFeedback("error", "email");
      }
      if (this.form.user_role === "") {
        this.setFeedback("error", "user_role");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}
</style>