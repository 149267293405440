<template>
  <div class="col-12">
    <h3 class="card-title">Change Password</h3>
    <form class="form-horizontal" @submit.prevent="submitForm">
      <div class="form-group row">
        <label class="col-md-3 form-label">New Password</label>
        <div class="col-md-9">
          <input v-model="password" type="password" class="form-control" />
          <p v-if="
            feedback.field === 'password' &&
            (password === null || password === '')
          " class="text-danger">
            {{ feedback.message }}
          </p>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 form-label">Confirm New Password</label>
        <div class="col-md-9">
          <input v-model="password_confirmation" type="password" class="form-control" />
          <p v-if="
            feedback.field === 'password_confirmation' &&
            (password_confirmation === null ||
              password_confirmation === '' ||
              password_confirmation !== password)
          " class="text-danger">
            {{ feedback.message }}
          </p>
        </div>
      </div>
      <div class="form-group row mb-0">
        <div class="col-12">
          <button type="submit" class="pull-right btn btn-sm btn-primary">
            Change
          </button>
        </div>
      </div>
    </form>
  </div>
</template>



<script>
export default {
  props: ["user"],
  emits: ["update-password"],
  data() {
    return {
      feedback: {
        alert_type: null,
        field: null,
        message: null,
      },
      password: null,
      password_confirmation: null,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
  },
  created() { },
  methods: {
    async submitForm() {
      this.setFeedback(null, null, null);

      if (!this.validate()) {
        return;
      }

      try {
        const response = await this.$store.dispatch("changePassword", {
          token: this.token,
          password_confirmation: this.password_confirmation,
          password: this.password,
        });

        if (response.status === 200) {
          this.$store.dispatch("setToken", {
            token: response.data.token,
          });
          this.$emit("update-password");
        }
      } catch (err) {
        console.log(err)
      }
    },
    validate() {
      if (this.password === null || this.password.length < 8) {
        this.setFeedback(
          "error",
          "Please enter value with at least 8 characters",
          "password"
        );
        return false;
      }
      if (
        this.password_confirmation === null ||
        this.password_confirmation.length < 8
      ) {
        this.setFeedback(
          "error",
          "Please enter value with at least 8 characters",
          "password_confirmation"
        );
        return false;
      }
      if (this.password !== this.password_confirmation) {
        this.setFeedback(
          "error",
          "Does not match with password",
          "password_confirmation"
        );
        return false;
      }
      return true;
    },
    setFeedback(type, message, field) {
      this.feedback.alert_type = type;
      this.feedback.field = field;
      this.feedback.message = message;
    },
  },
};
</script>