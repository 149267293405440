<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("updateCredit") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("updateCredit") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-7">
      <div class="card" v-if="Object.keys(selectedUser).length === 0">
        <div class="card-header">
          <h3 class="card-title">{{ $t("searchUser") }}</h3>
        </div>
        <div class="card-body">
          <input
            class="form-control form-control-sm mb-4"
            :class="{
              'border-red':
                findFieldinFeedback('search') && tableData.search === '',
            }"
            :placeholder="$t('searchUserNameOrMobile')"
            v-model="tableData.search"
            @blur="getUsers"
            type="text"
          />
          <div
            class="mb-3"
            v-if="findFieldinFeedback('search') && tableData.search === ''"
          >
            <span class="d-block text-danger">{{
              $t("pleaseEnterNameOrMobileNumber")
            }}</span>
          </div>
          <div class="mb-3" v-if="not_found">
            <span class="d-block text-danger">{{
              $t("mobithereIsNoUserWithTheseDetailsle")
            }}</span>
          </div>
        </div>
      </div>
      <div class="table-responsive mb-5" v-if="selectedUsers.length > 0">
        <table
          class="table card-table table-vcenter text-nowrap table-primary mb-0"
        >
          <thead class="bg-primary text-white">
            <tr>
              <th class="text-white">{{ $t("name") }}</th>
              <th class="text-white">{{ $t("mobile") }}</th>
              <th class="text-white">{{ $t("credit") }}</th>
              <th class="text-white"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in selectedUsers" :key="user.id">
              <td>{{ user.first_name }} {{ user.last_name }}</td>
              <td>{{ user.phone }}</td>
              <td>
                  <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span> {{ user.credit }}
              </td>
              <td>
                <button
                  class="btn btn-sm btn-success"
                  type="button"
                  @click="selectUser(user)"
                >
                  <i class="fe fe-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="card overflow-hidden"
        v-if="Object.keys(selectedUser).length > 0"
      >
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("userDetails") }}</h3>
          <div class="card-options">
            <button class="btn btn-sm text-white" @click="changeUser">
              {{ $t("changeUser") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="dropdown user-pro-body text-center" data-v-62b8754f="">
            <div class="user-pic" data-v-62b8754f="">
              <img
                :src="imgLink"
                alt="user-img"
                class="avatar-xl rounded-circle mb-1"
                data-v-62b8754f=""
              />
            </div>
          </div>
          <h4 class="card-title">{{ $t("personalDetails") }}</h4>
          <div class="table-responsive">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("name") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    {{ selectedUser.first_name }}
                    {{ selectedUser.last_name }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("email") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    <a
                      :href="getMailLink(selectedUser.email)"
                      target="_blank"
                      >{{ selectedUser.email }}</a
                    >
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("phone") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    <a
                      :href="getPhoneLink(selectedUser.phone)"
                      target="_blank"
                      >{{ selectedUser.phone }}</a
                    >
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("credit") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                     <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                    {{ selectedUser.credit }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        class="card overflow-hidden"
        v-if="Object.keys(selectedUser).length > 0"
      >
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("newCreditLimit") }}</h3>
        </div>
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("amount") }}<span>*</span>
                  </label>
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('amount') &&
                          (amount === '' || amount === 0),
                      }"
                      v-model.number="amount"
                      type="number"
                      min="0"
                      step="any"
                      class="form-control"
                      placeholder="Amount"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('amount') &&
                      (amount === '' || amount === 0)
                    "
                    class="text-danger"
                  >
                    {{ $t("pleaseEnterAnAmount") }}
                  </p>
                </div>
              </div>
              <!-- <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("creditLimitStartDate") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('creditLimitStartDate') &&
                          creditLimitStartDate === '',
                      }"
                      v-model="creditLimitStartDate"
                      type="date"
                      class="form-control"
                      :placeholder="$t('creditLimitStartDate')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('creditLimitStartDate') &&
                      creditLimitStartDate === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div> -->
              <!-- <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("creditLimitEndDate") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('creditLimitEndDate') &&
                          creditLimitEndDate === '',
                      }"
                      v-model="creditLimitEndDate"
                      type="date"
                      class="form-control"
                      :placeholder="$t('creditLimitEndDate')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('creditLimitEndDate') &&
                      creditLimitEndDate === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div> -->
            </div>
          </form>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12">
              <div class="card-options pull-right">
                <button
                  @click="submit"
                  type="submit"
                  class="d-block btn btn-success btn-sm"
                >
                  {{ $t("update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import fixStatus from "../../mixins/fixStatus";
import checkToken from "../../mixins/checkToken";
import setFeedbackMixin from "../../mixins/setFeedback";
import contact from "../../mixins/contact";
// import _ from "lodash";
export default {
  mixins: [
    showErrorMixin,
    notifyMixin,
    checkToken,
    fixStatus,
    setFeedbackMixin,
    contact,
  ],
  data() {
    return {
      user_id: "",
      feedback: [],
      tableData: {
        draw: 0,
        search: "",
      },
      amount: "",
      // creditLimitStartDate: "",
      // creditLimitEndDate: "",
      selectedUsers: [],
      selectedUser: {},
      not_found: false,
      loading: false,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    loggedUser() {
      return this.$store.getters.user;
    },
    imgLink() {
      if (this.selectedUser.photo) {
        return `${this.$baseURL}storage/${this.selectedUser.photo.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
  },
  methods: {
    getUsers() {
      this.loading = true;
      this.feedback = [];
      if (this.tableData.search === "") {
        this.setFeedback("error", "search");
      }
      if (this.feedback.length !== 0) {
        this.loading = false;
        return;
      }
      this.not_found = false;
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
      let url = "/api/v1/users/search-update-credit";
      let submit_method = "POST";
      let submit_data = this.tableData;
      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.selectedUsers = data.users;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log("Error");
          if (!!error.response && error.response.status === 404) {
            this.not_found = true;
          }
        });
    },
    selectUser(user) {
      this.selectedUser = user;
      this.amount = user.credit;
      // this.creditLimitStartDate = user.credit_limit_start_date;
      // this.creditLimitEndDate = user.credit_limit_end_date;
      this.selectedUsers = [];
    },
    changeUser() {
      this.tableData.search = "";
      this.tableData.draw = 0;
      this.selectedUser = {};
    },
    async submit() {
      this.feedback = [];
      if (this.amount === "" || this.amount === 0) {
        this.setFeedback("error", "amount");
      }
      // if (this.creditLimitStartDate === "") {
      //   this.setFeedback("error", "creditLimitStartDate");
      // }
      // if (this.creditLimitEndDate === "") {
      //   this.setFeedback("error", "creditLimitEndDate");
      // }
      if (this.feedback.length !== 0) {
        return;
      }

      const data = new FormData();
      data.append("id", this.selectedUser.id);
      data.append("credit", this.amount);
      // data.append("credit_limit_start_date", this.creditLimitStartDate);
      // data.append("credit_limit_end_date", this.creditLimitEndDate);
      data.append("_method", "PUT");

      try {
        const response = await this.$store.dispatch("users/updateUser", data);
        if (response.status === 200) {
          this.$router.replace(`/users/${response.data.id}`);
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
  },
};
</script>