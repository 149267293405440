<template>
  <div
    class="mt-5 alert text-white"
    :class="{
      'alert-success': feedback.alert_type === 'success',
      'alert-danger': feedback.alert_type === 'error',
    }"
    role="alert"
  >
    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
      ×
    </button>
    {{ feedback.message }}
  </div>
</template>

<script>
export default {
  props: ["feedback"],
};
</script>