export default {
    methods: {
        catchBadRequestError(err, message = null) {
            let errorMessage = message === null ? "Bad Request" : message;
            if (!!err.response && err.response.status === 400) {
                this.showError(errorMessage);
            }
        }
    }
};
