<template>
  <div class="page-header" v-if="Object.keys(order).length > 0">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("invoice") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/products-catalog">
            <a class="d-flex">{{ $t("productsCatalog") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("invoice") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="Object.keys(order).length > 0 && !loading">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="invoice-header text-right d-block mb-5">
            <h1 class="invoice-title font-weight-bold text-uppercase mb-1">
              {{ $t("invoice") }}
            </h1>
          </div>
          <div class="row mt-4">
            <div class="col-md">
              <label class="font-weight-bold">{{ $t("billedTo") }}</label>
              <div class="billed-to">
                <h6 v-if="order && order.client">
                  <span v-if="!!order.client.company_name">
                    {{ order.client.company_name }}
                  </span>
                  <span v-else>
                    {{ `${order.client.first_name} ${order.client.last_name}` }}
                  </span>
                </h6>
                <p v-if="order && order.address">
                  <span v-if="order.address.zip_code"
                    >{{ order.address.zip_code }},
                  </span>
                  <span v-if="order.address.street_address"
                    >{{ order.address.street_address }},
                  </span>
                  <span v-if="order.address.locality"
                    >{{ order.address.locality }},
                  </span>
                  <span v-if="order.address.city"
                    >{{ order.address.city }},
                  </span>
                  <span v-if="order.address.country">{{
                    order.address.country
                  }}</span>
                </p>
                <p v-if="order && order.client">
                  <span v-if="order.client.mobile">
                    {{ $t("telNo") }}:
                    <a
                      :href="getPhoneLink(order.client.mobile)"
                      target="_blank"
                      >{{ order.client.mobile }}</a
                    >
                  </span>
                  <br />
                  <span v-if="order.client.email">
                    {{ $t("email") }}:
                    <a
                      :href="getMailLink(order.client.email)"
                      target="_blank"
                      >{{ order.client.email }}</a
                    >
                  </span>
                </p>
              </div>
            </div>
            <div class="col-md">
              <div class="billed-from text-md-right">
                <label class="font-weight-bold">{{ $t("billedFrom") }}</label>
                <h6>
                  {{ order.company ? order.company.name : "" }}
                </h6>
                <p>
                  {{ order.company ? order.company.zip_code : "" }}
                  , {{ order.company ? order.company.address : "" }} ,
                  {{ order.company ? order.company.city : "" }}
                  {{
                    order.company && order.company.country
                      ? `, ${order.company.country}`
                      : ""
                  }}
                  {{
                    order.company && order.company.state
                      ? `, ${order.company.state}`
                      : ""
                  }}
                  <br />
                  <span v-if="order.company && order.company.mobile">
                    {{ $t("telNo") }}:
                    <a
                      :href="getPhoneLink(order.company.mobile)"
                      target="_blank"
                      >{{ order.company.mobile }}</a
                    >
                  </span>
                  <br />
                  <span v-if="order.company && order.company.email">
                    {{ $t("email") }}:
                    <a
                      :href="getMailLink(order.company.email)"
                      target="_blank"
                      >{{ order.company.email }}</a
                    >
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-4">
            <table class="table table-bordered border text-nowrap mb-0">
              <thead>
                <tr>
                  <th class="wd-20p">{{ $t("product") }}</th>
                  <th class="tx-center">{{ $t("quantity") }}</th>
                  <th class="tx-right">{{ $t("unitPrice") }}</th>
                  <th class="tx-right">{{ $t("amount") }}</th>
                </tr>
              </thead>
              <tbody v-if="order && order.products">
                <tr v-for="single in order.products" :key="single.id">
                  <td class="font-weight-bold">
                    <p>{{ displayName(single.product.name) }}</p>
                    <p>
                      <small
                        class="d-block"
                        v-for="attribute in single.product_attributes"
                        :key="attribute"
                      >
                        <span
                          v-if="
                            attribute.name === 'Color' ||
                            attribute.name === 'color' ||
                            attribute.name === 'Colour' ||
                            attribute.name === 'colour'
                          "
                        >
                          <span style="padding-right: 5px"
                            >{{ attribute.name }}:
                          </span>
                          <span :style="getStyle(attribute)"> </span>
                        </span>
                        <span v-else>
                          {{ attribute.name }}: {{ attribute.value }}
                        </span>
                      </small>
                    </p>
                  </td>
                  <td class="text-right">
                    {{ single.quantity }}
                  </td>
                  <td class="text-right">
                     <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                    {{ single.product.price }}
                  </td>
                  <td class="text-right">
                    <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                    {{ single.total }}
                  </td>
                </tr>
                <tr>
                  <td class="valign-middle" colspan="2" rowspan="4">
                    <div class="invoice-notes">
                      <label
                        class="main-content-label tx-13 font-weight-semibold"
                      ></label>
                      <p></p>
                    </div>
                  </td>
                  <td class="tx-right font-weight-semibold text-right">
                    {{ $t("totalAmount") }}
                  </td>
                  <td class="tx-right font-weight-semibold text-right">
                     <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span> {{ order.total }}
                  </td>
                </tr>
                <tr>
                  <td class="tx-right font-weight-semibold text-right">
                    {{ $t("paidAmount") }}
                  </td>
                  <td
                    class="tx-right font-weight-semibold text-right"
                    colspan="3"
                  >
                      <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                    {{ order.amount_paid }}
                  </td>
                </tr>
                <tr>
                  <td class="tx-right font-weight-semibold text-right">
                    {{ $t("remainingAmount") }}
                  </td>
                  <td
                    class="tx-right font-weight-semibold text-right"
                    colspan="3"
                  >
                     <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                    {{ order.amount_remaining }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="float-right">
            <router-link :to="`/catalog/payment/${order.id}`">
              <a
                v-if="order.amount_remaining > 0"
                type="button"
                class="btn btn-primary mt-4 mr-2"
              >
                <i class="si si-dollar"></i> {{ $t("payInvoice") }}
              </a>
            </router-link>
            <router-link
              :to="`/catalog/order/edit/${order.id}`"
              v-if="order.status === 'processing'"
            >
              <a class="btn btn-primary mt-4 mr-2">
                <i
                  class="fa fa-pencil"
                  data-toggle="tooltip"
                  title="fa fa-pencil"
                ></i>
              </a>
            </router-link>
            <router-link :to="`/invoice/${locale}/${order.id}`" target="_blank">
              <a target="_blank" type="button" class="btn btn-info mt-4">
                <i
                  class="fa fa-print"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="fa fa-print"
                ></i>
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import fixStatus from "../../mixins/fixStatus";
import checkToken from "../../mixins/checkToken";
import setFeedbackMixin from "../../mixins/setFeedback";
import contact from "../../mixins/contact";
import forbiddenError from "../../mixins/catchForbiddenError";
import displayNameMixin from "../../mixins/displayNameMixin";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  mixins: [
    showErrorMixin,
    notifyMixin,
    checkToken,
    fixStatus,
    setFeedbackMixin,
    contact,
    forbiddenError,
    displayNameMixin,
  ],
  data() {
    return {
      order: {},
      is_sending: false,
      loading: true,
    };
  },
  created() {
    if (this.id) {
      this.getOrder();
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
    locale() {
      return this.$store.getters.locale;
    },
  },
  methods: {
    async getOrder() {
      try {
        const response = await this.$store.dispatch("catalog/getOrder", {
          id: this.id,
        });
        this.loading = false;
        if (response.status === 200) {
          this.order = response.data;
        }
      } catch (err) {
        console.log("error");
        this.catchForbiddenError(err);
        this.loading = false;
      }
    },
    async downloadInvoice() {
      this.is_sending = true;
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = {
        id: this.order.id,
      };
      let url = "/api/v1/orders/download";

      try {
        const response = await this.$axios({
          method: submit_method,
          url: url,
          data: submit_data,
        });
        if (response.status === 200) {
          window.open(response.data.url, "Download");
          this.is_sending = false;
        }
      } catch (error) {
        this.is_sending = false;
        console.log("Error");
      }
    },
    getHexCode(attribute) {
      var formatedColorCode = attribute.value.substr(
        attribute.value.length - 6
      );
      return formatedColorCode;
    },
    getStyle(attribute) {
      return {
        background: `#${this.getHexCode(attribute)}`,
        height: "10px",
        width: "30px",
        display: "inline-block",
      };
    },
  },
};
</script>