export default {
    methods: {
        displayName(string, length = 20) {
            var trimmedString =
                string.length > length
                    ? string.substring(0, length - 3) + "..."
                    : string;
            return trimmedString;
        }
    }
};
