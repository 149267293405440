<template>
  <div class="page relative">
    <div class="page-content">
      <div class="container text-center">
        <div class="display-1 text-primary mb-5 font-weight-bold">400</div>
        <h1 class="h3 mb-3 font-weight-bold">{{ $t("badRequest") }}!</h1>
        <p class="h5 font-weight-normal mb-7 leading-normal">
          {{ $t("youMayHaveMistypedTheAddressOrThePageMayHaveMoved") }}
        </p>

        <router-link to="/dashboard">
          <a class="btn btn-primary"
            ><i class="fe fe-arrow-left-circle mr-1"></i>{{ $t("goBack") }}</a
          >
        </router-link>
      </div>
    </div>
  </div>
</template>