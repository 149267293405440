// import Login from "./components/frontend/auth/Login.vue";
// import Register from "./components/frontend/auth/Register.vue";
// import ForgotPassword from "./components/frontend/auth/ForgotPassword.vue";
// import ResetPassword from "./components/frontend/auth/ResetPassword.vue";
// import VerifyEmail from "./components/frontend/auth/VerifyEmail.vue";
// import Pages from "./components/frontend/pages/Show.vue";
// import BlogsIndex from "./components/frontend/blogs/Index.vue";
// import BlogsShow from "./components/frontend/blogs/Show.vue";
// import CareersIndex from "./components/frontend/careers/Index.vue";
// import CareersShow from "./components/frontend/careers/Show.vue";
// import DemoCreate from "./components/frontend/demo/Create.vue";

const routes = [
    // {
    //     path: "/demos/create",
    //     component: DemoCreate,
    //     name: "demo.create",
    // },
    // {
    //     path: "/careers/:slug",
    //     component: CareersShow,
    //     props: true
    // },
    // {
    //     path: "/careers",
    //     component: CareersIndex,
    // },
    // {
    //     path: "/blogs/:id",
    //     component: BlogsShow,
    //     props: true,
    // },
    // {
    //     path: "/blogs",
    //     component: BlogsIndex,
    // },
    // {
    //     path: "/login",
    //     component: Login,
    //     name: "login",
    // },
    // {
    //     path: "/register",
    //     component: Register,
    //     name: "register",
    // },
    // {
    //     path: "/forgot-my-password",
    //     component: ForgotPassword,
    //     name: "forgot.password",
    // },
    // {
    //     path: "/reset-password",
    //     component: ResetPassword,
    //     name: "reset.password",
    // },
    // {
    //     path: "/email/verify",
    //     component: VerifyEmail,
    //     name: "email.verify",
    //     meta: {
    //         requiresLoggedIn: true,
    //     },
    // },
    // {
    //     path: "/page/:link",
    //     component: Pages,
    //     props: true,
    //     name: "pages",
    // },
];

export default routes;
