const axios = require('axios').default;
export default {
    submitState(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/states";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    updateState(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/states/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    deleteState(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/states/${payload}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },
};
