const axios = require('axios').default;

export default {
    login(_, payload) {
        return axios({ method: "POST", url: "/api/v1/login", data: payload });
    },

    register(_, payload) {
        return axios({
            method: "POST",
            url: "/api/v1/register",
            data: payload,
        });
    },

    forgotPasswordFortify(_, payload) {
        return axios({
            headers: {
                Accept: "application/json",
            },
            method: "POST",
            url: "/forgot-password",
            data: payload,
        });
    },

    resetPasswordFortify(_, payload) {
        return axios({
            method: "POST",
            url: "/reset-password",
            data: payload,
        });
    },

    forgotPassword(_, payload) {
        let submit_method = "POST";
        let uri = "/api/v1/forgot-password";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    setToken(context, payload) {
        localStorage.setItem("access_token", payload.token);
        context.commit("retrieveToken", payload.token);
    },

    setUser(context, payload) {
        // Hide personal details on logged in user
        localStorage.setItem("user", JSON.stringify(payload.user));
        context.commit("setUser", payload.user);
    },

    setLocale(context, payload) {
        localStorage.setItem("locale", payload.locale);
        context.commit("setLocale", payload.locale);
    },

    setEmail(context, payload) {
        context.commit("setEmail", payload.email);
    },

    logOut(context) {
        if (!context.getters.loggedIn) {
            return;
        }

        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.getters.token;

        let submit_method = "POST";
        let uri = "/api/v1/logout";

        return axios({ method: submit_method, url: uri });
    },
  
    resendEmailVerification(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.getters.token;
        return axios({ method: "POST", url: "/email/verification-notification" });
    },

    destroyToken(context) {
        localStorage.removeItem("access_token");
        context.commit("destroyToken");
    },

    destroyUser(context) {
        localStorage.removeItem("user");
        context.commit("destroyUser");
    },

    changePasswordOTP(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + payload.token;

        let submit_method = "POST";
        let uri = "/api/v1/change-password-otp";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    changePassword(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + payload.token;

        let submit_method = "POST";
        let uri = "/api/v1/change-password";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    getUser(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.getters.token;

        let submit_method = "GET";
        let uri = "/api/v1/user/info";
        return axios({ method: submit_method, url: uri });
    },
};
