<template>
  <div class="page-header" v-if="editFormIsSetUp">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("products") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/products">
            <a>{{ $t("products") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ editOrCreate }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="editFormIsSetUp && !loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <div class="col-6">
              <h4 class="card-title">{{ typeOf }} {{ $t("product") }}</h4>
            </div>
            <div class="col-6 text-right">
              <button
                type="submit"
                form="form-product"
                class="pull-right btn btn-sm btn-primary"
                :class="{
                  'btn-loading': is_sending,
                }"
                :disabled="is_sending"
              >
                {{ submitBtnText }}
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="panel panel-primary">
            <form @submit.prevent="submitProductHandler" id="form-product">
              <div class="tab-menu-heading p-0 bg-light">
                <div class="tabs-menu1">
                  <ul class="nav panel-tabs">
                    <li class="">
                      <a
                        href="#tab5"
                        class="active"
                        :class="{
                          'text-danger':
                            findFieldinFeedback('name') && form.name === '',
                        }"
                        data-toggle="tab"
                        >{{ $t("generalInformation") }}</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab6"
                        data-toggle="tab"
                        class=""
                        :class="{
                          'text-danger':
                            (findFieldinFeedback('model') &&
                              form.model === '') ||
                            (findFieldinFeedback('price') &&
                              form.price === '') ||
                            (findFieldinFeedback('currency_id') &&
                              form.currency_id === '') ||
                            (findFieldinFeedback('quantity') &&
                              form.quantity === ''),
                        }"
                        >{{ $t("data") }}</a
                      >
                    </li>
                    <li>
                      <a href="#tab7" data-toggle="tab" class="">{{
                        $t("links")
                      }}</a>
                    </li>
                    <li>
                      <a href="#tab8" data-toggle="tab" class="">{{
                        $t("attributes")
                      }}</a>
                    </li>
                    <li>
                      <a href="#tab9" data-toggle="tab" class="">{{
                        $t("images")
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="panel-body tabs-menu-body">
                <div class="tab-content">
                  <div class="tab-pane active" id="tab5">
                    <div class="form-group row">
                      <label class="col-md-3 form-label"
                        >{{ $t("productName") }}<span>*</span></label
                      >
                      <div class="col-md-9">
                        <input
                          v-model.trim="form.name"
                          type="text"
                          class="form-control"
                          :class="{
                            'border-red':
                              findFieldinFeedback('name') && form.name === '',
                          }"
                          :placeholder="$t('productName')"
                        />
                        <p
                          v-if="findFieldinFeedback('name') && form.name === ''"
                          class="text-danger"
                        >
                          {{ $t("productNameCannotBeBlank") }}
                        </p>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{
                        $t("description")
                      }}</label>
                      <div class="col-md-9">
                        <ckeditor
                          :editor="editor"
                          v-model="form.description"
                          :config="editorConfig"
                        ></ckeditor>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab6">
                    <div class="form-group row">
                      <label class="col-md-3 form-label"
                        >{{ $t("model") }}<span>*</span></label
                      >
                      <div class="col-md-9">
                        <input
                          v-model.trim="form.model"
                          type="text"
                          class="form-control"
                          :class="{
                            'border-red':
                              findFieldinFeedback('model') && form.model === '',
                          }"
                          :placeholder="$t('model')"
                        />
                        <p
                          v-if="
                            findFieldinFeedback('model') && form.model === ''
                          "
                          class="text-danger"
                        >
                          {{ $t("modelCannotBeBlank") }}
                        </p>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{ $t("sku") }}</label>
                      <div class="col-md-9">
                        <input
                          v-model="form.sku"
                          type="text"
                          class="form-control"
                          placeholder="SKU"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label"
                        >{{ $t("price") }}<span>*</span></label
                      >
                      <div class="col-md-6">
                        <input
                          step="0.001"
                          v-model="form.price"
                          type="number"
                          class="form-control"
                          :placeholder="$t('price')"
                          :class="{
                            'border-red':
                              findFieldinFeedback('price') && form.price === '',
                          }"
                        />
                        <p
                          v-if="
                            findFieldinFeedback('price') && form.price === ''
                          "
                          class="text-danger"
                        >
                          {{ $t("priceCannotBeBlank") }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <prime-dropdown
                          v-model="form.currency_id"
                          :options="currencies"
                          optionLabel="code"
                          optionValue="id"
                          :filter="true"
                          :placeholder="$t('selectCurrency')"
                          :class="{
                            'border-red':
                              findFieldinFeedback('currency_id') &&
                              form.currency_id === '',
                          }"
                        >
                          <template #label="slotProps">
                            <div
                              class="country-item country-item-value"
                              v-if="slotProps.label"
                            >
                              <div>{{ slotProps }}</div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                          <template #option="slotProps">
                            <div class="country-item">
                              <div>{{ slotProps.option.code }}</div>
                            </div>
                          </template>
                        </prime-dropdown>
                        <p
                          v-if="
                            findFieldinFeedback('currency_id') &&
                            form.currency_id === ''
                          "
                          class="text-danger"
                        >
                          {{ $t("currencyCannotBeBlank") }}
                        </p>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">
                        {{ $t("quantity") }}<span>*</span></label
                      >
                      <div class="col-md-9">
                        <input
                          v-model.trim="form.quantity"
                          type="number"
                          class="form-control"
                          :placeholder="$t('quantity')"
                          :class="{
                            'border-red':
                              findFieldinFeedback('quantity') &&
                              form.quantity === '',
                          }"
                        />
                        <p
                          v-if="
                            findFieldinFeedback('quantity') &&
                            form.quantity === ''
                          "
                          class="text-danger"
                        >
                          {{ $t("quantityCannotBeBlank") }}
                        </p>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{
                        $t("minimumQuantity")
                      }}</label>
                      <div class="col-md-9">
                        <input
                          v-model="form.minimum"
                          type="number"
                          class="form-control"
                          :placeholder="$t('minimumQuantity')"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{
                        $t("subtractStock")
                      }}</label>
                      <div class="col-md-9">
                        <select v-model="form.subtract" class="form-control">
                          <option value="1">{{ $t("yes") }}</option>
                          <option value="0">{{ $t("no") }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{
                        $t("outOfStockStatus")
                      }}</label>
                      <div class="col-md-9">
                        <select
                          v-model="form.stock_status_id"
                          class="form-control"
                        >
                          <option value="1">{{ $t("twoToThreeDays") }}</option>
                          <option value="2">{{ $t("inStock") }}</option>
                          <option value="3">{{ $t("outOfStock") }}</option>
                          <option value="4">{{ $t("preOrder") }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{
                        $t("requiresShipping")
                      }}</label>
                      <div class="col-md-9">
                        <label class="radio-inline">
                          <input
                            type="radio"
                            v-model="form.shipping"
                            name="shipping"
                            value="1"
                            checked="checked"
                          />
                          {{ $t("yes") }}
                        </label>
                        <label class="radio-inline ml-2">
                          <input
                            v-model="form.shipping"
                            type="radio"
                            name="shipping"
                            value="0"
                          />
                          {{ $t("no") }}
                        </label>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{
                        $t("dateAvailable")
                      }}</label>
                      <div class="col-md-9">
                        <input
                          v-model="form.date_available"
                          type="date"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{
                        $t("status")
                      }}</label>
                      <div class="col-md-9">
                        <select v-model="form.status" class="form-control">
                          <option value="1">{{ $t("enabled") }}</option>
                          <option value="0">{{ $t("disabled") }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{
                        $t("sortOrder")
                      }}</label>
                      <div class="col-md-9">
                        <input
                          v-model="form.sort_order"
                          type="number"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab7">
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{
                        $t("brand")
                      }}</label>
                      <div class="col-md-9">
                        <prime-dropdown
                          v-model="form.manufacturer_id"
                          :options="brands"
                          optionLabel="name"
                          optionValue="id"
                          :filter="true"
                          :placeholder="$t('brand')"
                        >
                          <template #label="slotProps">
                            <div
                              class="country-item country-item-value"
                              v-if="slotProps.label"
                            >
                              <div>{{ slotProps }}</div>
                            </div>
                            <span v-else>
                              {{ slotProps.placeholder }}
                            </span>
                          </template>
                          <template #option="slotProps">
                            <div class="country-item">
                              <div>{{ slotProps.option.name }}</div>
                            </div>
                          </template>
                        </prime-dropdown>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 form-label">{{
                        $t("categories")
                      }}</label>
                      <div class="col-md-9">
                        <div class="row">
                          <div class="col">
                            <prime-dropdown
                              v-model="category"
                              :options="categories"
                              optionLabel="full_name"
                              optionValue="id"
                              :filter="true"
                              :placeholder="$t('addCategory')"
                              :class="{
                                'border-red':
                                  findFieldinFeedback('category') &&
                                  category === '',
                              }"
                            >
                              <template #label="slotProps">
                                <div
                                  class="country-item country-item-value"
                                  v-if="slotProps.label"
                                >
                                  <div>{{ slotProps }}</div>
                                </div>
                                <span v-else>
                                  {{ slotProps.placeholder }}
                                </span>
                              </template>
                              <template #option="slotProps">
                                <div class="country-item">
                                  <div>{{ slotProps.option.full_name }}</div>
                                </div>
                              </template>
                            </prime-dropdown>
                            <p
                              v-if="
                                findFieldinFeedback('category') &&
                                category === ''
                              "
                              class="text-danger d-block"
                            >
                              {{ $t("categoryCannotBeBlank") }}
                            </p>
                          </div>
                          <span class="col-auto">
                            <button
                              class="btn btn-sm btn-success"
                              type="button"
                              @click="addCategory"
                            >
                              <i class="fe fe-plus"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div
                        class="col-9 offset-3"
                        v-if="form.categories.length > 0"
                      >
                        <div
                          class="row mb-2"
                          v-for="(category, index) in form.categories"
                          :key="category"
                        >
                          <div class="col-6">
                            {{ getCategoryName(category) }}
                          </div>
                          <div class="col-6 text-right">
                            <button
                              class="btn btn-sm btn-light"
                              type="button"
                              @click="removeCategory(index)"
                            >
                              <i class="fe fe-minus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab8">
                    <div
                      class="row"
                      v-for="attribute in form.attributes"
                      :key="attribute"
                    >
                      <div
                        :class="{
                          'col-4': attribute.unit != '',
                          'col-6': attribute.unit === '',
                        }"
                      >
                        <div class="form-group">
                          <label class="form-label"
                            >{{ $t("attribute") }}<span>*</span></label
                          >
                          <div class="input-group">
                            <select
                              class="form-control"
                              @change="
                                changeAttribute($event, attribute, 'attribute')
                              "
                            >
                              <option
                                v-for="single in attributeTypes"
                                :key="single.id"
                                :value="single.id"
                                :selected="single.id === attribute.id"
                              >
                                {{ single.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-4" v-if="attribute.unit != ''">
                        <div class="form-group">
                          <label class="form-label">{{ $t("unit") }}</label>
                          <div class="input-group">
                            <select
                              class="form-control"
                              @change="
                                changeAttribute($event, attribute, 'unit')
                              "
                              :disabled="
                                getAttributeUnits(attribute.id).length === 0
                              "
                            >
                              <option
                                v-for="single in getAttributeUnits(
                                  attribute.id
                                )"
                                :key="single.id"
                                :value="single.id"
                                :selected="single.id === attribute.unit"
                              >
                                {{ single.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        :class="{
                          'col-4': attribute.unit != '',
                          'col-6': attribute.unit === '',
                        }"
                      >
                        <div class="form-group">
                          <label class="form-label"
                            >{{ $t("value") }}<span>*</span></label
                          >
                          <div class="row">
                            <div class="col">
                              <prime-color-picker
                                v-if="isColorAttribute(attribute)"
                                :defaultColor="
                                  attribute.value.substr(
                                    attribute.value.length - 6
                                  )
                                "
                                @focusin="setUpColorField(attribute)"
                                v-model="colors[attribute.value]"
                              ></prime-color-picker>
                              <input
                                v-else
                                @change="
                                  changeAttribute($event, attribute, 'value')
                                "
                                type="text"
                                class="form-control"
                                :placeholder="$t('value')"
                                :value="attribute.value"
                              />
                            </div>
                            <span class="col-auto">
                              <button
                                class="btn btn-sm btn-light"
                                type="button"
                                @click="removeAttribute(attribute)"
                              >
                                <i class="fe fe-minus"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        :class="{
                          'col-4': units.length > 0,
                          'col-6': units.length === 0,
                        }"
                      >
                        <div class="form-group">
                          <label class="form-label"
                            >{{ $t("attribute") }}<span>*</span></label
                          >
                          <div class="input-group">
                            <prime-dropdown
                              v-model="attribute.id"
                              :options="attributeTypes"
                              optionLabel="name"
                              optionValue="id"
                              :filter="true"
                              :placeholder="$t('selectAttribute')"
                              :class="{
                                'border-red':
                                  findFieldinFeedback('attribute_id') &&
                                  attribute.id === '',
                              }"
                            >
                              <template #label="slotProps">
                                <div
                                  class="country-item country-item-value"
                                  v-if="slotProps.label"
                                >
                                  <div>{{ slotProps }}</div>
                                </div>
                                <span v-else>
                                  {{ slotProps.placeholder }}
                                </span>
                              </template>
                              <template #option="slotProps">
                                <div class="country-item">
                                  <div>{{ slotProps.option.name }}</div>
                                </div>
                              </template>
                            </prime-dropdown>
                            <p
                              v-if="
                                findFieldinFeedback('attribute_id') &&
                                attribute.id === ''
                              "
                              class="text-danger d-block"
                            >
                              {{ $t("attributeTypeCannotBeBlank") }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-4" v-if="units.length > 0">
                        <div class="form-group">
                          <label class="form-label">{{ $t("unit") }}</label>
                          <div class="input-group">
                            <prime-dropdown
                              v-model="attribute.unit"
                              :options="units"
                              optionLabel="name"
                              optionValue="id"
                              :filter="true"
                              :disabled="units.length === 0"
                              :placeholder="$t('selectUnit')"
                              :class="{
                                'border-red':
                                  findFieldinFeedback('unit') &&
                                  attribute.unit === '',
                              }"
                            >
                              <template #label="slotProps">
                                <div
                                  class="country-item country-item-value"
                                  v-if="slotProps.label"
                                >
                                  <div>{{ slotProps }}</div>
                                </div>
                                <span v-else>
                                  {{ slotProps.placeholder }}
                                </span>
                              </template>
                              <template #option="slotProps">
                                <div class="country-item">
                                  <div>{{ slotProps.option.name }}</div>
                                </div>
                              </template>
                            </prime-dropdown>
                            <p
                              v-if="
                                findFieldinFeedback('unit') &&
                                attribute.unit === ''
                              "
                              class="text-danger d-block"
                            >
                              {{ $t("unitCannotBeBlank") }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        :class="{
                          'col-4': units.length > 0,
                          'col-6': units.length === 0,
                        }"
                      >
                        <div class="form-group">
                          <label class="form-label"
                            >{{ $t("value") }}<span>*</span></label
                          >
                          <div class="row">
                            <div class="col">
                              <prime-color-picker
                                v-if="
                                  activeAttribute &&
                                  Object.keys(activeAttribute).length > 0 &&
                                  isColor(activeAttribute.name)
                                "
                                v-model="attribute.value"
                              ></prime-color-picker>
                              <input
                                v-else
                                v-model="attribute.value"
                                type="text"
                                class="form-control"
                                :placeholder="$t('value')"
                              />
                              <p
                                v-if="
                                  feedback.field === 'value' &&
                                  attribute.value === ''
                                "
                                class="text-danger"
                              >
                                {{ feedback.message }}
                              </p>
                            </div>
                            <span class="col-auto">
                              <button
                                class="btn btn-sm btn-success"
                                type="button"
                                @click="addAttribute"
                              >
                                <i class="fe fe-plus"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab9">
                    <div class="row">
                      <div
                        class="col-lg-12"
                        v-for="photo in current_images"
                        :key="photo.id"
                      >
                        <div
                          class="
                            d-sm-flex
                            align-items-center
                            border
                            p-3
                            mb-3
                            br-7
                          "
                        >
                          <img
                            class="avatar avatar-lg"
                            :src="getCurrentImageLink(photo)"
                            alt="Product Image"
                          />
                          <div class="float-sm-right ml-auto mt-4 mt-sm-0">
                            <button
                              class="btn btn-sm btn-danger"
                              type="button"
                              @click="deleteCurrentImage(photo)"
                            >
                              <i class="fe fe-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-lg-12"
                        v-for="file in form.images"
                        :key="file"
                      >
                        <div
                          class="
                            d-sm-flex
                            align-items-center
                            border
                            p-3
                            mb-3
                            br-7
                          "
                        >
                          <img
                            class="avatar avatar-lg"
                            :src="getImageLink(file)"
                            alt="Product Image"
                          />
                          <div class="float-sm-right ml-auto mt-4 mt-sm-0">
                            <button
                              class="btn btn-sm btn-danger"
                              type="button"
                              @click="deleteFile(file)"
                            >
                              <i class="fe fe-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-label">{{ $t("productImage") }}</div>
                      <div class="custom-file">
                        <input
                          v-on:change="onImageChange"
                          type="file"
                          accept="image/*"
                          class="custom-file-input"
                          name="example-file-input-custom"
                          multiple
                        />
                        <label class="custom-file-label">{{
                          $t("chooseFile")
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ckEditorConfig from "../../mixins/cKEditorConfig";
import clearFormMixin from "../../mixins/clearForm";
import showErrorMixin from "../../mixins/showError";
import setFeedbackMixin from "../../mixins/setFeedback";
import notifyMixin from "../../mixins/notify";
import checkToken from "../../mixins/checkToken";
import catchForbiddenError from "../../mixins/catchForbiddenError";
import _ from "lodash";
export default {
  mixins: [
    clearFormMixin,
    showErrorMixin,
    setFeedbackMixin,
    notifyMixin,
    ckEditorConfig,
    checkToken,
    catchForbiddenError,
  ],
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      is_sending: false,
      validation_errors: {},
      feedback: [],
      category: "",
      units: [],
      attribute: {
        id: "",
        unit: "",
        value: "",
      },
      form: {
        name: "",
        description: "",
        model: "",
        sku: "",
        price: "",
        currency_id: "",
        quantity: "",
        minimum: "",
        subtract: 1,
        stock_status_id: 2,
        shipping: "",
        date_available: "",
        status: 1,
        sort_order: "",
        manufacturer_id: "",
        categories: [],
        attributes: [],
        images: [],
      },
      colors: [],
      color: "",
      selectedAttribute: {},
      current_images: [],
      mode: "",
      loading: true,
    };
  },
  created() {
    if (typeof this.id !== "undefined") {
      this.getProduct();
    }
    this.loadBrands();
    this.loadCurrencies();
    this.loadCategories();
    this.loadAttributeTypes();
  },
  computed: {
    brands() {
      return this.$store.getters["brands/brands"];
    },
    currencies() {
      return this.$store.getters["currencies/currencies"];
    },
    categories() {
      return this.$store.getters["categories/categories"];
    },
    typeOf() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("create");
    },
    submitBtnText() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("submit");
    },
    editOrCreate() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("create");
    },
    attributeTypes() {
      return this.$store.getters["attributeTypes/attributeTypes"];
    },
    attributeId() {
      return this.attribute.id;
    },
    editFormIsSetUp() {
      if (typeof this.id !== "undefined" && this.form.name === "") {
        return false;
      }
      return true;
    },
    activeAttribute() {
      return this.attributeTypes.find((type) => {
        return type.id === parseInt(this.attribute.id);
      });
    },
  },
  watch: {
    colors: {
      handler(val) {
        this.handleColorChange(val);
      },
      deep: true,
    },
    activeAttribute: function (newValue) {
      if (newValue && this.isColor(newValue.name)) {
        this.attribute.value = "000000";
      } else {
        this.attribute.value = "";
      }
    },
    attributeId: function (newValue) {
      this.units = [];
      const selected = this.attributeTypes.find((type) => {
        return type.id === parseInt(newValue);
      });

      if (!!selected && selected.units.length > 0) {
        this.units = selected.units;
      }
    },
  },
  methods: {
    handleColorChange: _.debounce(function () {
      if (Object.keys(this.selectedAttribute).length > 0 && (this.color in this.colors ) && this.colors[this.color].length === 6) {
        this.changeColorAttribute(this.selectedAttribute);
      }
    }, 100),
    async getProduct() {
      try {
        const response = await this.$store.dispatch("products/getProduct", {
          id: this.id,
        });
        if (response.status === 200) {
          this.setUpForm(response.data);
          this.loading = false;
        }
      } catch (err) {
        console.log("error");
        this.catchForbiddenError(err);
        this.loading = false;
      }
    },
    getImageLink(file) {
      if (file) {
        const src = URL.createObjectURL(file);
        return src;
      }
      return "";
    },
    getCurrentImageLink(photo) {
      if (photo) {
        const image_link = photo.file_path ? photo.file_path : "";
        return `${this.$baseURL}storage/${image_link}`;
      }
      return "";
    },
    deleteCurrentImage(photo) {
      const fileIndex = this.current_images.findIndex((i) => {
        return i.id === photo.id;
      });
      this.current_images.splice(fileIndex, 1);
    },
    async loadAttributeTypes() {
      try {
        const response = await this.$store.dispatch(
          "attributeTypes/getAttributeTypes"
        );
        if (response.status === 200) {
          this.$store.dispatch(
            "attributeTypes/setAttributeTypes",
            response.data.attribute_types
          );
          this.loading = false;
        }
      } catch (err) {
        console.log("error");
        this.loading = false;
      }
    },
    async loadCategories() {
      try {
        const response = await this.$store.dispatch("categories/getCategories");
        if (response.status === 200) {
          this.$store.dispatch(
            "categories/setCategories",
            response.data.categories
          );
          this.loading = false;
        }
      } catch (err) {
        console.log("error");
        this.loading = false;
      }
    },
    async loadBrands() {
      try {
        const response = await this.$store.dispatch("brands/getBrands");
        if (response.status === 200) {
          this.$store.dispatch("brands/setBrands", response.data.brands);
          this.loading = false;
        }
      } catch (err) {
        console.log("error");
        this.loading = false;
      }
    },
    async loadCurrencies() {
      try {
        const response = await this.$store.dispatch("currencies/getCurrencies");
        if (response.status === 200) {
          this.$store.dispatch(
            "currencies/setCurrencies",
            response.data.currencies
          );
          this.loading = false;
        }
      } catch (err) {
        console.log("error");
        this.loading = false;
      }
    },
    async submitProductHandler() {
      this.feedback = [];

      if (!this.validate()) {
        return;
      }

      this.is_sending = true;

      const data = new FormData();

      data.append("name", this.form.name);
      data.append("description", this.form.description);
      data.append("model", this.form.model);
      data.append("sku", this.form.sku);
      data.append("price", this.form.price);
      data.append("currency_id", this.form.currency_id);
      data.append("quantity", this.form.quantity);
      data.append("minimum", this.form.minimum);
      data.append("subtract", this.form.subtract);
      data.append("stock_status_id", this.form.stock_status_id);
      data.append("shipping", this.form.shipping);
      data.append("date_available", this.form.date_available);
      data.append("status", this.form.status);
      data.append("sort_order", this.form.sort_order);
      data.append("manufacturer_id", this.form.manufacturer_id);
      if (this.form.categories.length > 0) {
        // Create form array for the product categories
        for (var i = 0; i < this.form.categories.length; i++) {
          data.append("categories[]", this.form.categories[i]);
        }
      }

      if (this.form.attributes.length > 0) {
        // Create form array for the product attributes
        for (var j = 0; j < this.form.attributes.length; j++) {
          data.append("attributes[]", JSON.stringify(this.form.attributes[j]));
        }
      }

      if (this.form.images.length > 0) {
        for (var k = 0; k < this.form.images.length; k++) {
          if (this.form.images[k] && this.form.images[k] instanceof File) {
            data.append("images[]", this.form.images[k]);
          }
        }
      }

      if (this.current_images.length > 0) {
        for (var l = 0; l < this.current_images.length; l++) {
          data.append("current_images[]", parseInt(this.current_images[l].id));
        }
      }

      if (this.mode === "edit") {
        data.append("id", this.id);
        data.append("_method", "PUT");
      }

      try {
        if (this.mode === "edit") {
          const response = await this.$store.dispatch(
            "products/updateProduct",
            data
          );
          this.is_sending = false;
          if (response.status === 200) {
            this.notify("Product Updated");
            this.$router.replace(`/products/${response.data.id}`);
          }
        } else {
          const response = await this.$store.dispatch(
            "products/submitProduct",
            data
          );
          this.is_sending = false;
          if (response.status === 201) {
            this.notify("Product Saved");
            this.$router.replace(`/products/${response.data.id}`);
          }
        }
      } catch (err) {
        this.is_sending = false;
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    addAttribute() {
      this.feedback = [];
      if (this.attribute.id === "") {
        this.setFeedback("error", "attribute_id");
        return false;
      }
      if (this.attribute.unit === "" && this.units.length > 0) {
        this.setFeedback("error", "unit");
        return false;
      }
      if (this.attribute.value.trim() === "") {
        this.setFeedback("error", "value");
        return false;
      }

      let attribute = {};
      attribute.id = this.attribute.id;
      attribute.unit = this.attribute.unit;
      attribute.value = this.attribute.value;

      this.form.attributes.push(attribute);

      this.attribute.id = "";
      this.attribute.unit = "";
      this.attribute.value = "";
    },

    changeAttribute(event, attribute, type) {
      const attribute_index = this.form.attributes.findIndex((att) => {
        return att.id === attribute.id && att.value === attribute.value;
      });
      if (type == "value" && event.target.value != "") {
        this.form.attributes[attribute_index].value = event.target.value;
      } else if (type == "value" && event.target.value.trim() === "") {
        event.target.value = this.form.attributes[attribute_index].value;
      }
      if (type == "attribute") {
        const attUnits = this.getAttributeUnits(event.target.value);
        if (attUnits.length > 0) {
          this.form.attributes[attribute_index].unit = attUnits[0].id; // Default unit selection is the first one when attribute is changed
        } else {
          this.form.attributes[attribute_index].unit = "";
        }
        this.form.attributes[attribute_index].id = parseInt(event.target.value);
      }
      if (type == "unit") {
        this.form.attributes[attribute_index].unit = parseInt(
          event.target.value
        );
      }
    },

    changeColorAttribute(attribute) {
      const attribute_index = this.form.attributes.findIndex((att) => {
        return att.id === attribute.id && att.value === attribute.value;
      });
      this.form.attributes[attribute_index].value = this.colors[attribute.value];
      
      // `0xFF${
      //   this.colors[attribute.value]
      // }`;
    },

    getAttributeUnits(id) {
      let units = [];
      const selected = this.attributeTypes.find((type) => {
        return type.id === parseInt(id);
      });

      if (!!selected && selected.units.length > 0) {
        units = selected.units;
        return units;
      }
      return [];
    },
    removeAttribute(attribute) {
      const attribute_index = this.form.attributes.findIndex((att) => {
        return att.id === attribute.id && att.value === attribute.value;
      });
      this.form.attributes.splice(attribute_index, 1);
    },
    async submit() {
      this.feedback = [];

      if (!this.has_attribute) {
        this.form.attributes = [];
      }

      if (!this.validate()) {
        return;
      }

      try {
        const response = await this.$store.dispatch(
          "categories/submitCategory",
          this.form
        );
        if (response.status === 201) {
          this.notify(this.$t("saved"));
          this.$router.replace(`/categories/${response.data.id}`);
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    validate() {
      if (this.form.name === "") {
        this.setFeedback("error", "name");
      }
      if (this.form.model === "") {
        this.setFeedback("error", "model");
      }
      if (this.form.price === "") {
        this.setFeedback("error", "price");
      }
      if (this.form.currency_id === "") {
        this.setFeedback("error", "currency_id");
      }
      if (this.form.quantity === "") {
        this.setFeedback("error", "quantity");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },

    setUpForm(product) {
      this.form.name = product.name ?? "";
      this.form.description = product.description ?? "";
      this.form.model = product.model ?? "";
      this.form.sku = product.sku ?? "";
      this.form.upc = product.upc ?? "";
      this.form.ean = product.ean ?? "";
      this.form.jan = product.jan ?? "";
      this.form.isbn = product.isbn ?? "";
      this.form.mpn = product.mpn ?? "";
      this.form.location = product.location ?? "";
      this.form.price = product.price ?? "";
      this.form.currency_id = product.currency.id ?? "";
      this.form.quantity = product.quantity ?? "";
      this.form.minimum = product.minimum ?? "";
      this.form.subtract = product.subtract ?? "";
      this.form.stock_status_id = product.stock_status_id ?? "";
      this.form.shipping = product.shipping ?? "";
      this.form.date_available = product.date_available ?? "";
      this.form.status = product.status ?? "";
      this.form.sort_order = product.sort_order ?? "";
      this.form.manufacturer_id = product.manufacturer
        ? product.manufacturer.id
        : "";

      if (product.categories.length > 0) {
        product.categories.forEach((cat) => {
          if (!this.form.categories.includes(cat.id)) {
            this.form.categories.push(cat.id);
          }
        });
      }
      if (product.attributes.length > 0) {
        product.attributes.forEach((att) => {
          let attribute = {};
          attribute.id = att.id;
          attribute.unit = att.pivot.unit_id !== null ? att.pivot.unit_id : "";
          attribute.value = att.pivot.value;

          this.form.attributes.push(attribute);
        });
      }

      if (product.photos.length > 0) {
        this.current_images = product.photos;
      }
      this.mode = "edit";
    },

    addCategory() {
      this.feedback = [];

      if (this.category == "") {
        this.setFeedback("error", "category");
        return;
      }

      if (!this.form.categories.includes(this.category)) {
        this.form.categories.push(this.category);
      }

      // When we select the category of a product, find the category from its id.
      const selected = this.categories.find((single) => {
        return single.id === this.category;
      });

      // Now that we have the product category, push its attributes to the product attributes array
      selected.attributes.forEach((att) => {
        let attribute = {};
        attribute.id = att.id;
        attribute.unit = att.pivot.unit_id !== null ? att.pivot.unit_id : "";
        attribute.value = att.pivot.value;
        this.form.attributes.push(attribute);
      });

      this.category = "";
    },

    removeCategory(index) {
      this.form.categories.splice(index, 1);
    },

    getCategoryName(id) {
      const category = this.categories.find((single) => {
        return single.id === id;
      });
      if (category) {
        return category.full_name;
      }
      return "";
    },
    onImageChange(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        this.form.images.push(element);
      }
      event.target.value = null;
    },
    deleteFile(file) {
      const fileIndex = this.form.images.findIndex((i) => {
        return i.name === file.name;
      });
      this.form.images.splice(fileIndex, 1);
    },
    isColor(name) {
      return (
        name === "Color" ||
        name === "color" ||
        name === "colour" ||
        name === "Colour"
      );
    },
    isColorAttribute(attributeParam) {
      const attribute = this.attributeTypes.find((type) => {
        return type.id === parseInt(attributeParam.id);
      });

      if (
        attribute &&
        Object.keys(attribute).length > 0 &&
        this.isColor(attribute.name)
      ) {
        return true;
      }
      return false;
    },
    setUpColorField(attribute) {
      this.colors = {};
      this.color = attribute.value;
      this.colors[attribute.value] = attribute.value;
      this.selectedAttribute = attribute;
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}
.badge,
i {
  cursor: pointer;
}
</style>