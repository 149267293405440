<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("invoice") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("customerInvoice") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-6">
      <div class="card" v-if="Object.keys(client).length === 0">
        <div class="card-header">
          <h3 class="card-title">{{ $t("searchClient") }}</h3>
        </div>
        <div class="card-body">
          <input class="form-control form-control-sm mb-4" :class="{
            'border-red':
              findFieldinFeedback('search') && tableData.search === '',
          }" :placeholder="$t('searchClientNameOrMobile')" v-model="tableData.search" @blur="getClients"
            type="text" />
        </div>
      </div>
      <div class="mb-3" v-if="findFieldinFeedback('search') && tableData.search === ''">
        <span class="d-block text-danger">{{
            $t("pleaseEnterNameOrMobileNumber")
        }}</span>
      </div>
      <div class="mb-3" v-if="not_found">
        <span class="d-block text-danger">{{
            $t("thereIsNoClientWithTheseDetails")
        }}</span>
      </div>

      <loader v-if="loading"></loader>

      <p v-if="findFieldinFeedback('noOrders')">
        <span class="d-block text-danger">{{
            $t("thisClientHasNoOrdersPleaseSelectAnother")
        }}</span>
      </p>
      <div class="table-responsive mb-5" v-if="selectedClients.length > 0 && !loading">
        <table class="table card-table table-vcenter text-nowrap table-primary mb-0">
          <thead class="bg-primary text-white">
            <tr>
              <th class="text-white">{{ $t("name") }}</th>
              <th class="text-white">{{ $t("mobile") }}</th>
              <th class="text-white"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="client in selectedClients" :key="client.id">
              <td>{{ client.first_name }} {{ client.last_name }}</td>
              <td>{{ client.mobile }}</td>
              <td>
                <button class="btn btn-sm btn-success" type="button" @click="selectClient(client)">
                  <i class="fe fe-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row" v-if="client.orders && client.orders.length > 0 && !loading">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-white">{{ $t("clientDetails") }}</h3>
          <div class="card-options">
            <button class="btn btn-sm btn-info" @click="changeSelectedClient">
              {{ $t("changeClient") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row m-0 no-gutters">
            <div class="col-lg-4">
              <div class="border border-right-0 p-0 invoicelist" id="invoicelist">
                <div class="card-body p-0">
                  <div class="tab-menu-heading sub-panel-heading p-0 border-0">
                    <div class="tabs-menu">
                      <ul class="nav panel-tabs">
                        <li class="active order-list-item" v-for="single in client.orders" :key="single.id" :class="{
                          selected: single.id === order.id,
                        }">
                          <a class="d-flex" data-toggle="tab" @click.prevent="selectOrder(single)" aria-expanded="true">
                            <img src="/assets/images/files/file.png" alt="img" class="w-7 h-7 mr-4" />
                            <div class="mt-1">
                              <h5 class="mb-1 font-weight-bold mt-0">
                                {{ $t("invoiceNumber") }}
                                {{ single.order_number }}
                              </h5>
                              <p class="mb-0 text-muted">
                                {{ $t("orderTotal") }} :
                                <span v-if="loggedUser.company">
                                  {{ loggedUser.company.currency.symbol }}
                                </span>
                                {{ single.total }}
                              </p>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8 p-md-0">
              <div class="
                  panel-body
                  tabs-menu-body
                  p-6
                  invoice-print
                  d-block
                  invoicedetailspage
                " id="invoicedetailspage">
                <div class="tab-content" v-if="
                  order &&
                  order.client &&
                  order.products &&
                  order.products.length > 0
                ">
                  <div class="tab-pane tab-invoice" id="tab41">
                    <div class="card-body p-0">
                      <div class="invoice-header text-right d-block mb-5">
                        <h1 class="
                            invoice-title
                            font-weight-bold
                            text-uppercase
                            mb-1
                          ">
                          {{ $t("invoice") }}
                        </h1>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md">
                          <label class="font-weight-bold">{{
                              $t("billedTo")
                          }}</label>
                          <div class="billed-to">
                            <h6 v-if="order && order.client">
                              {{ order.client.name }}
                            </h6>
                            <p v-if="order && order.address">
                              <span v-if="order.address.zip_code">{{ order.address.zip_code }},
                              </span>
                              <span v-if="order.address.street_address">{{ order.address.street_address }},
                              </span>
                              <span v-if="order.address.locality">{{ order.address.locality }},
                              </span>
                              <span v-if="order.address.city">{{ order.address.city }},
                              </span>
                              <span v-if="order.address.country">{{
                                  order.address.country
                              }}</span>
                            </p>
                            <p v-if="order && order.client">
                              <span v-if="order.client.mobile">
                                {{ $t("telNo") }}:
                                <a :href="getPhoneLink(order.client.mobile)" target="_blank">{{ order.client.mobile
                                }}</a>
                              </span>
                              <br />
                              <span v-if="order.client.email">
                                {{ $t("email") }}:
                                <a :href="getMailLink(order.client.email)" target="_blank">{{ order.client.email }}</a>
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="col-md">
                          <div class="billed-from text-md-right">
                            <label class="font-weight-bold">{{
                                $t("billedFrom")
                            }}</label>
                            <h6>
                              {{ order.company ? order.company.name : "" }}
                            </h6>
                            <p>
                              {{ order.company ? order.company.zip_code : "" }}
                              ,
                              {{ order.company ? order.company.address : "" }} ,
                              {{ order.company ? order.company.city : "" }}
                              {{
                                  order.company && order.company.country
                                    ? `, ${order.company.country}`
                                    : ""
                              }}
                              {{
                                  order.company && order.company.state
                                    ? `, ${order.company.state}`
                                    : ""
                              }}
                              <br />
                              <span v-if="order.company && order.company.mobile">
                                {{ $t("telNo") }}:
                                <a :href="getPhoneLink(order.company.mobile)" target="_blank">{{ order.company.mobile
                                }}</a>
                              </span>
                              <br />
                              <span v-if="order.company && order.company.email">
                                {{ $t("email") }}:
                                <a :href="getMailLink(order.company.email)" target="_blank">{{ order.company.email
                                }}</a>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive mt-4">
                        <table class="table table-bordered border text-nowrap mb-0">
                          <thead>
                            <tr>
                              <th class="wd-20p">{{ $t("product") }}</th>
                              <th class="tx-center">{{ $t("quantity") }}</th>
                              <th class="tx-right">{{ $t("unitPrice") }}</th>
                              <th class="tx-right">{{ $t("amount") }}</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                          <tbody v-if="order && order.products">
                            <tr v-for="single in order.products" :key="single.id">
                              <td class="font-weight-bold">
                                {{ displayName(single.product.name, 25) }}
                              </td>
                              <td class="text-right">
                                {{ single.quantity }}
                              </td>
                              <td class="text-right">
                                <span v-if="loggedUser.company">
                                  {{ loggedUser.company.currency.symbol }}
                                </span>
                                {{ single.product.price }}
                              </td>
                              <td class="text-right">
                                <span v-if="loggedUser.company">
                                  {{ loggedUser.company.currency.symbol }}
                                </span>
                                {{ single.total }}
                              </td>
                            </tr>
                            <tr>
                              <td rowspan="2"></td>
                              <td class="tx-right font-weight-semibold">
                                {{ $t("totalAmount") }}
                              </td>
                              <td class="text-right font-weight-semibold" colspan="2">
                                <span v-if="loggedUser.company">
                                  {{ loggedUser.company.currency.symbol }}
                                </span>
                                {{ order.total }}
                              </td>
                            </tr>
                            <tr>
                              <td class="tx-right font-weight-semibold">
                                {{ $t("paidAmount") }}
                              </td>
                              <td class="text-right font-weight-semibold" colspan="2">
                                <span v-if="loggedUser.company">
                                  {{ loggedUser.company.currency.symbol }}
                                </span>
                                {{ order.amount_paid }}
                              </td>
                            </tr>
                            <tr>
                              <td rowspan="2"></td>
                              <td class="tx-right font-weight-semibold">
                                {{ $t("remainingAmount") }}
                              </td>
                              <td class="text-right font-weight-semibold" colspan="2">
                                <span v-if="loggedUser.company">
                                  {{ loggedUser.company.currency.symbol }}
                                </span>
                                {{ order.amount_remaining }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="float-right mb-4">
                        <router-link :to="`/invoice/${locale}/${order.id}`" target="_blank">
                          <a target="_blank" type="button" class="btn btn-info mt-4">
                            <i class="fa fa-print" data-toggle="tooltip" title="" data-original-title="fa fa-print"></i>
                          </a>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import checkToken from "../../mixins/checkToken";
import setFeedbackMixin from "../../mixins/setFeedback";
import fixStatus from "../../mixins/fixStatus";
import contact from "../../mixins/contact";
import catchNotFoundError from "../../mixins/catchNotFoundError";
import displayNameMixin from "../../mixins/displayNameMixin";

export default {
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  mixins: [
    showErrorMixin,
    notifyMixin,
    checkToken,
    setFeedbackMixin,
    fixStatus,
    contact,
    catchNotFoundError,
    displayNameMixin,
  ],
  data() {
    return {
      feedback: [],
      is_sending: false,
      tableData: {
        draw: 0,
        search: "",
      },
      not_found: false,
      client: {},
      order: {},
      loading: false,
      selectedClient: {},
      selectedClients: [],
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
    locale() {
      return this.$store.getters.locale;
    },
  },
  created() {
    if (this.id) {
      this.getClient();
    }
  },
  methods: {
    getClients() {
      this.selectedClients = [];
      this.loading = true;
      this.tableData.draw++;
      this.feedback = [];
      if (this.tableData.search === "") {
        this.setFeedback("error", "search");
      }
      if (this.feedback.length !== 0) {
        this.loading = false;
        return;
      }
      this.not_found = false;

      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let url = "/api/v1/clients/search-add-money";
      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          this.loading = false;
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.selectedClients = data.clients;
          }
        })
        .catch((error) => {
          console.log("Error");
          this.loading = false;
          if (!!error.response && error.response.status === 404) {
            this.not_found = true;
          }
        });
    },

    selectClient(client) {
      this.feedback = [];
      if (!client.orders || client.orders.length === 0) {
        this.setFeedback("error", "noOrders");
        return;
      }
      this.client = client;
      this.selectedClients = [];
      if (this.client && this.client.orders && this.client.orders.length > 0) {
        this.order = this.client.orders[0];
      }
    },

    changeSelectedClient() {
      this.tableData.search = "";
      this.tableData.draw = 0;
      this.client = {};
    },

    async getClient() {
      this.loading = true;
      try {
        const response = await this.$store.dispatch("clients/getClient", {
          id: this.id,
        });
        if (response.status === 200) {
          this.client = response.data;
          if (
            this.client &&
            this.client.orders &&
            this.client.orders.length > 0
          ) {
            this.order = this.client.orders[0];
          }
          this.loading = false;
        }
      } catch (err) {
        console.log("error");
        this.loading = false;
      }
    },
    selectOrder(order) {
      this.order = order;
    },
    async downloadInvoice() {
      this.is_sending = true;
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = {
        id: this.order.id,
      };
      let url = "/api/v1/orders/download";

      try {
        const response = await this.$axios({
          method: submit_method,
          url: url,
          data: submit_data,
        });
        if (response.status === 200) {
          window.open(response.data.url, "Download");
          this.is_sending = false;
        }
      } catch (error) {
        this.is_sending = false;
        console.log("Error");
      }
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}

.order-list-item {
  cursor: pointer;
}
</style>