<template>
  <attribute-dialog v-if="showModal" :product="product" @close="showModal = false" @selectAttribute="setAttributes">
  </attribute-dialog>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("products") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">{{ $t("productsCatalog") }}</li>
      </ol>
    </div>
  </div>
  <loader v-if="loading"></loader>
  <div class="row" v-else>
    <div class="col-lg-12">
      <div class="row">
        <div class="col-xl-3 col-lg-6 col-sm-6" v-for="product in products" :key="product.id">
          <div class="card item-card">
            <div class="card-body pb-0">
              <div class="text-center position-relative cart-container">
                <router-link :to="`/products/${product.id}`">
                  <img :src="getFirstImageLink(product)" alt="img" class="img-fluid product-image" />
                </router-link>
                <button @click="addBtnHandler(product)" class="btn btn-sm btn-primary position-absolute cart-btn"
                  :class="{
                    'btn-primary': !checkIfInCart(product),
                    'btn-success': checkIfInCart(product),
                  }">
                  <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path d="M15.55 11l2.76-5H6.16l2.37 5z" opacity=".3"></path>
                    <path
                      d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z">
                    </path>
                  </svg>
                  <span v-if="checkIfInCart(product)">{{
                      $t("addedToCart")
                  }}</span>
                  <span v-else>{{ $t("addToCart") }}</span>
                </button>
              </div>
              <div class="card-body px-0">
                <div class="cardtitle">
                  <router-link :to="`/products/${product.id}`">
                    <a class="shop-title">{{ displayName(product.name) }}</a>
                  </router-link>
                </div>
                <div class="">
                  <span>
                    <span v-if="loggedUser.company">
                      {{ loggedUser.company.currency.symbol }}
                    </span>

                    {{ product.price }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <pagination :pagination="pagination" @prev="getProducts(pagination.prevPageUrl)"
          @next="getProducts(pagination.nextPageUrl)">
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import fixStatus from "../../mixins/fixStatus";
import checkToken from "../../mixins/checkToken";
import displayNameMixin from "../../mixins/displayNameMixin";
// import _ from "lodash";
export default {
  mixins: [
    showErrorMixin,
    notifyMixin,
    checkToken,
    fixStatus,
    displayNameMixin,
  ],
  data() {
    return {
      products: [],
      tableData: {
        draw: 0,
        length: 12,
        search: "",
        column: 1,
        dir: "asc",
        categoryId: "",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      loading: true,
      product: {},
      showModal: false,
    };
  },
  created() {
    this.getProducts();
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    searchString() {
      return this.$store.getters["catalog/searchString"];
    },
    categoryId() {
      return this.$store.getters["catalog/categoryId"];
    },
    token() {
      return this.$store.getters.token;
    },
    cart() {
      return this.$store.getters["catalog/cart"];
    },
  },
  watch: {
    searchString: function (newValue) {
      // Set in the vuex store by the topbar input field
      this.tableData.search = newValue;
      this.getProducts();
    },
    categoryId: function (newValue) {
      // Set in the vuex store by the topbar category field
      this.tableData.categoryId = newValue;
      this.getProducts();
    },
  },
  methods: {
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    getProducts(url = "/api/v1/catalog-products") {
      this.loading = true;
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.products = data.products.data;
            this.configPagination(data.products);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    getFirstImageLink(product) {
      if (product.photos && product.photos.length > 0) {
        return `${this.$baseURL}storage/${product.photos[0].file_path}`;
      }
      return "/images/no-image.png";
    },

    addBtnHandler(product) {
      if (this.checkIfInCart(product)) {
        this.removeFromCart(product);
      } else {
        if (product.attributes.length > 0) {
          this.product = product;
          this.showModal = true;
        } else {
          this.addToCart(product);
        }
      }
    },

    setAttributes(attributes) {
      this.showModal = false;
      this.product = Object.assign({}, this.product);
      this.product.selectedAttributes = attributes;
      this.addToCart(this.product);
    },

    addToCart(product) {
      if (!("selectedAttributes" in product)) {
        product["selectedAttributes"] = [];
      }
      this.$store.dispatch("catalog/addToCart", product);
    },

    removeFromCart(product) {
      this.$store.dispatch("catalog/removeFromCart", product);
    },

    checkIfInCart(productParam) {
      if (productParam.attributes.length > 0) {
        return false;
      }
      const category = this.cart.find((product) => {
        return product.id === productParam.id;
      });
      return category ? true : false;
    },
  },
};
</script>