export default {
    retrieveToken(state, token) {
        state.token = token;
    },
    destroyToken(state) {
        state.token = null;
    },
    setUser(state, user) {
        state.user = user;
    },
    setEmail(state, email) {
        state.email = email;
    },
    destroyUser(state) {
        state.user = {};
    },
    setLocale(state, locale) {
        state.locale = locale;
    }
};
