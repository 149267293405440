export default {
    searchString(state) {
        return state.searchString;
    },
    categoryId(state) {
        return state.category;
    },
    cart(state) {
        return state.cart;
    },
    cartTotal(state) {
        return state.cart.reduce((acc, curr) => {
            let price = parseFloat(curr.amountInCart) * parseFloat(curr.price);
            return acc + price;
        }, 0);
    },
    shippingAddress(state) {
        return state.shipping_address;
    },
    client(state) {
        return state.client;
    },
    payment(state) {
        return state.payment;
    },
    mobileToCreate(state) {
        return state.mobileToCreate;
    }
};
