export default {
    methods: {
        setFeedback(type, field) {
            let validation_error = {};
            validation_error.alert_type = type;
            validation_error.field = field;
            this.feedback.push(validation_error);
        },

        findFieldinFeedback(field_name) {
            const field_in_feedback = this.feedback.find((feedback) => {
                return feedback.field === field_name;
            });
            if (field_in_feedback) {
                return true;
            }
            return false;
        },

        removeFromFeedback(field) {
            const index = this.feedback.findIndex((i) => {
                return i.field === field;
            });
            this.feedback.splice(index, 1);
        },

        setServerFeedback(type, message, field) {
            let validation_error = {};
            validation_error.alert_type = type;
            validation_error.message = message;
            validation_error.field = field;
            this.serverCheckFeedback.push(validation_error);
        },

        findFieldinServerFeedback(field_name) {
            const field_in_feedback = this.serverCheckFeedback.find(
                (feedback) => {
                    return feedback.field === field_name;
                }
            );
            if (field_in_feedback) {
                return true;
            }
            return false;
        },

        getServerFeedbackMessage(field_name) {
            const field_in_feedback = this.serverCheckFeedback.find(
                (feedback) => {
                    return feedback.field === field_name;
                }
            );
            if (field_in_feedback.message !== "") {
                return field_in_feedback.message;
            }
            return `Check ${field_name}`;
        },

        removeFromServerFeedback(field) {
            const index = this.serverCheckFeedback.findIndex((i) => {
                return i.field === field;
            });
            this.serverCheckFeedback.splice(index, 1);
        },
    },
};
