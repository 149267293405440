<script>
import { Line } from "vue3-chart-v2";
export default {
    extends: Line,
    props: {
        label: {
            type: String,
        },
        chartData: {
            type: Array,
        },
        options: {
            type: Object,
        },
        chartColors: {
            type: Object,
        },
    },
    mounted() {
        const dates = this.chartData.map((d) => d.date).reverse();
        const totals = this.chartData.map((d) => d.total).reverse();

        const {
            borderColor,
            pointBorderColor,
            pointBackgroundColor,
            backgroundColor,
        } = this.chartColors;

        this.renderChart(
            {
                labels: dates,
                datasets: [
                    {
                        label: this.label,
                        data: totals,
                        borderColor: borderColor,
                        pointBorderColor: pointBorderColor,
                        pointBackgroundColor: pointBackgroundColor,
                        backgroundColor: backgroundColor,
                    },
                ],
            },
            this.options
        );
    },
};
</script>
