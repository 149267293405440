// import "@assets/plugins/notify/js/notifIt.js";

export default {
    methods: {
        notify(message) {
            this.notif({
                msg: message,
                type: "success",
            });
        },
        notif(type) {
            console.log(type);
        }
    },
};
