<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("payment") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/products-catalog">
            <a class="d-flex">{{ $t("productsCatalog") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("payment") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="card overflow-hidden" v-if="Object.keys(order).length > 0">
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("clientDetails") }}</h3>
        </div>
        <div class="card-body">
          <div class="dropdown user-pro-body text-center" data-v-62b8754f="">
            <div class="user-pic" data-v-62b8754f="">
              <img
                :src="imgLink"
                alt="user-img"
                class="avatar-xl rounded-circle mb-1"
                data-v-62b8754f=""
              />
            </div>
          </div>
          <h4 class="card-title mb-1">{{ $t("personalDetails") }}</h4>
          <div class="table-responsive">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("name") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    {{ order.client.first_name }}
                    {{ order.client.last_name }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("email") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    <a
                      :href="getMailLink(order.client.email)"
                      target="_blank"
                      >{{ order.client.email }}</a
                    >
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("phone") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    <a
                      :href="getPhoneLink(order.client.mobile)"
                      target="_blank"
                      >{{ order.client.mobile }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        class="card overflow-hidden card-collapsed"
        v-if="
          Object.keys(order).length > 0 && Object.keys(order.address).length > 0
        "
      >
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("deliveryAddress") }}</h3>
          <div class="card-options">
            <a
              href="#"
              class="card-options-collapse mr-2"
              data-toggle="card-collapse"
              ><i class="fe fe-chevron-up text-white"></i
            ></a>
          </div>
        </div>
        <div class="card-body">
          <div
            class="
              d-sm-flex
              mt-0
              p-3
              sub-review-section
              border border-bottom-0
              br-bl-0 br-br-0
            "
          >
            <div class="media-body">
              <h5 class="mt-0 mb-1 font-weight-semibold">
                {{ order.client.first_name }}
                {{ order.client.last_name }}
              </h5>
              <p class="font-13 mb-2 mt-2">
                <span v-if="order.address.zip_code"
                  >{{ order.address.zip_code }},
                </span>
                <span v-if="order.address.street_address"
                  >{{ order.address.street_address }},
                </span>
                <span v-if="order.address.locality"
                  >{{ order.address.locality }},
                </span>
                <span v-if="order.address.city"
                  >{{ order.address.city }},
                </span>
                <span v-if="order.address.country">{{
                  order.address.country
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card" v-if="Object.keys(order).length > 0">
        <div class="card-header">
          <h3 class="card-title">{{ $t("paymentDetails") }}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <strong>{{ $t("invoiceTotal") }}</strong>
            </div>
            <div class="col-sm-6 text-right">
              <span>
                 <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                {{ parseFloat(order.total).toFixed(2) }}</span
              ><br />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <strong>{{ $t("paidAmount") }}</strong>
            </div>
            <div class="col-sm-6 text-right">
              <span>
                 <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                {{ parseFloat(order.amount_paid).toFixed(2) }}</span
              ><br />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-sm-6">
              <strong>{{ $t("remainingAmount") }}</strong>
            </div>
            <div class="col-sm-6 text-right">
              <span>
                 <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                {{ parseFloat(order.amount_remaining).toFixed(2) }}</span
              ><br />
            </div>
          </div>
        </div>
      </div>
      <div
        class="card overflow-hidden"
        v-if="parseFloat(order.amount_remaining).toFixed(2) > 0"
      >
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("payment") }}</h3>
        </div>
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("method") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <select
                      @change="changePayment"
                      v-model="payment.method"
                      class="form-control"
                      :class="{
                        'border-red':
                          findFieldinFeedback('method') &&
                          payment.method === '',
                      }"
                    >
                      <option value="wallet">{{ $t("wallet") }}</option>
                      <option value="cash">{{ $t("cash") }}</option>
                    </select>
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('method') && payment.method === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("methodCannotBeBlank") }}
                  </p>
                </div>
              </div>
              <div class="col-12" v-if="payment.method == 'wallet'">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("typeOfWallet") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <select
                      @change="changePayment"
                      v-model="payment.walletType"
                      class="form-control"
                      :class="{
                        'border-red':
                          findFieldinFeedback('typeOfWallet') &&
                          payment.typeOfWallet === '',
                      }"
                    >
                      <option value="smilepay">Smilepay</option>
                      <option value="orangePay">Orange pay</option>
                    </select>
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('typeOfWallet') &&
                      payment.typeOfWallet === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("walletTypeCannotBeBlank") }}
                  </p>
                </div>
              </div>
              <div class="col-12" v-if="payment.method == 'wallet'">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("transactionId") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      @blur="changePayment"
                      :class="{
                        'border-red':
                          findFieldinFeedback('transaction_id') &&
                          payment.method === 'wallet' &&
                          payment.transaction_id === '',
                      }"
                      v-model="payment.transaction_id"
                      type="text"
                      class="form-control"
                      :placeholder="$t('transactionId')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('transaction_id') &&
                      payment.method === 'wallet' &&
                      payment.transaction_id === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("enterATransactionId") }}
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("paidAmount") }}<span>*</span>
                  </label>
                  <div class="input-group">
                    <input
                      @blur="changePayment"
                      :class="{
                        'border-red':
                          findFieldinFeedback('paid') && fullPriceIsNotPaid,
                      }"
                      v-model.number="payment.paid"
                      type="number"
                      min="0"
                      step="any"
                      class="form-control"
                      :placeholder="$t('paidAmount')"
                    />
                  </div>
                  <p
                    v-if="findFieldinFeedback('paid') && fullPriceIsNotPaid"
                    class="text-danger"
                  >
                    {{
                      $t(
                        "amountPaidAmountRemainingMustEqualTheTotalAmountRemaining"
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("remainingAmount") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      @blur="changePayment"
                      :class="{
                        'border-red':
                          findFieldinFeedback('remaining') &&
                          payment.remaining === '',
                      }"
                      v-model="payment.remaining"
                      type="number"
                      step="any"
                      min="0"
                      readonly
                      class="form-control"
                      :placeholder="$t('remainingAmount')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('remaining') &&
                      payment.remaining === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("enterAnAmount") }}
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12">
              <div class="card-options pull-right">
                <button
                  @click="savePayment"
                  class="d-block btn btn-success btn-sm"
                >
                  {{ $t("save") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import fixStatus from "../../mixins/fixStatus";
import checkToken from "../../mixins/checkToken";
import setFeedbackMixin from "../../mixins/setFeedback";
import contact from "../../mixins/contact";
// import _ from "lodash";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  mixins: [
    showErrorMixin,
    notifyMixin,
    checkToken,
    fixStatus,
    setFeedbackMixin,
    contact,
  ],
  data() {
    return {
      client_id: "",
      feedback: [],
      tableData: {
        search: "",
      },
      payment: {
        method: "wallet",
        walletType: "smilepay",
        paid: 0,
        remaining: 0,
        transaction_id: "",
      },
      order: {},
    };
  },
  created() {
    this.getOrder();
  },
  watch: {
    cashPaid: function (newValue) {
      if (newValue === "") {
        this.payment.remaining = parseFloat(this.order.amount_remaining);
      } else {
        this.payment.remaining = parseFloat(
          (
            parseFloat(this.order.amount_remaining) -
            parseFloat(this.payment.paid)
          ).toFixed(2)
        );
      }
    },
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },

    cashPaid() {
      return this.payment.paid;
    },
    token() {
      return this.$store.getters.token;
    },
    imgLink() {
      if (this.order.photo) {
        return `${this.$baseURL}storage/${this.order.photo.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
    fullPriceIsNotPaid() {
      return (
        parseFloat(this.payment.paid === "" ? 0 : this.payment.paid) +
          parseFloat(this.payment.remaining) !==
        parseFloat(this.order.amount_remaining)
      );
    },
  },
  methods: {
    async getOrder() {
      try {
        const response = await this.$store.dispatch("catalog/getOrder", {
          id: this.id,
        });
        if (response.status === 200) {
          this.order = response.data;
          if (parseFloat(this.order.amount_remaining)) {
            this.payment.paid = parseFloat(this.order.amount_remaining);
            this.payment.remaining = parseFloat(
              (
                parseFloat(this.order.amount_remaining) -
                parseFloat(this.payment.paid)
              ).toFixed(2)
            );
          }
        }
      } catch (err) {
        console.log("error");
      }
    },
    changePayment() {
      if (this.payment.method === "cash") {
        this.payment.walletType = "smilepay";
        this.payment.transaction_id = "";
      }
    },

    addClient() {
      const mobile = this.tableData.search;
      this.$store.dispatch("catalog/mobileToCreate", mobile);
      this.$router.push("/clients/create/order-preview");
    },

    async savePayment() {
      this.feedback = [];

      if (this.fullPriceIsNotPaid) {
        this.setFeedback("error", "paid");
      }

      if (
        this.payment.method === "wallet" &&
        this.payment.transaction_id === ""
      ) {
        this.setFeedback("error", "transaction_id");
      }
      if (this.feedback.length !== 0) {
        return;
      }

      const data = {
        payment_method: this.payment.method,
        type_of_wallet: this.payment.walletType,
        transaction_id: this.payment.transaction_id,
        amount_paid: this.payment.paid === "" ? 0 : this.payment.paid,
        amount_remaining: this.payment.remaining,
        id: this.order.id,
      };

      try {
        const response = await this.$store.dispatch(
          "catalog/updateOrderPayment",
          data
        );
        if (response.status === 200) {
          this.payment.payment_method = "wallet";
          this.notify(this.$t("updated"));
          this.getOrder();
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}
</style>