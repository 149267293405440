export default {
    methods: {
        getMailLink(email) {
            return `mailto:${email}`;
        },

        getPhoneLink(phone) {
            return `tel:${phone}`;
        },
    },
};
