<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("roles") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a> {{ $t("roles") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 pr-5">
              <h3 class="card-title">
                {{ typeOfRole }} {{ $t("role") }}
                <button v-if="mode === 'edit'" @click="changeToNew" class="pull-right btn btn-primary btn-sm">
                  {{ $t("clear") }}
                </button>
              </h3>
              <form class="form-horizontal" @submit.prevent="submitRoleHandler">
                <div class="form-group row">
                  <label class="col-md-3 form-label">{{ $t("name") }} <span>*</span></label>
                  <div class="col-md-9">
                    <input :class="{
                      'border-red':
                        findFieldinFeedback('name') && form.name === '',
                    }" :placeholder="$t('name')" v-model="form.name" type="text" class="form-control" />
                    <p v-if="findFieldinFeedback('name') && form.name === ''" class="text-danger">
                      {{ $t("nameCannotBeBlank") }}
                    </p>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-12">
                    <button type="submit" class="pull-right btn btn-sm btn-primary">
                      {{ submitBtnText }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm-6 pl-5">
              <div class="table-responsive">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select v-model="tableData.length" @change="getRoles()" name="example1_length"
                            aria-controls="example1" class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            ">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div id="example1_filter" class="dataTables_filter">
                        <label><input type="search" v-model="tableData.search" @input="search()"
                            class="form-control form-control-sm" :placeholder="$t('search')"
                            aria-controls="example1" /></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                        <tbody>
                          <tr role="row" class="odd" v-for="role in roles" :key="role.id">
                            <td>
                              {{ role.name }}
                            </td>
                            <td>
                              <span class="mr-2">
                                <i @click="setUpForm(role)" class="fa fa-pencil" data-toggle="tooltip"
                                  title="fa fa-pencil"></i>
                              </span>
                              <span>
                                <i @click="deleteRole(role.id)" class="text-danger fa fa-trash pointer"
                                  data-toggle="tooltip" title="fa fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination :pagination="pagination" @prev="getRoles(pagination.prevPageUrl)"
                      @next="getRoles(pagination.nextPageUrl)">
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../../mixins/showError";
import notifyMixin from "../../../mixins/notify";
import setFeedbackMixin from "../../../mixins/setFeedback";
import checkToken from "../../../mixins/checkToken";
import catchBadRequestError from "../../../mixins/catchBadRequestError";
import _ from "lodash";

export default {
  mixins: [
    showErrorMixin,
    setFeedbackMixin,
    notifyMixin,
    checkToken,
    catchBadRequestError,
  ],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t('name'),
        name: "name",
        sortable: true,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.name = 1;

    return {
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "name",
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      roles: [],

      validation_errors: {},
      feedback: [],
      form: {
        name: "",
      },
      role_id: null,
      mode: null,
      loading: true,
    };
  },
  created() {
    this.getRoles();
  },
  computed: {
    submitBtnText() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("submit");
    },
    typeOfRole() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("new");
    },
    token() {
      return this.$store.getters.token;
    },
  },
  methods: {
    search: _.debounce(function () {
      this.getRoles();
    }, 500),
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getRoles();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },

    getRoles(url = "/api/v1/paginated-roles") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          this.loading = false;
          if (this.tableData.draw === data.draw) {
            this.roles = data.roles.data;
            this.configPagination(data.roles);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    async submitRoleHandler() {
      this.feedback = [];

      if (!this.validate()) {
        return;
      }

      try {
        if (this.mode === "edit") {
          const response = await this.$store.dispatch("roles/updateRole", {
            id: this.role_id,
            name: this.form.name,
          });
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.getRoles();
            this.mode = null;
            this.role_id = null;
            this.form.name = null;
          }
        } else {
          const response = await this.$store.dispatch(
            "roles/submitRole",
            this.form
          );
          if (response.status === 201) {
            this.notify(this.$t("saved"));
            this.getRoles();
            this.form.name = null;
          }
        }
      } catch (err) {
        if (err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else if (err.response.status === 400) {
          this.catchBadRequestError(err, this.$t("thisRoleCannotBeUpdated"));
          console.log("Error");
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    validate() {
      if (this.form.name === "") {
        this.setFeedback("error", "name");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },

    setUpForm(role) {
      this.form.name = role.name;
      this.role_id = role.id;
      this.mode = "edit";
    },

    async deleteRole(id) {
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        icon: "warning",
        text: this.$t("youWontBeAbleToReverseThis"),
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("delete"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch("roles/deleteRole", id);
          if (response.status === 204) {
            this.notify(this.$t("deleted"));
            this.getRoles();
          }
        } catch (err) {
          this.catchBadRequestError(err, this.$t("thisRoleCannotBeDeleted"));
          console.log("Error");
        }
      }
    },
    changeToNew() {
      this.mode = null;
      this.clearForm();
    },

    clearForm() {
      this.form.name = "";
    },
  },
};
</script>

<style scoped>
i {
  cursor: pointer;
}

.border-red {
  border: 1px solid red;
}
</style>