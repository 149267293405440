<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("clients") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("clients") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header pr-0">
          <div class="row w-100">
            <div class="col-6 offset-6 text-right">
              <router-link to="/clients/create/index">
                <a class="pull-right btn btn-sm btn-primary">{{
                    $t("addNew")
                }}</a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select v-model="tableData.length" @change="getClients()" name="example1_length"
                            aria-controls="example1" class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            ">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy"
                        :tableIsSearchable="tableIsSearchable" @search="searchField">
                        <tbody>
                          <tr role="row" class="odd" v-for="client in clients" :key="client.id">
                            <td>
                              <router-link :to="`/clients/${client.id}`">
                                <a class="text-primary font-weight-bold fs-12">{{ client.first_name }}
                                  {{ client.last_name }}</a>
                              </router-link>
                            </td>
                            <td>
                              <a :href="getPhoneLink(client.mobile)" target="_blank">{{ client.mobile }}</a>
                            </td>
                            <td>
                              <a :href="getMailLink(client.email)" target="_blank">{{ client.email }}</a>
                            </td>
                            <td>
                              <span @click="updateStatus(client)" :class="{
                                'badge-success': client.status === 1,
                                'badge-danger': client.status === 0,
                              }" class="badge">
                                {{ fixStatus(client.status) }}
                              </span>
                            </td>
                            <td>
                              <span class="mr-2">
                                <router-link :to="`/clients/${client.id}/edit`">
                                  <a><i class="fa fa-pencil" data-toggle="tooltip" title="fa fa-pencil"></i></a>
                                </router-link>
                              </span>
                              <span>
                                <i @click="deleteClient(client.id)" class="text-danger fa fa-trash edit-icon"
                                  data-toggle="tooltip" title="fa fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination :pagination="pagination" @prev="getClients(pagination.prevPageUrl)"
                      @next="getClients(pagination.nextPageUrl)">
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import showErrorMixin from "../../mixins/showError";
import setFeedbackMixin from "../../mixins/setFeedback";
import notifyMixin from "../../mixins/notify";
import checkToken from "../../mixins/checkToken";
import contact from "../../mixins/contact";
import _ from "lodash";
export default {
  mixins: [showErrorMixin, setFeedbackMixin, notifyMixin, checkToken, contact],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("name"),
        name: "first_name",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("mobile"),
        name: "mobile",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("email"),
        name: "email",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("status"),
        name: "status",
        sortable: false,
        searchable: false,
        relationship: false,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
        searchable: false,
        relationship: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.name = 1;
    return {
      tableIsSearchable: true,
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "name",
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        field: "",
        relationship: false,
        relationship_field: "",
        dir: "asc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      clients: [],
      loading: true,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  created() {
    this.getClients();
  },
  methods: {
    searchField(searchValue, searchField, relationship, relationship_field) {
      this.tableData.search = searchValue;
      this.tableData.field = searchField;
      this.tableData.relationship = relationship;
      this.tableData.relationship_field = relationship_field;

      this.getClients();
    },
    search: _.debounce(function () {
      this.getClients();
    }, 500),
    sortBy(key) {
      this.sortKey = key; // Name of collumn being sorted
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getClients();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },

    getClients(url = "/api/v1/paginated-clients") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.clients = data.clients.data;
            this.configPagination(data.clients);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async deleteClient(id) {
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        icon: "warning",
        text: this.$t("thisWillDeleteTheClientSOrdersAndCannotBeReversed"),
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("delete"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch(
            "clients/deleteClient",
            id
          );
          if (response.status === 204) {
            this.notify(this.$t("deleted"));
            this.getClients();
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
    async updateStatus(client) {
      const status = client.status === 1 ? 0 : 1;
      let text;
      if (client.status === 1) {
        text = this.$t("doYouWantToInactivateThisClient");
      } else {
        text = this.$t("doYouWantToActivateThisClient");
      }
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        text: text,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("update"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch("clients/updateStatus", {
            id: client.id,
            status: status,
          });
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.getClients();
            this.mode = "";
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
          console.log("Error");
        }
      }
    },
    fixStatus(status) {
      if (status === 1) {
        return this.$t("active");
      }
      return this.$t("inactive");
    },
  },
};
</script>

<style scoped>
.badge,
.edit-icon {
  cursor: pointer;
}
</style>