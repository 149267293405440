<template>
  <div class="page-header" v-if="Object.keys(jobApplication).length > 0">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("jobApplication") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/job-applications">
            <a>{{ $t("jobApplications") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("view") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="Object.keys(jobApplication).length > 0 && !loading">
    <div class="col-xl-12 col-lg-12 col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td class="py-2 px-0 w-30">
                    <span class="font-weight-semibold"
                      >{{ $t("jobPosting") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    {{ jobApplication.job.title }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0 w-30">
                    <span class="font-weight-semibold"
                      >{{ $t("fullName") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    {{ jobApplication.fullName }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0 w-30">
                    <span class="font-weight-semibold">{{
                      $t("mobileNumber")
                    }}</span>
                  </td>
                  <td class="py-2 px-0 ">{{ jobApplication.mobileNumber }}</td>
                </tr>
                <tr>
                  <td class="py-2 px-0 w-30">
                    <span class="font-weight-semibold">{{
                      $t("email")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">{{ jobApplication.email }}</td>
                </tr>
                <tr>
                  <td class="py-2 px-0 w-30">
                    <span class="font-weight-semibold">{{
                      $t("qualification")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">{{ jobApplication.qualification }}</td>
                </tr>
                <tr>
                  <td class="py-2 px-0 w-30">
                    <span class="font-weight-semibold">{{
                      $t("experienceInYears")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                    {{ jobApplication.experienceInYears }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0 w-30">
                    <span class="font-weight-semibold">{{
                      $t("salaryExpectations")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                    {{ jobApplication.salaryExpectations }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0 w-30">
                    <span class="font-weight-semibold">{{
                      $t("coverLetter")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                    <p v-html="jobApplication.coverLetter"></p>
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0 w-30">
                    <span class="font-weight-semibold">{{
                      $t("cv")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                    <a
                      :href="jobApplication.cv.file_path"
                      class="btn btn-sm btn-success"
                      download
                    >
                      <i
                        class="fa fa-download"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="fa fa-download"
                      ></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import checkToken from "../../mixins/checkToken";
// import fixStatus from "../../mixins/fixStatus";
// import contact from "../../mixins/contact";
// import catchForbiddenError from "../../mixins/catchForbiddenError";
// import showError from "../../mixins/showError";
// import _ from "lodash";
export default {
  // mixins: [checkToken, fixStatus, contact, catchForbiddenError, showError],
  props: ["id"],
  data() {
    return {
      // sortOrders: sortOrders,
      // columns: columns,
      // sortKey: "name",
      // tableData: {
      //   draw: 0,
      //   length: 10,
      //   search: "",
      //   column: 0,
      //   dir: "asc",
      //   user_id: null,
      // },

      // pagination: {
      //   lastPage: "",
      //   currentPage: "",
      //   total: "",
      //   lastPageUrl: "",
      //   nextPageUrl: "",
      //   prevPageUrl: "",
      //   from: "",
      //   to: "",
      // },

      // clients: [],
      // user: {},
      loading: false,
      jobApplication: {},
    };
  },
  created() {
    // this.tableData.user_id = this.id;
    // this.getUser();
    // this.getClients();
    this.getJobApplication();
  },
  mounted() {
    // if (Object.keys(this.user).length > 0) {
    //   this.resetActiveClass();
    // }
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    // users() {
    //   return this.$store.getters["users/users"];
    // },
    // loggedUser() {
    //   return this.$store.getters.user;
    // },
    // imgLink() {
    //   if (this.user.photo) {
    //     return `${this.$baseURL}storage/${this.user.photo.file_path}`;
    //   }
    //   return "/assets/images/users/16.jpg";
    // },
  },
  methods: {
    // resetActiveClass() {
    //   // Reset the active class of the tab links to be the first one
    //   const panelTabs = document.querySelector(".nav.panel-tabs");
    //   const listItems = panelTabs.getElementsByTagName("li");

    //   for (let i = 0; i <= listItems.length - 1; i++) {
    //     let item = listItems[i];
    //     const link = item.querySelector("a");
    //     if (i === 0) {
    //       if (!link.classList.contains("active")) {
    //         link.classList.add("active");
    //       }
    //     } else {
    //       if (link.classList.contains("active")) {
    //         link.classList.remove("active");
    //       }
    //     }
    //   }
    // },
    // search: _.debounce(function () {
    //   this.getClients();
    // }, 500),
    // sortBy(key) {
    //   this.sortKey = key; // Name of collumn being sorted
    //   this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
    //   this.tableData.column = this.getIndex(this.columns, "name", key);
    //   this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
    //   this.getClients();
    // },
    // getIndex(array, key, value) {
    //   return array.findIndex((i) => i[key] === value);
    // },
    // configPagination(data) {
    //   this.pagination.lastPage = data.last_page;
    //   this.pagination.currentPage = data.current_page;
    //   this.pagination.total = data.total;
    //   this.pagination.lastPageUrl = data.last_page_url;
    //   this.pagination.nextPageUrl = data.next_page_url;
    //   this.pagination.prevPageUrl = data.prev_page_url;
    //   this.pagination.from = data.from;
    //   this.pagination.to = data.to;
    // },
    // getJobApplication(url = "/api/v1/paginated-clients") {
    //   axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

    //   let submit_method = "POST";
    //   let submit_data = this.tableData;

    //   axios({ method: submit_method, url: url, data: submit_data })
    //     .then((response) => {
    //       this.loading = false;
    //       let data = response.data;
    //       if (this.tableData.draw === data.draw) {
    //         this.clients = data.clients.data;
    //         this.configPagination(data.clients);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("Error");
    //       this.loading = false;
    //     });
    // },
    async getJobApplication() {
      this.loading = true;
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      try {
        const response = await this.$axios({
          method: "GET",
          url: `/api/v1/job-applications/${this.id}`,
        });
        this.loading = false;
        if (response.status === 200) {
          this.jobApplication = response.data;
        }
      } catch (err) {
        console.log("error");
        console.log(err);
        this.loading = false;
      }
    },
  },
};
</script>