<template>
  <div class="page page-style1">
    <div class="page-single">
      <div class="p-5">
        <div class="row">
          <div class="col mx-auto">
            <div class="row justify-content-center">
              <div class="col-lg-9 col-xl-8">
                <div class="card-group mb-0">
                  <div class="card p-4 page-content">
                    <div class="card-body page-single-content">
                      <div class="w-100">
                        <div class="">
                          <h1 class="mb-2">{{ $t("login") }}</h1>
                          <p class="text-muted">{{ $t("signInToYourAccount") }}</p>
                          <p class="text-muted">
                            {{ $t("user") }}: 'admin@crm.test', {{ $t("password") }}: 'password'
                          </p>
                          <p class="text-muted">
                            {{ $t("user") }}: 'admin2@crm.test', {{ $t("password") }}: 'password'
                          </p>
                        </div>
                        <hr class="divider my-3" />
                        <div class="alert alert-danger" role="alert" v-if="
                          feedback.field === 'none' &&
                          feedback.message != null
                        ">
                          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                            ×
                          </button>
                          <strong> {{ feedback.message }} </strong>
                        </div>
                        <p class="text-m" v-if="feedback.change_pw && this.url_token !== null">
                          <router-link :to="changePwUrl">
                            <a>{{ $t("changePassword") }}</a>
                          </router-link>
                        </p>
                        <form id="login-form" @submit.prevent="submitForm">
                          <div class="input-group mb-3">
                            <span class="input-group-addon"><svg class="svg-icon" xmlns="http://www.w3.org/2000/svg"
                                height="24" viewBox="0 0 24 24" width="24">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 16c-2.69 0-5.77 1.28-6 2h12c-.2-.71-3.3-2-6-2z" opacity=".3" />
                                <circle cx="12" cy="8" opacity=".3" r="2" />
                                <path
                                  d="M12 14c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm-6 4c.22-.72 3.31-2 6-2 2.7 0 5.8 1.29 6 2H6zm6-6c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z" />
                              </svg>
                            </span>
                            <input v-model="email" type="email" class="form-control" required autofocus
                              :placeholder="$t('emailAddress')" :class="{
                                'is-invalid': feedback.field === 'email',
                              }" />
                          </div>
                          <div class="input-group mb-4">
                            <span class="input-group-addon"><svg class="svg-icon" xmlns="http://www.w3.org/2000/svg"
                                height="24" viewBox="0 0 24 24" width="24">
                                <g fill="none">
                                  <path d="M0 0h24v24H0V0z" />
                                  <path d="M0 0h24v24H0V0z" opacity=".87" />
                                </g>
                                <path d="M6 20h12V10H6v10zm6-7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
                                  opacity=".3" />
                                <path
                                  d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" />
                              </svg>
                            </span>

                            <input v-model="password" type="password" class="form-control" required
                              :placeholder="$t('password')" :class="{
                                'is-invalid': feedback.field === 'password',
                              }" />
                          </div>
                          <div class="row">
                            <div class="col-12"></div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <button type="submit" class="btn btn-lg btn-primary btn-block">
                                <i class="fe fe-arrow-right"></i> {{ $t('login') }}
                              </button>
                            </div>
                            <!-- <div class="col-12">
                              <router-link to="/forgot">
                                <a class="btn btn-link box-shadow-0 px-0">{{ $t('forgotPassword') }}?</a>
                              </router-link>
                            </div> -->
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="
                      card
                      text-white
                      bg-primary
                      py-5
                      d-md-down-none
                      page-content
                      mt-0
                    ">
                    <div class="
                        card-body
                        text-center
                        justify-content-center
                        page-single-content
                      ">
                      <img src="assets/images/pattern/login.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      feedback: {
        alert_type: null,
        field: null,
        message: null,
        change_pw: false,
      },
      email: null,
      password: null,
      url_token: null,
    };
  },
  computed: {
    changePwUrl() {
      if (this.url_token !== null && this.feedback.change_pw) {
        return `/change-password/${this.url_token}`;
      }
      return null;
    },
  },
  created() {
    // console.log("backendUrl");
    // console.log(this.$backendUrl);
  },
  methods: {
    async submitForm() {
      // TODO: check validation = "" on all forms
      this.feedback.field = null;
      this.feedback.alert_type = null;
      this.feedback.message = null;
      if (
        this.email === null ||
        this.email == "" ||
        !this.email.includes("@")
      ) {
        this.feedback.alert_type = "error";
        this.feedback.field = "email";
        this.feedback.message = this.$t("pleaseEnterValidEmail");
        return;
      }

      if (this.password === null || this.password == "") {
        this.feedback.alert_type = "error";
        this.feedback.field = "password";
        this.feedback.message = this.$t("pleaseEnterPassword");
        return;
      }

      try {
        const response = await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        if (response.status === 201) {
          if (
            response.data.user.active === 0 ||
            !response.data.user.email_verified_at
          ) {
            throw this.$t("pleaseVerifyYourAccountToLogin");
          }
          if (
            (response.data.user.active === 0 &&
              response.data.user.email_verified_at) ||
            response.data.user.force_pw_change === 1
          ) {
            this.feedback.change_pw = true;
            const token = response.data.token;
            this.url_token = token.replace("|", "-");
            throw this.$t("pleaseChangeYourPasswordToLogin");
          }
          this.$store.dispatch("setToken", {
            token: response.data.token,
          });
          this.$store.dispatch("setUser", {
            user: response.data.user,
          });
          this.$store.dispatch("setLocale", {
            locale: response.data.user.locale,
          });

          this.$store.dispatch("catalog/clearCart");
          this.$router.replace("/dashboard");
        }
      } catch (err) {
        if (err.response) {
          this.feedback.field = "none";
          this.feedback.alert_type = "error";
          this.feedback.message = err.response.data.message;
          return;
        }
        this.feedback.field = "none";
        this.feedback.alert_type = "error";
        this.feedback.message = err;
      }
    },
  },
};
</script>
