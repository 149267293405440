<template>
  <div class="page-header" v-if="editFormIsSetUp">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("pages") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/pages">
            <a>{{ $t("pages") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ submitBtnText }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" id="lead-div" v-if="editFormIsSetUp && !loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{ typeOfPage }} {{ $t("page") }}</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="submitUserHandler">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("title") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('title') && form.title === '',
                      }"
                      v-model="form.title"
                      type="text"
                      class="form-control"
                      :placeholder="$t('title')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('title') && form.title.trim() === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                  <p v-if="errors.title" class="text-danger">
                    {{ errors.title[0] }}
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("link") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      @focus="editLinkManually = true"
                      :class="{
                        'border-red':
                          findFieldinFeedback('link') && form.link === '',
                      }"
                      v-model="form.link"
                      type="text"
                      class="form-control"
                      :placeholder="$t('link')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('link') && form.link.trim() === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                  <p
                    v-if="
                      findFieldinFeedback('link') && form.link.indexOf(' ') >= 0
                    "
                    class="text-danger"
                  >
                    {{ $t("noSpacesAllowed") }}
                  </p>
                  <p v-if="errors.link" class="text-danger">
                    {{ errors.link[0] }}
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("content") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <prime-editor
                      v-model="form.content"
                      editorStyle="height: 320px"
                    ></prime-editor>
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('content') &&
                      form.content.trim() === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :class="{
                    'btn-loading': is_sending,
                  }"
                  :disabled="is_sending"
                >
                  {{ submitBtnText }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clearFormMixin from "../../mixins/clearForm";
import showErrorMixin from "../../mixins/showError";
import setFeedbackMixin from "../../mixins/setFeedback";
import notifyMixin from "../../mixins/notify";
import catchForbiddenError from "../../mixins/catchForbiddenError";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ckEditorConfig from "../../mixins/cKEditorConfig";
import _ from "lodash";

export default {
  mixins: [
    clearFormMixin,
    showErrorMixin,
    setFeedbackMixin,
    notifyMixin,
    catchForbiddenError,
    ckEditorConfig,
  ],
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      is_sending: false,
      errors: [],
      feedback: [],
      form: {
        title: "",
        link: "",
        content: "",
      },
      mode: "",
      loading: false,
      editLinkManually: false,
    };
  },
  created() {
    if (typeof this.id !== "undefined") {
      this.getPage();
    }
  },
  computed: {
    title() {
      return this.form.title;
    },
    editFormIsSetUp() {
      if (typeof this.id !== "undefined" && this.form.first_name === "") {
        return false;
      }
      return true;
    },
    submitBtnText() {
      if (this.mode === "edit" && this.is_sending) {
        return this.$t("updating");
      }
      if (this.mode === "edit") {
        return this.$t("update");
      }
      if (this.is_sending) {
        return this.$t("submitting");
      }
      return this.$t("submit");
    },
    typeOfPage() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("new");
    },
  },
  watch: {
    title: function (newValue) {
      if (!this.editLinkManually) {
        this.form.link = _.kebabCase(newValue);
      }
    },
  },
  methods: {
    async getPage() {
      try {
        const response = await this.$store.dispatch("pages/getPage", {
          id: this.id,
        });
        this.loading = false;
        if (response.status === 200) {
          this.setUpForm(response.data);
        }
      } catch (err) {
        console.log("error");
        this.loading = false;
      }
    },
    async submitUserHandler() {
      this.feedback = [];

      if (!this.validate()) {
        return;
      }

      this.is_sending = true;
      console.log(this.form);
      try {
        if (this.mode === "edit") {
          this.form.id = this.id;
          const response = await this.$store.dispatch(
            "pages/update",
            this.form
          );
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.$router.replace("/pages");
          }
        } else {
          const response = await this.$store.dispatch(
            "pages/submit",
            this.form
          );
          this.is_sending = false;
          if (response.status === 201) {
            this.notify(this.$t("saved"));
            this.$router.replace("/pages");
          }
        }
      } catch (err) {
        console.log("err");
        console.log(err);
        this.is_sending = false;
        if (!!err.response && err.response.status === 422) {
          this.errors = err.response.data.errors;
          const first_error = this.errors[Object.keys(this.errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    validate() {
      if (this.form.title.trim() === "") {
        this.setFeedback("error", "title");
      }
      if (this.form.link.trim() === "") {
        this.setFeedback("error", "link");
      }
      if (this.form.link.indexOf(" ") >= 0) {
        this.setFeedback("error", "link");
      }
      if (this.form.content.trim() === "") {
        this.setFeedback("error", "content");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },

    setUpForm(page) {
      this.clearForm();
      this.form.title = page.title;
      this.form.link = page.link;
      this.form.content = page.content;
      this.mode = "edit";
      this.editLinkManually = true;
    },

    clearForm() {
      this.form.title = "";
      this.form.link = "";
      this.form.content = "";
      this.mode = "";
    },
  },
};
</script>

<style>
.border-red {
  border: 1px solid red;
}
</style>