const axios = require('axios').default;
export default {
    setSearchString(context, payload) {
        context.commit("setSearchString", payload);
    },

    setCategory(context, payload) {
        context.commit("setCategory", payload);
    },

    addToCart(context, payload) {
        payload.amountInCart = 1; // Add one pc to cart by default
        context.commit("addToCart", payload);
        localStorage.setItem("cart", JSON.stringify(context.getters.cart));
    },

    removeFromCart(context, payload) {
        context.commit("removeFromCart", payload);
        localStorage.setItem("cart", JSON.stringify(context.getters.cart));
    },

    clearCart(context) {
        context.commit("clearCart");
        localStorage.removeItem("cart");
        localStorage.removeItem("shipping_address");
    },

    clearClient(context) {
        context.commit("clearClient");
        localStorage.removeItem("client");
    },

    clearPayment(context) {
        context.commit("clearPayment");
        localStorage.removeItem("payment");
    },

    changeAmountInCart(context, payload) {
        context.commit("changeAmountInCart", payload);
        localStorage.setItem("cart", JSON.stringify(context.getters.cart));
    },
    changeAddress(context, payload) {
        context.commit("changeAddress", payload);
        localStorage.setItem(
            "shipping_address",
            JSON.stringify(context.getters.shippingAddress)
        );
    },

    changeClient(context, payload) {
        context.commit("changeClient", payload);
        localStorage.setItem("client", JSON.stringify(context.getters.client));
    },

    changePayment(context, payload) {
        context.commit("changePayment", payload);
        localStorage.setItem(
            "payment",
            JSON.stringify(context.getters.payment)
        );
    },

    mobileToCreate(context, payload) {
        context.commit("mobileToCreate", payload);
    },

    submitOrder(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/orders";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    updateOrder(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/orders/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    updateOrderPayment(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/orders/payment/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    updateOrderStatus(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/orders/update-status/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    approve(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/orders/approve/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    getOrder(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = `/api/v1/orders/${payload.id}`;
        return axios({ method: submit_method, url: uri });
    },

    approveCollection(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/payments/approve/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    denyCollection(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/payments/deny/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    }
};
