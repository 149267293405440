<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">Profile</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard" class="d-flex"
            ><svg
              class="svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0V0z" fill="none"></path>
              <path
                d="M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3zm5 15h-2v-6H9v6H7v-7.81l5-4.5 5 4.5V18z"
              ></path>
              <path
                d="M7 10.19V18h2v-6h6v6h2v-7.81l-5-4.5z"
                opacity=".3"
              ></path></svg
            ><span class="breadcrumb-icon"> Home</span></router-link
          >
        </li>
        <li class="breadcrumb-item"><a href="#">Profile</a></li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-12">
      <div class="card box-widget widget-user">
        <div class="widget-user-image mx-auto mt-5">
          <img
            alt="User Avatar"
            class="rounded-circle"
            :src="imgLink"
            width="100"
          />
        </div>

        <div class="card-body text-center">
          <div class="pro-user">
            <h4 class="pro-user-username text-dark mb-1 font-weight-bold">
              {{ `${loggedUser.first_name} ${loggedUser.last_name}` }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12">
      <div class="main-content-body main-content-body-profile card mg-b-20">
        <div class="main-profile-body">
          <div class="tab-content">
            <div class="tab-pane show active" id="about">
              <div class="card-body">
                <div
                  class="alert alert-warning"
                  role="alert"
                  v-if="loggedUser.company === null"
                >
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-hidden="true"
                  >
                    ×</button
                  >Please update your company details.
                </div>
                <div class="panel panel-primary">
                  <div class="tab-menu-heading p-0 bg-light">
                    <div class="tabs-menu1">
                      <ul id="profile-panel-tab-ul" class="nav panel-tabs">
                        <li class="">
                          <a
                            href="#personal-details-tab"
                            class="active"
                            data-toggle="tab"
                            >Personal Details</a
                          >
                        </li>
                        <li class="">
                          <a
                            href="#company-details-tab"
                            id="company-details-link"
                            data-toggle="tab"
                            >Company Details
                            <span
                              class="text-warning"
                              v-if="loggedUser.company === null"
                            >
                              <i class="fa fa-exclamation"></i>
                              <!-- <i class="fa fa-pencil"></i> -->
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#change-password-tab" data-toggle="tab">
                            Change Your Password
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="panel-body tabs-menu-body">
                    <div class="tab-content">
                      <div class="tab-pane active" id="personal-details-tab">
                        <table class="table mb-0">
                          <tbody>
                            <tr>
                              <td class="py-2 px-0">
                                <span class="font-weight-semibold w-50"
                                  >Name
                                </span>
                              </td>
                              <td class="py-2 px-0">
                                {{
                                  `${loggedUser.first_name} ${loggedUser.last_name}`
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="py-2 px-0">
                                <span class="font-weight-semibold w-50"
                                  >Email
                                </span>
                              </td>
                              <td class="py-2 px-0">{{ loggedUser.email }}</td>
                            </tr>
                            <tr>
                              <td class="py-2 px-0">
                                <span class="font-weight-semibold w-50"
                                  >Username
                                </span>
                              </td>
                              <td class="py-2 px-0">
                                {{ loggedUser.username }}
                              </td>
                            </tr>
                            <tr>
                              <td class="py-2 px-0">
                                <span class="font-weight-semibold w-50"
                                  >Phone
                                </span>
                              </td>
                              <td class="py-2 px-0">{{ loggedUser.phone }}</td>
                            </tr>
                            <tr>
                              <td class="py-2 px-0">
                                <span class="font-weight-semibold w-50"
                                  >Role
                                </span>
                              </td>
                              <td class="py-2 px-0">
                                {{ loggedUser.role.name }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <router-link to="/profile/personal-details/edit">
                          <a class="btn btn-sm btn-primary">
                            Edit Personal Details
                          </a>
                        </router-link>
                      </div>
                      <div class="tab-pane" id="company-details-tab">
                        <div class="main-profile-bio mb-0">
                          <div class="table-responsive">
                            <table class="table mb-0">
                              <tbody>
                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50"
                                      >{{ $t("name") }}
                                    </span>
                                  </td>
                                  <td class="py-2 px-0">{{ company.name }}</td>
                                </tr>
                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50"
                                      >{{ $t("industry") }}
                                    </span>
                                  </td>
                                  <td class="py-2 px-0">
                                    {{ company.industry }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50"
                                      >{{ $t("mobile") }}
                                    </span>
                                  </td>
                                  <td class="py-2 px-0">
                                    {{ company.mobile }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50"
                                      >{{ $t("fax") }}
                                    </span>
                                  </td>
                                  <td class="py-2 px-0">{{ company.fax }}</td>
                                </tr>
                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50"
                                      >{{ $t("email") }}
                                    </span>
                                  </td>
                                  <td class="py-2 px-0">{{ company.email }}</td>
                                </tr>
                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50"
                                      >{{ $t("currency") }}
                                    </span>
                                  </td>
                                  <td class="py-2 px-0">
                                    <span v-if="company.currency">
                                      {{ company.currency.name }} ({{
                                        company.currency.code
                                      }})
                                    </span>
                                    <span v-else>NA</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50">{{
                                      $t("address")
                                    }}</span>
                                  </td>
                                  <td class="py-2 px-0">
                                    {{ company.address }}
                                  </td>
                                </tr>

                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50">{{
                                      $t("zipCode")
                                    }}</span>
                                  </td>
                                  <td class="py-2 px-0">
                                    {{ company.zip_code }}
                                  </td>
                                </tr>

                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50">{{
                                      $t("city")
                                    }}</span>
                                  </td>
                                  <td class="py-2 px-0">{{ company.city }}</td>
                                </tr>

                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50">{{
                                      $t("state")
                                    }}</span>
                                  </td>
                                  <td class="py-2 px-0">{{ company.state }}</td>
                                </tr>

                                <tr>
                                  <td class="py-2 px-0">
                                    <span class="font-weight-semibold w-50">{{
                                      $t("country")
                                    }}</span>
                                  </td>
                                  <td class="py-2 px-0">
                                    {{ company.country }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <router-link to="/profile/company-details/edit">
                              <a class="btn btn-sm btn-primary">
                                Edit Company Details
                              </a>
                            </router-link>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="change-password-tab">
                        <change-my-password
                          :user="loggedUser"
                          @update-password="passwordUpdated"
                        ></change-my-password>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkToken from "../../../mixins/checkToken";
import ChangeMyPassword from "../ChangePassword.vue";

export default {
  mixins: [checkToken],
  components: { ChangeMyPassword },
  data() {
    return {
      company: {},
      loading: true,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    imgLink() {
      if (this.loggedUser.photo) {
        return `${this.$baseURL}storage/${this.loggedUser.photo.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
  },
  created() {
    this.getCompany();
  },
  mounted() {
    this.resetActiveTab();
  },
  methods: {
    resetActiveTab() {
      // Reset the active class of the tab links to be the first one
      const panelTabs = document.getElementById("profile-panel-tab-ul");
      const listItems = panelTabs.getElementsByTagName("li");

      for (let i = 0; i <= listItems.length - 1; i++) {
        let item = listItems[i];
        const link = item.querySelector("a");
        if (i === 0) {
          if (!link.classList.contains("active")) {
            link.classList.add("active");
          }
        } else {
          if (link.classList.contains("active")) {
            link.classList.remove("active");
          }
        }
      }
    },
    async getCompany() {
      try {
        const response = await this.$store.dispatch(
          "companies/getUserCompany",
          {
            id: this.id,
          }
        );
        this.loading = false;
        if (response.status === 200) {
          this.company = response.data;
        }
      } catch (err) {
        console.log("error");
        console.log(err);
        this.loading = false;
      }
    },
    passwordUpdated() {
      this.notify("Password Changed");
    },
    notify(message) {
      console.log(message);
    },
  },
};
</script>