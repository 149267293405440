<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">Permissions for {{ role.name }} (in progress)</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> Dashboard</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>Permissions</a>
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row pb-4 border-bottom">
            <div class="col-xl-4">
              <div class="form-group m-0">
                <div class="form-label mb-2">Leads</div>
                <div class="custom-controls-stacked">
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox1"
                      value="option1"
                      checked=""
                    />
                    <span class="custom-control-label">View All</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox4"
                      value="option4"
                    />
                    <span class="custom-control-label">View Own</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox2"
                      value="option2"
                    />
                    <span class="custom-control-label">Create</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox3"
                      value="option3"
                    />
                    <span class="custom-control-label">Edit</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-xl-4 mt-4 mt-xl-0">
              <div class="form-group m-0">
                <div class="form-label mb-2">Countries</div>
                <div class="custom-controls-stacked">
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox1"
                      value="option1"
                      checked=""
                    />
                    <span class="custom-control-label">Create</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox2"
                      value="option2"
                    />
                    <span class="custom-control-label">Edit</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox3"
                      value="option3"
                      disabled=""
                    />
                    <span class="custom-control-label">View</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox4"
                      value="option4"
                      checked=""
                      disabled=""
                    />
                    <span class="custom-control-label">Delete</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-xl-4 mt-4 mt-xl-0">
              <div class="form-group m-0">
                <div class="form-label mb-2">Roles</div>
                <div class="custom-controls-stacked">
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox1"
                      value="option1"
                      checked=""
                    />
                    <span class="custom-control-label">Create</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox2"
                      value="option2"
                    />
                    <span class="custom-control-label">Edit</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox3"
                      value="option3"
                      disabled=""
                    />
                    <span class="custom-control-label">View</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox4"
                      value="option4"
                      checked=""
                      disabled=""
                    />
                    <span class="custom-control-label">Delete</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row py-4 border-bottom">
            <div class="col-xl-4">
              <div class="form-group m-0">
                <div class="form-label mb-2">Leads</div>
                <div class="custom-controls-stacked">
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox1"
                      value="option1"
                      checked=""
                    />
                    <span class="custom-control-label">Create</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox2"
                      value="option2"
                    />
                    <span class="custom-control-label">Edit</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox3"
                      value="option3"
                      disabled=""
                    />
                    <span class="custom-control-label">View</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox4"
                      value="option4"
                      checked=""
                      disabled=""
                    />
                    <span class="custom-control-label">Delete</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-xl-4 mt-4 mt-xl-0">
              <div class="form-group m-0">
                <div class="form-label mb-2">Countries</div>
                <div class="custom-controls-stacked">
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox1"
                      value="option1"
                      checked=""
                    />
                    <span class="custom-control-label">Create</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox2"
                      value="option2"
                    />
                    <span class="custom-control-label">Edit</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox3"
                      value="option3"
                      disabled=""
                    />
                    <span class="custom-control-label">View</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox4"
                      value="option4"
                      checked=""
                      disabled=""
                    />
                    <span class="custom-control-label">Delete</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-xl-4 mt-4 mt-xl-0">
              <div class="form-group m-0">
                <div class="form-label mb-2">Roles</div>
                <div class="custom-controls-stacked">
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox1"
                      value="option1"
                      checked=""
                    />
                    <span class="custom-control-label">Create</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox2"
                      value="option2"
                    />
                    <span class="custom-control-label">Edit</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox3"
                      value="option3"
                      disabled=""
                    />
                    <span class="custom-control-label">View</span>
                  </label>
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="example-checkbox4"
                      value="option4"
                      checked=""
                      disabled=""
                    />
                    <span class="custom-control-label">Delete</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../../mixins/showError";
import checkToken from "../../../mixins/checkToken";
export default {
  mixins: [showErrorMixin, checkToken],
  props: ["id"],
  data() {
    return {
      role: {},
    };
  },
  created() {
    this.getRole();
  },
  methods: {
    async getRole() {
      try {
        const response = await this.$store.dispatch("roles/getRole", {
          id: this.id,
        });
        if (response.status === 200) {
          this.role = response.data;
        }
      } catch (err) {
        console.log("Error");
      }
    },
  },
};
</script>

<style scoped>
.bottom-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
</style>