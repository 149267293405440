<template>
  <div class="page page-style1">
    <div class="page-single">
      <div class="p-5">
        <div class="row">
          <div class="col mx-auto">
            <div class="row justify-content-center">
              <div class="col-lg-9 col-xl-8">
                <div class="card-group mb-0">
                  <div class="card p-4 page-content">
                    <div class="card-body page-single-content">
                      <div class="w-100">
                        <div class="">
                          <h1 class="mb-2">{{ $t("passwordReset") }}</h1>
                          <div class="row" v-if="notFound">
                            <div class="col-12">
                              <div class="alert alert-danger">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="alert"
                                  aria-hidden="true"
                                >
                                  ×
                                </button>
                                <strong>{{ $t("noSuchAccount") }}</strong>
                                <hr class="message-inner-separator" />
                                <p>
                                  {{
                                    $t(
                                      "weCouldNotFindAnAccountWithThatEmailAddress"
                                    )
                                  }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="row" v-if="wrongOTP">
                            <div class="col-12">
                              <div class="alert alert-danger">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="alert"
                                  aria-hidden="true"
                                >
                                  ×
                                </button>
                                <strong>{{ $t("noSuchAccount") }}</strong>
                                <hr class="message-inner-separator" />
                                <p>{{ $t("wrongOTP") }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr class="divider my-3" />
                        <form
                          id="login-form"
                          @submit.prevent="changePasswordFormHandler"
                        >
                          <div class="input-group mb-3">
                            <span class="input-group-addon"
                              ><svg
                                class="svg-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path
                                  d="M12 16c-2.69 0-5.77 1.28-6 2h12c-.2-.71-3.3-2-6-2z"
                                  opacity=".3"
                                />
                                <circle cx="12" cy="8" opacity=".3" r="2" />
                                <path
                                  d="M12 14c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm-6 4c.22-.72 3.31-2 6-2 2.7 0 5.8 1.29 6 2H6zm6-6c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
                                /></svg
                            ></span>
                            <input
                              v-model="email"
                              type="email"
                              class="form-control"
                              required
                              autofocus
                              :placeholder="$t('emailAddress')"
                            />
                          </div>
                          <div
                            class="input-group mb-4"
                            :class="{
                              'border-red':
                                findFieldinFeedback('otp') &&
                                (otp === '' ||
                                  otp === null ||
                                  otp.length !== 8),
                            }"
                          >
                            <span class="input-group-addon"
                              ><svg
                                class="svg-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <g fill="none">
                                  <path d="M0 0h24v24H0V0z" />
                                  <path d="M0 0h24v24H0V0z" opacity=".87" />
                                </g>
                                <path
                                  d="M6 20h12V10H6v10zm6-7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
                                  opacity=".3"
                                />
                                <path
                                  d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
                                /></svg
                            ></span>

                            <input
                              v-model="otp"
                              type="password"
                              class="form-control"
                              placeholder="OTP"
                            />
                          </div>
                          <p
                            v-if="
                              findFieldinFeedback('otp') &&
                              (otp === '' || otp === null || otp.length !== 8)
                            "
                            class="text-danger"
                          >
                            {{ $t("otpShouldBe8CharactersLong") }}
                          </p>
                          <div
                            class="input-group mb-4"
                            :class="{
                              'border-red':
                                findFieldinFeedback('password') &&
                                (password === '' ||
                                  password === null ||
                                  password.length !== 8),
                            }"
                          >
                            <span class="input-group-addon"
                              ><svg
                                class="svg-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <g fill="none">
                                  <path d="M0 0h24v24H0V0z" />
                                  <path d="M0 0h24v24H0V0z" opacity=".87" />
                                </g>
                                <path
                                  d="M6 20h12V10H6v10zm6-7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
                                  opacity=".3"
                                />
                                <path
                                  d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
                                /></svg
                            ></span>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              :placeholder="$t('password')"
                            />
                          </div>
                          <p
                            v-if="
                              findFieldinFeedback('password') &&
                              (password === '' ||
                                password === null ||
                                password.length !== 8)
                            "
                            class="text-danger"
                          >
                            {{ $t("passwordShouldBeAtLeast8CharactersLong") }}
                          </p>

                          <div
                            class="input-group mb-4"
                            :class="{
                              'border-red':
                                findFieldinFeedback('password_confirmation') &&
                                password !== password_confirmation,
                            }"
                          >
                            <span class="input-group-addon"
                              ><svg
                                class="svg-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <g fill="none">
                                  <path d="M0 0h24v24H0V0z" />
                                  <path d="M0 0h24v24H0V0z" opacity=".87" />
                                </g>
                                <path
                                  d="M6 20h12V10H6v10zm6-7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
                                  opacity=".3"
                                />
                                <path
                                  d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
                                /></svg
                            ></span>
                            <input
                              type="password"
                              v-model="password_confirmation"
                              class="form-control"
                              :placeholder="$t('retypePassword')"
                            />
                          </div>
                          <p
                            v-if="
                              findFieldinFeedback('password_confirmation') &&
                              password !== password_confirmation
                            "
                            class="text-danger"
                          >
                            {{ $t("passwordsDoNotMatch") }}
                          </p>

                          <div class="row">
                            <div class="col-12"></div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <button
                                type="submit"
                                class="btn btn-lg btn-primary btn-block"
                              >
                                <i class="fe fe-arrow-right"></i>
                                {{ $t("login") }}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      card
                      text-white
                      bg-primary
                      py-5
                      d-md-down-none
                      page-content
                      mt-0
                    "
                  >
                    <div
                      class="
                        card-body
                        text-center
                        justify-content-center
                        page-single-content
                      "
                    >
                      <img src="assets/images/pattern/login.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import setFeedbackMixin from "../../mixins/setFeedback";
export default {
  mixins: [showErrorMixin, setFeedbackMixin],
  data() {
    return {
      feedback: [],
      email: "",
      otp: "",
      password: "",
      password_confirmation: "",
      notFound: false,
      wrongOTP: false,
    };
  },
  computed: {
    setEmail() {
      return this.$store.getters.email;
    },
  },
  created() {
    if (this.setEmail !== null) {
      this.email = this.setEmail;
    }
  },
  methods: {
    async changePasswordFormHandler() {
      this.notFound = false;
      this.wrongOTP = false;
      if (this.email === null || this.email === "") {
        this.setFeedback("error", "email");
        return;
      }
      if (this.otp === null || this.otp === "" || this.otp.length !== 4) {
        this.setFeedback("error", "otp");
        return;
      }
      if (
        this.password === null ||
        this.password_confirmation === null ||
        this.password.length < 8 ||
        this.password_confirmation.length < 0
      ) {
        this.setFeedback("error", "password");
        return;
      }

      if (this.password !== this.password_confirmation) {
        this.setFeedback("error", "password_confirmation");
        return;
      }

      try {
        const response = await this.$store.dispatch("changePasswordOTP", {
          email: this.email,
          otp: this.otp,
          password: this.password,
          password_confirmation: this.password_confirmation,
        });
        if (response.status === 200 && response.data.token !== null) {
          this.$router.replace("/auth");
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else if (!!err.response && err.response.status === 404) {
          this.notFound = true;
        } else if (!!err.response && err.response.status === 401) {
          this.wrongOTP = true;
        } else {
          this.showError(
            this.$t("somethingWentWrong")
          );
          console.log("Error");
        }
      }
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}
</style>
