<template>
  <manage></manage>
</template>

<script>
import Manage from "./Manage.vue";
export default {
  components: {
    Manage,
  }
}
</script>