const axios = require('axios').default;
export default {
    submit(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        return axios({
            method: "POST",
            url: "/api/v1/pages",
            data: payload,
        });
    },

    updateStatus(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;
        return axios({
            method: "PUT",
            url: `/api/v1/pages/status/${payload.id}`,
            data: payload,
        });
    },

    getPage(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;
        return axios({
            method: "GET",
            url: `/api/v1/pages/${payload.id}`,
        });
    },

    update(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        return axios({
            method: "PUT",
            url: `/api/v1/pages/${payload.id}`,
            data: payload,
        });
    },

    deletePage(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        return axios({
            method: "DELETE",
            url: `/api/v1/pages/${payload}`,
            data: payload,
        });
    },
};
