<template>
  <div class="page-header" v-if="editFormIsSetUp">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("clients") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/clients">
            <a>{{ $t("clients") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ editOrCreate }}</a>
        </li>
      </ol>
    </div>
  </div>

  <div class="row" v-if="editFormIsSetUp">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row w-100">
            <div class="col-6">
              <h4 class="card-title">{{ typeOf }} {{ $t("client") }}</h4>
            </div>
            <div class="col-6 text-right">
              <button type="submit" form="form-product" class="pull-right btn btn-sm btn-primary" :class="{
                'btn-loading': is_sending,
              }" :disabled="is_sending">
                {{ submitBtnText }}
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="panel panel-primary">
            <form @submit.prevent="submitHandler" id="form-product">
              <div class="tab-menu-heading p-0 bg-light">
                <div class="tabs-menu1">
                  <ul class="nav panel-tabs">
                    <li class="">
                      <a id="test-id" class="active" href="#general-tab" :class="{
                        'text-danger':
                          (findFieldinFeedback('first_name') &&
                            form.first_name.trim() === '') ||
                          (findFieldinFeedback('last_name') &&
                            form.last_name.trim() === '') ||
                          (findFieldinFeedback('mobile') &&
                            (form.mobile === '' ||
                              form.mobile.trim().split('-').join('')
                                .length !== 10)) ||
                          (findFieldinFeedback('email') &&
                            (form.email === '' ||
                              !form.email.includes('@') ||
                              !form.email.includes('.'))),
                      }" data-toggle="tab">{{ $t("generalInformation") }}</a>
                    </li>
                    <li>
                      <a href="#shipping-address-tab" data-toggle="tab" class="" :class="{
                        'text-danger':
                          findFieldinFeedback('addresses') &&
                          form.addresses.length === 0,
                      }">{{ $t("shippingAddress") }}</a>
                    </li>
                    <li>
                      <a href="#billing-address-tab" data-toggle="tab" class="" :class="{
                        'text-danger':
                          findFieldinFeedback('billing_addresses') &&
                          form.addresses.length === 0,
                      }">{{ $t("billingAddress") }}</a>
                    </li>
                    <li>
                      <a href="#documents-tab" data-toggle="tab" class="">{{
                          $t("documents")
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="panel-body tabs-menu-body">
                <div class="tab-content">
                  <div class="tab-pane active" id="general-tab">
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("firstName") }} <span>*</span></label>
                          <div class="input-group">
                            <input v-model.trim="form.first_name" type="text" class="form-control" :class="{
                              'border-red':
                                findFieldinFeedback('first_name') &&
                                form.first_name === '',
                            }" :placeholder="$t('firstName')" />
                          </div>
                          <p v-if="
                            findFieldinFeedback('first_name') &&
                            form.first_name === ''
                          " class="text-danger">
                            {{ $t("firstNameCannotBeBlank") }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("lastName") }} <span>*</span></label>
                          <div class="input-group">
                            <input v-model.trim="form.last_name" type="text" class="form-control" :class="{
                              'border-red':
                                findFieldinFeedback('last_name') &&
                                form.last_name === '',
                            }" :placeholder="$t('lastName')" />
                          </div>
                          <p v-if="
                            findFieldinFeedback('last_name') &&
                            form.last_name === ''
                          " class="text-danger">
                            {{ $t("lastNameCannotBeBlank") }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("companyName") }} <span>*</span></label>
                          <div class="input-group">
                            <input v-model.trim="form.company_name" type="text" class="form-control" :class="{
                              'border-red':
                                findFieldinFeedback('company_name') &&
                                form.company_name === '',
                            }" :placeholder="$t('companyName')" />
                          </div>
                          <p v-if="
                            findFieldinFeedback('company_name') &&
                            form.company_name === ''
                          " class="text-danger">
                            {{ $t("lastNameCannotBeBlank") }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("mobile") }} <span>*</span></label>
                          <div class="input-group">
                            <input v-model.trim="form.mobile" type="text" class="form-control" :class="{
                              'border-red':
                                findFieldinFeedback('mobile') &&
                                (form.mobile === '' ||
                                  (form.mobile.length !== 9 &&
                                    form.mobile.length !== 10)),
                            }" :placeholder="$t('mobile')" />
                            <!-- v-maska="'###-###-####'" -->
                          </div>
                          <p v-if="
                            findFieldinFeedback('mobile') &&
                            (form.mobile === '' ||
                              (form.mobile.length !== 9 &&
                                form.mobile.length !== 10))
                          " class="text-danger">
                            {{ $t("pleaseEnterAMobileNumberWith9or10Characters") }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("email") }} <span>*</span></label>
                          <div class="input-group">
                            <input v-model.trim="form.email" @blur="checkEmail"
                              @focus="removeFromServerFeedback('email')" type="text" class="form-control" :class="{
                                'border-red':
                                  emailHasError || emailHasServerError,
                              }" :placeholder="$t('email')" />
                          </div>
                          <p v-if="emailHasError" class="text-danger">
                            {{ $t("emailMustContainAnAndAtLeastOneDot") }}
                            <span v-if="form.email.trim() === ''">
                              {{ $t("andMustNotBeBlank") }}</span>
                          </p>
                          <p v-if="emailHasServerError" class="text-danger">
                            {{ getServerFeedbackMessage("email") }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">
                            {{ $t("photo") }}
                            <span><a v-if="isPhotoLink" :href="photoLink" target="_blank"><small> ({{ $t("viewCurrent")
                            }}) </small></a></span>
                          </label>

                          <div class="input-group">
                            <div class="custom-file">
                              <input v-on:change="onImageChange" type="file" accept="image/*" class="custom-file-input"
                                name="example-file-input-custom" />
                              <label class="custom-file-label">{{
                                  $t("chooseFile")
                              }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="imageUploaded">
                      <div class="col-lg-6">
                        <div class="
                            d-sm-flex
                            align-items-center
                            border
                            p-3
                            mb-3
                            br-7
                          ">
                          <img class="avatar avatar-lg" :src="getImageLink(form.image)" alt="Img" />
                          <div class="float-sm-right ml-auto mt-4 mt-sm-0">
                            <button class="btn btn-sm btn-danger" type="button" @click="deleteFile">
                              <i class="fe fe-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="shipping-address-tab">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-label">{{
                              $t("shippingAddress")
                          }}</label>
                          <div class="input-group">
                            <input id="address-autocomplete" ref="address_autocomplete" v-model.trim="address_input"
                              type="text" class="form-control" :placeholder="$t('shippingAddress')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("street") }}</label>
                          <div class="input-group">
                            <input id="street_number" v-model.trim="address.street_number" type="text"
                              class="form-control" :placeholder="$t('street')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("zipCode") }}</label>
                          <div class="input-group">
                            <input id="postal_code" v-model.trim="address.postal_code" type="text" class="form-control"
                              :placeholder="$t('zipCode')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("locality") }}</label>
                          <div class="input-group">
                            <input id="sublocality_level_1" v-model.trim="address.sublocality_level_1" type="text"
                              class="form-control" :placeholder="$t('locality')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("city") }}</label>
                          <div class="input-group">
                            <input id="locality" v-model.trim="address.locality" type="text" class="form-control"
                              :placeholder="$t('city')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("state") }}</label>
                          <div class="input-group">
                            <input id="administrative_area_level_1" v-model.trim="address.administrative_area_level_1"
                              type="text" class="form-control" :placeholder="$t('state')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("country") }}<span>*</span></label>
                          <div class="input-group">
                            <input :class="{
                              'border-red':
                                (findFieldinFeedback('country') &&
                                  address.country === '') ||
                                (findFieldinFeedback('addresses') &&
                                  form.addresses.length === 0 &&
                                  address.country === ''),
                            }" id="country" v-model.trim="address.country" type="text" class="form-control"
                              :placeholder="$t('country')" />
                          </div>
                          <p v-if="
                            findFieldinFeedback('country') &&
                            address.country === ''
                          " class="text-danger">
                            {{ $t("countryCannotBeBlank") }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label"></label>
                          <div class="input-group">
                            <label class="custom-switch">
                              <input v-model="address.is_default" type="checkbox" name="custom-switch-checkbox"
                                class="custom-switch-input" />
                              <span class="custom-switch-indicator"></span>
                              <span class="custom-switch-description">{{
                                  $t("defaultAddress")
                              }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button class="pull-right btn btn-sm btn-success" type="button"
                          :disabled="address.country === ''" @click="addAddress">
                          <i class="fe fe-plus"></i>
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div class="row" v-if="form.addresses.length > 0">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="
                              table
                              card-table
                              table-vcenter
                              text-nowrap
                              mb-0
                            ">
                            <thead>
                              <tr>
                                <th>{{ $t("street") }}</th>
                                <th>{{ $t("zipCode") }}</th>
                                <th>{{ $t("locality") }}</th>
                                <th>{{ $t("city") }}</th>
                                <th>{{ $t("state") }}</th>
                                <th>{{ $t("country") }}</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(address, index) in form.addresses" :key="address">
                                <td>
                                  {{
                                      address.street_number
                                        ? address.street_number
                                        : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                      address.postal_code
                                        ? address.postal_code
                                        : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                      address.sublocality_level_1
                                        ? address.sublocality_level_1
                                        : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                      address.locality ? address.locality : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                      address.administrative_area_level_1
                                        ? address.administrative_area_level_1
                                        : "-"
                                  }}
                                </td>
                                <td>
                                  {{ address.country ? address.country : "-" }}
                                </td>
                                <td>
                                  <span class="badge badge-success" v-if="address.is_default">
                                    {{ $t("defaultAddress") }}
                                  </span>
                                </td>
                                <td>
                                  <button class="btn btn-sm btn-light mr-2" type="button" @click="removeAddress(index)">
                                    <i class="fe fe-minus"></i>
                                  </button>
                                  <button class="btn btn-light" type="button" @click="editAddress(address, index)">
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="billing-address-tab">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-label">{{
                              $t("billingAddress")
                          }}</label>
                          <div class="input-group">
                            <input id="billing-address-autocomplete" v-model.trim="billing_address_input" type="text"
                              class="form-control" :placeholder="$t('billingAddress')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("street") }}</label>
                          <div class="input-group">
                            <input id="billing_street_number" v-model.trim="billing_address.street_number" type="text"
                              class="form-control" :placeholder="$t('street')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("zipCode") }}</label>
                          <div class="input-group">
                            <input id="billing_postal_code" v-model.trim="billing_address.postal_code" type="text"
                              class="form-control" :placeholder="$t('zipCode')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("locality") }}</label>
                          <div class="input-group">
                            <input id="billing_sublocality_level_1" v-model.trim="billing_address.sublocality_level_1"
                              type="text" class="form-control" :placeholder="$t('locality')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("city") }}</label>
                          <div class="input-group">
                            <input id="billing_locality" v-model.trim="billing_address.locality" type="text"
                              class="form-control" :placeholder="$t('city')" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">State</label>
                          <div class="input-group">
                            <input id="billing_administrative_area_level_1" v-model.trim="
                              billing_address.administrative_area_level_1
                            " type="text" class="form-control" placeholder="State" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("country") }}<span>*</span></label>
                          <div class="input-group">
                            <input id="billing_country" :class="{
                              'border-red':
                                (findFieldinFeedback('country') &&
                                  billing_address.country === '') ||
                                (findFieldinFeedback('billing_addresses') &&
                                  form.billing_addresses.length === 0 &&
                                  billing_address.country === ''),
                            }" v-model.trim="billing_address.country" type="text" class="form-control"
                              :placeholder="$t('country')" />
                          </div>
                          <p v-if="
                            findFieldinFeedback('country') &&
                            billing_address.country === ''
                          " class="text-danger">
                            {{ $t("countryCannotBeBlank") }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label"></label>
                          <div class="input-group">
                            <label class="custom-switch">
                              <input v-model="billing_address.is_default" type="checkbox" name="custom-switch-checkbox"
                                class="custom-switch-input" />
                              <span class="custom-switch-indicator"></span>
                              <span class="custom-switch-description">
                                {{ $t("defaultAddress") }}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button class="pull-right btn btn-sm btn-success" type="button"
                          :disabled="billing_address.country === ''" @click="addBillingAddress">
                          <i class="fe fe-plus"></i>
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div class="row" v-if="form.billing_addresses.length > 0">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="
                              table
                              card-table
                              table-vcenter
                              text-nowrap
                              mb-0
                            ">
                            <thead>
                              <tr>
                                <th>{{ $t("street") }}</th>
                                <th>{{ $t("zipCode") }}</th>
                                <th>{{ $t("locality") }}</th>
                                <th>{{ $t("city") }}</th>
                                <th>{{ $t("state") }}</th>
                                <th>{{ $t("country") }}</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(
                                  address, index
                                ) in form.billing_addresses" :key="address">
                                <td>
                                  {{
                                      address.street_number
                                        ? address.street_number
                                        : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                      address.postal_code
                                        ? address.postal_code
                                        : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                      address.sublocality_level_1
                                        ? address.sublocality_level_1
                                        : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                      address.locality ? address.locality : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                      address.administrative_area_level_1
                                        ? address.administrative_area_level_1
                                        : "-"
                                  }}
                                </td>
                                <td>
                                  {{ address.country ? address.country : "-" }}
                                </td>
                                <td>
                                  <span class="badge badge-success" v-if="address.is_default">
                                    {{ $t("defaultAddress") }}
                                  </span>
                                </td>
                                <td>
                                  <button class="btn btn-sm btn-light mr-2" type="button"
                                    @click="removeBillingAddress(index)">
                                    <i class="fe fe-minus"></i>
                                  </button>
                                  <button class="btn btn-light" type="button"
                                    @click="editBillingAddress(address, index)">
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="documents-tab">
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("name") }}</label>
                          <div class="input-group">
                            <input v-model.trim="document.name" type="text" class="form-control" :class="{
                              'border-red':
                                findFieldinFeedback('document_name') &&
                                document.name === '',
                            }" :placeholder="$t('documentName')" />
                          </div>
                          <p v-if="
                            findFieldinFeedback('document_name') &&
                            document.name === ''
                          " class="text-danger">
                            {{ $t("documentNameCannotBeBlank") }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">{{ $t("document") }}</label>
                          <div class="row">
                            <div class="input-group col">
                              <div class="custom-file" :class="{
                                'border-red':
                                  findFieldinFeedback('file') &&
                                  document.file === null,
                              }">
                                <input v-on:change="onFileChange" type="file" class="custom-file-input"
                                  name="example-file-input-custom" />
                                <label class="custom-file-label">{{
                                    $t("chooseFile")
                                }}</label>
                              </div>
                            </div>
                            <span class="col-auto">
                              <button class="btn btn-sm btn-success" type="button" @click="addDocument">
                                <i class="fe fe-plus"></i>
                              </button>
                            </span>
                          </div>
                          <p v-if="
                            findFieldinFeedback('file') &&
                            document.file === null
                          " class="text-danger">
                            {{ $t("pleaseSelectAFile") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12" v-for="document in current_documents" :key="document.id">
                        <div class="
                            d-sm-flex
                            align-items-center
                            border
                            p-3
                            mb-3
                            br-7
                          ">
                          <a :href="docLink(document)" class="text-primary font-weight-bold fs-12" target="_blank">
                            <span>
                              {{ document.original_name }}
                            </span>
                          </a>
                          <div class="float-sm-right ml-auto mt-4 mt-sm-0">
                            <a :href="docLink(document)" class="btn btn btn-success mr-1" download>
                              <i class="fa fa-download" data-toggle="tooltip" title=""
                                data-original-title="fa fa-download"></i>
                            </a>
                            <button class="btn btn-sm btn-danger" type="button"
                              @click="deleteCurrentDocument(document)">
                              <i class="fe fe-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12" v-for="file in form.documents" :key="file.name">
                        <div class="
                            d-sm-flex
                            align-items-center
                            border
                            p-3
                            mb-3
                            br-7
                          ">
                          <span>
                            {{ file.name }}
                          </span>
                          <div class="float-sm-right ml-auto mt-4 mt-sm-0">
                            <button class="btn btn-sm btn-danger" type="button" @click="deleteDocument(file)">
                              <i class="fe fe-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ckEditorConfig from "../../mixins/cKEditorConfig";
import clearFormMixin from "../../mixins/clearForm";
import showErrorMixin from "../../mixins/showError";
import setFeedbackMixin from "../../mixins/setFeedback";
import notifyMixin from "../../mixins/notify";
import checkToken from "../../mixins/checkToken";
import catchForbiddenError from "../../mixins/catchForbiddenError";
export default {
  mixins: [
    clearFormMixin,
    showErrorMixin,
    setFeedbackMixin,
    notifyMixin,
    ckEditorConfig,
    checkToken,
    catchForbiddenError,
  ],
  props: {
    id: {
      type: Number,
      required: false,
    },
    from: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      is_sending: false,
      validation_errors: {},
      feedback: [],
      serverCheckFeedback: [],
      document: {
        name: "",
        file: null,
      },
      address: {
        street_number: "",
        sublocality_level_1: "",
        locality: "",
        administrative_area_level_1: "",
        country: "",
        postal_code: "",
        is_billing: false,
        is_default: false,
      },
      billing_address: {
        street_number: "",
        sublocality_level_1: "",
        locality: "",
        administrative_area_level_1: "",
        country: "",
        postal_code: "",
        is_billing: true,
        is_default: false,
      },
      address_input: "",
      billing_address_input: "",
      form: {
        first_name: "",
        last_name: "",
        company_name: "",
        mobile: "",
        email: "",
        documents: [],
        image: {},
        addresses: [],
        billing_addresses: [],
      },
      current_documents: [],
      mode: "",
    };
  },
  created() {
    if (typeof this.id !== "undefined") {
      this.getClient();
    }
    if (this.from === "order-preview") {
      this.form.mobile = this.mobileToCreate;
    }
  },
  mounted() {
    let addressScript = document.getElementById("address-script");
    if (addressScript !== null) {
      addressScript.remove();
    }
    if (addressScript === null) {
      this.loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCuX-hAjtq9BiF4vZywT-dvUQaHWbEVd6Q&libraries=places"
      )
        .then(() => {
          // this.initAutocomplete();
        })
        .catch((error) => {
          console.error("Script loading failed!");
          console.error(error);
        });
    } else {
      // this.initAutocomplete();
    }
  },
  computed: {
    editFormIsSetUp() {
      if (typeof this.id !== "undefined" && this.form.first_name === "") {
        return false;
      }
      return true;
    },
    isPhotoLink() {
      return (
        this.form.image &&
        this.mode === "edit" &&
        this.id &&
        !(this.form.image instanceof File)
      );
    },
    photoLink() {
      if (this.form.image) {
        return `${this.$baseURL}storage/${this.form.image.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
    imageUploaded() {
      return this.form.image instanceof File;
    },
    currencies() {
      return this.$store.getters["currencies/currencies"];
    },
    mobileToCreate() {
      return this.$store.getters["catalog/mobileToCreate"];
    },
    typeOf() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("create");
    },
    submitBtnText() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("submit");
    },
    editOrCreate() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("create");
    },
    token() {
      return this.$store.getters.token;
    },
    emailHasError() {
      let emailHasError = false;
      if (
        this.findFieldinFeedback("email") &&
        (this.form.email === "" ||
          !this.form.email.includes("@") ||
          !this.form.email.includes("."))
      ) {
        emailHasError = true;
      }

      return emailHasError;
    },
    emailHasServerError() {
      let hasError = false;
      if (this.findFieldinServerFeedback("email")) {
        hasError = true;
      }
      return hasError;
    },
  },
  methods: {
    loadScript(scriptUrl) {
      const script = document.createElement("script");
      script.src = scriptUrl;
      script.id = "address-script";
      document.body.appendChild(script);

      return new Promise((res, rej) => {
        script.onload = function () {
          res();
        };
        script.onerror = function () {
          rej();
        };
      });
    },
    async getClient() {
      try {
        const response = await this.$store.dispatch("clients/getClient", {
          id: this.id,
        });
        if (response.status === 200) {
          this.setUpForm(response.data);
        }
      } catch (err) {
        console.log("error");
        this.catchForbiddenError(err);
      }
    },
    getImageLink(file) {
      if (file && file instanceof File) {
        const src = URL.createObjectURL(file);
        return src;
      }
      return "";
    },
    deleteCurrentDocument(document) {
      const fileIndex = this.current_documents.findIndex((i) => {
        return i.id === document.id;
      });
      this.current_documents.splice(fileIndex, 1);
    },
    deleteDocument(file) {
      const fileIndex = this.form.documents.findIndex((i) => {
        return i.name === file.name;
      });
      this.form.documents.splice(fileIndex, 1);
    },
    async loadCurrencies() {
      try {
        const response = await this.$store.dispatch("currencies/getCurrencies");
        if (response.status === 200) {
          this.$store.dispatch(
            "currencies/setCurrencies",
            response.data.currencies
          );
        }
      } catch (err) {
        console.log("error");
      }
    },
    async checkEmail() {
      this.removeFromServerFeedback("email");
      this.removeFromFeedback("email");
      if (
        this.form.email.trim() === "" ||
        !this.form.email.includes("@") ||
        !this.form.email.includes(".")
      ) {
        this.setFeedback("error", "email");
      }
      if (this.feedback.length !== 0) {
        return;
      }

      try {
        this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
        let url = "/api/v1/clients/check-email";
        let submit_method = "POST";
        let submit_data = {
          email: this.form.email,
        };

        const response = await this.$axios({
          method: submit_method,
          url: url,
          data: submit_data,
        });

        if (response.status === 200) {
          // let data = response.data;
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.email) {
          this.setServerFeedback("error", err.response.data.email[0], "email");
        }
      }
    },
    async submitHandler() {
      this.feedback = [];

      if (!this.validate()) {
        this.resetActiveClass();
        return;
      }

      if (this.form.addresses.length === 0) {
        this.addAddress();
      }

      if (this.form.billing_addresses.length === 0) {
        this.addBillingAddress();
      }

      this.is_sending = true;

      const data = new FormData();

      data.append("first_name", this.form.first_name);
      data.append("last_name", this.form.last_name);
      data.append("company_name", this.form.company_name);
      data.append("mobile", this.form.mobile.split("-").join(""));
      data.append("email", this.form.email);

      if (this.form.image && this.form.image instanceof File) {
        data.append("uploaded_photo", this.form.image);
      }

      if (this.form.documents.length > 0) {
        for (var i = 0; i < this.form.documents.length; i++) {
          if (
            this.form.documents[i].name !== "" &&
            this.form.documents[i].file instanceof File
          ) {
            const name = this.form.documents[i].name;
            const file = this.form.documents[i].file;
            data.append(`documents[${name}]`, file);
          }
        }
      }

      if (this.form.addresses.length > 0) {
        for (var j = 0; j < this.form.addresses.length; j++) {
          data.append("addresses[]", JSON.stringify(this.form.addresses[j]));
        }
      }

      if (this.form.billing_addresses.length > 0) {
        for (var k = 0; k < this.form.billing_addresses.length; k++) {
          data.append(
            "addresses[]",
            JSON.stringify(this.form.billing_addresses[k])
          );
        }
      }

      if (this.current_documents.length > 0) {
        for (var l = 0; l < this.current_documents.length; l++) {
          data.append(
            "current_documents[]",
            parseInt(this.current_documents[l].id)
          );
        }
      }

      if (this.mode === "edit") {
        data.append("id", this.id);
        data.append("_method", "PUT");
      }

      try {
        if (this.mode === "edit") {
          const response = await this.$store.dispatch(
            "clients/updateClient",
            data
          );
          this.is_sending = false;
          if (response.status === 200) {
            if (this.from === "order-preview") {
              this.$router.replace(
                `/catalog/order-preview/${response.data.id}`
              );
            } else {
              this.$router.replace(`/clients/${response.data.id}`);
            }
          }
        } else {
          const response = await this.$store.dispatch(
            "clients/submitClient",
            data
          );
          this.is_sending = false;
          if (response.status === 201) {
            if (this.from === "order-preview") {
              this.$router.replace(
                `/catalog/order-preview/${response.data.id}`
              );
            } else {
              this.$router.replace(`/clients/${response.data.id}`);
            }
          }
        }
      } catch (err) {
        this.is_sending = false;
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
    validate() {
      if (this.form.first_name === "") {
        this.setFeedback("error", "first_name");
      }
      if (this.form.last_name === "") {
        this.setFeedback("error", "last_name");
      }
      if (this.form.company_name === "") {
        this.setFeedback("error", "company_name");
      }
      // if (
      //   this.form.mobile === "" ||
      //   this.form.mobile.trim().split("-").join("").length !== 10
      // ) {
      //   this.setFeedback("error", "mobile");
      // }
      if (
        this.form.mobile === "" ||
        (this.form.mobile.length !== 9 && this.form.mobile.length !== 10)
      ) {
        this.setFeedback("error", "mobile");
      }
      if (
        this.form.email.trim() === "" ||
        !this.form.email.includes("@") ||
        !this.form.email.includes(".")
      ) {
        this.setFeedback("error", "email");
      }
      if (this.form.addresses.length === 0 && this.address.country === "") {
        this.setFeedback("error", "addresses");
      }
      if (
        this.form.billing_addresses.length === 0 &&
        this.billing_address.country === ""
      ) {
        this.setFeedback("error", "billing_addresses");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },

    resetActiveClass() {
      const form = document.querySelector("form");
      const tabs = form.querySelectorAll("a");
      let first_active_set = false;
      for (let i = 0; i <= tabs.length - 1; i++) {
        let link = tabs[i];
        if (link.classList.contains("active")) {
          link.classList.remove("active");
        }
        if (!first_active_set) {
          if (link.classList.contains("text-danger")) {
            link.click();
            link.classList.add("active");
          }
          first_active_set = true;
        }
      }
    },

    setUpForm(client) {
      this.form.first_name = client.first_name ?? "";
      this.form.last_name = client.last_name ?? "";
      this.form.company_name = client.company_name ?? "";
      this.form.mobile = client.mobile ?? "";
      this.form.email = client.email ?? "";
      this.form.street_number = client.street_address ?? "";
      this.form.postal_code = client.zip_code ?? "";
      this.form.locality = client.city ?? "";
      this.form.sublocality_level_1 = client.locality ?? "";
      this.form.administrative_area_level_1 = client.state ?? "";
      this.form.country = client.country ?? "";
      this.form.image = client.photo;

      if (client.documents.length > 0) {
        this.current_documents = client.documents;
      }

      if (client.shipping_addresses.length > 0) {
        client.shipping_addresses.forEach((address) => {
          let existing = {
            id: address.id ?? "",
            street_number: address.street_address ?? "",
            sublocality_level_1: address.locality ?? "",
            locality: address.city ?? "",
            administrative_area_level_1: address.state ?? "",
            country: address.country ?? "",
            postal_code: address.zip_code ?? "",
            is_billing: address.is_billing === 1 ? true : false,
            is_default: address.is_default === 1 ? true : false,
          };
          this.form.addresses.push(existing);
        });
      }

      if (client.billing_addresses.length > 0) {
        client.billing_addresses.forEach((address) => {
          let existing = {
            id: address.id ?? "",
            street_number: address.street_address ?? "",
            sublocality_level_1: address.locality ?? "",
            locality: address.city ?? "",
            administrative_area_level_1: address.state ?? "",
            country: address.country ?? "",
            postal_code: address.zip_code ?? "",
            is_billing: address.is_billing === 1 ? true : false,
            is_default: address.is_default === 1 ? true : false,
          };
          this.form.billing_addresses.push(existing);
        });
      }

      this.mode = "edit";
    },
    onImageChange(event) {
      const element = event.target.files[0];
      this.form.image = element;
      event.target.value = null;
    },
    deleteFile() {
      this.form.image = {};
    },
    onFileChange(event) {
      const element = event.target.files[0];
      this.document.file = element;
    },

    removeAddress(index) {
      this.form.addresses.splice(index, 1);
    },
    removeBillingAddress(index) {
      this.form.billing_addresses.splice(index, 1);
    },
    addDocument() {
      this.feedback = [];

      if (this.document.name === "") {
        this.setFeedback("error", "document_name");
      }
      if (this.document.file === null) {
        this.setFeedback("error", "file");
      }
      if (this.feedback.length !== 0) {
        return;
      }

      const newDoc = {
        name: this.document.name,
        file: this.document.file,
      };

      this.form.documents.push(newDoc);
      this.document = {};
    },
    initAutocomplete() {
      // const field_id = "address-autocomplete";
      // const field = document.getElementById(field_id);

      // if (!field) {
      //   return;
      // }
      // autocomplete = new google.maps.places.Autocomplete(field, {
      //   types: ["geocode"],
      // });
      // autocomplete.addListener("place_changed", this.fillInAddress);

      // const billing_field_id = "billing-address-autocomplete";
      // const billing_field = document.getElementById(billing_field_id);

      // if (!billing_field) {
      //   return;
      // }

      // autocomplete_billing = new google.maps.places.Autocomplete(
      //   billing_field,
      //   {
      //     types: ["geocode"],
      //   }
      // );
      // autocomplete_billing.addListener(
      //   "place_changed",
      //   this.fillInBillingAddress
      // );
    },

    fillInBillingAddress() {
      // var place = autocomplete_billing.getPlace();

      // this.clearBillingAddress();

      // for (var i = 0; i < place.address_components.length; i++) {
      //   var addressType = place.address_components[i].types[0];
      //   if (componentForm[addressType]) {
      //     var val = place.address_components[i][componentForm[addressType]];
      //     this.billing_address[addressType] = val;
      //   }
      // }
    },

    fillInAddress() {
      // var place = autocomplete.getPlace();

      // this.clearAddress();

      // for (var i = 0; i < place.address_components.length; i++) {
      //   var addressType = place.address_components[i].types[0];
      //   if (componentForm[addressType]) {
      //     var val = place.address_components[i][componentForm[addressType]];
      //     this.address[addressType] = val;
      //   }
      // }
    },
    clearAddress() {
      this.address.id = "";
      this.address.street_number = "";
      this.address.sublocality_level_1 = "";
      this.address.locality = "";
      this.address.administrative_area_level_1 = "";
      this.address.country = "";
      this.address.postal_code = "";
      this.address.type = 1;
      this.address.is_default = false;
    },
    clearBillingAddress() {
      this.billing_address.id = "";
      this.billing_address.street_number = "";
      this.billing_address.sublocality_level_1 = "";
      this.billing_address.locality = "";
      this.billing_address.administrative_area_level_1 = "";
      this.billing_address.country = "";
      this.billing_address.postal_code = "";
      this.billing_address.type = 1;
      this.billing_address.is_default = false;
    },
    addBillingAddress() {
      this.feedback = [];
      if (this.billing_address.country === "") {
        this.setFeedback("error", "country");
      }
      if (this.feedback.length !== 0) {
        return;
      }
      if (this.form.billing_addresses.length === 0) {
        // The first address added is default
        this.billing_address.is_default = true;
      }

      if (this.billing_address.is_default) {
        for (
          let address = 0;
          address < this.form.billing_addresses.length;
          address++
        ) {
          const element = this.form.billing_addresses[address];
          element.is_default = false;
        }
      }
      let address = {
        id: this.billing_address.id ?? "",
        street_number: this.billing_address.street_number,
        sublocality_level_1: this.billing_address.sublocality_level_1,
        locality: this.billing_address.locality,
        administrative_area_level_1:
          this.billing_address.administrative_area_level_1,
        country: this.billing_address.country,
        postal_code: this.billing_address.postal_code,
        is_billing: true,
        is_default: this.billing_address.is_default ?? false,
      };
      this.form.billing_addresses.push(address);
      this.clearBillingAddress();
    },
    addAddress() {
      this.feedback = [];
      if (this.address.country === "") {
        this.setFeedback("error", "country");
      }
      if (this.feedback.length !== 0) {
        return;
      }
      if (this.form.addresses.length === 0) {
        // The first address added is default
        this.address.is_default = true;
      }
      if (this.address.is_default) {
        for (let address = 0; address < this.form.addresses.length; address++) {
          const element = this.form.addresses[address];
          element.is_default = false;
        }
      }
      let address = {
        id: this.address.id ?? "",
        street_number: this.address.street_number,
        sublocality_level_1: this.address.sublocality_level_1,
        locality: this.address.locality,
        administrative_area_level_1: this.address.administrative_area_level_1,
        country: this.address.country,
        postal_code: this.address.postal_code,
        is_billing: false,
        is_default: this.address.is_default ?? false,
      };
      this.form.addresses.push(address);
      this.clearAddress();
    },
    editAddress(address, index) {
      (this.address.id = address.id ?? ""),
        (this.address.street_number = address.street_number);
      this.address.sublocality_level_1 = address.sublocality_level_1;
      this.address.locality = address.locality;
      this.address.administrative_area_level_1 =
        address.administrative_area_level_1;
      this.address.country = address.country;
      this.address.postal_code = address.postal_code;
      this.address.is_default = address.is_default;

      this.removeAddress(index);
    },
    editBillingAddress(address, index) {
      (this.billing_address.id = address.id ?? ""),
        (this.billing_address.street_number = address.street_number);
      this.billing_address.sublocality_level_1 = address.sublocality_level_1;
      this.billing_address.locality = address.locality;
      this.billing_address.administrative_area_level_1 =
        address.administrative_area_level_1;
      this.billing_address.country = address.country;
      this.billing_address.postal_code = address.postal_code;
      this.billing_address.is_default = address.is_default;

      this.removeBillingAddress(index);
    },
    docLink(document) {
      if (document.file_path) {
        return `${this.$baseURL}storage/${document.file_path}`;
      }
      return "#";
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}

.badge,
i {
  cursor: pointer;
}
</style>