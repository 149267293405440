import _ from "lodash";

export default {
    setSearchString(state, payload) {
        state.searchString = payload;
    },
    setCategory(state, payload) {
        state.category = payload;
    },
    addToCart(state, payload) {
        state.cart.push(payload);
    },
    removeFromCart(state, payload) {
        const product_index = state.cart.findIndex(product => {
            const similarAttributesExistInCart = _.isEqual(
                product.selectedAttributes,
                payload.selectedAttributes
            );
            return product.id === payload.id && similarAttributesExistInCart;
        });
        state.cart.splice(product_index, 1);
    },
    clearCart(state) {
        state.cart = [];
    },
    clearPayment(state) {
        state.payment = {};
    },
    clearClient(state) {
        state.client = {};
    },
    changeAmountInCart(state, payload) {
        const product_index = state.cart.findIndex(product => {
            const similarAttributesExistInCart = _.isEqual(
                product.selectedAttributes,
                payload.product.selectedAttributes
            );
            return product.id === payload.product.id && similarAttributesExistInCart;
        });
        state.cart[product_index].amountInCart = payload.newQuantity;
    },

    changeAddress(state, payload) {
        state.shipping_address = payload;
    },

    changeClient(state, payload) {
        state.client = payload;
    },

    changePayment(state, payload) {
        state.payment = payload;
    },

    mobileToCreate(state, payload) {
        state.mobileToCreate = payload;
    }
};
