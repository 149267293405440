<template>
  <div class="col-sm-12 col-md-5">
    <div
      class="dataTables_info"
      id="example1_info"
      role="status"
      aria-live="polite"
    >
      {{ $t("showing") }} {{ pagination.from }} {{ $t("to") }} {{ pagination.to }} {{ $t("of") }}
      {{ pagination.total }} {{ $t("entries") }}
    </div>
  </div>
  <div class="col-sm-12 col-md-7">
    <div
      class="dataTables_paginate paging_simple_numbers"
      id="example1_paginate"
    >
      <ul class="pagination">
        <li
          class="paginate_button page-item previous"
          v-if="pagination.prevPageUrl"
          id="example1_previous"
        >
          <a
            href="#"
            aria-controls="example1"
            data-dt-idx="0"
            tabindex="0"
            class="page-link"
            @click="$emit('prev')"
            >{{ $t("previous") }}</a
          >
        </li>
        <li
          v-else
          class="paginate_button page-item previous disabled"
          id="example1_previous"
        >
          <a
            href="#"
            aria-controls="example1"
            data-dt-idx="0"
            tabindex="0"
            class="page-link"
            >{{ $t("previous") }}</a
          >
        </li>
        
        <li
          v-if="pagination.nextPageUrl"
          class="paginate_button page-item next"
          id="example1_next"
        >
          <a
            href="#"
            aria-controls="example1"
            data-dt-idx="6"
            tabindex="0"
            class="page-link"
            @click="$emit('next')"
            >{{ $t("next") }}</a
          >
        </li>
        <li
          v-else
          class="paginate_button page-item next disabled"
          id="example1_next"
        >
          <a
            href="#"
            aria-controls="example1"
            data-dt-idx="6"
            tabindex="0"
            class="page-link"
            >{{ $t("next") }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pagination"],
};
</script>