<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("addClient") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/products-catalog">
            <a class="d-flex">{{ $t("productsCatalog") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/catalog/cart">
            <a class="d-flex">{{ $t("cart") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("orderPreview") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="card" v-if="Object.keys(selectedClient).length === 0">
        <div class="card-header">
          <h3 class="card-title">{{ $t("searchClient") }}</h3>
        </div>
        <div class="card-body">
          <input
            class="form-control form-control-sm mb-4"
            :class="{
              'border-red':
                findFieldinFeedback('search') && tableData.search === '',
            }"
            :placeholder="$t('searchClientNameOrMobile')"
            v-model="tableData.search"
            @blur="getClients"
            type="text"
          />
          <div
            class="mb-3"
            v-if="findFieldinFeedback('search') && tableData.search === ''"
          >
            <span class="d-block text-danger">{{
              $t("pleaseEnterNameOrMobileNumber")
            }}</span>
          </div>
          <div class="mb-3" v-if="not_found">
            <span class="d-block text-danger">{{
              $t("thereIsNoClientWithTheseDetails")
            }}</span>
          </div>
        </div>
      </div>

      <loader v-if="loading"></loader>
      <div
        class="table-responsive mb-5"
        v-if="selectedClients.length > 0 && !loading"
      >
        <table
          class="table card-table table-vcenter text-nowrap table-primary mb-0"
        >
          <thead class="bg-primary text-white">
            <tr>
              <th class="text-white">{{ $t("name") }}</th>
              <th class="text-white">{{ $t("mobile") }}</th>
              <th class="text-white"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="client in selectedClients" :key="client.id">
              <td>{{ client.first_name }} {{ client.last_name }}</td>
              <td>{{ client.mobile }}</td>
              <td>
                <button
                  class="btn btn-sm btn-success"
                  type="button"
                  @click="selectClient(client)"
                >
                  <i class="fe fe-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mb-3" v-if="not_found">
        <button class="btn btn-primary btn-sm" @click="addClient">
          {{ $t("addClient") }}
        </button>
      </div>
      <div
        class="card overflow-hidden"
        v-if="Object.keys(selectedClient).length > 0"
      >
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("clientDetails") }}</h3>
          <div class="card-options">
            <button class="btn btn-sm btn-info" @click="changeSelectedClient">
              {{ $t("changeClient") }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="dropdown user-pro-body text-center" data-v-62b8754f="">
            <div class="user-pic" data-v-62b8754f="">
              <img
                :src="imgLink"
                alt="user-img"
                class="avatar-xl rounded-circle mb-1"
                data-v-62b8754f=""
              />
            </div>
          </div>
          <h4 class="card-title mb-1">{{ $t("personalDetails") }}</h4>
          <div class="table-responsive">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("name") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    {{ selectedClient.first_name }}
                    {{ selectedClient.last_name }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("email") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    <a
                      :href="getMailLink(selectedClient.email)"
                      target="_blank"
                      >{{ selectedClient.email }}</a
                    >
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("phone") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    <a
                      :href="getPhoneLink(selectedClient.mobile)"
                      target="_blank"
                      >{{ selectedClient.mobile }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        class="card overflow-hidden card-collapsed"
        v-if="
          Object.keys(selectedClient).length > 0 &&
          selectedClient.shipping_addresses &&
          selectedClient.shipping_addresses.length > 0
        "
      >
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("deliveryAddress") }}</h3>
          <div class="card-options">
            <a
              href="#"
              class="card-options-collapse mr-2"
              data-toggle="card-collapse"
              ><i class="fe fe-chevron-up text-white"></i
            ></a>
          </div>
        </div>
        <div class="card-body">
          <div
            class="
              d-sm-flex
              mt-0
              p-3
              sub-review-section
              border border-bottom-0
              br-bl-0 br-br-0
            "
            v-for="address in selectedClient.shipping_addresses"
            :key="address.id"
          >
            <div class="media-body">
              <h5 class="mt-0 mb-1 font-weight-semibold">
                {{ selectedClient.first_name }}
                {{ selectedClient.last_name }}
                <span
                  class="badge badge-success mt-2"
                  v-if="address.id === shippingAddress.id"
                  >{{ $t("selected") }}</span
                >
              </h5>
              <p class="font-13 mb-2 mt-2">
                <span v-if="address.zip_code">{{ address.zip_code }}, </span>
                <span v-if="address.street_address"
                  >{{ address.street_address }},
                </span>
                <span v-if="address.locality">{{ address.locality }}, </span>
                <span v-if="address.city">{{ address.city }}, </span>
                <span v-if="address.country">{{ address.country }}</span>
              </p>
              <button
                class="btn btn-sm btn-primary"
                v-if="address.id !== address_id"
                @click="address_id = address.id"
              >
                {{ $t("deliverHere") }}
              </button>
            </div>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">{{ $t("orderDetails") }}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <strong>{{ $t("total") }}({{ cart.length }})</strong>
            </div>
            <div class="col-sm-6 text-right">
              <span>
                <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                {{ cartTotal.toFixed(2) }}</span
              ><br />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-sm-6">
              <strong>{{ $t("total") }}</strong>
            </div>
            <div class="col-sm-6 text-right">
              <span>
               <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                {{ cartTotal.toFixed(2) }}</span
              ><br />
            </div>
          </div>
        </div>
      </div>
      <div
        class="card overflow-hidden"
        v-if="
          Object.keys(client).length > 0 &&
          Object.keys(shippingAddress).length > 0
        "
      >
        <div class="card-header bg-primary">
          <h3 class="card-title text-white">{{ $t("payment") }}</h3>
        </div>
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("pay") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <select
                      @change="changePayment"
                      v-model="payment.when"
                      class="form-control"
                      :class="{
                        'border-red':
                          findFieldinFeedback('when') && payment.when === '',
                      }"
                    >
                      <option value="later">{{ $t("payLater") }}</option>
                      <option value="now">{{ $t("payNow") }}</option>
                    </select>
                  </div>
                  <p
                    v-if="findFieldinFeedback('when') && payment.when === ''"
                    class="text-danger"
                  >
                    {{ $t("payWhenCannotBeBlank") }}
                  </p>
                </div>
              </div>
              <div class="col-12" v-if="payment.when === 'now'">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("method") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <select
                      @change="changePayment"
                      v-model="payment.method"
                      class="form-control"
                      :class="{
                        'border-red':
                          findFieldinFeedback('method') &&
                          payment.method === '',
                      }"
                    >
                      <option value="wallet">{{ $t("wallet") }}</option>
                      <option value="cash">{{ $t("cash") }}</option>
                    </select>
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('method') && payment.method === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("methodCannotBeBlank") }}
                  </p>
                </div>
              </div>
              <div
                class="col-12"
                v-if="payment.method == 'wallet' && payment.when === 'now'"
              >
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("typeOfWallet") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <select
                      @change="changePayment"
                      v-model="payment.walletType"
                      class="form-control"
                      :class="{
                        'border-red':
                          findFieldinFeedback('typeOfWallet') &&
                          payment.typeOfWallet === '',
                      }"
                    >
                      <option value="smilepay">Smilepay</option>
                      <option value="orangePay">Orange pay</option>
                    </select>
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('typeOfWallet') &&
                      payment.typeOfWallet === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("walletTypeCannotBeBlank") }}
                  </p>
                </div>
              </div>
              <div
                class="col-12"
                v-if="payment.method == 'wallet' && payment.when === 'now'"
              >
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("transactionId") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      @blur="changePayment"
                      :class="{
                        'border-red':
                          findFieldinFeedback('transaction_id') &&
                          payment.method === 'wallet' &&
                          payment.transaction_id === '',
                      }"
                      v-model="payment.transaction_id"
                      type="text"
                      class="form-control"
                      :placeholder="$t('transactionId')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('transaction_id') &&
                      payment.method === 'wallet' &&
                      payment.transaction_id === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("enterATransactionId") }}
                  </p>
                </div>
              </div>
              <div class="col-12" v-if="payment.when === 'now'">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("paidAmount") }}<span>*</span>
                  </label>
                  <div class="input-group">
                    <input
                      @blur="changePayment"
                      :class="{
                        'border-red':
                          findFieldinFeedback('paid') && fullPriceIsNotPaid,
                      }"
                      v-model.number="payment.paid"
                      type="number"
                      min="0"
                      step="any"
                      class="form-control"
                      :placeholder="$t('paidAmount')"
                    />
                  </div>
                  <p
                    v-if="findFieldinFeedback('paid') && fullPriceIsNotPaid"
                    class="text-danger"
                  >
                    {{
                      $t(
                        "amountPaidAmountRemainingMustEqualTheTotalAmountRemaining"
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="col-12" v-if="payment.when === 'now'">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("remainingAmount") }}<span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      @blur="changePayment"
                      :class="{
                        'border-red':
                          findFieldinFeedback('remaining') &&
                          payment.remaining === '',
                      }"
                      v-model="payment.remaining"
                      type="number"
                      step="any"
                      min="0"
                      readonly
                      class="form-control"
                      :placeholder="$t('remainingAmount')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('remaining') &&
                      payment.remaining === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("enterAnAmount") }}
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12">
              <div class="card-options pull-right">
                <button
                  @click="saveOrder"
                  class="d-block btn btn-success btn-sm"
                  v-if="Object.keys(selectedClient).length > 0"
                >
                  {{ $t("createOrder") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import fixStatus from "../../mixins/fixStatus";
import checkToken from "../../mixins/checkToken";
import setFeedbackMixin from "../../mixins/setFeedback";
import contact from "../../mixins/contact";
import catchBadRequestError from "../../mixins/catchBadRequestError";
// import _ from "lodash";

export default {
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  mixins: [
    showErrorMixin,
    notifyMixin,
    checkToken,
    fixStatus,
    setFeedbackMixin,
    contact,
    catchBadRequestError,
  ],
  data() {
    return {
      client_id: "",
      feedback: [],
      tableData: {
        draw: 0,
        search: "",
      },
      selectedClients: [],
      selectedClient: {},
      address_id: "",
      not_found: false,
      payment: {
        when: "later",
        method: "wallet",
        walletType: "smilepay",
        paid: 0,
        remaining: 0,
        transaction_id: "",
      },
      loading: false,
    };
  },
  created() {
    if (this.id) {
      this.getClient();
    }
    this.payment.remaining = parseFloat(this.getCartPrice());
    this.changeAddress({});
    this.changePayment({});
  },
  watch: {
    address_id: function (newValue) {
      if (newValue) {
        const selected = this.selectedClient.shipping_addresses.find(
          (address) => {
            return address.id === parseInt(newValue);
          }
        );

        if (selected) {
          this.changeAddress(selected);
        }
      } else {
        this.changeAddress({});
      }
    },
    selectedClient: function (newValue) {
      this.changeClient(newValue);
    },
    cashPaid: function (newValue) {
      if (newValue === "") {
        this.payment.remaining = parseFloat(this.getCartPrice());
      } else {
        this.payment.remaining = parseFloat(
          (
            parseFloat(this.getCartPrice()) - parseFloat(this.payment.paid)
          ).toFixed(2)
        );
      }
    },
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    cashPaid() {
      return this.payment.paid;
    },
    token() {
      return this.$store.getters.token;
    },
    cart() {
      return this.$store.getters["catalog/cart"];
    },
    shippingAddress() {
      return this.$store.getters["catalog/shippingAddress"];
    },
    client() {
      return this.$store.getters["catalog/client"];
    },
    clients() {
      return this.$store.getters["clients/clients"];
    },
    imgLink() {
      if (this.selectedClient.photo) {
        return `${this.$baseURL}storage/${this.selectedClient.photo.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
    fullPriceIsNotPaid() {
      return (
        parseFloat(this.payment.paid === "" ? 0 : this.payment.paid) +
          parseFloat(this.payment.remaining) !==
        parseFloat(this.getCartPrice())
      );
    },
    cartTotal() {
      return this.$store.getters["catalog/cartTotal"];
    },
  },
  methods: {
    getClients() {
      this.selectedClients = [];
      this.loading = true;
      this.tableData.draw++;
      this.feedback = [];
      if (this.tableData.search === "") {
        this.setFeedback("error", "search");
      }
      if (this.feedback.length !== 0) {
        this.loading = false;
        return;
      }
      this.not_found = false;

      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let url = "/api/v1/clients/search-add-money";
      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          this.loading = false;
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.selectedClients = data.clients;
          }
        })
        .catch((error) => {
          console.log("Error");
          this.loading = false;
          if (!!error.response && error.response.status === 404) {
            this.not_found = true;
          }
        });
    },
    getCartPrice() {
      let cartTotal = 0.0;
      this.cart.forEach((product) => {
        let price =
          parseFloat(product.amountInCart) * parseFloat(product.price);
        cartTotal = cartTotal + price;
      });
      return cartTotal;
    },

    async getClient() {
      try {
        const response = await this.$store.dispatch("clients/getClient", {
          id: this.id,
        });
        if (response.status === 200) {
          this.selectClient(response.data);
        }
      } catch (err) {
        console.log("error");
      }
    },

    async selectClient(client) {
      if (
        !client.shipping_addresses ||
        client.shipping_addresses.length === 0
      ) {
        const swal_response = await this.$swal.fire({
          title: this.$t("warning"),
          text: this.$t("thisClientHasNoShippingAddresses"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: this.$t("cancel"),
          confirmButtonText: this.$t("goToEdit"),
        });

        if (swal_response.isConfirmed) {
          this.$router.replace(`/clients/${client.id}/edit/order-preview`);
        }

        return;
      }
      this.selectedClient = client;
      this.selectedClients = [];
      if (
        this.selectedClient.shipping_addresses &&
        this.selectedClient.shipping_addresses.length > 0
      ) {
        if (this.selectedClient.shipping_addresses.length === 1) {
          this.address_id = this.selectedClient.shipping_addresses[0].id;
          return;
        }
        this.selectedClient.shipping_addresses.forEach((address) => {
          if (address.is_default) {
            this.address_id = address.id;
          }
        });
      }
    },

    changeSelectedClient() {
      this.tableData.search = "";
      this.address_id = "";
      this.tableData.draw = 0;
      this.selectedClient = {};
    },

    changeClient(client) {
      this.$store.dispatch("catalog/changeClient", client);
    },
    changeAddress(address) {
      this.$store.dispatch("catalog/changeAddress", address);
    },
    changePayment() {
      if (this.payment.when === "now") {
        this.payment.paid === 0;
      }
      if (this.payment.method === "cash") {
        this.payment.walletType = "smilepay";
        this.payment.transaction_id = "";
      }
      this.$store.dispatch("catalog/changePayment", this.payment);
    },

    addClient() {
      const mobile = this.tableData.search;
      this.$store.dispatch("catalog/mobileToCreate", mobile);
      this.$router.push("/clients/create/order-preview");
    },

    async saveOrder() {
      this.feedback = [];

      if (this.fullPriceIsNotPaid) {
        this.setFeedback("error", "paid");
      }
      if (
        this.payment.method === "wallet" &&
        this.payment.when === "now" &&
        this.payment.transaction_id === ""
      ) {
        this.setFeedback("error", "transaction_id");
      }
      if (this.feedback.length !== 0) {
        return;
      }

      // https://stackoverflow.com/questions/54907549/keep-only-selected-keys-in-every-object-from-array
      const cart_keys_to_submit = ["id", "amountInCart", "selectedAttributes"];

      const redux = (array) =>
        array.map((o) =>
          cart_keys_to_submit.reduce((acc, curr) => {
            acc[curr] = o[curr];
            return acc;
          }, {})
        );

      const data = {
        total: this.getCartPrice(),
        payment_when: this.payment.when,
        payment_method: this.payment.method,
        type_of_wallet: this.payment.walletType,
        transaction_id: this.payment.transaction_id,
        amount_paid: this.payment.paid === "" ? 0 : this.payment.paid,
        amount_remaining: this.payment.remaining,
        address_id: this.shippingAddress.id,
        client_id: this.client.id,
        cart: redux(this.cart),
      };

      console.log(data);

      try {
        const response = await this.$store.dispatch(
          "catalog/submitOrder",
          data
        );
        if (response.status === 201) {
          this.$store.dispatch("catalog/clearCart");
          this.$store.dispatch("catalog/clearPayment");
          this.$store.dispatch("catalog/clearClient");
          this.$router.push(`/catalog/invoice/${response.data.id}`);
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else if (err.response.status === 400) {
          this.catchBadRequestError(
            err,
            this.$t("theRemainingAmountIsGreaterThanYourAvailableCreditLimit")
          );
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}
</style>