<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">All Orders</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">Dashboard</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>Order By Debts</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select v-model="tableData.length" @change="getPending()" name="example1_length"
                            aria-controls="example1" class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            ">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy"
                        :tableIsSearchable="tableIsSearchable" @search="searchField">
                        <tbody>
                          <tr role="row" class="odd" v-for="order in orders" :key="order.id">
                            <td>
                              {{ order.created_at }}
                            </td>
                            <td>
                              <router-link :to="`/catalog/invoice/${order.id}`">
                                <a class="text-primary font-weight-bold fs-12">
                                  {{ order.order_number }}
                                </a>
                              </router-link>
                            </td>
                            <td>
                              <router-link :to="`/clients/${order.client.id}`">
                                <a class="text-primary font-weight-bold fs-12">
                                  {{
                                      order.client
                                        ? `${order.client.first_name} ${order.client.last_name}`
                                        : ""
                                  }}
                                </a>
                              </router-link>
                            </td>
                            <td class="text-right"> <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span> {{ order.total }}</td>
                            <td class="text-right">
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span> {{ order.amount_paid }}
                            </td>
                            <td class="text-right">
                              <span v-if="loggedUser.company">
                                {{ loggedUser.company.currency.symbol }}
                              </span> {{ order.amount_remaining }}
                            </td>
                            <td>
                              <router-link :to="`/invoice/${locale}/${order.id}`" target="_blank">
                                <a target="_blank" type="button" class="btn btn-info btn-sm mr-1">
                                  <i class="fa fa-print" data-toggle="tooltip" title=""
                                    data-original-title="fa fa-print"></i>
                                </a>
                              </router-link>
                              <router-link :to="`/catalog/order/edit/${order.id}`" v-if="order.status === 'processing'">
                                <a class="btn btn-info btn-sm">
                                  <span>
                                    <i class="fa fa-pencil" style="font-size: 0.5rem" data-toggle="tooltip"
                                      title="fa fa-pencil"></i>
                                  </span>
                                </a>
                              </router-link>
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination :pagination="pagination" @prev="getPending(pagination.prevPageUrl)"
                      @next="getPending(pagination.nextPageUrl)">
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import catchForbiddenError from "../../mixins/catchForbiddenError";
import notify from "../../mixins/notify";
import showError from "../../mixins/showError";
export default {
  mixins: [showError, notify, catchForbiddenError],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: "Date",
        name: "created_at",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: "Order #",
        name: "order_number",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: "Client",
        name: "client",
        sortable: false,
        searchable: true,
        relationship: true,
        relationship_field: "first_name",
      },
      {
        label: "Total",
        name: "total",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: "Paid Amount",
        name: "amount_paid",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: "Debt",
        name: "amount_remaining",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
        searchable: false,
        relationship: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    // sortOrders.created_at = 0;
    return {
      tableIsSearchable: true,
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "created_at",
      tableData: {
        tableColumns: columns.map(({ name }) => name),
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        field: "",
        relationship: false,
        relationship_field: "",
        dir: "desc",
        // pending: 1,
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      orders: [],
      loading: true,
    };
  },
  created() {
    this.getPending();
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
    locale() {
      return this.$store.getters.locale;
    },
  },
  methods: {
    searchField(searchValue, searchField, relationship, relationship_field) {
      this.tableData.search = searchValue;
      this.tableData.field = searchField;
      this.tableData.relationship = relationship;
      this.tableData.relationship_field = relationship_field;
      this.getPending();
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getPending();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    getPending(url = "/api/v1/orders/all") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          if (this.tableData.draw === data.draw) {
            this.orders = data.orders.data;
            this.configPagination(data.orders);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async downloadInvoice(id) {
      this.is_sending = id;
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = {
        id: id,
      };
      let url = "/api/v1/orders/download";

      try {
        const response = await this.$axios({
          method: submit_method,
          url: url,
          data: submit_data,
        });
        if (response.status === 200) {
          window.open(response.data.url, "Download");
          this.is_sending = "";
        }
      } catch (error) {
        this.is_sending = "";
        console.log("Error");
      }
    },
    async approve(order) {
      const swal_response = await this.$swal.fire({
        title: `Are you sure?`,
        text: `Do you want to approve this order?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Approve`,
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch("catalog/approve", {
            id: order.id,
          });
          if (response.status === 200) {
            this.notify(`Order approved`);
            this.getPending();
          }
        } catch (err) {
          this.catchForbiddenError(err);
          console.log("error");
        }
      }
    },
  },
};
</script>