export default {
    methods: {
        fixStatus(status) {
            if (status === 1) {
                return this.$t("active");
            }
            return this.$t("inactive");
        },
    },
};
