const axios = require('axios').default;

export default {
    submitCompany(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/companies";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    getCompanies(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = "/api/v1/companies";
        return axios({ method: submit_method, url: uri });
    },

    getUserCompany(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = "/api/v1/companies/user";
        return axios({ method: submit_method, url: uri });
    },

    setCompanies(context, payload) {
        context.commit("setCompanies", payload);
    },

    updateCompany(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/companies/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    updateUserCompany(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/companies/user/update`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    // updateActivityPeriod(context, payload) {
    //     axios.defaults.headers.common["Authorization"] =
    //         "Bearer " + context.rootGetters.token;

    //     let submit_method = "PUT";
    //     let uri = "/api/v1/companies/user/update-activity-period";
    //     let submit_data = payload;
    //     return axios({ method: submit_method, url: uri, data: submit_data });
    // },

    deleteCompany(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/companies/${payload}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    }
};
