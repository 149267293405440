<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("brands") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon">{{ $t("dashboard") }}</span></a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("brands") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-5 pr-2">
              <h3 class="card-title">
                {{ typeOf }} {{ $t("brand") }}
                <button v-if="mode === 'edit'" @click="changeToNew" class="pull-right btn btn-primary btn-sm">
                  {{ $t("clear") }}
                </button>
              </h3>
              <form class="form-horizontal" @submit.prevent="submitBrandHandler">
                <div class="form-group row">
                  <label class="col-md-4 form-label">{{ $t("name") }} <span>*</span></label>
                  <div class="col-md-8">
                    <input :class="{
                      'border-red':
                        findFieldinFeedback('name') && form.name === '',
                    }" v-model="form.name" type="text" class="form-control" :placeholder="$t('name')" />
                    <p v-if="
                      findFieldinFeedback('name') && form.name.trim() === ''
                    " class="text-danger">
                      {{ $t("nameCannotBeBlank") }}
                    </p>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 form-label">
                    {{ $t("logo") }} <span>*</span>
                    <br />
                    <a v-if="isPhotoLink" :href="photoLink" target="_blank">{{
                        $t("viewCurrent")
                    }}</a>
                  </label>
                  <div class="col-md-8">
                    <div class="custom-file" :class="{
                      'border-red':
                        findFieldinFeedback('photo') && form.photo === '',
                    }">
                      <input v-on:change="onChange" type="file" class="custom-file-input"
                        name="example-file-input-custom" />
                      <label class="custom-file-label">{{
                          $t("choosePhoto")
                      }}</label>
                    </div>
                    <p v-if="findFieldinFeedback('photo') && form.photo === ''" class="text-danger">
                      {{ $t("noPhotoSelected") }}
                    </p>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="col-12">
                    <button type="submit" class="pull-right btn btn-sm btn-primary" :class="{
                      'btn-loading': is_sending,
                    }" :disabled="is_sending">
                      {{ submitBtnText }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm-6 pl-5">
              <div class="table-responsive">
                <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select v-model="tableData.length" @change="getBrands()" name="example1_length"
                            aria-controls="example1" class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            ">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div id="example1_filter" class="dataTables_filter">
                        <label><input type="search" v-model="tableData.search" @input="search()"
                            class="form-control form-control-sm" :placeholder="$t('search')"
                            aria-controls="example1" /></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                        <tbody>
                          <tr role="row" class="odd" v-for="brand in brands" :key="brand.id">
                            <td>
                              {{ brand.name }}
                            </td>
                            <td>
                              <img v-if="brand.logo !== null" :src="getLogoLink(brand)" alt="brand_logo"
                                class="avatar-xl" />
                            </td>
                            <td>
                              <span class="mr-2">
                                <i @click="setUpForm(brand)" class="fa fa-pencil" data-toggle="tooltip"
                                  title="fa fa-pencil"></i>
                              </span>
                              <span>
                                <i @click="deleteBrand(brand.id)" class="text-danger fa fa-trash pointer"
                                  data-toggle="tooltip" title="fa fa-trash"></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination :pagination="pagination" @prev="getBrands(pagination.prevPageUrl)"
                      @next="getBrands(pagination.nextPageUrl)">
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import setFeedbackMixin from "../../mixins/setFeedback";
import checkToken from "../../mixins/checkToken";
import _ from "lodash";

export default {
  mixins: [showErrorMixin, notifyMixin, setFeedbackMixin, checkToken],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("name"),
        name: "name",
        sortable: true,
      },
      {
        label: this.$t("logo"),
        name: "logo",
        sortable: false,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.name = 1;

    return {
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "name",
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      brands: [],

      is_sending: false,
      validation_errors: {},
      feedback: [],
      form: {
        name: "",
        photo: "",
      },

      id: "",
      mode: "",
      loading: true,
    };
  },
  created() {
    this.getBrands();
  },
  computed: {
    isPhotoLink() {
      return (
        this.form.photo &&
        this.mode === "edit" &&
        this.id &&
        !(this.form.photo instanceof File)
      );
    },
    photoLink() {
      if (this.form.photo) {
        return `${this.$baseURL}storage/${this.form.photo}`;
      }
      return "";
    },
    submitBtnText() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("submit");
    },
    typeOf() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("new");
    },
    token() {
      return this.$store.getters.token;
    },
  },
  methods: {
    search: _.debounce(function () {
      this.getBrands();
    }, 500),

    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getBrands();
    },

    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },

    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },

    onChange(event) {
      this.form.photo = event.target.files[0];
      event.target.value = null;
    },

    getBrands(url = "/api/v1/paginated-brands") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          this.loading = false;
          if (this.tableData.draw === data.draw) {
            this.brands = data.brands.data;
            this.configPagination(data.brands);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    async submitBrandHandler() {
      this.feedback = [];

      if (!this.validate()) {
        return;
      }

      this.is_sending = true;

      const data = new FormData();

      data.append("name", this.form.name);
      if (this.form.photo && this.form.photo instanceof File) {
        data.append("uploaded_photo", this.form.photo);
      }

      if (this.mode === "edit") {
        data.append("id", this.id);
        data.append("_method", "PUT");
      }

      try {
        if (this.mode === "edit") {
          const response = await this.$store.dispatch(
            "brands/updateBrand",
            data
          );
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.form.name = "";
            this.form.photo = "";
            this.getBrands();
            this.mode = "";
            this.id = "";
            this.is_sending = false;
          }
        } else {
          const response = await this.$store.dispatch(
            "brands/submitBrand",
            data
          );
          this.is_sending = false;
          if (response.status === 201) {
            this.notify(this.$t("saved"));
            this.form.name = "";
            this.form.photo = "";
            this.getBrands();
            this.is_sending = false;
          }
        }
      } catch (err) {
        this.is_sending = false;
        if (!!err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          const first_error = errors[Object.keys(errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    validate() {
      if (this.form.name.trim() === "") {
        this.setFeedback("error", "name");
      }
      if (this.form.photo === "") {
        this.setFeedback("error", "photo");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },

    getLogoLink(brand) {
      const logo_link = brand.logo ? brand.logo.file_path : "";
      return `${this.$baseURL}storage/${logo_link}`;
    },

    setUpForm(brand) {
      this.form.name = "";
      this.form.photo = "";
      this.form.name = brand.name;
      this.form.photo = brand.logo ? brand.logo.file_path : "";
      this.id = brand.id;
      this.mode = "edit";
    },

    async deleteBrand(id) {
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        icon: "warning",
        text: this.$t("youWontBeAbleToReverseThis"),
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("delete"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch("brands/deleteBrand", id);
          if (response.status === 204) {
            this.notify(this.$t("deleted"));
            this.getBrands();
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    changeToNew() {
      this.mode = "";
      this.form.name = "";
      this.form.photo = "";
    },
  },
};
</script>

<style scoped>
i,
.badge {
  cursor: pointer;
}

.form-label a {
  text-decoration: underline;
}

.border-red {
  border: 1px solid red;
}
</style>