<template>
  <div class="page page-style1">
    <div class="page">
      <div class="page-single">
        <div class="container">
          <div class="row">
            <div class="col mx-auto">
              <div class="row justify-content-center">
                <div class="col-md-5">
                  <div class="card card-group mb-0">
                    <div class="card p-4">
                      <div class="card-body">
                        <div class="text-center title-style mb-6">
                          <h1 class="mb-2">{{ $t("setPassword") }}</h1>
                          <hr />
                          <div
                            class="alert alert-danger"
                            role="alert"
                            v-if="
                              feedback.field === 'none' &&
                              feedback.message != null
                            "
                          >
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              ×
                            </button>
                            <strong> {{ feedback.message }} </strong>
                          </div>
                        </div>
                        <div class="input-group mb-4">
                          <span class="input-group-addon"
                            ><svg
                              class="svg-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <g fill="none">
                                <path d="M0 0h24v24H0V0z" />
                                <path d="M0 0h24v24H0V0z" opacity=".87" />
                              </g>
                              <path
                                d="M6 20h12V10H6v10zm6-7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
                                opacity=".3"
                              />
                              <path
                                d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
                              /></svg
                          ></span>
                          <input
                            type="password"
                            v-model="password"
                            class="form-control"
                            :placeholder="$t('newPassword')"
                          />
                        </div>
                        <div class="input-group mb-4">
                          <span class="input-group-addon"
                            ><svg
                              class="svg-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <g fill="none">
                                <path d="M0 0h24v24H0V0z" />
                                <path d="M0 0h24v24H0V0z" opacity=".87" />
                              </g>
                              <path
                                d="M6 20h12V10H6v10zm6-7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
                                opacity=".3"
                              />
                              <path
                                d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
                              /></svg
                          ></span>
                          <input
                            type="password"
                            v-model="password_confirmation"
                            class="form-control"
                            :placeholder="$t('retypePassword')"
                          />
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <button
                              type="button"
                              @click="submitForm"
                              class="btn btn-lg btn-primary btn-block px-4"
                            >
                              <i class="fe fe-arrow-right"></i>
                              {{ $t("reset") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: null,
      feedback: {
        alert_type: null,
        field: null,
        message: null,
      },
      password: null,
      password_confirmation: null,
    };
  },
  created() {
    const url_token = this.$route.params.token;
    this.token = url_token.replace("-", "|");
  },
  methods: {
    async submitForm() {
      this.feedback.field = null;
      this.feedback.alert_type = null;
      this.feedback.message = null;

      if (
        this.password === null ||
        this.password_confirmation === null ||
        this.password.length < 8 ||
        this.password_confirmation.length < 0
      ) {
        this.feedback.alert_type = "error";
        this.feedback.field = "none";
        this.feedback.message = this.$t(
          "pleaseEnterPasswordMinLengthIs8Characters"
        );
        return;
      }

      if (this.password !== this.password_confirmation) {
        this.feedback.alert_type = "error";
        this.feedback.field = "none";
        this.feedback.message = this.$t("passwordsToNotMatch");
        return;
      }

      try {
        const response = await this.$store.dispatch("changePassword", {
          token: this.token,
          password_confirmation: this.password_confirmation,
          password: this.password,
        });

        if (response.status === 200) {
          this.$router.replace("/auth");
        }
      } catch (err) {
        this.feedback.field = "none";
        this.feedback.alert_type = "error";
        this.feedback.message = err.response.data.message;
      }
    },
  },
};
</script>
