const axios = require('axios').default;
export default {
    submitMaster(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/masters";
        let submit_data = payload;
        return axios({
            method: submit_method,
            url: uri,
            data: submit_data,
        });
    },

    getMasters(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = `/api/v1/masters/type/${payload.type}`;
        return axios({ method: submit_method, url: uri });
    },

    setMasters(context, payload) {
        context.commit("setMasters", payload);
    },

    setTypes(context, payload) {
        context.commit("setTypes", payload);
    },

    getMaster(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = `/api/v1/masters/${payload.id}`;
        return axios({ method: submit_method, url: uri });
    },

    updateMaster(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/masters/${payload.id}`;
        let submit_data = payload;
        return axios({
            method: submit_method,
            url: uri,
            data: submit_data,
        });
    },

    deleteMaster(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/masters/${payload}`;
        let submit_data = payload;
        return axios({
            method: submit_method,
            url: uri,
            data: submit_data,
        });
    },
};
