import { createApp } from "vue";
import axios from 'axios';
import VueSweetalert2 from "vue-sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import Dropdown from "primevue/dropdown";
import ColorPicker from 'primevue/colorpicker';
import Maska from "maska";
import ProductZoomer from "vue-product-zoomer";
import { createI18n } from "vue-i18n";
import PrimeVue from "primevue/config";
import Editor from "primevue/editor";
import "primeicons/primeicons.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/primevue.min.css";

import App from "./App.vue";

// import App from "./components/frontend/App.vue";

import store from "./store/index.js";
import router from "./router.js";
import en from "./locales/en.json";
import fr from "./locales/fr.json";
import Footer from "./components/frontend/partials/Footer.vue";
import Alerts from "./components/partials/Alerts.vue";
import ValidationErrors from "./components/partials/ValidationErrors.vue";
import TheAside from "./components/partials/TheAside.vue";
import TheMenus from "./components/partials/aside/TheMenus.vue";
import TheTopbar from "./components/partials/TheTopbar.vue";
import TheFooter from "./components/partials/TheFooter.vue";
import Datatable from "./components/presentation/Datatable.vue";
import Pagination from "./components/presentation/Pagination.vue";
import TheLoader from "./components/partials/TheLoader.vue";
import BaseDialog from "./components/clients/partials/BaseDialog.vue";
import DenyDialog from "./components/partials/DenyDialog.vue";
import AttributeDialog from "./components/products_catalog/partials/AttributeDialog.vue";

const i18n = createI18n({
    locale: store.getters.locale,
    fallbackLocale: "en",
    messages: { en, fr }
});

const app = createApp(App);
app.use(router);
app.use(store);
app.config.globalProperties.$axios = axios;
app.use(VueSweetalert2);
app.use(PrimeVue);
app.use(CKEditor);
app.use(ProductZoomer);
app.use(Maska);
app.use(i18n);
axios.defaults.baseURL = 'https://csv.jithvar.com/';
app.config.globalProperties.$baseURL = "https://csv.jithvar.com/";
// axios.defaults.baseURL = 'http://csv.bar:8080/';
// app.config.globalProperties.$baseURL = "http://csv.bar:8080/";
app.config.globalProperties.$axios = axios;
app.component("frontend-footer", Footer);
app.component("prime-dropdown", Dropdown);
app.component("prime-editor", Editor);
app.component("prime-color-picker", ColorPicker);
app.component("alert", Alerts);
app.component("validation-errors", ValidationErrors);
app.component("the-aside", TheAside);
app.component("the-topbar", TheTopbar);
app.component("the-menus", TheMenus);
app.component("the-footer", TheFooter);
app.component("datatable", Datatable);
app.component("pagination", Pagination);
app.component("loader", TheLoader);
app.component("base-dialog", BaseDialog);
app.component("deny-dialog", DenyDialog);
app.component("attribute-dialog", AttributeDialog);

app.mount("#app");
