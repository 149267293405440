const axios = require('axios').default;

export default {
    submitUser(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/users";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    getUsers(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = "/api/v1/users";
        return axios({ method: submit_method, url: uri });
    },
    getUser(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = `/api/v1/users/${payload.id}`;
        return axios({ method: submit_method, url: uri });
    },
    setUsers(context, payload) {
        context.commit("setUsers", payload);
    },

    updateUser(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = `/api/v1/users/${payload.get("id")}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    deleteUser(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/users/${payload}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    submitDeposit(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/user-cash";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    updateDeposit(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = `/api/v1/user-cash/${payload.get("id")}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    deleteCollection(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/user-cash/${payload}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    approveCollection(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/user-cash/approve/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    denyCollection(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/user-cash/deny/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    }
};
