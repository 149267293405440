<template>
  <div id="global-loader">
    <img src="/assets/images/svgs/loader.svg" alt="loader" />
  </div>

  <div class="switcher-wrapper" v-if="cart.length > 0 && !isCart && !isPreview">
    <div class="demo_changer" :class="{
      'active': this.cartIsActive,
      'inactive': !this.cartIsActive
    }">
      <div class="demo-icon">
        <a @click="handleCartHandler" class="bg-success">
          <i class="fa fa-shopping-cart"></i>
          <span class="cart-counter" v-if="cart.length > 0">{{
              cart.length
          }}</span>
        </a>
      </div>
      <div class="form_holder sidebar-right1" v-if="cart.length > 0">
        <div class="row">
          <div class="mt-2 col-12" v-for="item in cart" :key="item.id">
            <div class="d-flex align-items-center justify-content-between p-2 mb-2">
              <div>
                <img :src="getFirstImageLink(item)" alt="img" title="" class="w-8 h-8" />
              </div>
              <div class="wrapper ml-sm-3 mt-4 mt-sm-0">
                <p class="mb-0 mt-1 text-dark font-weight-semibold">
                  {{ item.name.substring(0, 20) }}
                </p>
                <small class="text-muted">
                  {{
                      item.currency.symbol
                        ? item.currency.symbol
                        : item.currency.code
                  }}

                  {{ item.price }}
                </small>
              </div>
              <div class="float-sm-right ml-auto mt-4 mt-sm-0">
                <span @click="removeFromCart(item)" class="text-danger badgetext">
                  <i class="text-danger fa fa-trash pointer" data-toggle="tooltip" title="fa fa-trash"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 mt-5">
            <div class="row">
              <div class="col-sm-6">
                <strong>{{ $t("total") }}</strong>
              </div>
              <div class="col-sm-6 text-right">
                <span>
                  <span v-if="loggedUser.company">
                    {{ loggedUser.company.currency.symbol }}
                  </span>
                  {{ cartTotal.toFixed(2) }}</span><br />
              </div>
            </div>
          </div>
          <div class="col-12 mt-5">
            <router-link to="/catalog/cart">
              <a class="btn btn-success btn-block">{{ $t("goToCart") }} </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page" v-if="isLoggedIn">
    <div id="app">
      <div class="page-main">
        <div class="app-sidebar app-sidebar2">
          <div class="app-sidebar__logo">
            <router-link to="/dashboard">
              <a class="header-brand">
                <!-- <span class="text-white" style="font-size: 24px">CSV</span> -->
                <img src="/assets/images/logo.png" alt="Logo" class="width-100" />
              </a>
            </router-link>
          </div>
        </div>
        <the-aside></the-aside>
        <div class="app-content main-content">
          <div class="side-app">
            <the-topbar></the-topbar>
            <router-view :key="$route.path"></router-view>
          </div>
        </div>
      </div>
      <the-footer></the-footer>
    </div>
    <!-- End Page -->
    <!-- Back to top -->
    <a href="#top" id="back-to-top">
      <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
      </svg>
    </a>
  </div>
  <router-view v-else></router-view>
</template>

<script>
import TheAside from "./components/partials/TheAside.vue";
import showErrorMixin from "./mixins/showError";
export default {
  mixins: [showErrorMixin],
  components: { TheAside },
  data() {
    return {
      cartIsActive: false
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    token() {
      return this.$store.getters.token;
    },
    isCatalog() {
      return this.$route.name === "catalog";
    },
    isCart() {
      return this.$route.name === "cart";
    },
    isPreview() {
      return this.$route.name === "orderPreview";
    },
    cart() {
      return this.$store.getters["catalog/cart"];
    },
    cartTotal() {
      return this.$store.getters["catalog/cartTotal"];
    },
  },

  methods: {
    handleCartHandler() {
      this.cartIsActive = !this.cartIsActive;
    },
    getFirstImageLink(product) {
      if (product.photos && product.photos.length > 0) {
        return `${this.$baseURL}storage/${product.photos[0].file_path}`;
      }
      return "/images/no-image.png";
    },
    removeFromCart(product) {
      this.$store.dispatch("catalog/removeFromCart", product);
    },
  },
};
</script>

<style scoped>
.badgetext {
  cursor: pointer;
}

.active {
  right: 0px;
}

.inactive {
  right: -355px;
}

.width-100 {
  width: 100px
}
</style>