const axios = require('axios').default;
export default {
    // getProducts(context) {
    //     axios.defaults.headers.common["Authorization"] =
    //         "Bearer " + context.rootGetters.token;

    //     let submit_method = "POST";
    //     let uri = "/api/v1/paginated-products";
    //     return axios({ method: submit_method, url: uri });
    // },

    submitProduct(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = "/api/v1/products";
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    getProduct(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "GET";
        let uri = `/api/v1/products/${payload.id}`;
        return axios({ method: submit_method, url: uri });
    },

    updateProduct(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "POST";
        let uri = `/api/v1/products/${payload.get("id")}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    updateStatus(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "PUT";
        let uri = `/api/v1/products/status/${payload.id}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },

    deleteProduct(context, payload) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootGetters.token;

        let submit_method = "DELETE";
        let uri = `/api/v1/products/${payload}`;
        let submit_data = payload;
        return axios({ method: submit_method, url: uri, data: submit_data });
    },
};
