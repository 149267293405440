<template>
  <div class="page-header" v-if="Object.keys(client).length > 0">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("clientProfile") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/clients">
            <a>{{ $t("clients") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("view") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="Object.keys(client).length > 0 && !loading">
    <div class="col-xl-4 col-lg-4 col-md-12">
      <div class="card box-widget widget-user">
        <div class="widget-user-image mx-auto mt-5">
          <div class="user-pic">
            <img
              :src="imgLink"
              alt="user-img"
              class="avatar-xl rounded-circle mb-1"
            />
          </div>
        </div>
        <div class="card-body text-center">
          <div class="pro-user">
            <h4 class="pro-user-username text-dark font-weight-bold">
              {{ client.first_name }} {{ client.last_name }}
              <i
                v-if="client.is_premium"
                class="fa fa-diamond text-success"
                data-toggle="tooltip"
                title=""
                data-original-title="fa fa-diamond"
              ></i>
            </h4>
            <router-link :to="`/clients/${client.id}/edit`">
              <a class="btn btn-primary btn-sm mt-3">
                <i
                  class="fa fa-pencil pointer"
                  data-toggle="tooltip"
                  title="fa fa-trash"
                ></i>
              </a>
            </router-link>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">{{ $t("personalDetails") }}</h4>
          <div class="table-responsive">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("name") }}
                    </span>
                  </td>
                  <td class="py-2 px-0 text-right">
                    {{ client.first_name }} {{ client.last_name }}
                  </td>
                </tr>
                <!-- <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("credit") }}
                    </span>
                  </td>
                  <td class="py-2 px-0 text-right"> {{ loggedUser.company.currency.symbol}} {{ client.balance }}</td>
                </tr> -->
                <tr v-if="!!client.company_name">
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("company") }}
                    </span>
                  </td>
                  <td class="py-2 px-0 text-right">
                    {{ client.company_name }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("level") }}
                    </span>
                  </td>
                  <td class="py-2 px-0 text-right">
                    {{ clientLevel }}
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("email") }}
                    </span>
                  </td>
                  <td class="py-2 px-0 text-right">
                    <a :href="getMailLink(client.email)" target="_blank">{{
                      client.email
                    }}</a>
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("phone") }}
                    </span>
                  </td>
                  <td class="py-2 px-0 text-right">
                    <a :href="getPhoneLink(client.mobile)" target="_blank">{{
                      client.mobile
                    }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12">
      <div class="main-content-body main-content-body-profile card mg-b-20">
        <div class="main-profile-body">
          <div class="card-header">
            <div class="card-options">
              <div class="btn-group">
                <button
                  type="button"
                  @click="markAsHandler"
                  class="btn btn-sm mr-2"
                  :class="{
                    'btn-success': client.is_premium === 0,
                    'btn-light': client.is_premium === 1,
                  }"
                >
                  {{ $t("markAs") }} {{ newClientLevel }}
                </button>
                <router-link :to="`/invoices/${client.id}`">
                  <a class="btn btn-sm btn-primary mr-2">{{
                    $t("viewInvoices")
                  }}</a>
                </router-link>
                <router-link to="/clients/create">
                  <a class="btn btn-sm btn-primary mr-2">{{ $t("addNew") }}</a>
                </router-link>
                <router-link :to="`/clients/${client.id}/edit`">
                  <a class="btn btn-sm btn-primary">
                    <i
                      class="fa fa-pencil pointer"
                      data-toggle="tooltip"
                      title="fa fa-trash"
                    ></i>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="panel panel-primary">
              <div class="tab-menu-heading p-0 bg-light">
                <div class="tabs-menu1">
                  <ul id="panel-tab-ul" class="nav panel-tabs">
                    <li class="">
                      <a href="#orders-tab" class="active" data-toggle="tab">{{
                        $t("orders")
                      }}</a>
                    </li>
                    <li>
                      <a href="#debts-tab" data-toggle="tab">{{
                        $t("debts")
                      }}</a>
                    </li>
                    <li>
                      <a href="#documents-tab" data-toggle="tab">{{
                        $t("documents")
                      }}</a>
                    </li>
                    <li>
                      <a href="#shipping-addresses-tab" data-toggle="tab">{{
                        $t("shippingAddress")
                      }}</a>
                    </li>
                    <li>
                      <a href="#billing-addresses-tab" data-toggle="tab">{{
                        $t("billingAddress")
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="panel-body tabs-menu-body">
                <div class="tab-content">
                  <div class="tab-pane active" id="orders-tab">
                    <orders :id="id"></orders>
                  </div>
                  <div class="tab-pane" id="debts-tab">
                    <orders :id="id" :is-debt="true"></orders>
                  </div>
                  <div class="tab-pane" id="documents-tab">
                    <div class="row">
                      <div
                        class="col-lg-12"
                        v-for="document in client.documents"
                        :key="document.id"
                      >
                        <div
                          class="
                            d-flex
                            align-items-center
                            border
                            p-3
                            mb-3
                            br-7
                            justify-content-between
                          "
                        >
                          <span>
                            {{ document.original_name }}
                          </span>
                          <span>
                            <a
                              :href="docLink(document)"
                              class="btn btn-sm btn-success"
                              download
                            >
                              <i
                                class="fa fa-download"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="fa fa-download"
                              ></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="shipping-addresses-tab">
                    <div
                      class="row"
                      v-if="
                        client.shipping_addresses &&
                        client.shipping_addresses.length > 0
                      "
                    >
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table
                            class="
                              table
                              card-table
                              table-vcenter
                              text-nowrap
                              mb-0
                            "
                          >
                            <thead>
                              <tr>
                                <th>{{ $t("street") }}</th>
                                <th>{{ $t("zipCode") }}</th>
                                <th>{{ $t("locality") }}</th>
                                <th>{{ $t("city") }}</th>
                                <th>{{ $t("state") }}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="address in client.shipping_addresses"
                                :key="address"
                              >
                                <td>
                                  {{
                                    address.street_address
                                      ? address.street_address
                                      : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                    address.zip_code ? address.zip_code : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                    address.locality ? address.locality : "-"
                                  }}
                                </td>
                                <td>
                                  {{ address.city ? address.city : "-" }}
                                </td>
                                <td>
                                  {{ address.state ? address.state : "-" }}
                                </td>
                                <td>
                                  <span
                                    class="badge badge-success"
                                    v-if="address.is_default"
                                  >
                                    {{ $t("default") }}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="billing-addresses-tab">
                    <div
                      class="row"
                      v-if="
                        client.billing_addresses &&
                        client.billing_addresses.length > 0
                      "
                    >
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table
                            class="
                              table
                              card-table
                              table-vcenter
                              text-nowrap
                              mb-0
                            "
                          >
                            <thead>
                              <tr>
                                <th>{{ $t("street") }}</th>
                                <th>{{ $t("zipCode") }}</th>
                                <th>{{ $t("locality") }}</th>
                                <th>{{ $t("city") }}</th>
                                <th>{{ $t("state") }}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="address in client.billing_addresses"
                                :key="address"
                              >
                                <td>
                                  {{
                                    address.street_address
                                      ? address.street_address
                                      : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                    address.zip_code ? address.zip_code : "-"
                                  }}
                                </td>
                                <td>
                                  {{
                                    address.locality ? address.locality : "-"
                                  }}
                                </td>
                                <td>
                                  {{ address.city ? address.city : "-" }}
                                </td>
                                <td>
                                  {{ address.state ? address.state : "-" }}
                                </td>
                                <td>
                                  <span
                                    class="badge badge-success"
                                    v-if="address.is_default"
                                  >
                                    {{ $t("default") }}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkToken from "../../mixins/checkToken";
import contact from "../../mixins/contact";
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import Orders from "./partials/Orders.vue";
import catchForbiddenError from "../../mixins/catchForbiddenError";
export default {
  mixins: [
    checkToken,
    contact,
    showErrorMixin,
    notifyMixin,
    catchForbiddenError,
  ],
  components: {
    Orders,
  },
  props: ["id"],
  data() {
    return {
      client: {},
      loading: true,
    };
  },
  created() {
    this.getClient();
  },
  mounted() {
    if (this.client && Object.keys(this.client).length > 0) {
      this.resetActiveClass();
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    imgLink() {
      if (this.client.photo) {
        return `${this.$baseURL}storage/${this.client.photo.file_path}`;
      }
      return "/assets/images/users/16.jpg";
    },
    clientLevel() {
      if (this.client.is_premium) {
        return this.$t("premium");
      }
      return this.$t("normal");
    },
    newClientLevel() {
      if (this.client.is_premium) {
        return this.$t("normal");
      }
      return this.$t("premium");
    },
  },
  methods: {
    resetActiveClass() {
      // Reset the active class of the tab links to be the first one
      const panelTabs = document.getElementById("panel-tab-ul");
      const listItems = panelTabs.getElementsByTagName("li");

      for (let i = 0; i <= listItems.length - 1; i++) {
        let item = listItems[i];
        const link = item.querySelector("a");
        if (i === 0) {
          if (!link.classList.contains("active")) {
            link.classList.add("active");
          }
        } else {
          if (link.classList.contains("active")) {
            link.classList.remove("active");
          }
        }
      }
    },
    async getClient() {
      this.loading = true;
      try {
        const response = await this.$store.dispatch("clients/getClient", {
          id: this.id,
        });
        if (response.status === 200) {
          this.client = response.data;
        }
        this.loading = false;
      } catch (err) {
        console.log("error");
        this.catchForbiddenError(err);
        this.loading = false;
      }
    },
    docLink(document) {
      if (document.file_path) {
        return `${this.$baseURL}storage/${document.file_path}`;
      }
      return "#";
    },
    async markAsHandler() {
      const data = new FormData();
      data.append("id", this.id);
      data.append("is_premium", this.client.is_premium === 0 ? 1 : 0);
      data.append("_method", "PUT");

      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        icon: "warning",
        text: this.$t("markThisClientAs") + " " + this.newClientLevel + "?",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("yes"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch(
            "clients/updateClientLevel",
            data
          );
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.getClient();
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },
  },
};
</script>