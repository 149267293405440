export default {
    masters(state) {
        return state.masters;
    },
    types(state) {
        return state.types;
    },
    titles(state) {
        return state.masters.filter((master) => {
            return master.type === "title";
        });
    },
    lead_source(state) {
        return state.masters.filter((master) => {
            return master.type === "lead_source";
        });
    },
    industry(state) {
        return state.masters.filter((master) => {
            return master.type === "industry";
        });
    },
    lead_status(state) {
        return state.masters.filter((master) => {
            return master.type === "lead_status";
        });
    },
    rating(state) {
        return state.masters.filter((master) => {
            return master.type === "rating";
        });
    },
    attributes(state) {
        return state.masters.filter((master) => {
            return master.type === "attribute";
        });
    },
};
