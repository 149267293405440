<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("categoriesDetails") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/categories">
            <a>{{ $t("categories") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("view") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="Object.keys(category).length > 0 && !loading">
    <div class="col-xl-6 col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">{{ $t("details") }}</h3>
          <div class="card-options">
            <div class="btn-group">
              <router-link :to="`/categories/${category.id}/edit`">
                <a class="btn btn-sm btn-primary">
                  <i
                    class="fa fa-pencil pointer"
                    data-toggle="tooltip"
                    title="fa fa-trash"
                  ></i>
                </a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("name") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">{{ category.full_name }}</td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50"
                      >{{ $t("parent") }}
                    </span>
                  </td>
                  <td class="py-2 px-0">
                    <span>{{
                      category.parent ? category.parent.name : ""
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50">{{
                      $t("code")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                    <span>{{ category.code }}</span>
                  </td>
                </tr>
                <tr v-if="!category.updated">
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50">{{
                      $t("createdBy")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                    <span>{{
                      category.creator
                        ? category.creator.first_name +
                          " " +
                          category.creator.last_name
                        : ""
                    }}</span>
                  </td>
                </tr>
                <tr v-else>
                  <td class="py-2 px-0">
                    <span class="font-weight-semibold w-50">{{
                      $t("updatedBy")
                    }}</span>
                  </td>
                  <td class="py-2 px-0">
                    <span>{{
                      category.updater
                        ? category.updater.first_name +
                          " " +
                          category.updater.last_name
                        : ""
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-xl-4 col-lg-4 col-md-12"
      v-if="category.attributes.length > 0"
    >
      <div class="main-content-body main-content-body-profile card mg-b-20">
        <div class="main-profile-body">
          <div class="tab-content">
            <div class="tab-pane show active" id="about">
              <div class="card-body">
                <h5 class="font-weight-bold">{{ $t("attributes") }}</h5>
                <div class="main-profile-bio mb-0">
                  <table class="table mb-0">
                    <tbody>
                      <tr
                        v-for="attribute in category.attributes"
                        :key="attribute.id"
                      >
                        <td class="py-2 px-0">
                          <span class="font-weight-semibold w-50">{{
                            attribute.name
                          }}</span>
                        </td>
                        <td class="py-2 px-0">
                          <span v-if="!isColor(attribute.name)">
                            {{ attribute.pivot.value }}
                            {{
                              attribute.pivot.unit_name
                                ? attribute.pivot.unit_name
                                : ""
                            }}
                          </span>
                          <span v-else :style="getStyle(attribute)">
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import catchForbiddenError from "../../mixins/catchForbiddenError";
import checkToken from "../../mixins/checkToken";
import showError from "../../mixins/showError";
export default {
  mixins: [checkToken, catchForbiddenError, showError],
  props: ["id"],
  data() {
    return {
      category: {},
      loading: true,
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    async getCategory() {
      try {
        const response = await this.$store.dispatch("categories/getCategory", {
          id: this.id,
        });
        if (response.status === 200) {
          this.category = response.data;
          this.loading = false;
        }
      } catch (err) {
        console.log("error");
        this.catchForbiddenError(err);
        this.loading = false;
      }
    },
    isColor(name) {
      return (
        name === "Color" ||
        name === "color" ||
        name === "colour" ||
        name === "Colour"
      );
    },
       getHexCode(attribute) {
      var formatedColorCode = attribute.value.substr(
        attribute.value.length - 6
      );
      return formatedColorCode;
    },
    getStyle(attribute) {
      return {
        background: `#${this.getHexCode(attribute.pivot)}`,
        height: "10px",
        width: "30px",
        display: "inline-block",
      };
    },
  },
};
</script>

<style scoped>
i {
  width: 26px;
}
</style>