<template>
  <li class="slide">
    <router-link to="/dashboard">
      <a class="side-menu__item" data-toggle="">
        <i class="fa fa-home" data-toggle="tooltip" title="fa fa-home"></i>
        <span class="side-menu__label ml-3">Dashboard</span></a
      >
    </router-link>
  </li>
  <li class="slide">
    <router-link to="/categories">
      <a class="side-menu__item" data-toggle="">
        <i
          class="fa fa-th"
          data-toggle="tooltip"
          title=""
          data-original-title="fa fa-th"
        ></i>
        <span class="side-menu__label ml-3">Categories</span></a
      >
    </router-link>
  </li>
  <li class="slide">
    <a class="side-menu__item" data-toggle="slide" href="#">
      <i
        class="fa fa-group"
        data-toggle="tooltip"
        title=""
        data-original-title="fa fa-group"
      ></i>
      <span class="side-menu__label ml-3">User management</span
      ><i class="angle fa fa-angle-right"></i
    ></a>
    <ul class="slide-menu">
      <li>
        <router-link to="/users">
          <a class="slide-item" data-toggle="">Users </a>
        </router-link>
      </li>
      <li>
        <router-link to="/roles">
          <a class="slide-item" data-toggle="">Roles</a>
        </router-link>
      </li>
    </ul>
  </li>
  <li class="slide">
    <a class="side-menu__item" data-toggle="slide" href="#">
      <i
        class="fa fa-folder-o"
        data-toggle="tooltip"
        title=""
        data-original-title="fa fa-folder-o"
      ></i>

      <span class="side-menu__label ml-3">Masters</span
      ><i class="angle fa fa-angle-right"></i
    ></a>
    <ul class="slide-menu">
      <li>
        <router-link to="/masters/all">
          <a class="slide-item" data-toggle="">Masters </a>
        </router-link>
      </li>
      <li>
        <router-link to="/countries">
          <a class="slide-item" data-toggle="">Countries</a>
        </router-link>
      </li>
      <li>
        <router-link to="/states">
          <a class="slide-item" data-toggle="">States</a>
        </router-link>
      </li>
      <li>
        <router-link to="/companies">
          <a class="slide-item" data-toggle="">Companies</a>
        </router-link>
      </li>
    </ul>
  </li>
  <li class="slide">
    <a
      class="side-menu__item"
      data-toggle="slide"
      href="#"
      @click.prevent="toggleExpanded"
    >
      <svg
        class="side-menu__icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path
          d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
        ></path>
        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
        <line x1="12" y1="22.08" x2="12" y2="12"></line>
      </svg>
      <span class="side-menu__label">Widgets</span
      ><i class="angle fa fa-angle-right"></i
    ></a>
    <ul class="slide-menu">
      <li><a href="widgets-1" class="slide-item">Widgets</a></li>
      <li><a href="widgets-2" class="slide-item">Chart Widgets</a></li>
    </ul>
  </li>
</template>




