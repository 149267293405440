import { createStore } from "vuex";

import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

import roleModule from "./roles/index.js";
import countriesModule from "./countries/index.js";
import usersModule from "./users/index.js";
import mastersModule from "./masters/index.js";
import leadsModule from "./leads/index.js";
import statesModule from "./states/index.js";
import companiesModule from "./companies/index.js";
import categoriesModule from "./categories/index.js";
import brandsModule from "./brands/index.js";
import attributeTypesModule from "./attribute_types/index.js";
import productsModule from "./products/index.js";
import currenciesModule from "./currencies/index.js";
import clientsModule from "./clients/index.js";
import catalogModule from "./catalog/index.js";
import pagesModule from "./pages/index.js";
import blogsModule from "./frontend/blogs/index.js";

const store = createStore({
    modules: {
        roles: roleModule,
        countries: countriesModule,
        users: usersModule,
        masters: mastersModule,
        leads: leadsModule,
        states: statesModule,
        companies: companiesModule,
        categories: categoriesModule,
        brands: brandsModule,
        attributeTypes: attributeTypesModule,
        products: productsModule,
        currencies: currenciesModule,
        clients: clientsModule,
        catalog: catalogModule,
        pages: pagesModule,
        blogs: blogsModule,
    },
    state() {
        return {
            token: localStorage.getItem("access_token") || null,
            locale: localStorage.getItem("locale") || "en",
            user: JSON.parse(localStorage.getItem("user")) || {},
            email: "",
        };
    },
    mutations,
    actions,
    getters,
});

export default store;
