<template>
  <table
    class="table table-bordered text-nowrap dataTable no-footer"
    role="grid"
    aria-describedby="example1_info"
  >
    <thead>
      <tr role="row">
        <th
          v-for="column in columns"
          :key="column.name"
          :class="[sortClass(column), {
            'text-right': column.right
          }]"
          @click="sort(column)"
          class="wd-15p border-bottom-0"
          tabindex="0"
          aria-controls="example1"
          rowspan="1"
          colspan="1"
          aria-label="First name: activate to sort column descending"
          aria-sort="ascending"
        >
          {{ column.label }}
        </th>
      </tr>
      <tr id="search-row" role="row" v-if="tableIsSearchable">
        <th
          v-for="column in columns"
          :key="column.name"
          class="wd-15p border-bottom-0"
        >
          <input
            :data-searchField="column.name"
            @input="searchField($event, column)"
            v-if="column.searchable"
            type="text"
            class="form-control"
            placeholder="Search"
          />
        </th>
      </tr>
    </thead>
    <slot></slot>
  </table>
</template>

<script>
import _ from "lodash";
export default {
  props: ["columns", "sortKey", "sortOrders", "tableIsSearchable"],
  data() {
    return {
      value: "",
      field: "",
      relationship: false,
      relationship_field: "",
    };
  },
  computed: {},
  methods: {
    sort(column) {
      if (column.sortable) {
        this.$emit("sort", column.name);
      }
    },
    sortClass(column) {
      let class_name;
      if (column.sortable) {
        if (this.sortKey === column.name) {
          if (this.sortOrders[column.name] > 0) {
            class_name = "sorting_asc";
          } else {
            class_name = "sorting_desc";
          }
        } else {
          class_name = "sorting";
        }
      } else {
        class_name = "";
      }
      return class_name;
    },
    search: _.debounce(function () {
      this.$emit("search", this.value, this.field, this.relationship, this.relationship_field);
    }, 500),
    searchField(event, column) {
      this.relationship = false;
      this.relationship_field = "";
      if(column.relationship) {
        this.relationship = true;
        this.relationship_field = column.relationship_field;
      }
      const field = event.target;
      this.value = field.value;
      this.field = field.getAttribute("data-searchField");
      const row = document.getElementById("search-row"); 
      let inputFields = row.getElementsByTagName("input");
      for (const element of inputFields) {
        if (element.getAttribute("data-searchField") !== this.field) { // Clear other input fields in the row
          element.value = "";
        }
      }
      this.search();
    },
  },
};
</script>