
<template>
  <manage :id="id" :from="from"></manage>
</template>

<script>
import Manage from "./Manage.vue";
export default {
  components: {
    Manage,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    from: {
      type: String,
      required: false,
    },
  },
};
</script>