<template>
  <div class="page-header" v-if="editFormIsSetUp">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("pages") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/pages">
            <a>{{ $t("pages") }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ submitBtnText }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" id="lead-div" v-if="editFormIsSetUp && !loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{ typeOfPage }} {{ $t("page") }}</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="submitBlogHandler">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("cover") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <cover-uploader
                      @loaded="updateCover"
                      :cover="currentCover"
                    ></cover-uploader>
                  </div>
                  <p
                    v-if="findFieldinFeedback('cover') && form.cover === ''"
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                  <p v-if="errors.title" class="text-danger">
                    {{ errors.title[0] }}
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("title") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <input
                      :class="{
                        'border-red':
                          findFieldinFeedback('title') && form.title === '',
                      }"
                      v-model="form.title"
                      type="text"
                      class="form-control"
                      :placeholder="$t('title')"
                    />
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('title') && form.title.trim() === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                  <p v-if="errors.title" class="text-danger">
                    {{ errors.title[0] }}
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("category") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <prime-dropdown
                      v-model="form.categoryId"
                      :options="categories"
                      optionLabel="name"
                      optionValue="id"
                      :filter="true"
                      :placeholder="$t('selectCategory')"
                      :class="{
                        'border-red':
                          findFieldinFeedback('categoryId') &&
                          form.categoryId === '',
                      }"
                    >
                      <template #label="slotProps">
                        <div
                          class="country-item country-item-value"
                          v-if="slotProps.label"
                        >
                          <div>{{ slotProps }}</div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="country-item">
                          <div>{{ slotProps.option.name }}</div>
                        </div>
                      </template>
                    </prime-dropdown>
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('categoryId') &&
                      form.categoryId === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label"
                    >{{ $t("content") }} <span>*</span></label
                  >
                  <div class="input-group">
                    <prime-editor
                      v-model="form.content"
                      editorStyle="height: 320px"
                    ></prime-editor>
                  </div>
                  <p
                    v-if="
                      findFieldinFeedback('content') &&
                      form.content.trim() === ''
                    "
                    class="text-danger"
                  >
                    {{ $t("thisFieldIsRequired") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :class="{
                    'btn-loading': is_sending,
                  }"
                  :disabled="is_sending"
                >
                  {{ submitBtnText }}
                </button>
                <router-link
                  to="/cms-blogs"
                  class="btn btn-sm btn-light ml-1"
                >
                  Cancel
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clearFormMixin from "../../mixins/clearForm";
import showErrorMixin from "../../mixins/showError";
import setFeedbackMixin from "../../mixins/setFeedback";
import notifyMixin from "../../mixins/notify";
import catchForbiddenError from "../../mixins/catchForbiddenError";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ckEditorConfig from "../../mixins/cKEditorConfig";
import CoverUploader from "./CoverUploader";

import _ from "lodash";

export default {
  components: { CoverUploader },
  mixins: [
    clearFormMixin,
    showErrorMixin,
    setFeedbackMixin,
    notifyMixin,
    catchForbiddenError,
    ckEditorConfig,
  ],
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      is_sending: false,
      errors: [],
      feedback: [],
      form: {
        title: "",
        link: "",
        content: "",
        categoryId: "",
        cover: "",
      },
      categories: [],
      mode: "",
      loading: false,
      editLinkManually: false,
      currentCover: "",
    };
  },
  created() {
    this.getCategories();
    if (typeof this.id !== "undefined") {
      this.getBlog();
    }
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    title() {
      return this.form.title;
    },
    editFormIsSetUp() {
      if (typeof this.id !== "undefined" && this.form.first_name === "") {
        return false;
      }
      return true;
    },
    submitBtnText() {
      if (this.mode === "edit" && this.is_sending) {
        return this.$t("updating");
      }
      if (this.mode === "edit") {
        return this.$t("update");
      }
      if (this.is_sending) {
        return this.$t("submitting");
      }
      return this.$t("submit");
    },
    typeOfPage() {
      if (this.mode === "edit") {
        return this.$t("update");
      }
      return this.$t("new");
    },
  },
  watch: {
    title: function (newValue) {
      if (!this.editLinkManually) {
        this.form.link = _.kebabCase(newValue);
      }
    },
  },
  methods: {
    async getCategories() {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      try {
        const response = await this.$axios({
          method: "GET",
          url: `/api/v1/blog-categories`,
        });
        if (response.status === 200) {
          this.categories = response.data.data;
          if (this.categories.length > 0 && !this.mode !== "edit") {
            this.form.categoryId = this.categories[0]["id"];
          }
        }
      } catch (error) {
        console.log("Error");
      }
    },
    updateCover(cover) {
      this.form.cover = cover;
    },
    async getBlog() {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      try {
        const response = await this.$axios({
          method: "GET",
          url: `/api/v1/blogs/${this.id}`,
        });
        this.loading = false;
        if (response.status === 200) {
          this.setUpForm(response.data);
        }
      } catch (err) {
        console.log("error");
        this.loading = false;
      }
    },
    async submitBlogHandler() {
      this.feedback = [];

      if (!this.validate()) {
        return;
      }

      this.is_sending = true;
      console.log("this.form");

      try {
        if (this.mode === "edit") {
          this.form.id = this.id;
          const response = await this.$axios({
            method: "PUT",
            url: `/api/v1/blogs/${this.id}`,
            data: this.form,
          });
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.$router.replace("/cms-blogs");
          }
        } else {
          const response = await this.$axios({
            method: "POST",
            url: "/api/v1/blogs",
            data: this.form,
          });
          this.is_sending = false;
          if (response.status === 201) {
            this.notify(this.$t("saved"));
            this.$router.replace("/cms-blogs");
          }
        }
      } catch (err) {
        console.log("err");
        this.is_sending = false;
        if (!!err.response && err.response.status === 422) {
          this.errors = err.response.data.errors;
          const first_error = this.errors[Object.keys(this.errors)[0]][0];
          this.showError(first_error);
        } else {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    validate() {
      this.feedback = [];
      if (this.form.cover === "" && this.mode !== "edit") {
        this.setFeedback("error", "cover");
      }
      if (this.form.title === "") {
        this.setFeedback("error", "title");
      }
      if (this.form.categoryId === "") {
        this.setFeedback("error", "categoryId");
      }
      if (this.form.content === "") {
        this.setFeedback("error", "content");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },

    setUpForm(blog) {
      this.clearForm();
      this.form.title = blog.title;
      this.form.content = blog.content;
      this.form.categoryId = blog.categoryId;
      if (blog.cover) {
        this.currentCover = blog.cover.file_path;
      }
      this.mode = "edit";
    },

    clearForm() {
      this.form.title = "";
      this.form.content = "";
      this.form.categoryId = "";
      this.form.cover = "";
      this.mode = "";
    },
  },
};
</script>

<style>
.border-red {
  border: 1px solid red;
}
</style>