<template>
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title">{{ $t("users") }}</h4>
    </div>
    <div class="page-rightheader ml-auto d-lg-flex d-none">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">
            <a class="d-flex">
              <span class="breadcrumb-icon"> {{ $t("dashboard") }}</span></a
            >
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>{{ $t("users") }}</a>
        </li>
      </ol>
    </div>
  </div>

  <loader v-if="loading"></loader>

  <div class="row" v-if="!loading">
    <div class="col-12">
      <div class="card">
        <div class="card-header pr-0">
          <div class="row w-100">
            <div class="col-6 offset-6 text-right">
              <router-link to="/users/create">
                <a class="pull-right btn btn-sm btn-primary">{{
                  $t("addNew")
                }}</a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <div
                  id="example1_wrapper"
                  class="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="example1_length">
                        <label>
                          <select
                            v-model="tableData.length"
                            @change="getUsers()"
                            name="example1_length"
                            aria-controls="example1"
                            class="
                              custom-select custom-select-sm
                              form-control form-control-sm
                            "
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <datatable
                        :columns="columns"
                        :sortKey="sortKey"
                        :sortOrders="sortOrders"
                        :tableIsSearchable="tableIsSearchable"
                        @sort="sortBy"
                        @search="searchField"
                      >
                        <tbody>
                          <tr
                            role="row"
                            class="odd"
                            v-for="user in users"
                            :key="user.id"
                          >
                            <td>
                              <router-link :to="`/users/${user.id}`">
                                <a class="text-primary font-weight-bold fs-12"
                                  >{{ user.first_name }} {{ user.last_name }}</a
                                >
                              </router-link>
                            </td>
                            <td>
                              <a
                                :href="getPhoneLink(user.phone)"
                                target="_blank"
                                >{{ user.phone }}</a
                              >
                            </td>
                            <td>
                              <a
                                :href="getMailLink(user.email)"
                                target="_blank"
                                >{{ user.email }}</a
                              >
                            </td>
                            <td>
                              {{ user.their_role ? user.their_role.name : "" }}
                            </td>
                            <td>
                              <span
                                @click="updateStatus(user)"
                                :class="{
                                  'badge-success': user.active === 1,
                                  'badge-danger': user.active === 0,
                                }"
                                class="badge"
                              >
                                {{ fixStatus(user.active) }}
                              </span>
                            </td>
                            <td>
                              <span class="mr-2">
                                <router-link :to="`/users/${user.id}/edit`">
                                  <a
                                    ><i
                                      class="fa fa-pencil"
                                      data-toggle="tooltip"
                                      title="fa fa-pencil"
                                    ></i
                                  ></a>
                                </router-link>
                              </span>
                              <span>
                                <i
                                  @click="deleteUser(user.id)"
                                  class="text-danger fa fa-trash pointer"
                                  data-toggle="tooltip"
                                  title="fa fa-trash"
                                ></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </datatable>
                    </div>
                  </div>
                  <div class="row">
                    <pagination
                      :pagination="pagination"
                      @prev="getUsers(pagination.prevPageUrl)"
                      @next="getUsers(pagination.nextPageUrl)"
                    >
                    </pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import clearFormMixin from "../../mixins/clearForm";
import showErrorMixin from "../../mixins/showError";
import notifyMixin from "../../mixins/notify";
import setFeedbackMixin from "../../mixins/setFeedback";
import checkToken from "../../mixins/checkToken";
import contact from "../../mixins/contact";
import _ from "lodash";

export default {
  mixins: [
    clearFormMixin,
    showErrorMixin,
    notifyMixin,
    setFeedbackMixin,
    checkToken,
    contact,
  ],
  data() {
    let sortOrders = {};
    let columns = [
      {
        label: this.$t("name"),
        name: "first_name",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("mobile"),
        name: "phone",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("email"),
        name: "email",
        sortable: true,
        searchable: true,
        relationship: false,
      },
      {
        label: this.$t("role"),
        name: "theirRole",
        sortable: false,
        searchable: true,
        relationship: true,
        relationship_field: "name",
      },
      {
        label: this.$t("status"),
        name: "status",
        sortable: false,
        searchable: false,
        relationship: false,
      },
      {
        label: "",
        name: "actions",
        sortable: false,
        searchable: false,
        relationship: false,
      },
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1; // Descending sort order for default
    });
    sortOrders.first_name = 1;

    return {
      sortOrders: sortOrders,
      columns: columns,
      sortKey: "first_name",
      tableIsSearchable: true,
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        field: "",
        relationship: false,
        relationship_field: "",
        column: 0,
        dir: "asc",
      },

      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      users: [],

      is_sending: false,
      validation_errors: {},
      feedback: [],
      form: {
        title: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        phone: "",
        user_role: "",
        photo: null,
        force_pw_change: false,
      },

      id: null,
      mode: null,
      loading: true,
    };
  },
  created() {
    this.getUsers();
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    loggedUser() {
      return this.$store.getters.user;
    },
  },
  methods: {
    searchField(searchValue, searchField, relationship, relationship_field) {
      this.tableData.search = searchValue;
      this.tableData.field = searchField;
      this.tableData.relationship = relationship;
      this.tableData.relationship_field = relationship_field;

      this.getUsers();
    },
    search: _.debounce(function () {
      this.getUsers();
    }, 500),
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1; // For component to change class
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getUsers();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] === value);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },

    getUsers(url = "/api/v1/paginated-users") {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

      let submit_method = "POST";
      let submit_data = this.tableData;

      this.$axios({ method: submit_method, url: url, data: submit_data })
        .then((response) => {
          let data = response.data;
          this.loading = false;
          if (this.tableData.draw === data.draw) {
            this.users = data.users.data;
            this.configPagination(data.users);
          }
        })
        .catch(() => {
          console.log("Error");
          this.loading = false;
        });
    },

    async deleteUser(id) {
      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        icon: "warning",
        text: this.$t("youWontBeAbleToReverseThis"),
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("delete"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch("users/deleteUser", id);
          if (response.status === 204) {
            this.notify(this.$t("deleted"));
            this.getUsers();
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
        }
      }
    },

    fixStatus(status) {
      if (status === 1) {
        return this.$t("active");
      }
      return this.$t("inactive");
    },

    async updateStatus(user) {
      const data = new FormData();
      const active = user.active === 1 ? 0 : 1;

      data.append("id", user.id);
      data.append("active", active);
      data.append("_method", "PUT");

      let text;

      if (user.status === 1) {
        text = this.$t("doYouWantToInactivateThisUser");
      } else {
        text = this.$t("doYouWantToActivateThisUser");
      }

      const swal_response = await this.$swal.fire({
        title: this.$t("areYouSure"),
        text: text,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("update"),
      });

      if (swal_response.isConfirmed) {
        try {
          const response = await this.$store.dispatch("users/updateUser", data);
          if (response.status === 200) {
            this.notify(this.$t("updated"));
            this.getUsers();
            this.mode = null;
            this.id = null;
            this.clearForm();
          }
        } catch (err) {
          this.showError(this.$t("somethingWentWrong"));
          console.log("Error");
        }
      }
    },
  },
};
</script>

<style scoped>
i,
.badge {
  cursor: pointer;
}

.form-label a {
  text-decoration: underline;
}

.border-red {
  border: 1px solid red;
}
</style>